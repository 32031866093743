import React, { useContext, useState } from 'react';
import { BusFareDepartContext, BusFareReturnContext, LoyaltyDiscountContext, TotalBusFareContext, VoucherCodeContext, VoucherDiscountContext } from '../UserContext';
import { checkIsReschedule, getSelectedAddOnData, getToken, tokenIsValid } from './Function';

export const BusFareTotal = ({
    page
    }) => {
  let {TotalBusFare, setTotalBusFare} = useContext(TotalBusFareContext);
  const {BusFareAddOn, setBusFareAddOn} = useState(0);
  const {BusFareDepart, setBusFareDepart} = useContext(BusFareDepartContext);
  const {BusFareReturn, setBusFareReturn} = useContext(BusFareReturnContext);
  let {TotalLoyalty, setLoyalty} = useContext(LoyaltyDiscountContext);
  const { VoucherCode, setVoucherCode } = useContext(VoucherCodeContext);
  const { VoucherDiscount, setVoucherDiscount } = useContext(VoucherDiscountContext);
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  let oldcartguid = null;
  let origin_data = sessionStorage.getItem("Origin_data");
  if(origin_data!=null) {
    origin_data = JSON.parse(origin_data);
    oldcartguid = origin_data.OldCartGuid;
  }
  const checkReschedule = checkIsReschedule();
  const [rescheduleAmount, setRescheduleAmount] = useState(0);
  const addOnData = getSelectedAddOnData();
  // console.log(addOnData);
  TotalBusFare = BusFareDepart + BusFareReturn;
  // console.log(TotalBusFare);
  
  sessionStorage.setItem("busFare",JSON.stringify(TotalBusFare));
  if(page == 'payment' || page =='checkout') {
    // BusFareAddOn = JSON.parse(sessionStorage.getItem("addOn"));
    TotalBusFare = JSON.parse(sessionStorage.getItem("busFare"));
    TotalLoyalty = JSON.parse(localStorage.getItem("loyalty"));
    // console.log(TotalBusFare);
    
  }
  let total_addOn = 0;
  if(addOnData != null && addOnData.length != 0) {
    addOnData.forEach(element => {
      element.details.forEach(sub => {
        if(sub.subtotal != '') {
          total_addOn += parseFloat(sub.subtotal);
        }
        // console.log("--------------")
        // console.log(sub.qty)
        // console.log(sub.subtotal)
        // console.log(total_addOn)
      })
    });
  }
  // console.log(total_addOn)
  // console.log(TotalLoyalty)

  if(TotalLoyalty > TotalBusFare) {
    TotalLoyalty = TotalBusFare;
    // console.log(TotalBusFare)
  }
  
  // console.log('depart: '+BusFareDepart)
  let TotalAmount = TotalBusFare + total_addOn - TotalLoyalty - VoucherDiscount;
  if(checkReschedule && getToken()!=null) {
    const reschedule_api_url = `${SERVER_URL}/eb/get/reschedule/fee`;
            //call API to save data into db
            
            const reschedule_requestOptions = {
                method: 'POST',
                headers: { 
                  'Content-Type': 'application/json',
                  'Authorization': getToken()
                 },
                body: JSON.stringify({
                  "OldCartGuid":oldcartguid,
                  "NewPaymentAmount":TotalAmount
                })
            };
            // console.log(reschedule_requestOptions)
            const RescheduleData = async () => {
                try {
                    const reschedule_response = await fetch(
                        reschedule_api_url,reschedule_requestOptions
                    );
                    if (!reschedule_response.ok) {
                    throw new Error(
                        `This is an HTTP error: The status is ${reschedule_response.status}`
                    );
                    }
                    let reschedule_Data = await reschedule_response.json();
                    setRescheduleAmount(reschedule_Data.AmendmentFee);
                    sessionStorage.setItem("rescheduleAmount",JSON.stringify(reschedule_Data.AmendmentFee));
                    // console.log(reschedule_Data);
                } catch(err) {
                    // console.log(err.message);
                    setRescheduleAmount(null);
                }
                }
                tokenIsValid();
                RescheduleData();
        }
  return (
    <>
    <div>
        <div className='bus-fare-total'>
            <table className='mt-4 add-on-table'>
              {
                checkReschedule == false && rescheduleAmount == 0 ?
                <>
                <td><h6>Total</h6></td>
                <td><h3>RM {TotalAmount.toFixed(2)}</h3></td>
                </>
                :
                <>
                <td><h6>Rescheduled Total</h6></td>
                <td><h3>RM {rescheduleAmount.toFixed(2)}</h3></td>
                </>
              }
            </table>
        </div>
    </div>
    </>
  );
}