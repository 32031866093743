import React, { useState, useEffect } from 'react';
import '../../App.css';
import BookingAddOn from '../BookingAddOn';
import { BookingDetails } from '../BookingDetails';
import { BookingHeader } from '../BookingHeader';
import { useSearchParams } from 'react-router-dom';
import { LoadingScreenModal } from '../LoadingScreenModal';
import { convertEBticketToCWLticket, tokenIsValid, getToken } from '../Function';

function BookingHistory() {
	const SERVER_URL = process.env.REACT_APP_SERVER_URL;
	let guid = sessionStorage.getItem("history_guid");
	let addon = sessionStorage.getItem("addon");
	let oldcartguid = sessionStorage.getItem("oldcartguid");

	if (guid != null) {
		guid = JSON.parse(guid);
	}
	if (addon != null) {
		addon = JSON.parse(addon);
	}
	const [searchParams, setSearchParams] = useSearchParams();
	const token = getToken();
	// console.log(token)
	// const addon = sessionStorage.getItem("addon");
	const reschedule = searchParams.get("reschedule");
	// console.log(reschedule);
	// console.log(addon);
	// console.log(guid);
	const [loading, setLoading] = useState(true);
	const [historyData, setHistoryData] = useState(null);
	useEffect(() => {
		setLoading(true)
		const getHistoryData = async () => {
			try {
				const history_response = await fetch(
					`${SERVER_URL}/me/get/ordersummary/${guid}`,
					{
						method: 'post',
						headers: {
							'Authorization': token
						}
					}
				);
				if (!history_response.ok) {
					throw new Error(
						`This is an HTTP error: The status is ${history_response.status}`
					);
				}
				let history_Data = await history_response.text();
				if (history_Data != "") {
					history_Data = JSON.parse(history_Data);
					setHistoryData(history_Data);
					setLoading(false)
				}
				else {
					getHistoryData()
				}
				// console.log(history_Data)

			} catch (err) {
				setHistoryData(null);
				setLoading(false)
				// console.log("Error")
				// console.log(err.message)
			} finally {
				// setLoading(false)
			}
		}
		tokenIsValid()
		getHistoryData()
	}, [token])

	// console.log(historyData);
	if (loading) return (
		<div className='center'>
			<LoadingScreenModal />
		</div>
	)
	if (historyData != null) {
		var today = new Date();
		var depart_date = new Date(historyData.departtripinfo.departdate);

		// console.log(historyData.details.OrderSummaryHtml);
		// console.log(convertEBticketToCWLticket(historyData.details.OrderSummaryHtml));
		// console.log(convertEBticketToCWLticket(historyData.details.OrderSummaryHtml).search('Server :'));
		// console.log(`depart_date > today = ${depart_date > today}`)

		return (
			<>
				<BookingHeader
					booking_status="Confirmed"
					printTicket="Re-Print Ticket"
					historyData={historyData}
					trip_id={historyData.ordernumber}
					ticketPDF={historyData.pdffile}
					editBooking={reschedule == "true" ? true : false}
				/>
				<BookingDetails
					historyData={historyData}
					view={reschedule === "true" && depart_date > today && oldcartguid != "" ? false : true}
					addon={addon}
				/>
				<BookingAddOn
					addon={addon}
				/>
			</>
		);
	}
}

export default BookingHistory;