import React, { useContext, useEffect, useState } from "react";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import {
  BusFareDepartContext,
  BusFareReturnContext,
  LoyaltyDiscountContext,
} from "../UserContext";
import { calculateLoyaltyPointsToEarn, checkIsReschedule, getUserPrimaryEmail } from "./Function";
import { LoginSignUpModal } from "./LoginSignUpModal";
import "./SignInToCollectLoyalty.css";

export const SignInToCollectLoyalty = ({ manjaRewards = 0, login }) => {
  // let {TotalBusFare, setTotalBusFare} = useContext(TotalBusFareContext);
  // let {BusFareAddOn, setBusFareAddOn} = useContext(BusFareAddOnContext);
  let { BusFareDepart, setBusFareDepart } = useContext(BusFareDepartContext);
  let { BusFareReturn, setBusFareReturn } = useContext(BusFareReturnContext);
  let { TotalLoyalty, setLoyalty } = useContext(LoyaltyDiscountContext);
  let BusFareAddOn = JSON.parse(sessionStorage.getItem("addOn"));
  let TotalBusFare = JSON.parse(sessionStorage.getItem("busFare"));
  // let TotalLoyalty = JSON.parse(localStorage.getItem("loyalty"));
  let total_addOn = 0;
  BusFareAddOn.forEach((element) => {
    total_addOn += element.sub_totalPrice;
  });
  // console.log('add on sub price'+ total_addOn);

  TotalBusFare = TotalBusFare + total_addOn - TotalLoyalty;
  const [show, setShow] = useState(null);
  const email = getUserPrimaryEmail();
  // console.log('checking sign in to collect: ' + show)
  // console.log('total: '+ TotalBusFare);
  const checkReschedule = checkIsReschedule();
  const earn_points = calculateLoyaltyPointsToEarn(checkReschedule ? 0 : TotalBusFare);

  useEffect(() => {
    if (show == false) {
      setShow(true);
    }
  }, [show === false]);

  if (login === true) {
    return (
      <>
        <div className="sign_in_to_collect_loyalty">
          <Row>
            <Col xs={7} sm={7} className="pt-3">
              <h5>Sign in as </h5>
              <h4>{email}</h4>
              <h2>You will earn {earn_points.toFixed()} points</h2>
            </Col>
            <Col xs={5} sm={5} className="right pt-3">
              <h4>
                {manjaRewards.points != null && manjaRewards.points != "" ? manjaRewards.points : 0} points
              </h4>
              <h6>Balance</h6>
            </Col>
          </Row>
        </div>
      </>
    );
  } else {
    function openModal() {
      setShow(!show);
      // console.log('checking here')
    }

    return (
      <>
        {show === true ? <LoginSignUpModal /> : ""}
        <div className="sign_in_to_collect_loyalty">
          <Row>
            <Col sm={7} className="pt-3">
              <h3>
                Sign in to collect <span>{earn_points.toFixed()}</span> loyalty points
                from this booking now!
              </h3>
            </Col>
            <Col sm={5} className="right pt-3">
              <button
                className="signIn_button"
                type="button"
                onClick={() => openModal()}
              >
                Sign in here
              </button>
            </Col>
          </Row>
        </div>
      </>
    );
  }
};
