import React from 'react';
import '../App.css';
import BookingAddOn from './BookingAddOn';
import { BookingAddOnDeals } from './BookingAddOnDeals';
import { BookingDetails } from './BookingDetails';
import { BookingHeader } from './BookingHeader';

export const BookingSuccessful = ({
    booking_status = "Confirmed",
    trip_id,
    amount,
    email,
    printTicket="Print Ticket",
    editBooking="Edit Booking",
    depart_info,
    return_info,
    data_depart,
    data_destination,
    data_passenger
    }) => {
  return (
    <>
      <BookingHeader
        booking_status= {booking_status}
        trip_id={trip_id}
        email={email}
        printTicket={printTicket}
        editBooking={editBooking}
      >
      </BookingHeader>
      <BookingDetails
        depart_info = {depart_info}
        return_info = {return_info}
        data_depart = {data_depart}
        data_destination = {data_destination}
        data_passenger = {data_passenger}   
        view = 'true' 
      >
      </BookingDetails>
      <BookingAddOn />
      <BookingAddOnDeals 
      component="5"
      />
    </>
  );
}

export default BookingSuccessful;