import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useSearchParams } from 'react-router-dom';
import { ContentTitle } from './ContentTitle';
import { changeDateFormatToDayMonthYear, getAMorPMonTime, getEstimateTimeArrival, getEstimateTimeDurationInMinutes } from './Function';
import { LinkButton } from "./LinkButton";
import './SelectedTripSummaryModal.css';

export const SelectedOneWayTripModal = ({
    depart,
    destination,
    passenger=1
}) => {
  const [show, setShow] = useState(true);
  const departData = JSON.parse(sessionStorage.getItem("departData"));
  const [searchParams, setSearchParams] = useSearchParams();
  const request_id = searchParams.get("request_id") ?? "";
  const data_depart_date = searchParams.get("depart_date");
  const data_return_date = searchParams.get("return_date");
  // console.log(getEstimateTimeDurationInMinutes(departData.duration_in_seconds))
  // console.log(getEstimateTimeDurationInMinutes())
  const trip_duration = getEstimateTimeDurationInMinutes(departData.duration_in_seconds);

  // console.log(data_return_date);

  const converted_depart_date = new Intl.DateTimeFormat('en', 
  {
      year: 'numeric', 
      month: 'short',
      day: '2-digit',
      weekday: 'long'
  }
).format(Date.parse(departData.departure_time.split(" ")[0]));

  let departDate = departData.departure_time.split(" ")[0];
  let departTime = departData.departure_time.split(" ")[1];

  let depart_ETA = (getEstimateTimeArrival(departTime,trip_duration));
  
  const header_departDate = changeDateFormatToDayMonthYear(departDate);
  let title_description = `${header_departDate}, ${passenger} pax`;

  function changeTrip() {
    setShow(false);
    window.location.reload();
  }

  return (
    <>
        
    <Modal
        show={show}
        onHide={() => setShow(false)}
        aria-labelledby="SelectedTripSummaryModal"
        backdrop="static"
        keyboard={false}
        
    >
        <ContentTitle 
        title="One Way Trip"
        description={title_description}
        />
    <Modal.Body>
        <div className='SelectedTripSummary'>
        <table width={'100%'}>
            <tr>
                <td width={'65%'}>
                    <h2>{converted_depart_date}</h2>
                </td>
                <td width={'35%'}>
                    <a onClick={()=>changeTrip()}><h3>Change Trip</h3></a>
                </td>
            </tr>
            <tr>
                <td>
                <span><div className='circle' /> -------- <div className='circle circle_destination' /></span>
                    <h4 className='ps-4'>{departData.depart_from}</h4>
                    <h5 className='ps-4 pb-3'>{departData.pick_up}</h5>
                </td>
                <td>
                    <h6>{getAMorPMonTime(departTime)}</h6>
                </td>
            </tr>
            <tr>
                <td>
                    <h4 className='ps-4'>{departData.depart_to}</h4>
                    <h5 className='ps-4 pb-2'>{departData.drop_off}</h5>
                </td>
                <td>
                    <h6>{getAMorPMonTime(depart_ETA)}</h6>
                </td>
            </tr>
        </table>
        </div>
    </Modal.Body>
    <Modal.Footer className="buttonConfirm">
        <LinkButton
        className="buttonConfirm"
        children= "Confirm One Way Trip"
        linkTo= "/selectSeat"
        onClick={()=>sessionStorage.removeItem("returnData")}
        buttonStyle= "btn--print--ticket--full--width"
        buttonSize= "btn--ticket"
        params={
            [
                `depart=${depart}`,
                `destination=${destination}`,
                `depart_date=${departData.departure_time.split(" ")[0]}`,
                "return_date=null",
                `passenger=${passenger}`,
                `request_id=${request_id}`
            ]
        }
        >
        </LinkButton>
        <LinkButton
        className="buttonConfirm mt-3"
        children= "Add Return Trip"
        linkTo= "/pickReturn"
        buttonStyle= "btn--yellow--white--full--width"
        buttonSize= "btn--ticket"
        params={
            [
                `depart=${depart}`,
                `destination=${destination}`,
                `depart_date=${departData.departure_time.split(" ")[0]}`,
                `return_date=${data_return_date}`,
                `passenger=${passenger}`,
                `request_id=${request_id}`
            ]
        }
        >
        </LinkButton>
    </Modal.Footer>
    </Modal>
    </>
  );
}
