import React from 'react';

function ExperienceItem(props) {
  return (
    <>
      <div className='experience_item'>
        <a className='experience_item__link' href={props.path}>
          <figure className='experience_item__pic-wrap'>
            <img
              className='experience_item__img'
              alt='Experience'
              src={props.src}
            />
          </figure>
          <div className='experience_item__info'>
            <h5 className='experience_item__title'>{props.title}</h5>
            <h6 className='experience_item__text'>{props.text}</h6>
          </div>
        </a>
      </div>
    </>
  );
}

export default ExperienceItem;
