import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Link, useSearchParams } from 'react-router-dom';
import { ContentTitle } from './ContentTitle';
import { changeDateFormatToDayMonthYear, checkIsReschedule, getAMorPMonTime, getEstimateTimeArrival, getEstimateTimeDurationInMinutes } from './Function';
import { LinkButton } from "./LinkButton";
import './SelectedTripSummaryModal.css';

export const SelectedTripSummaryModal = ({
    title,
    depart,
    destination,
    passenger=1
}) => {
  const [show, setShow] = useState(true);
//   const {departData, setDepartData} = useContext(DepartDataContext);
//   const {returnData, setReturnData} = useContext(ReturnDataContext);
  const departData = JSON.parse(sessionStorage.getItem("departData"));
  const returnData = JSON.parse(sessionStorage.getItem("returnData"));
  const [searchParams, setSearchParams] = useSearchParams();
  const data_depart_date = searchParams.get("depart_date");
  const data_return_date = searchParams.get("return_date");
  const request_id = searchParams.get("request_id") ?? "";
  const trip_duration = getEstimateTimeDurationInMinutes(departData.duration_in_seconds);

  const checkReschedule = checkIsReschedule();
//   console.log(depart);
//   console.log(departData);
//   console.log(returnData);

  const converted_depart_date = new Intl.DateTimeFormat('en', 
  {
      year: 'numeric', 
      month: 'short',
      day: '2-digit',
      weekday: 'long'
  }
).format(Date.parse(departData.departure_time.split(" ")[0]));

  const converted_return_date = new Intl.DateTimeFormat('en', 
  {
      year: 'numeric', 
      month: 'short',
      day: '2-digit',
      weekday: 'long'
  }
).format(Date.parse(returnData.departure_time.split(" ")[0]));

  let params = `depart=${depart}&destination=${destination}&depart_date=${data_depart_date}&return_date=${data_return_date}&passenger=${passenger}&request_id=${request_id}`;
  if(title === 'Return') {
      params = `depart=${destination}&destination=${depart}&depart_date=${data_depart_date}&return_date=${data_return_date}&passenger=${passenger}&request_id=${request_id}`;
  }
  if(checkReschedule === true) {
    params = '';
  }
  let href = `/pickDepart?${params}`;
  let departDate = departData.departure_time.split(" ")[0];
  let returnDate = returnData.departure_time.split(" ")[0];
  let departTime = departData.departure_time.split(" ")[1];
  let returnTime = returnData.departure_time.split(" ")[1];

  let depart_ETA = (getEstimateTimeArrival(departTime,trip_duration));
  let return_ETA = (getEstimateTimeArrival(returnTime,trip_duration));
  
  const header_departDate = changeDateFormatToDayMonthYear(departDate);
  const header_returnDate = changeDateFormatToDayMonthYear(returnDate);
  let title_description = `${header_departDate} - ${header_returnDate}, ${passenger} pax`;
  
  function changeTrip() {
    setShow(false);
    window.location.reload();
  }

  return (
    <>
        
    <Modal
        show={show}
        onHide={() => setShow(false)}
        aria-labelledby="SelectedTripSummaryModal"
        backdrop="static"
        keyboard={false}
        
    >
        <ContentTitle 
        title="Round Trip"
        description={title_description}
        />
    <Modal.Body>
        <div className='SelectedTripSummary'>
        <table width={'100%'}>
            <tr>
                <td>
                    <h2>{converted_depart_date}</h2>
                </td>
                <td>
                    <Link to={`/pickDepart?${params}`}><h3>Change Trip</h3></Link>
                </td>
            </tr>
            <tr>
                <td>
                <span><div className='circle' /> -------- <div className='circle circle_destination' /></span>
                    <h4 className='ps-4'>{departData.depart_from}</h4>
                    <h5 className='ps-4 pb-3'>{departData.pick_up}</h5>
                </td>
                <td>
                    <h6>{getAMorPMonTime(departTime)}</h6>
                </td>
            </tr>
            <tr>
                <td>
                    <h4 className='ps-4'>{departData.depart_to}</h4>
                    <h5 className='ps-4 pb-2'>{departData.drop_off}</h5>
                </td>
                <td>
                    <h6>{getAMorPMonTime(depart_ETA)}</h6>
                </td>
            </tr>
            <tr>
                <td colSpan={2}>
                    <hr></hr>
                </td>
            </tr>
            <tr>
                <td>
                    <h2>{converted_return_date}</h2>
                </td>
                <td>
                    <a onClick={()=>changeTrip()}><h3>Change Trip</h3></a>
                </td>
            </tr>
            <tr>
                <td>
                <span><div className='circle' /> -------- <div className='circle circle_destination' /></span>
                    <h4 className='ps-4'>{returnData.depart_from}</h4>
                    <h5 className='ps-4 pb-3'>{returnData.pick_up}</h5>
                </td>
                <td>
                    <h6>{getAMorPMonTime(returnTime)}</h6>
                </td>
            </tr>
            <tr>
                <td>
                    <h4 className='ps-4'>{returnData.depart_to}</h4>
                    <h5 className='ps-4 pb-2'>{returnData.drop_off}</h5>
                </td>
                <td>
                    <h6>{getAMorPMonTime(return_ETA)}</h6>
                </td>
            </tr>
        </table>
        </div>
    </Modal.Body>
    <Modal.Footer className="buttonConfirm">
        {
            checkReschedule == false ?
            <LinkButton
            className="buttonConfirm"
            children= "Confirm"
            linkTo= "/selectSeat"
            buttonStyle= "btn--print--ticket--full--width"
            buttonSize= "btn--ticket"
            params={
                [
                    `depart=${destination}`,
                    `destination=${depart}`,
                    `depart_date=${departData.departure_time.split(" ")[0]}`,
                    `return_date=${returnData.departure_time.split(" ")[0]}`,
                    `passenger=${passenger}`,
                    `request_id=${request_id}`
                ]
            }
            >
            </LinkButton>
            :
            <LinkButton
            className="buttonConfirm"
            children= "Confirm"
            linkTo= "/selectSeat"
            buttonStyle= "btn--print--ticket--full--width"
            buttonSize= "btn--ticket"
            >
            </LinkButton>
        }
    </Modal.Footer>
    </Modal>
    </>
  );
}
