import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/esm/Container';
import { BookingAddOnCardGroup } from './BookingAddOnCardGroup';
import './BookingAddOnDeals.css';
import { getToken, tokenIsValid } from './Function';


export const BookingAddOnDeals = ({
    component
    }) => {

component = component == null ? "5" : component;
const CWL_HOLIDAYS_URL = process.env.REACT_APP_CAUSEWAYLINK_HOLIDAYS_URL;
const API_KEY = process.env.REACT_APP_CAUSEWAYLINK_HOLIDAYS_KEY;
const [data, setHolidaysData] = useState(null);
const [loading, setLoading] = useState(true);
const [error, setError] = useState(null);

useEffect(() => {
    const getHolidaysData = async () => {
      try {
        const holidays_response = await fetch(
          `${CWL_HOLIDAYS_URL}/Packages/express/addon/KL-JB`,
          {
            method: 'GET',
            mode: 'cors',
            headers: {
              'apikey': API_KEY,
              'Authorization': getToken()
            }
          }
        );
        if (!holidays_response.ok) {
          throw new Error(
            `This is an HTTP error: The status is ${holidays_response.status}`
          );
        }
        let Holidays_Data = await holidays_response.json();
        setHolidaysData(Holidays_Data);
        sessionStorage.setItem("addon_data",JSON.stringify(Holidays_Data.results));
        setError(null);
        // console.log(Holidays_Data)
      } catch(err) {
        setError(err.message);
        setHolidaysData(null);
        // console.log("Error")
        // console.log(err.message)
      } finally {
        setLoading(false);
      }  
    }
    tokenIsValid()
    getHolidaysData()
  }, [])
return (
    <>
        <div className = 'blur'>
            <Container>
                <div className='bookingAddOnTitleContainer'>
                    <h1>ADD ON Deals</h1>
                    <h5>Add on & save more! Unlock up to 30% off activities in KL now!</h5>
                </div>
            </Container>
            <div className='maxWidth m-auto'>
            <BookingAddOnCardGroup 
            component={component}
            data={data}
            />
            </div>
        </div>
    </>
  );

}