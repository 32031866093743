import React from 'react';
import Col from 'react-bootstrap/esm/Col';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import '../../App.css';
import { AddOn } from '../AddOn';
import { BookingAddOnDeals } from '../BookingAddOnDeals';
import { BusFareTotal } from '../BusFareTotal';
import { checkIsReschedule } from '../Function';
import { ProceedBusFare } from '../ProceedBusFare';
import { Proceed_Departure } from '../Proceed_Departure';
import { Proceed_Return } from '../Proceed_Return';
import { SelectSeatButton } from '../SelectSeatButton';
import { SelectSeatErrorMsg } from '../SelectSeatErrorMsg';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;
class ProceedComponent extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			// items:[],
			ConversionRate: 0,
			message: "",
			DataisLoaded: false
		};
	}
	componentDidMount() {

		// const reloadCount = sessionStorage.getItem('reloadCount');
		// if (reloadCount < 2) {
		//     sessionStorage.setItem('reloadCount', String(reloadCount + 1));
		//     window.location.reload();
		// } else {
		//     sessionStorage.removeItem('reloadCount');
		// }
		// fetch(`${SERVER_URL}/eb/get/currency/rate/SGD/MYR`).then(res => res.json()).then(
		// result=>{
		//     this.setState({ConversionRate:result.ConversionRate});
		//     this.setState({message:result.Message});
		//     // console.log(result);
		//     }
		// )
	}
	render() {
		const returnData = sessionStorage.getItem("returnData");
		// console.log(returnData);
		// console.log(sessionStorage.getItem("departData"));
		const checkReschedule = checkIsReschedule();
		if (sessionStorage.getItem("departData") == null) {
			window.location.href = '/';
		}
		return (
			<>
				<Container>
					<Row>
						<Col lg={7}>

							{/* Rate: { this.state.ConversionRate } */}
							<Proceed_Departure />
							{returnData != null ?
								<Proceed_Return />
								: ""
							}
							{
								checkReschedule == false ?
									<BookingAddOnDeals
										component="2" />
									: ""
							}
						</Col>
						<Col lg={5}>
							<div className='proceedBusFare_container'>
								<div className='bus_fare_depart'>
									<h1>Bus Fare</h1>
									<ProceedBusFare />
									<AddOn />
									<Row>
										<BusFareTotal
											page='selectSeat'
										/>
									</Row>
									<Row>
										<SelectSeatButton />
									</Row>
								</div>
							</div>
							<SelectSeatErrorMsg />
						</Col>
					</Row>
				</Container>
			</>
		);
	}
}
export default ProceedComponent;
