import React, { useState, useEffect } from 'react';
import DateRangePicker from "react-bootstrap-daterangepicker";
import Col from 'react-bootstrap/esm/Col';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import { tokenIsValid, getToken, calculateDateRange, changeDateFormatToMMDDYYYY, changeDateFormatToYYYYMMDD, changeDateFormatToYYYYMMDDwithDash, checkIsReschedule, checkVenue, getSubPlaceShortName } from './Function';
import './SelectDepartureHeader.css';
import { useSearchParams } from 'react-router-dom';

export const SelectDepartureHeader = ({
    subplace_data,
    title,
    edit_search,
    depart,
    destination,
    date_depart,
    date_return,
    passenger
    }) => {
// console.log("==========================")
// console.log(sessionStorage.getItem("Origin_data"))
// console.log(sessionStorage.getItem("Destination_data"))
const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const [searchParams, setSearchParams] = useSearchParams();
const request_id = searchParams.get("request_id") ?? "";
const checkReschedule = checkIsReschedule();
let oldcartguid = null;
let reschedule_departDate = null;
let reschedule_returnDate = null;
let reschedule_passenger = null;
let reschedule_reschedule = null;
let reschedule_passengerDetails = null;
let origin_data = sessionStorage.getItem("Origin_data");
if(origin_data != null) {
    origin_data = JSON.parse(origin_data);
    // console.log(origin_data)
    if(checkReschedule) {
        oldcartguid = origin_data.OldCartGuid;
        reschedule_departDate = origin_data.DepartDate;
        reschedule_returnDate = origin_data.ReturnDate;
        reschedule_passenger = origin_data.Passenger;
        reschedule_reschedule = origin_data.Reschedule;
        reschedule_passengerDetails = origin_data.PassengerDetails;
    }
}
const [new_depart, setDepart] = useState(depart.subplaceuniqueshortname);
const [new_destination, setDestination] = useState(destination.subplaceuniqueshortname);
const [new_depart_date, setDepartDate] = useState(date_depart);
const [new_return_date, setReturnDate] = useState(date_return);
const [new_passenger, setPassenger] = useState(passenger);
// console.log(depart)
// console.log(new_destination)
// console.log(new_depart_date)
// console.log(new_return_date)
// console.log(new_passenger)
const [oneWayTrip, setOneWayTrip] = useState(true); //true = is one way trip; false = round trip
const [editMode, setEditMode] = useState(false);
const [availableDestination,setAvailableDestination] = useState(null);
// console.log(availableDestination)

useEffect(() => {
    let selected_subplace_id = 32;
    if(sessionStorage.getItem("Origin_data")!= null) {
        const origin_data = JSON.parse(sessionStorage.getItem("Origin_data"));
        selected_subplace_id = origin_data.subplaceid;
        // console.log(selected_subplace_id)
        // console.log(origin_data)
    }
    const getAvailableDestination = async () => {
        try {
            const response = await fetch(`${SERVER_URL}/me/get/subplaces/origin/${selected_subplace_id}`,
            {
                headers: {
                  'Authorization': getToken()
                }
            });
            if (!response.ok) {
                throw new Error(
                    `This is an HTTP error: The status is ${response.status}`
                );
            }
            let actualData = await response.json();
            // console.log(actualData)
            setAvailableDestination(actualData);
        } catch (err) {
            setAvailableDestination(null);
        }
    }
    tokenIsValid()
    getAvailableDestination();

}, [new_depart]);
const setOriginData = (selected_place) => {
    sessionStorage.removeItem("Origin_data");
    for (let i = 0; i < subplace_data.length; i++) {
        for (let j = 0; j < subplace_data[i].subplaces.length; j++) {
            if(subplace_data[i].subplaces[j].subplaceuniqueshortname === selected_place) {
                setDepart(subplace_data[i].subplaces[j].subplaceuniqueshortname);
                if(checkReschedule == false) {
                    sessionStorage.setItem("Origin_data",JSON.stringify(
                        {
                            "placeid":subplace_data[i].placeid, 
                            "placename":subplace_data[i].placename, 
                            "placeuniqueshortname":subplace_data[i].placeuniqueshortname, 
                            "subplaceid":subplace_data[i].subplaces[j].subplaceid, 
                            "subplacename":subplace_data[i].subplaces[j].subplacename, 
                            "subplaceuniqueshortname":subplace_data[i].subplaces[j].subplaceuniqueshortname
                        }
                    )
                    )
                }
                else {
                    sessionStorage.setItem("Origin_data",JSON.stringify(
                        {
                            "placeid":subplace_data[i].placeid, 
                            "placename":subplace_data[i].placename, 
                            "placeuniqueshortname":subplace_data[i].placeuniqueshortname, 
                            "subplaceid":subplace_data[i].subplaces[j].subplaceid, 
                            "subplacename":subplace_data[i].subplaces[j].subplacename, 
                            "subplaceuniqueshortname":subplace_data[i].subplaces[j].subplaceuniqueshortname,

                            "oldcartguid":oldcartguid,
                            "departdate":reschedule_departDate,
                            "returndate":reschedule_returnDate,
                            "passenger":reschedule_passenger,
                            "reschedule":reschedule_reschedule,
                            "passengerdetails":reschedule_passengerDetails
                        }
                    )
                    )
                }
            }            
        }
    }
}

const setDestinationData = (selected_place) => {
    sessionStorage.removeItem("Destination_data");
    // console.log(selected_place);
    for (let i = 0; i < subplace_data.length; i++) {
        for (let j = 0; j < subplace_data[i].subplaces.length; j++) {
            if(subplace_data[i].subplaces[j].subplaceuniqueshortname === selected_place) {
                setDestination(subplace_data[i].subplaces[j].subplaceuniqueshortname);
                sessionStorage.setItem("Destination_data",JSON.stringify(
                    {
                        "placeid":subplace_data[i].placeid, 
                        "placename":subplace_data[i].placename, 
                        "placeuniqueshortname":subplace_data[i].placeuniqueshortname, 
                        "subplaceid":subplace_data[i].subplaces[j].subplaceid, 
                        "subplacename":subplace_data[i].subplaces[j].subplacename, 
                        "subplaceuniqueshortname":subplace_data[i].subplaces[j].subplaceuniqueshortname
                    }
                )
            )
            }   
        }         
    }
}

function swapVenue() {
    if(depart != '' && destination != '') {
        var ov = document.getElementById('origin_venue');
        var dv = document.getElementById('destination_venue');
        var tempOV = ov.value;
        var tempDV = dv.value;
        ov.value = tempDV;
        dv.value = tempOV;
        setOriginData(ov.value);
        setDestinationData(dv.value);
    }
    // console.log(depart)
    // console.log(destination)
}

// console.log(oneWayTrip);
// console.log(subplace_data);
const converted_depart_date = new Intl.DateTimeFormat('en', 
    {
        month: 'short',
        day: '2-digit'
    }
).format(Date.parse(date_depart));

// console.log(changeDateFormatToMMDDYYYY(date_depart))
// console.log(date_depart)
// console.log(date_return)
const editDepartDate = changeDateFormatToMMDDYYYY(date_depart);
// console.log(editDepartDate)
const editReturnDate = changeDateFormatToMMDDYYYY(date_return);

// console.log(editReturnDate)
const checkOneWay = date_return == "null" ? true : false;
let converted_return_date = null;
// console.log(checkOneWay)

function handleCallbackDepart(start) {
    // console.log(changeDateFormatToYYYYMMDD(start._d));
    setDepartDate(changeDateFormatToYYYYMMDD(start._d));
  }

function handleCallbackReturn(end) {
// console.log(end);
setReturnDate(changeDateFormatToYYYYMMDD(end._d));
}
const handleCancel = (event, picker) => {
    picker.element.val('');
    setReturnDate(null)
};
const handleApply = (event, picker) => {
    picker.element.val(
        picker.startDate.format('MM/DD/YYYY')
    );
};
// console.log(parseInt('07'))
function checkValidation() {
    // console.log(changeDateFormatToYYYYMMDDwithDash(new_depart_date))
    // console.log(changeDateFormatToYYYYMMDDwithDash(new_return_date))
    if (new_depart === '' || new_destination === '' || new_depart_date === '' || new_passenger === '') {
        alert("Please Key In all data");
        return false;
    }
    else {
        // console.log(new_return_date)
        if (new_return_date !== '' && new_return_date !== null) {
            if (changeDateFormatToYYYYMMDDwithDash(new_return_date) < changeDateFormatToYYYYMMDDwithDash(new_depart_date)) {
                alert("Selected date cannot be earlier than today or return date cannot be earlier than depart date");
            }
            else if (new_passenger < 1 || new_passenger > 8) {
                alert("Passenger should not less than 1 or more than 8!");
            }
            else if (checkVenue() == false) {
                alert("Selected Origin or Destination is invalid!");
            }
            else return true;
        }
        else {
            // else if (calculateDateRange(new_depart_date, new_return_date) > 6) {
            //     alert("Return date cannot be more than 7 days");
            // }
            if (new_passenger < 1 || new_passenger > 8) {
                alert("Passenger should not less than 1 or more than 8!");
            }
            else if (checkVenue() == false) {
                alert("Selected Origin or Destination is invalid!");
            }
            else return true;
        }
    }
    return false;
}

function editSearch() {
    const edit_validation = checkValidation();
    if(edit_validation) {
        setEditMode(false)
        if(checkReschedule == false) {
            window.location.href = `/pickDepart?depart=${new_depart}&destination=${new_destination}&depart_date=${new_depart_date}&return_date=${new_return_date}&passenger=${new_passenger}&request_id=${request_id}`;
        }
        else {
            window.location.href = '/pickDepart';
        }

    }
}

function clearDestination() {
    setDestinationData('');
    var dv = document.getElementById('destination_venue');
    dv.value = '';
}

function getHref() {
    return `/pickDepart?depart=${new_depart}&destination=${new_destination}&depart_date=${new_depart_date}&return_date=${new_return_date}&passenger=${new_passenger}`
}

if(!checkOneWay) {
converted_return_date = new Intl.DateTimeFormat('en', 
        {
            month: 'short',
            day: '2-digit'
        }
    ).format(Date.parse(date_return))
}
// console.log(converted_return_date)

  return (
    <>
    <div className='departure-header-container'>
        <Container>
        {
            !editMode ?
            <Row>
                <Col xs={5} sm={5} lg={2}>
                    <h6>{depart.placename}</h6>
                    <h5>{(depart.subplacedisplayname)}</h5> 
                </Col>
                <Col xs={2} sm={2} lg={1} className='m-auto'>
                    <img src = 'images/vector.png' />
                </Col>
                <Col xs={5} sm={5} lg={2}>
                    <h6>{destination.placename}</h6>
                    <h5>{(destination.subplacedisplayname)}</h5> 
                </Col>
                <hr />
                <Col xs={5} sm={5} lg={2} className='header_border-left'>
                    <h6>Passenger</h6>
                    <h5>{passenger} Pax</h5>
                </Col>
                <Col xs={2} sm={2} lg={1} className=''>
                </Col>
                <Col xs={5} sm={5} lg={2}>
                    <h6>Date</h6>
                    {date_return == null 
                    ?
                    <h5>{converted_depart_date}</h5> 
                    :
                    <h5>{converted_depart_date} {converted_return_date == null ? "" : "-"} {converted_return_date}</h5>
                    }
                </Col>
                <Col xs={12} sm={12} lg={2} className='m-auto center'>
                    <a onClick={()=>setEditMode(true)}>
                        <h3 className='m-auto float-right'>{edit_search}&nbsp;
                        <span className="fas fa-light fa-caret-down icon-blue"></span></h3>
                    </a>
                </Col>
            </Row>
            :
            <Row>
                <Col xs={12} sm={12} lg={2}>
                    <h6>Origin</h6>
                    {/* <input id='origin_venue' className="edit_venue" defaultValue={depart.SubPlaceName} autocomplete="off" type="text" list="venue" name="origin" onChange={event => setOriginData(event.target.value)} required />
                    <datalist id="venue">
                    {subplace_data && subplace_data.map(({ SubPlaceName }) => (
                        <option value={SubPlaceName} />
                    ))}
                    </datalist> */}
                    <select name='origin_venue' id="origin_venue" className="edit_venue" defaultValue={ depart.subplaceuniqueshortname } onChange={event => {setOriginData(event.target.value); clearDestination()}}>
                        <option value=''>City</option>
                            {subplace_data && subplace_data.map(({ placename, subplaces },index) => (
                            <optgroup label={placename}>
                                {subplaces && subplaces.map(({ subplacename,subplaceuniqueshortname },index) => (
                                <option value={subplaceuniqueshortname}>{subplacename}</option>
                                ))}
                            </optgroup>
                            ))}
                    </select>
                </Col>
                <Col lg={1} className='none-992 m-auto'>
                    <img className='vectorSwap' onClick={swapVenue} src = 'images/vector.png' />
                </Col>
                <Col xs={12} sm={12} lg={2}>
                    <h6>Destination</h6>
                    <select name='destination_venue' id="destination_venue" className="edit_venue" defaultValue={ destination.subplaceuniqueshortname } onChange={event => setDestinationData(event.target.value)}>
                        <option value=''>City</option>
                            {availableDestination && availableDestination.map(({ placename, subplaces },index) => (
                            <optgroup label={placename}>
                                {subplaces && subplaces.map(({ subplacename, subplaceuniqueshortname },index) => (
                                <option value={subplaceuniqueshortname}>{subplacename}</option>
                                ))}
                            </optgroup>
                            ))}
                            {/* {subplace_data && subplace_data.map(({ placename, subplaces },index) => (
                            <optgroup label={placename}>
                                {subplaces && subplaces.map(({ subplacename, subplaceuniqueshortname },index) => (
                                <option value={subplaceuniqueshortname}>{subplacename}</option>
                                ))}
                            </optgroup>
                            ))} */}
                    </select>
                    {/* <input id='destination_venue' className="edit_venue" defaultValue={destination.SubPlaceName} autocomplete="off" type="text" list="venue" name="destination" onChange={event => setDestinationData(event.target.value)} required /> */}
                </Col>
                <Col xs={12} sm={12} lg={2} className='header_border-left'>
                    <h6>Passenger</h6>
                    <input className="" type="number" min="1" max="8" name="passenger" defaultValue={passenger} onChange={event => setPassenger(event.target.value)} required disabled={checkReschedule==true?true:false}/>
                    
                </Col>
                <Col xs={12} sm={12} lg={4}>
                    <h6>Date</h6>
                    <DateRangePicker 
                        onCallback={handleCallbackDepart}
                        onApply={handleApply}
                        initialSettings={{
                                autoUpdateInput: false,
                                minDate: new Date(),
                                singleDatePicker: true
                        }}>
                    <input 
                    id="calendar_depart"
                    defaultValue={editDepartDate}
                    autocomplete="off"
                    className="edit_calendar" 
                    type="text" 
                    name="depart" 
                    onChange={handleCallbackDepart} 
                    placeholder="MM DD YYYY"
                    />
                    </DateRangePicker> 
                    &nbsp;&nbsp;
                    <DateRangePicker 
                        onCallback={handleCallbackReturn}
                        onCancel={handleCancel}
                        onApply={handleApply}
                        initialSettings={{
                                autoUpdateInput: false,
                                minDate: new Date(),
                                singleDatePicker: true,
                                locale: { cancelLabel: 'Clear' }
                        }}>
                    <input 
                    id="calendar_return"
                    defaultValue={date_return !== 'null' ? editReturnDate : null}
                    autocomplete="off"
                    className="edit_calendar" 
                    type="text" 
                    name="return" 
                    onChange={handleCallbackReturn} 
                    placeholder="MM DD YYYY"
                    />
                    </DateRangePicker> 
                </Col>
                <Col xs={12} sm={12} lg={1} className='m-auto center'>
                    <button className='search_button' onClick={editSearch}>
                        Search
                    </button>
                </Col>
            </Row>
        }
        </Container>
    </div>
    </>
  );
}

