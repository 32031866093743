import 'bootstrap-daterangepicker/daterangepicker.css';
import 'bootstrap/dist/css/bootstrap.css';
import React, { useContext, useEffect, useState } from "react";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Form from 'react-bootstrap/Form';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import "../App.css";
import { BusFareAddOnContext, BusFareDepartContext, BusFareReturnContext, LoyaltyDiscountContext, SelectSeatDepartContext, SelectSeatReturnContext, TotalBusFareContext } from '../UserContext';
import { tokenIsValid, getToken, calculateDateRange, changeDateFormatToMMDDYYYY, changeDateFormatToYYYYMMDD, checkVenue, passengerValidation } from './Function';
import "./HeaderSection.css";
import { LinkButton } from "./LinkButton";
import { useSearchParams } from 'react-router-dom';

function Booking(props) {
    const SERVER_URL = process.env.REACT_APP_SERVER_URL;
    const [availableDestination, setAvailableDestination] = useState(null);
    // console.log(availableDestination)
    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height
        };
    }
    function useWindowDimensions() {
        const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

        useEffect(() => {
            function handleResize() {
                setWindowDimensions(getWindowDimensions());
            }

            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }, []);

        return windowDimensions;
    }
    const [searchParams, setSearchParams] = useSearchParams();
    const request_id = searchParams.get("request_id") ?? "";
    const { height, width } = useWindowDimensions();
    const [depart, setDepart] = useState('');
    const [destination, setDestination] = useState('');
    const [depart_date, setDepartDate] = useState('');
    let [return_date, setReturnDate] = useState('');
    const [passenger, setPassenger] = useState(1);
    const [oneWayTrip, setOneWayTrip] = useState(true);
    const [show, setShow] = useState(false);
    // console.log(oneWayTrip);
    // console.log(show);
    const subplace_data = props.subplace_data;
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        let selected_subplace_id = 32;
        if (sessionStorage.getItem("Origin_data") != null) {
            const origin_data = JSON.parse(sessionStorage.getItem("Origin_data"));
            selected_subplace_id = origin_data.subplaceid;
        }
        const getAvailableDestination = async () => {
            try {
                const response = await fetch(`${SERVER_URL}/me/get/subplaces/origin/${selected_subplace_id}`,
                    {
                        headers: {
                            'Authorization': getToken()
                        }
                    });
                if (!response.ok) {
                    throw new Error(
                        `This is an HTTP error: The status is ${response.status}`
                    );
                }
                let actualData = await response.json();
                setAvailableDestination(actualData);
                setError(null);
            } catch (err) {
                setError(err.message);
                setAvailableDestination(null);
            } finally {
                setLoading(false);
            }
        }
        tokenIsValid()
        getAvailableDestination();

    }, [depart]);

    function handleCallback(date) {
        // console.log(date)
        // console.log(typeof(date))
        // // console.log(changeDateFormatToYYYYMMDD(start._d));
        if (date && typeof (date) === 'object') {
            if (date.length > 0) {
                var start = document.getElementById('calendar_depart')
                var end = document.getElementById('calendar_return')
                // console.log(end)
                var _depart = changeDateFormatToYYYYMMDD(date[0]);
                var _return = changeDateFormatToYYYYMMDD(date[1]);
                start.value = changeDateFormatToMMDDYYYY(_depart)
                end.value = changeDateFormatToMMDDYYYY(_return)
                var el = document.getElementsByClassName('calendar');
                // console.log(el)
                el[0].classList.remove('show')
                setDepartDate(_depart);
                setReturnDate(_return);
            }
            else {
                var start = document.getElementById('calendar_depart')
                var _depart = changeDateFormatToYYYYMMDD(date);
                start.value = changeDateFormatToMMDDYYYY(_depart)
                // var el = document.getElementsByClassName('calendar');
                // // console.log(el)
                // el[0].classList.remove('show')
                setDepartDate(_depart);
                setReturnDate('');
            }
        }
    }

    function closeCalendar(oneway) {
        if (oneway === true) {
            setReturnDate('')
        }
        setShow(false)
    }

    function handleCallbackReturn(end) {
        // console.log(end);
        setReturnDate(changeDateFormatToYYYYMMDD(end._d));
    }
    // console.log(depart_date)
    // console.log(return_date)
    const handleApply = (event, picker) => {
        picker.element.val(
            picker.startDate.format('DD MMM YY')
        );
    };

    const { selectedDepartSeat, setDepartSeat } = useContext(SelectSeatDepartContext);
    const { selectedReturnSeat, setReturnSeat } = useContext(SelectSeatReturnContext);
    let { TotalBusFare, setTotalBusFare } = useContext(TotalBusFareContext);
    let { BusFareAddOn, setBusFareAddOn } = useContext(BusFareAddOnContext);
    const { BusFareDepart, setBusFareDepart } = useContext(BusFareDepartContext);
    const { BusFareReturn, setBusFareReturn } = useContext(BusFareReturnContext);
    const { TotalLoyalty, setLoyalty } = useContext(LoyaltyDiscountContext);

    function pad2(n) {
        return (n < 10 ? '0' : '') + n;
    }

    const setOriginData = (selected_place) => {

        sessionStorage.removeItem("Origin_data");
        for (let i = 0; i < subplace_data.length; i++) {
            for (let j = 0; j < subplace_data[i].subplaces.length; j++) {
                if (subplace_data[i].subplaces[j].subplaceuniqueshortname === selected_place) {
                    
                    setDepart(subplace_data[i].subplaces[j].subplaceuniqueshortname);
                    sessionStorage.setItem("Origin_data", JSON.stringify(
                        {
                            "placeid": subplace_data[i].placeid,
                            "placename": subplace_data[i].placename,
                            "placeuniqueshortname": subplace_data[i].placeuniqueshortname,
                            "subplaceid": subplace_data[i].subplaces[j].subplaceid,
                            "subplacename": subplace_data[i].subplaces[j].subplacename,
                            "subplaceuniqueshortname": subplace_data[i].subplaces[j].subplaceuniqueshortname
                        }
                    )
                    )
                }
            }
        }
    }

    const setDestinationData = (selected_place) => {
        sessionStorage.removeItem("Destination_data");
        for (let i = 0; i < subplace_data.length; i++) {
            for (let j = 0; j < subplace_data[i].subplaces.length; j++) {
                if (subplace_data[i].subplaces[j].subplaceuniqueshortname === selected_place) {
                    setDestination(subplace_data[i].subplaces[j].subplaceuniqueshortname);
                    sessionStorage.setItem("Destination_data", JSON.stringify(
                        {
                            "placeid": subplace_data[i].placeid,
                            "placename": subplace_data[i].placename,
                            "placeuniqueshortname": subplace_data[i].placeuniqueshortname,
                            "subplaceid": subplace_data[i].subplaces[j].subplaceid,
                            "subplacename": subplace_data[i].subplaces[j].subplacename,
                            "subplaceuniqueshortname": subplace_data[i].subplaces[j].subplaceuniqueshortname
                        }
                    )
                    )
                }
            }
        }
    }

    const handleSubmit = event => {
        var d = new Date();
        var month = pad2(d.getMonth() + 1);//months (0-11)
        var day = pad2(d.getDate());//day (1-31)
        var year = d.getFullYear();
        var formattedDate = year + "-" + month + "-" + day;
        // console.log(depart)
        // console.log(destination)
        // console.log(depart_date)
        // console.log(return_date)
        // console.log(passenger)
        const passengerCheck = passengerValidation(passenger)
        // console.log(passengerCheck)

        if (depart === '' || destination === '' || depart_date === '' || passenger === '') {
            event.preventDefault(); //prevent page refresh
            alert("Please Key In all data");

        }
        else {
            if (checkVenue() == false) {
                event.preventDefault(); //prevent page refresh
                alert("Selected Origin or Destination is invalid!");
            }

            if (return_date === '') {
                return_date = depart_date;
            }

            if (depart_date < formattedDate || return_date < depart_date) {
                event.preventDefault(); //prevent page refresh
                alert("Selected date cannot be earlier than today or return date cannot be earlier than depart date");
            }

            // if (calculateDateRange(depart_date, return_date) > 6) {
            //     event.preventDefault(); //prevent page refresh
            //     alert("Return date cannot be more than 7 days");
            // }

            if (passengerCheck === false) {
                event.preventDefault(); //prevent page refresh
                alert("Passenger should not less than 1 or more than 8!");
            }
        }
        // 👇️ clear all input values in the form

        setTotalBusFare(0);
        setBusFareAddOn([]);
        setBusFareDepart(0);
        setBusFareReturn(0);
        setLoyalty(0);
        setDepartSeat([]);
        setReturnSeat([]);
        sessionStorage.removeItem("departData");
        sessionStorage.removeItem("returnData");
        sessionStorage.removeItem("busFare");
        localStorage.removeItem("loyalty");
        sessionStorage.removeItem("addOn");
        sessionStorage.removeItem("selectedDepartSeat");
        sessionStorage.removeItem("selectedReturnSeat");
        localStorage.removeItem("selected_addon_data")
        localStorage.removeItem("addOnSession");
        sessionStorage.removeItem("addon_data");
        sessionStorage.removeItem("depart_TripKey");
        sessionStorage.removeItem("return_TripKey");
        sessionStorage.removeItem('rescheduleAmount');
        sessionStorage.removeItem('history_guid');
        sessionStorage.removeItem("addOn");
        sessionStorage.removeItem('oldcartguid');
    };

    function showCalendar() {
        setShow(!show);
        // var el = document.getElementsByClassName('calendar');
        // // console.log(el)
        // el[0].classList.add('show');
    }

    function selectedOneOrTwoWay(selected) {
        setOneWayTrip(selected);
        if (selected == true) {
            var end = document.getElementById('calendar_return')
            end.value = null;
            setReturnDate('');
        }
    }

    function clearDestination() {
        setDestinationData('');
        var dv = document.getElementById('destination_venue');
        dv.value = '';
    }

    function swapVenue() {
        if (depart != '' && destination != '') {
            var ov = document.getElementById('origin_venue');
            var dv = document.getElementById('destination_venue');
            var tempOV = ov.value;
            var tempDV = dv.value;
            ov.value = tempDV;
            dv.value = tempOV;
            setOriginData(ov.value);
            setDestinationData(dv.value);
        }
    }
    // console.log(sessionStorage.getItem("Origin_data"))
    return (
        <div className="booking-container">
            <form className="form" action="" method="get" encType="multipart/form-data" onSubmit={handleSubmit}>
                <div className="form-grid">
                    <Row>
                        <Col md={12}>
                            <div className="title">
                                <h1 className="">Book Bus Ticket</h1>
                            </div>
                        </Col>
                        <Col md={7}>
                            <div className="item2 mt-4">
                                <label>Origin</label>
                                <select name='origin_venue' id="origin_venue"
                                    className="item2 half-width border-none box-size border-right origin-box" onChange={event => { setOriginData(event.target.value); clearDestination() }}>
                                    <option value=''>City</option>
                                    {subplace_data && subplace_data.map(({ placename, subplaces }, index) => (
                                        <optgroup key={index} label={placename}>
                                            {subplaces && subplaces.map(({ subplacename, subplaceuniqueshortname }, index) => (
                                                <option key={subplaceuniqueshortname} value={subplaceuniqueshortname}>{subplacename}</option>
                                            ))}
                                        </optgroup>
                                    ))}
                                </select>
                                {/* <input id='origin_venue' className="half-width border-none box-size border-right pt-22px" autocomplete="off" type="text" name="origin" onChange={event => setOriginData(event.target.value)} required /> */}
                                {/* <datalist id="venue">
                                    <select>
                                    {subplace_data && subplace_data.map(({ SubPlaceName }) => (
                                        <option value={SubPlaceName} />
                                    ))}
                                    </select>
                                </datalist> */}
                                {/* <label>Origin</label>
                                <input id='origin_venue' className="half-width border-none box-size border-right pt-22px" autocomplete="off" type="text" list="venue" name="origin" onChange={event => setOriginData(event.target.value)} required />
                                <datalist id="venue">
                                    <select>
                                        {subplace_data && subplace_data.map(({ SubPlaceName }) => (
                                            <option value={SubPlaceName} />
                                        ))}
                                    </select>
                                </datalist> */}
                                <label>Destination</label>
                                <img onClick={swapVenue} className="icon-switch" src="images/vector.png" alt="" />
                                {/* <input id='destination_venue' className="half-width border-none box-size pt-22px" autocomplete="off" type="text" list="venue" name="destination" onChange={event => setDestinationData(event.target.value)} required /> */}
                                <select name='destination_venue' id="destination_venue" className="item2 half-width border-none box-size origin-box" onChange={event => setDestinationData(event.target.value)}>
                                    <option value=''>City</option>
                                    {availableDestination && availableDestination.map(({ placename, subplaces }, index) => (
                                        <optgroup label={placename}>
                                            {subplaces && subplaces.map(({ subplacename, subplaceuniqueshortname }, index) => (
                                                <option value={subplaceuniqueshortname}>{subplacename}</option>
                                            ))}
                                        </optgroup>
                                    ))}
                                    {/* {subplace_data && subplace_data.map(({ placename, subplaces },index) => (
                                        <optgroup label={placename}>
                                            {subplaces && subplaces.map(({ subplacename, subplaceuniqueshortname },index) => (
                                            <option value={subplaceuniqueshortname}>{subplacename}</option>
                                            ))}
                                        </optgroup>
                                        ))} */}
                                </select>
                            </div>
                        </Col>
                        <Col md={5}>
                            {/* <Row>
                                <Col xs={6}>
                                    <Form.Check type="radio" id="one_way" label="One way" aria-label="oneway" name="one_way" onChange={()=>setOneWayTrip(true)} defaultChecked/>
                                </Col>
                                <Col xs={6}>
                                    <Form.Check type="radio" id="two_way" label="Return" aria-label="oneway" name="one_way" onChange={()=>setOneWayTrip(false)}/>
                                </Col>
                            </Row> */}
                            {
                                oneWayTrip == true ?

                                    <div className="item3 mt-4">
                                        {
                                            show ?
                                                <div className="checkOneWay">
                                                    <Form.Check type="radio" id="one_way" label="One way" aria-label="oneway" name="one_way" onChange={() => selectedOneOrTwoWay(true)} defaultChecked />
                                                    <Form.Check className='twoway' type="radio" id="two_way" label="Return" aria-label="oneway" name="one_way" onChange={() => selectedOneOrTwoWay(false)} />
                                                </div>
                                                : ""
                                        }
                                        <div onClick={showCalendar}>
                                            <label>Depart</label>
                                            <img className="icon-calendar" src="images/calendar.png" alt="" />
                                            {/* <DateRangePicker 
                                                    onCallback={handleCallbackDepart}
                                                    onApply={handleApply}
                                                    initialSettings={{
                                                            autoUpdateInput: false,
                                                            minDate: new Date(),
                                                            singleDatePicker: true,
                                                    }}>
                                                <input 
                                                id="calendar_depart"
                                                autocomplete="off"
                                                className="half-width border-none box-size border-right pt-22px" 
                                                type="text" 
                                                name="depart" 
                                                onChange={handleCallbackDepart} 
                                                placeholder="DD MM YY"
                                                />
                                                </DateRangePicker> */}
                                            <input
                                                id="calendar_depart"
                                                autocomplete="off"
                                                className="border-none pt-22px"
                                                readOnly
                                                type="text"
                                                name="depart"
                                                placeholder="MM DD YYYY"
                                            />
                                        </div>
                                        {
                                            show ?
                                                <>
                                                    <Calendar
                                                        prev2Label={null}
                                                        next2Label={null}
                                                        className='calendar'
                                                        onChange={handleCallback}
                                                        onClickDay={(value, event) => handleCallback(value)}
                                                        minDate={new Date()}
                                                        showDoubleView={width > 768 ? true : false}
                                                        selectRange={false}
                                                    />
                                                    <button type='button' className="calendar_button" onClick={() => closeCalendar(true)}>
                                                        Confirm
                                                    </button>
                                                </>
                                                : ""
                                        }
                                    </div>

                                    :
                                    <>
                                        <div className="item3 mt-4">
                                            {
                                                show ?
                                                    <div className="checkOneWay">
                                                        <Form.Check type="radio" id="one_way" label="One way" aria-label="oneway" name="one_way" onChange={() => setOneWayTrip(true)} />
                                                        <Form.Check className='twoway' type="radio" id="two_way" label="Return" aria-label="oneway" name="one_way" onChange={() => setOneWayTrip(false)} defaultChecked />
                                                    </div>
                                                    : ""
                                            }
                                            <div onClick={() => showCalendar()}>
                                                <label>Depart</label>
                                                <img className="icon-calendar" src="images/calendar.png" alt="" />
                                                {/* <DateRangePicker 
                                                onCallback={handleCallbackDepart}
                                                onApply={handleApply}
                                                initialSettings={{
                                                        autoUpdateInput: false,
                                                        minDate: new Date()
                                                }}>
                                            <input 
                                            id="calendar_depart"
                                            autocomplete="off"
                                            className="half-width border-none box-size border-right pt-22px" 
                                            type="text" 
                                            name="depart" 
                                            onChange={handleCallbackDepart} 
                                            placeholder="DD MM YY"
                                            />
                                            </DateRangePicker> */}
                                                <input
                                                    id="calendar_depart"
                                                    autocomplete="off"
                                                    className="half-width border-none box-size border-right pt-22px"
                                                    readOnly
                                                    type="text"
                                                    name="depart"
                                                    placeholder="MM DD YYYY"
                                                />
                                                <label>Return</label>
                                                <img className="icon-calendar" src="images/calendar.png" alt="" />
                                                <input
                                                    id="calendar_return"
                                                    autocomplete="off"
                                                    readOnly
                                                    className="half-width border-none box-size pt-22px"
                                                    type="text"
                                                    name="return"
                                                    placeholder="MM DD YYYY"
                                                />
                                            </div>
                                            {
                                                show ?
                                                    <>
                                                        <Calendar
                                                            className='calendar'
                                                            prev2Label={null}
                                                            next2Label={null}
                                                            onChange={handleCallback}
                                                            // onClickDay={(value, event) => console.log(value)}
                                                            minDate={new Date()}
                                                            showDoubleView={width > 768 ? true : false}
                                                            selectRange={true}
                                                            goToRangeStartOnSelect={false}
                                                        />
                                                        <button type='button' className="calendar_button" onClick={() => closeCalendar(false)}>
                                                            Confirm
                                                        </button>
                                                    </>
                                                    : ""
                                            }
                                        </div>
                                    </>
                            }
                        </Col>
                        {/* <input className="half-width border-none box-size pt-3" type="date" name="return" onChange={event => setReturnDate(event.target.value)} required placeholder="Return" /> */}
                        <Col md={4} className="mt-4">
                            <div className="item4">
                                <label>Passenger</label>
                                <input className="full-width border-none box-size pt-3" type="number" min="1" max="8" name="passenger" value={passenger} onChange={event => setPassenger(event.target.value)} required />
                            </div>
                        </Col>
                        <Col md={8} className="mt-4">
                            <h1 className="item5">
                                <LinkButton
                                    className="btns"
                                    type="submit"
                                    buttonStyle="btn--outline"
                                    buttonSize="btn--full"
                                    onClick={handleSubmit}
                                    linkTo="/pickDepart"
                                    params={
                                        [
                                            `depart=${depart}`,
                                            `destination=${destination}`,
                                            `depart_date=${depart_date}`,
                                            `return_date=${return_date === '' ? null : return_date}`,
                                            `passenger=${passenger}`,
                                            `request_id=${request_id}`
                                        ]
                                    }
                                >
                                    Search Bus
                                </LinkButton>
                            </h1>
                        </Col>
                    </Row>
                </div>
            </form>
        </div>

    );
}

export default Booking;