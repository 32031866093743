import React, { useContext } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { OriginContext } from '../UserContext';
import CardItem from './CardItem';
import './Cards.css';

function Cards(props) {
  function displayOrigin(title) {
    if(origin == title)
    return 'hidden';
  }
  const {origin, setOrigin} = useContext(OriginContext);
  function changeOrigin(title) {
    setOrigin(title);
  }
  // const [origin, setOrigin] = useState('Johor Bahru');
  // console.log(props.subplace_data);
    return (
      <div className='cards'>
        <h1>Feature Deals From</h1>
        {/* <h1 id='jb'><span onClick={displayOrigin}>{origin}</span><span className="fas fa-light fa-caret-down icon-blue"></span></h1>
        <h1 id='kl' className='collapse'><span onClick={displayOrigin}>{origin}</span><span className="fas fa-light fa-caret-down icon-blue"></span></h1> */}
        <h1>
          <DropdownButton id="dropdown-basic-button" title={origin} bsPrefix='originDropdown'>
            <Dropdown.Item className={displayOrigin('Kuala Lumpur')} bsPrefix='originDropdownItem' onClick={()=>changeOrigin('Kuala Lumpur')}>Kuala Lumpur</Dropdown.Item>
            <Dropdown.Item className={displayOrigin('Johor Bahru')} bsPrefix='originDropdownItem' onClick={()=>changeOrigin('Johor Bahru')}>Johor Bahru</Dropdown.Item>
          </DropdownButton>
        </h1>
        <div className='cards__container'>
          <div className='cards__wrapper'>
            {
              origin == 'Johor Bahru' ?
            <>
            <ul className='cards__items'>
              <CardItem
                origin={origin}
                src='images/img_kl.png'
                title='Kuala Lumpur'
                // text='Travel Period: 01 Oct 2022 - 31 Nov 2022'
                price='34.10'
                return='Return Ticket'
                path='/pickDepart'
                subplace_data={props.subplace_data}
              />
              <CardItem
                origin={origin}
                src='images/img_mersing.jpg'
                title='Mersing'
                // text='Travel Period: 01 Oct 2022 - 31 Nov 2022'
                price='12.80'
                return='Return Ticket'
                path='/pickDepart'
                subplace_data={props.subplace_data}
              />
              </ul>
              <ul className='cards__items'>
              <CardItem
                origin={origin}
                src='images/img_senai_airport.jpg'
                title='Senai Airport'
                // text='Travel Period: 01 Oct 2022 - 31 Nov 2022'
                price='8'
                return='Return Ticket'
                path='/pickDepart'
                subplace_data={props.subplace_data}
              />
              <CardItem
                origin={origin}
                src='images/img_shah_alam.jpg'
                title='Shah Alam'
                // text='Travel Period: 01 Oct 2022 - 31 Nov 2022'
                price='37.20'
                return='Return Ticket'
                path='/pickDepart'
                subplace_data={props.subplace_data}
              />
              </ul>
              <ul className='cards__items'>
              <CardItem
                origin={origin}
                src='images/img_klang.jpg'
                title='Klang'
                // text='Travel Period: 01 Oct 2022 - 31 Nov 2022'
                price='37.20'
                return='Return Ticket'
                path='/pickDepart'
                subplace_data={props.subplace_data}
              />
              <CardItem
                origin=''
                src=''
                title=''
                text=''
                price=''
                return=''
                path=''
                subplace_data={props.subplace_data}
              />
            </ul>
            </>
            : 
            <ul className='cards__items'>
            <CardItem
              origin={origin}
              src='images/img_johor_bahru.jpg'
              title='Johor Bahru'
              // text='Travel Period: 01 Oct 2022 - 31 Nov 2022'
              price='34.10'
              return='Return Ticket'
              path='/pickDepart'
              subplace_data={props.subplace_data}
            />
            <CardItem
              origin={origin}
              src='images/img_pengerang.jpg'
              title='Pengerang'
              // text='Travel Period: 01 Oct 2022 - 31 Nov 2022'
              price='50'
              return='Return Ticket'
              path='/pickDepart'
              subplace_data={props.subplace_data}
            />
            </ul>
            }
          </div>
          {/* <div className='see-more-button'>
            <LinkButton             
                className=""
                buttonStyle="btn--outline"
                buttonSize="btn--large--curve"
                linkTo="/learn_more"
              >
                See More Deal
              </LinkButton>
            </div> */}
        </div>
      </div>
    );
  }

export default Cards;
