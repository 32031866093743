import React, { useContext } from 'react';
import { BusFareDepartContext, BusFareReturnContext, VoucherDiscountContext } from '../UserContext';
import './BusFareSubTotal.css';
import { checkIsReschedule, getSelectedAddOnData } from './Function';


export const BusFareSubTotal = ({
    page,
    bus_fare_price,
    loyalty_discount_amount,
    activities_price,
    ground_transport_price = 0
}) => {
    const addOnData = getSelectedAddOnData();
    // let {BusFareAddOn, setBusFareAddOn} = useContext(BusFareAddOnContext);
    const { BusFareDepart, setBusFareDepart } = useContext(BusFareDepartContext);
    const { BusFareReturn, setBusFareReturn } = useContext(BusFareReturnContext);
    const { VoucherDiscount, setVoucherDiscount } = useContext(VoucherDiscountContext);
    const checkReschedule = checkIsReschedule();

    const BusFareAddOn = JSON.parse(sessionStorage.getItem("addOn"));
    let TotalBusFare = BusFareDepart + BusFareReturn;
    if (page == 'payment') {
        TotalBusFare = parseInt(sessionStorage.getItem("busFare"));
        loyalty_discount_amount = JSON.parse(localStorage.getItem("loyalty"));
        // console.log(TotalBusFare);
    }
    else {
        localStorage.setItem("loyalty", JSON.stringify(loyalty_discount_amount));
    }
    // console.log(BusFareAddOn)
    let total_addOn = 0;
    if(addOnData != null && addOnData.length != 0) {
      addOnData.forEach(element => {
        element.details.forEach(sub => {
          if(sub.subtotal != '') {
            total_addOn += parseFloat(sub.subtotal);
          }
          // console.log("--------------")
          // console.log(sub.qty)
          // console.log(sub.subtotal)
          // console.log(total_addOn)
        })
      });
    }
    else if(checkReschedule == true) {
        var origin_data = sessionStorage.getItem("Origin_data");
        if(origin_data != null) {
            origin_data = JSON.parse(origin_data)
        }
        if(origin_data.AddOnData != null && origin_data.AddOnData.length != 0) {
            origin_data.AddOnData.forEach(element => {
                element.forEach(el => {
                    total_addOn += parseFloat(el.totalamt)
                })
            });
        }
    }
    // console.log(total_addOn)
    // console.log(BusFareAddOn)
    if(VoucherDiscount > TotalBusFare) {
        setVoucherDiscount(TotalBusFare)
    }
    return (
        <>
            <div>
                <div className='bus-fare-sub-total'>
                    <table className='subTotal_table' width={'100%'}>
                        <tr>
                            <td><h6>Bus Fare</h6></td>
                            <td><h3>RM {checkReschedule ? 0 : TotalBusFare.toFixed(2)}</h3></td>
                        </tr>
                        {checkReschedule ? (
                            <tr>
                                <td><h6>Admin Charge</h6></td>
                                <td><h3>RM {sessionStorage.getItem("rescheduleAmount")}</h3></td>
                            </tr>
                        ) : ("")}
                        {loyalty_discount_amount != 0 ? (
                            <tr>
                                <td className='SubTotal_loyaltyDiscount'><h6>- Loyalty Discount</h6></td>
                                <td className='SubTotal_loyaltyDiscount'><h3>-RM {loyalty_discount_amount}</h3></td>
                            </tr>
                        ) : ("")}
                        {VoucherDiscount != 0 ? (
                            <tr>
                                <td className='SubTotal_loyaltyDiscount'><h6>- Promo Code Discount</h6></td>
                                <td className='SubTotal_loyaltyDiscount'><h3>-RM {VoucherDiscount}</h3></td>
                            </tr>
                        ) : ("")}

                        {total_addOn != 0 ? (
                        <tr>
                            <td><h6>Activities</h6></td>
                            <td><h3>RM {total_addOn}</h3></td>
                        </tr>
                        ) : ("")}
                        {/* {ground_transport_price != null ? (
                        <tr>
                            <td><h6>Ground Transport</h6></td>
                            <td><h3>RM 0</h3></td>
                        </tr>
                        ) : ("")} */}
                    </table>
                </div>
            </div>
        </>
    );
}