import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './LinkButton.css';

const STYLES = ['btn--primary', 'btn--outline', 'btn--test', 'btn--print--ticket', 'btn--print--ticket--rectangle', 'btn--print--ticket--full--width', 'btn--yellow--white--full--width', 'btn--signUp', 'btn--logIn'];

const SIZES = ['btn--medium', 'btn--large', 'btn--full', 'btn--large--curve', 'btn--find--more', 'btn--large--rectangle', 'btn--ticket', 'btn--change--trip', 'btn--full--curve', 'btn--80w'];

const LINKTO = ['/', '/learn_more', '/pickDepart', '/pickReturn', '/selectSeat', '/checkout', '/sign_in', '/payment', '/booking_status', '/BookingEdit'];


export const LinkButton = ({
  id,
  className,
  children,
  type,
  linkTo,
  icon,
  onClick,
  buttonStyle,
  buttonSize,
  params
}) => {
  const checkButtonStyle = STYLES.includes(buttonStyle)
    ? buttonStyle
    : STYLES[0];

  const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];
  const link = LINKTO.includes(linkTo) ? linkTo : LINKTO[0];
  var allParams = (params != null) ? (
    params.join('&')
  ) : "";

  
  if (allParams) {
    if (allParams != '') {
      allParams = `?${allParams}`;
    }
  }

  const [count, setCount] = useState(0);

  return (
    <Link to={`${link}${allParams}`} >
      <button
        id={id}
        className={`btn ${checkButtonStyle} ${checkButtonSize} ${className}`}
        onClick={onClick}
        type={type}
      >
        {icon != null ? <img src={icon} /> : ""}&nbsp;{children}&nbsp;
      </button>
    </Link>
  );
};
