import React, { useContext, useEffect, useState } from 'react';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { BusFareAddOnContext } from '../UserContext';
import { checkIsReschedule, deleteSelectedAddOnData, getAddOnTicketType, getSelectedAddOnData, getToken, setSelectedAddOnData, tokenIsValid } from './Function';

export const AddOn = ({
    page
    }) => {
const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const checkReschedule = checkIsReschedule();
let origin_data = sessionStorage.getItem("Origin_data");
if(origin_data != null) {
  origin_data = JSON.parse(origin_data);
}
// console.log(origin_data)
let {BusFareAddOn, setBusFareAddOn} = useContext(BusFareAddOnContext);
const [addOnData, setAddOnData] = useState(null);
const [loading, setLoading] = useState(true);
const [error, setError] = useState(null);
let addonSession = localStorage.getItem("addOnSession");
// console.log(getToken())
useEffect(() => {
    // console.log(addonSession)
    if(addonSession != null) {
        addonSession = JSON.parse(addonSession);
    }
    const getAddOnData = async () => {
      try {
        const addOn_response = await fetch(
          `${SERVER_URL}/order/addon/${addonSession}`,
          {
            headers: {
                'Authorization': getToken()
            }
          }
        );
        if (!addOn_response.ok) {
          throw new Error(
            `This is an HTTP error: The status is ${addOn_response.status}`
          );
        }
        else {
          let addOn_Data = await addOn_response.json();
          setAddOnData(addOn_Data);
          if(addOn_Data != 'null' && addOn_Data != null && addOn_Data.length != 0) {
            setSelectedAddOnData(addOn_Data);
          }
          setError(null);
          // console.log(addOn_Data)
          localStorage.removeItem("addOnSession");
          window.location.reload();
        }
      } catch(err) {
        setError(err.message);
        setAddOnData(null);
        // console.log("Error")
        // console.log(err.message)
    } finally {
        setLoading(false);
      }  
    }
    tokenIsValid()
    if(addonSession != null) {
      getAddOnData()
    }
  }, [getToken()])
  useEffect(() => {
    if(loading === false) {
        localStorage.removeItem("addOnSession");
    }
  }, [addonSession])
  // console.log(addOnData)
  // console.log(getSelectedAddOnData())
  // console.log((localStorage.getItem("selected_addon_data")))
const selectedAddOnData = getSelectedAddOnData();
// console.log(selectedAddOnData);
// console.log('add on: '+BusFareAddOn);

function removeAddOn(index, sessionID, labelID) {
    tokenIsValid()
    var addOn_Data = getSelectedAddOnData();
    // console.log(addOn_Data)
    // console.log('delete: '+index)
    deleteSelectedAddOnData(index);
    localStorage.removeItem("addOnSession");
    //call api to chg status
    const update_addOn_api_url = `${SERVER_URL}/order/update/addon/status`;
        
    const update_addOn_requestOptions = {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': getToken()
         },
        body: JSON.stringify({
            "SessionID": sessionID,
            "LabelID": labelID,
            "Status": "N"
        })
    };
    // console.log(update_addOn_requestOptions)
    const updateStatus = async () => {
        try {
            const update_addOn_response = await fetch(
                update_addOn_api_url,update_addOn_requestOptions
            );
            if (!update_addOn_response.ok) {
              throw new Error(
                `This is an HTTP error: The status is ${update_addOn_response.status}`
              );
            }
            else {
              setError(null);
              // console.log(update_addOn_response);
              localStorage.removeItem("addOnSession");
              alert("Item Removed")
              window.location.reload();
            }
        } catch(err) {
            // console.log(err.message);
            alert(err.message);
            setError(err.message);
        }
        }
        updateStatus();

}

if(selectedAddOnData !== null && selectedAddOnData.length !== 0) {
  return (
    <>
    <div className='bus_fare_add_on'>
        <Row>
            <Col lg={12}>
                <div className='add-On-container'>
                <div className='text-in-line'>
                            <h2>Add On</h2>
                        </div>
                    <div className='arrow-add-on'>
                        
                    </div>
                </div>
            </Col>
        </Row>
        {selectedAddOnData.map(({ id,name,totalqty,traveldate,totalamt,details,sessionid}, index) => (
        <div className='bus-fare-add-on'>
        <Row>
            <table className='mt-4 add-on-table'>
                <tr>
                    <td width={'70%'} colSpan={2}><h4>{name}</h4></td>
                    <td width={'30%'}><img className='cursor_pointer' onClick={()=>removeAddOn(index,sessionid,id)} src = "./images/ic_delete_addon.png" /></td>
                    <td ></td>
                </tr>
                {details.map(({ id,name,qty,subtotal}, index) => (
                <tr>
                    <td width={'50%'}><h6>{getAddOnTicketType(name)}</h6></td>
                    <td><h6>x {qty}</h6></td>
                    <td><h5>RM {subtotal != '' ? subtotal : 0}</h5></td>
                </tr>
                ))}
            </table>
        </Row>
        </div>
        ))}
        </div>
    </>
  );
}
else if(checkReschedule == true && origin_data.AddOnData != null && origin_data.AddOnData.length != 0) {
  return (
    <>
    <div className='bus_fare_add_on'>
        <Row>
            <Col lg={12}>
                <div className='add-On-container'>
                <div className='text-in-line'>
                            <h2>Add On</h2>
                        </div>
                    <div className='arrow-add-on'>
                        
                    </div>
                </div>
            </Col>
        </Row>
        {origin_data.AddOnData.map(({ }, index) => (
        <div className='bus-fare-add-on'>
        <Row>
            <table className='mt-4 add-on-table'>
              {origin_data.AddOnData[index].map(({details,imageurl,id,name,sessionid,totalamt,totalqty,traveldate}, index) => (
                <>
                  <tr>
                      <td width={'70%'} colSpan={2}><h4>{name}</h4></td>
                      <td></td>
                      <td></td>
                  </tr>
                  {details.map(({ id,name,qty,subtotal}, index) => (
                  <tr>
                      <td width={'50%'}><h6>{getAddOnTicketType(name)}</h6></td>
                      <td><h6>x {qty}</h6></td>
                      <td><h5>RM {subtotal != '' ? subtotal : 0}</h5></td>
                  </tr>
                  ))}
                </>
              ))}
            </table>
        </Row>
        </div>
        ))}
        </div>
    </>
  );
}
}