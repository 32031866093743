import React, { useContext } from 'react';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { Link } from 'react-router-dom';
import { BusFareDepartContext, BusFareReturnContext, ChildAmountContext, SelectSeatDepartContext, SelectSeatReturnContext, TotalBusFareContext } from '../UserContext';
import { checkIsSgdTrip, getMonetaryUnitBasedOnCurrency, checkIsReschedule, getAMorPMonTime, getSubPlaceShortName } from "./Function";
import { useSearchParams } from 'react-router-dom';

  
export const BusFareSummary = ({
    include,
    depart_unique_name,
    destination_unique_name,
    depart,
    selectedSeat = [],
    destination,
    depart_date,
    return_date,
    passenger,
    change_trip,
    depart_info,
    return_info
    }) => {
    let Fare_total = 0;
    const checkReschedule = checkIsReschedule();
    const monetary_unit = getMonetaryUnitBasedOnCurrency();
    const {childAmount, setChildAmount} = useContext(ChildAmountContext);
    const [searchParams, setSearchParams] = useSearchParams();
    const request_id = searchParams.get("request_id") ?? "";
    // const child_discount = 2;
    if(checkReschedule == true) {
        if(sessionStorage.getItem("Origin_data")!=null) {
            const reschedule_origin_data = JSON.parse(sessionStorage.getItem("Origin_data"));
            depart_date = depart_info.departure_time.split(" ")[0];
            if(return_info != null) {
                return_date = return_info.departure_time.split(" ")[0];
            }
            passenger = reschedule_origin_data.Passenger;
        }
    }
    // const [child_discount,setChildDiscount] = useState(0);
    // console.log(depart_info);
    // console.log(depart_info.fare);
    // console.log(depart_info.child_fare);
    // console.log(childAmount);
    let converted_return_date = null;
    const {selectedDepartSeat, setDepartSeat} = useContext(SelectSeatDepartContext);
    const {selectedReturnSeat, setReturnSeat} = useContext(SelectSeatReturnContext);
    const {BusFareDepart, setBusFareDepart} = useContext(BusFareDepartContext);
    const {BusFareReturn, setBusFareReturn} = useContext(BusFareReturnContext);
    const {TotalBusFare, setTotalBusFare} = useContext(TotalBusFareContext);
    

    // // console.log('selected:'+ selectedSeat[0].DisplayCode);
    // console.log('length: ' + selectedSeat.length);
    function selectedSeatFunction() {
        if(selectedSeat.length === 0) {
            return 'Not Selected';
        }
        else if(selectedSeat.length === 1) {
            return selectedSeat[0].DisplayCode;
        }
        else {
            var array = [];
            for(var i=0;i<selectedSeat.length;++i) {
                array.push(selectedSeat[i].DisplayCode)
            }
            var stringSelected = array.join(',');
            // console.log(array)
            return stringSelected;
        }
    }

    // function updateBusFare(fare) {
    //     setTotalBusFare(TotalBusFare=>[...TotalBusFare,fare]);
    // }
    if(return_info != null) {
        const selected_return_date = (return_info.departure_time.split(" ")[0]);
        // console.log(selected_return_date)
        converted_return_date = new Intl.DateTimeFormat('en', 
        {
            year: 'numeric', 
            month: 'short',
            day: '2-digit',
            weekday: 'long'
        }
        ).format(Date.parse(selected_return_date));
        // console.log(converted_return_date);
    }

  const converted_depart_date = new Intl.DateTimeFormat('en', 
    {
        year: 'numeric', 
        month: 'short',
        day: '2-digit',
        weekday: 'long'
    }
).format(Date.parse(depart_date));

if(include == 'depart') {
    const selected_depart_time = getAMorPMonTime(depart_info.departure_time.split(" ")[1]);
    let params = `depart=${depart_unique_name}&destination=${destination_unique_name}&depart_date=${depart_date}&return_date=${return_date}&passenger=${passenger}&request_id=${request_id}`;
    let href = `${change_trip}?${params}`;
    if(checkReschedule) {
        href = change_trip;
    }
    // const child_discount = 2.2849.toFixed(2);
    let child_discount = (depart_info.fare - depart_info.child_fare).toFixed(2);
    // console.log(child_discount)
    function getDepartFare(fare) {
        setBusFareDepart(fare);
        if(checkReschedule) {
            return 0;
        }
        else {
            return fare.toFixed(2);
        }
    }
    // console.log(depart_info);
    Fare_total = depart_info.fare * selectedSeat.length - (child_discount*childAmount);
    if(checkIsSgdTrip()) {
        child_discount = (depart_info.AdultPriceInMYR - depart_info.ChildPriceInMYR).toFixed(2);
        Fare_total = depart_info.AdultPriceInMYR * selectedSeat.length - (child_discount*childAmount);
    }
    // console.log('money:'+Fare_total);
    // console.log('bus fare:'+TotalBusFare);
  return (
    <><Row>
        <Col sm={12}>
        
        <div className='arrow-container'>
            <div className='text-in-line'>
                {getSubPlaceShortName(depart)}
            </div>
            <div className='arrow-right'>
                
            </div>
        </div>

        <div className='arrow-container ms-left'>
            <div className='text-in-line'>
                {getSubPlaceShortName(destination)}
            </div>
            <div className='arrow-right-front'></div>
        </div>
        </Col>
    </Row>
    <Row>
        <Col>
            <table width={'100%'}>
                <tr>
                    <td>
                        <h6>Date Selected</h6>
                    </td>
                    <td>
                        <h5>{converted_depart_date}</h5>
                    </td>
                </tr>
                <tr>
                    <td>
                        <h6>Departure Time</h6>
                    </td>
                    <td>
                        <h5>{selected_depart_time}</h5>
                    </td>
                </tr>
                <tr>
                    <td>
                        <h6>Seat Selected</h6>
                    </td>
                    <td>
                        <h5>{selectedSeatFunction()}</h5>
                    </td>
                </tr>
                <tr>
                    <td>
                        <h6>Fare</h6>
                    </td>
                    <td>
                        <h5>RM {getDepartFare(Fare_total)}</h5>
                    </td>
                </tr>
            </table>
                <Link to={href} onClick={()=>{setDepartSeat([]); setReturnSeat([])}}>Change Trip</Link>    
    
        </Col>
    </Row>
    </>
  );
}
if(return_info != null) {
    const selected_return_time = getAMorPMonTime(return_info.departure_time.split(" ")[1]);
    // let str_depart_info = JSON.stringify(depart_info);
    let params = `depart=${depart_unique_name}&destination=${destination_unique_name}&depart_date=${depart_date}&return_date=${return_date}&passenger=${passenger}&depart_info=${depart_info}&request_id=${request_id}`;
    let href = `${change_trip}?${params}`;
    if(checkReschedule) {
        href = change_trip
    }
    // const child_discount = 8;
    let child_discount = (return_info.fare - return_info.child_fare).toFixed(2);

    function getReturnFare(fare) {
        setBusFareReturn(fare);
        if(checkReschedule) {
            return 0;
        }
        else {
            return fare.toFixed(2);
        }
    }
    Fare_total = return_info.fare * selectedSeat.length - (child_discount*childAmount);
    if(checkIsSgdTrip()) {
        child_discount = (return_info.AdultPriceInMYR - return_info.ChildPriceInMYR).toFixed(2);
        Fare_total = return_info.AdultPriceInMYR * selectedSeat.length - (child_discount*childAmount);
    }
  return (
    <><Row>
        <Col sm={12}>
        
        <div className='arrow-container'>
            <div className='text-in-line'>
                {getSubPlaceShortName(destination)}
            </div>
            <div className='arrow-right'>
                
            </div>
        </div>

        <div className='arrow-container ms-left'>
            <div className='text-in-line'>
                {getSubPlaceShortName(depart)}
            </div>
            <div className='arrow-right-front'></div>
        </div>
        </Col>
    </Row>
    <Row>
        <Col>
            <table width={'100%'}>
                <tr>
                    <td>
                        <h6>Date Selected</h6>
                    </td>
                    <td>
                        <h5>{converted_return_date}</h5>
                    </td>
                </tr>
                <tr>
                    <td>
                        <h6>Departure Time</h6>
                    </td>
                    <td>
                        <h5>{selected_return_time}</h5>
                    </td>
                </tr>
                <tr>
                    <td>
                        <h6>Seat Selected</h6>
                    </td>
                    <td>
                        <h5>{selectedSeatFunction()}</h5>
                    </td>
                </tr>
                <tr>
                    <td>
                        <h6>Fare</h6>
                    </td>
                    <td>
                        <h5>{monetary_unit} {getReturnFare(Fare_total)}</h5>
                    </td>
                </tr>
            </table>               
                <Link to={href} onClick={()=>{setDepartSeat([]); setReturnSeat([])}}>Change Trip</Link>
    
        </Col>
    </Row>
    </>
  );
}
}