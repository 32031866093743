import React, {useState, useEffect} from 'react';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import { VoucherCodeContext,VoucherDiscountContext,OriginContext,LoyaltyIDContext,ChildAmountContext, ReturnDataContext, DepartDataContext, UserContext, SelectSeatDepartContext, SelectSeatReturnContext, TotalBusFareContext, BusFareDepartContext, BusFareReturnContext, BusFareAddOnContext,LoyaltyDiscountContext } from './UserContext';
// import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './components/pages/Home';
import BusSchedule from './components/pages/BusSchedule';
import ManageBooking from './components/pages/ManageBooking';
import Loyalty from './components/pages/loyalty';
import About from './components/pages/About';
import PaymentAck from './components/pages/PaymentAck';
import BookingEdit from './components/pages/BookingEdit';
import PickDepart from './components/pages/PickDepart';
import ProceedComponent from './components/pages/SelectSeat';
import Checkout from './components/pages/Checkout';
import PickReturn from './components/pages/PickReturn';
import { BookingStatus } from './components/pages/BookingStatus';
import MyAccount from './components/pages/MyAccount';
import ScrollToTop from './components/ScrollToTop';
import BookingHistory from './components/pages/BookingHistory';
import ResetPassword from './components/pages/ResetPassword';
import './fonts/gotham-rounded-medium.otf';
import './fonts/gotham-rounded-bold.otf';
import { tokenIsValid, getToken, getUserTokenExpiry, checkWindowLocation, getLoginStatus, clearUserToken, clearUserTokenExpiry } from './components/Function';

function App() {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  let [selectedDepartSeat, setDepartSeat] = useState([]);
  let [selectedReturnSeat, setReturnSeat] = useState([]);
  let [BusFareDepart, setBusFareDepart] = useState(0);
  let [BusFareReturn, setBusFareReturn] = useState(0);
  let [BusFareAddOn, setBusFareAddOn] = useState([]);
  let [TotalBusFare, setTotalBusFare] = useState(0);
  let [TotalLoyalty, setLoyalty] = useState(0);
  let [LoyaltyID, setLoyaltyID] = useState(null);
  let [VoucherDiscount, setVoucherDiscount] = useState(0);
  let [VoucherCode, setVoucherCode] = useState(null);
  let [departData, setDepartData] = useState({});
  let [returnData, setReturnData] = useState({});
  let [childAmount, setChildAmount] = useState(0);
  let [origin, setOrigin] = useState('Johor Bahru');
  const [subplace_data, setSubPlaceData] = useState(null);

  if(localStorage.getItem("currency") == null) {
    localStorage.setItem("currency",JSON.stringify("MYR"));
  }

  useEffect(()=>{
    if(getLoginStatus() == true && getUserTokenExpiry() == null || 
      getLoginStatus() == true && Date.now() > getUserTokenExpiry()) {
      localStorage.removeItem("login");
      localStorage.removeItem("loginStatus");
      localStorage.removeItem("userData");
      localStorage.removeItem("primaryEmail");
      // localStorage.removeItem("primaryPassword");
      localStorage.removeItem("userLoyaltyData");
      localStorage.removeItem("friendsAndFamilyData");
      clearUserToken();
      clearUserTokenExpiry();
      alert('Your session has expired, please Login again')
      sessionStorage.removeItem('rescheduleAmount');
      sessionStorage.removeItem('history_guid');
      sessionStorage.removeItem("addOn");
      sessionStorage.removeItem('oldcartguid');
      sessionStorage.removeItem("Origin_data");
      sessionStorage.removeItem("Destination_data");
      window.location.href = '/';
    }
    // console.log(Date.now())
    // console.log("=====")
    // console.log(getUserTokenExpiry())
  },[])

  useEffect(()=> {
    if(sessionStorage.getItem("Origin_data") != null) {
      const origin_data = JSON.parse(sessionStorage.getItem("Origin_data"));
      // console.log(origin_data)
    //   if(origin_data.PlaceId === 4) {
    //     localStorage.setItem("currency",JSON.stringify("SGD"));
    //   }
    //   else {
        localStorage.setItem("currency",JSON.stringify("MYR"));
    //   }
    }
  },[sessionStorage.getItem("Origin_data")])
  // console.log(window.location.pathname)
  useEffect(() => {
    const getSubPlaceData = async () => {
        try {
            const response = await fetch(`${SERVER_URL}/me/get/subplaces`,
            {
                headers: {
                  'Authorization': getToken()
                }
            });
            if (!response.ok) {
                throw new Error(
                    `This is an HTTP error: The status is ${response.status}`
                );
            }
            let actualData = await response.json();
            setSubPlaceData(actualData);
            sessionStorage.setItem("subplace_data",JSON.stringify(actualData));
        } catch (err) {
            setSubPlaceData(null);
        }
    }
    tokenIsValid()
    if(getToken() != null) {
      getSubPlaceData()
    }
}, [getToken()])

  return (
    <>
        <Router>
          <ScrollToTop />
            <DepartDataContext.Provider value={{departData, setDepartData}}>
            <ReturnDataContext.Provider value={{returnData, setReturnData}}>
            <SelectSeatDepartContext.Provider value={{selectedDepartSeat, setDepartSeat}}>
            <SelectSeatReturnContext.Provider value={{selectedReturnSeat, setReturnSeat}}>
              <TotalBusFareContext.Provider value={{TotalBusFare, setTotalBusFare}}>
              <BusFareDepartContext.Provider value={{BusFareDepart, setBusFareDepart}}>
              <BusFareReturnContext.Provider value={{BusFareReturn, setBusFareReturn}}>
              <BusFareAddOnContext.Provider value={{BusFareAddOn, setBusFareAddOn}}>
              <LoyaltyDiscountContext.Provider value={{TotalLoyalty, setLoyalty}}>
              <LoyaltyIDContext.Provider value={{LoyaltyID, setLoyaltyID}}>
              <ChildAmountContext.Provider value={{childAmount, setChildAmount}}>
              <OriginContext.Provider value={{origin, setOrigin}}>
              <VoucherDiscountContext.Provider value={{VoucherDiscount, setVoucherDiscount}}>
              <VoucherCodeContext.Provider value={{VoucherCode, setVoucherCode}}>
                  {window.location.pathname.includes('/resetPassword') ? null : <Navbar /> }
                    <Routes>
                        <Route exact path='/' element={<Home subplace_data={subplace_data} />} />
                        <Route path='/resetPassword' element={<ResetPassword />} />
                        <Route path='/busSchedule' element={<BusSchedule />} />
                        <Route path='/manageBooking' element={<ManageBooking />} />
                        <Route path='/loyalty' element={<Loyalty />} />
                        <Route path='/about' element={<About />} />
                        <Route path='/bookingEdit' element={<BookingEdit />} />
                        
                        <Route path='/pickDepart' element={<PickDepart subplace_data={subplace_data} />} />
                        <Route path='/pickReturn' element={<PickReturn subplace_data={subplace_data} />} />
                        <Route path='/selectSeat' element={<ProceedComponent />} />
                        <Route path='/checkout' element={<Checkout />} />
                        <Route path='/PaymentAck' element={<PaymentAck />} />

                        <Route path='/booking_status' element={<BookingStatus />} />
                        <Route path='/bookingHistory' element={<BookingHistory /> } />
                        <Route path='/myAccount' element={<MyAccount />} />
                    </Routes>
                  {window.location.pathname.includes('/resetPassword') ? null : <Footer /> }
              </VoucherCodeContext.Provider>
              </VoucherDiscountContext.Provider>
              </OriginContext.Provider>
              </ChildAmountContext.Provider>
              </LoyaltyIDContext.Provider>
              </LoyaltyDiscountContext.Provider>
              </BusFareAddOnContext.Provider>
              </BusFareReturnContext.Provider>
              </BusFareDepartContext.Provider>
              </TotalBusFareContext.Provider>
            </SelectSeatReturnContext.Provider>
            </SelectSeatDepartContext.Provider>
            </ReturnDataContext.Provider>
            </DepartDataContext.Provider>
        </Router>
    </>
  )
}

export default App;