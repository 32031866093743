import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';
import { getNextDay, getYYYYMMDDtwoDigit, checkWindowLocationForCurrency, checkIsReschedule, checkWindowLocation, getTokenExpiry, getUserData, getUsername, clearUserToken, clearUserTokenExpiry } from './Function';
import { LoginSignUpModal } from './LoginSignUpModal';
import { useSearchParams } from 'react-router-dom';
import './Navbar.css';

function Navbar_bootstrap() {
	const SERVER_URL = process.env.REACT_APP_SERVER_URL;
	const [searchParams, setSearchParams] = useSearchParams();
	const [login, setLogin] = useState(false);
	const [language, setLanguage] = useState('EN')
	const checkLogin = JSON.parse(localStorage.getItem("login"));
	const userData = getUserData();
	const checkReschedule = checkIsReschedule();
	const [currency, setCurrency] = useState(localStorage.getItem("currency") != null ? JSON.parse(localStorage.getItem("currency")) : "MYR");
	// console.log(login);
	// console.log(localStorage.getItem("currency"));

	const token_expiry = getTokenExpiry();

	// const location = useLocation();

	// // check token validation every time redirect to another page
	// React.useEffect(() => {
	//   if(tokenIsValid() == false) {
	//     generateToken()
	//   }
	// },[location])

	// const MINUTE_MS = 300000;
	// // check token validation every 5 minutes
	// useEffect(() => {
	//   const interval = setInterval(() => {
	//     if(tokenIsValid() == false) {
	//       generateToken()
	//     }
	//   }, MINUTE_MS);

	//   return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
	// }, [])

	//   const getData = async () => {
	//     try {
	//         const response = await fetch(
	//             url,
	//             {
	//                 method: "POST",
	//                 headers: {
	//                     'Authorization': getToken()
	//                 }
	//             }
	//         );
	//         if (!response.ok) {
	//             throw new Error(
	//                 `This is an HTTP error: The status is ${response.status}`
	//             );
	//         }
	//         let actualData = await response.json();

	//         // console.log(actualData);

	//         setData(actualData.Trips);
	//         setError(null);
	//     } catch (err) {
	//         setError(err.message);
	//         setData(null);
	//     } finally {
	//         setLoading(false);
	//     }
	// }

	const handleLanguage = (e) => {
		// console.log(e);
		localStorage.setItem("language", JSON.stringify(e));
		setLanguage(e);
		closeMobileMenu();
	}
	const handleSelect = (e) => {
		// console.log(e);
		localStorage.setItem("currency", JSON.stringify(e));
		setCurrency(e);
		closeMobileMenu();
		// const updateCurrencyResult = checkWindowLocationForCurrency();
		// if(updateCurrencyResult === 'BackToPickDepart') {
		//   //pickReturn page
		//   //back to pick depart
		//   const origin_data = JSON.parse(sessionStorage.getItem("Origin_data"));
		//   const destination_data = JSON.parse(sessionStorage.getItem("Destination_data"));
		//   const data_depart_date = searchParams.get("depart_date")!=null?searchParams.get("depart_date"):origin_data.DepartDate;
		//   const data_return_date = searchParams.get("return_date")!=null?searchParams.get("return_date"):origin_data.ReturnDate;
		//   const data_passenger = searchParams.get("passenger")!=null?searchParams.get("passenger"):origin_data.Passenger;    
		//   let params = `depart=${origin_data.SubPlaceUniqueShortName}&destination=${destination_data.SubPlaceUniqueShortName}&depart_date=${data_depart_date}&return_date=${data_return_date}&passenger=${data_passenger}`;
		//   let href = `/pickDepart?${params}`;
		//   window.location.href = href;
		// }
		// else if(updateCurrencyResult === 'CallApiToGetPrice') {
		//   //selectSeat page, checkout page
		//   //call api to get new currency price
		//   const origin_data = JSON.parse(sessionStorage.getItem("Origin_data"));
		//   const destination_data = JSON.parse(sessionStorage.getItem("Destination_data"));
		//   const depart_data = JSON.parse(sessionStorage.getItem("departData"));
		//   const data_passenger = searchParams.get("passenger")!=null?searchParams.get("passenger"):origin_data.Passenger;
		//   const depart_tripkey = JSON.parse(sessionStorage.getItem("depart_TripKey"));
		//   const return_tripkey = sessionStorage.getItem("return_TripKey") != null ? JSON.parse(sessionStorage.getItem("return_TripKey")) : null;
		//   const departDate = depart_data.departure_time.split(" ")[0];
		//   const nextDay = getNextDay(departDate)
		//   const url = `${SERVER_URL}/eb/get/trips/${currency}/${getYYYYMMDDtwoDigit(departDate)}/${origin_data.PlaceId}/${getYYYYMMDDtwoDigit(nextDay)}/${destination_data.PlaceId}/${data_passenger}`

		//   console.log(origin_data)
		//   console.log(destination_data)
		//   console.log(depart_data)
		//   console.log(data_passenger)
		//   console.log(depart_tripkey)
		//   console.log(return_tripkey)
		// }
	}
	function getLanguage() {
		var lang = (localStorage.getItem("language"));
		if (lang != null) {
			lang = JSON.parse(lang);
			return lang;
		}
		else return language;
	}
	function getCurrency() {
		var curr = localStorage.getItem("currency");
		if (curr != null) {
			curr = JSON.parse(curr);
			return curr;
		}
		else return currency;
	}
	function getLoginStatus() {
		var login_status = (localStorage.getItem("login"));
		if (login_status != null) {
			login_status = JSON.parse(login_status);
			return login_status;
		}
		else return login;
	}
	const login_signUp = getLoginStatus() == true ? "log-in p-0" : "nav-item p-0";
	const hidden = getLoginStatus() == true ? "nav-item p-0" : "log-in p-0";

	const [click, setClick] = useState(false);
	const [button, setButton] = useState(true);
	const [show, setShow] = useState(null);

	const handleClick = () => setClick(!click);
	const closeMobileMenu = () => {
		// var el = document.getElementById('basic-navbar-nav');
		// el.classList.remove('show');
		// var el2 = document.getElementById('basic-navbar-navbar');
		// el2.classList.add('collapsed')
	};
	const closeLoginMenu = () => setShow(false);

	const showButton = () => {
		if (window.innerWidth <= 960) {
			setButton(false);
		} else {
			setButton(true);
		}
	};

	useEffect(() => {
		showButton();
		if (checkLogin) {
			setLogin(checkLogin);
		}
	}, []);

	function loginSignUp() {
		setClick(false);
		setShow(false);
	}

	useEffect(() => {
		if (show == false) {
			setShow(true);
		}
	}, [show === false]);
	// console.log(show);
	window.addEventListener('resize', showButton);
	// console.log(window.location.pathname)

	function logout() {
		localStorage.removeItem("login");
		localStorage.removeItem("loginStatus");
		localStorage.removeItem("userData");
		localStorage.removeItem("primaryEmail");
		// localStorage.removeItem("primaryPassword");
		localStorage.removeItem("userLoyaltyData");
		localStorage.removeItem("friendsAndFamilyData");
		setLogin(false);
		clearUserToken();
		clearUserTokenExpiry();
		if (checkReschedule) {
			sessionStorage.removeItem('rescheduleAmount');
			sessionStorage.removeItem('history_guid');
			sessionStorage.removeItem("addOn");
			sessionStorage.removeItem('oldcartguid');
			sessionStorage.removeItem("Origin_data");
			sessionStorage.removeItem("Destination_data");
			window.location.href = '/';
		}
		else {
			if (checkWindowLocation()) {
				window.location.href = '/';
			}
			else {
				window.location.reload();
			}
		}
	}
	// console.log(checkWindowLocation())
	return (
		<>
			{
				show ?
					<LoginSignUpModal
					/>
					: ""
			}
			{/* <nav className='navbar'>
          <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
            <div className='menu-logo-container'>
              <img className='menu-header' src='images/header_edge.png' alt='logo'/>
              <img className='menu-logo' src='images/logo-1.png' alt='logo' />
            </div>
          </Link>
        <div className='navbar-container'>
          <div className='menu-icon' onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>
          <div className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item'>
              <Link to='/busSchedule' 
              className='nav-links' 
              onClick={closeMobileMenu}>
                Bus Schedule
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/manageBooking'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                My Bookings
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/loyalty'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                Loyalty
              </Link>
            </li>

            <li className='nav-item'>
              <Link
                to='/about'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                About
              </Link>
            </li>
          
            <li className={hidden}>
              <Link to='/myAccount' 
              className='nav-links' 
              onClick={closeMobileMenu}>
                {userData != null ? userData.Username : ""}
                &nbsp;<span className="fas fa-light fa-caret-down"></span>
              </Link>
            </li>

            <li className='nav-item'>
            <a>
              <select className="nav-links p-0 lang" onChange={(e) => handleLanguage(e.target.value)}>
                <option value='En'>En</option>
                <option value='Malay'>Malay</option>
              </select>
              </a>  
            </li>

            <li className='nav-item'>
              <a>
              <select className="nav-links p-1" onChange={(e) => handleSelect(e.target.value)}>
                <option value='MYR'>MYR</option>
                <option value='SGD'>SGD</option>
              </select>
              </a>  
            </li>

            <li className={hidden}>
              <a
              className='nav-links' 
              onClick={()=>logout()}>
                Log Out
              </a>
            </li>

            <li className={login_signUp}>
              <a 
              className='nav-links' 
              onClick={()=>{loginSignUp();}}>
                Log In / Sign Up
              </a>
            </li>
          </div>
        </div>
      </nav> */}
			<Navbar collapseOnSelect bg="light" expand="lg">
				<Navbar.Brand href="#home">
					<Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
						<div className='menu-logo-container'>
							<img className='menu-header' src='images/header_edge.png' alt='logo' />
							<img className='menu-logo' src='images/logo-1.png' alt='logo' />
						</div>
					</Link>
				</Navbar.Brand>
				<Container className='navbar-container g-0'>
					<Navbar.Toggle aria-controls="basic-navbar-nav" id='basic-navbar-navbar' />
					<Navbar.Collapse id="basic-navbar-nav" className='nav_collapse'>
						<Nav className="me-auto">
							{/* <Nav.Link className='p-0'>              
            <Link to='/busSchedule' 
              className='nav-links' 
              onClick={closeMobileMenu}>
                Bus Schedule
              </Link>
              </Nav.Link> */}
							<Nav.Link className={hidden} bsPrefix="nav_link">
								<Link
									to='/manageBooking'
									className='nav-links'
									onClick={closeMobileMenu}
								>
									My Bookings
								</Link>
							</Nav.Link>
							{/* <Nav.Link className='p-0'>              
            <Link
                to='/loyalty'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                Loyalty
              </Link>
              </Nav.Link> */}
							{/* <Nav.Link className='p-0'>              
            <Link
                to='/about'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                About
              </Link>
              </Nav.Link> */}
							<Nav.Link className={hidden}>
								<Link to='/myAccount'
									className='nav-links'
									onClick={closeMobileMenu}>
									{userData != null ? userData.fullname != '' ? userData.fullname : "Profile" : getUsername}
									<span className="fas fa-light fa-caret-down ms-1"></span>
								</Link>
							</Nav.Link>
							{/* <NavDropdown className='nav-links' title={getLanguage()} id="basic-nav-dropdown">
              <NavDropdown.Item onClick={()=>handleLanguage("En")}>
                En
              </NavDropdown.Item>
              <NavDropdown.Item onClick={()=>handleLanguage("Malay")}>
                Malay
              </NavDropdown.Item>
            </NavDropdown> */}
							{/* <Nav.Link bsPrefix='g-0'>              
              <a
              className='nav-links'
              >
                En
              </a>
              </Nav.Link> */}
							{/* <NavDropdown className='nav-links' title={getCurrency()} id="basic-nav-dropdown">
              <NavDropdown.Item onClick={()=>handleSelect("MYR")}>
                MYR
              </NavDropdown.Item>
              <NavDropdown.Item onClick={()=>handleSelect("SGD")}>
                SGD
              </NavDropdown.Item>
            </NavDropdown> */}
							<Nav.Link className={hidden}>
								<a
									className='nav-links'
									onClick={() => logout()}>
									Log Out
								</a>
							</Nav.Link>
							<Nav.Link className={login_signUp}>
								<a
									className='nav-links'
									onClick={() => { loginSignUp(); }}>
									Log In / Sign Up
								</a>
							</Nav.Link>
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
		</>
	);
}

export default Navbar_bootstrap;
