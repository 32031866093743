import React from 'react';
import Col from 'react-bootstrap/esm/Col';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import './BookingAddOn.css';
import { getAddOnTicketType } from './Function';

function BookingAddOn({addon}) {
    // console.log(addon)
if(addon != null && addon.length != 0 && addon[0] != null && addon[0] != "null" && addon != []) {
    return (
        <>
    <Container>
            <div className='upcoming-booking-container'>
                <div className='table-container'>
            <Row className="clear-margin">
                <Col className='background-blue'>
                    <h1>ADD ON</h1>
                </Col>    
            </Row>
            <Row className="clear-margin p-3">
                <Col sm={12}>
                {addon.map(({}, index) => (
                    <Row className='addOn-container-border p-top-bottom'>
                        {
                            addon[index].map(({details,imageurl,id,name,sessionid,totalamt,totalqty,traveldate},index)=> (
                                <>
                            <Col sm={4}>
                                <img src = {imageurl} />
                            </Col>
                                <Col sm={8}>
                                    <h5>{name}</h5>
                                    
                                <table className='mt-4'>
                                    {details.map(({id,imageurl,name,qty,subtotal},index)=>(
                                        <tr>
                                            <td><h6>{getAddOnTicketType(name)}</h6></td>
                                            <td><h6>x {qty}</h6></td>
                                        </tr>
                                    ))}
                                    {/* <tr>
                                        <td colSpan={2}><h6>* Please collect your ticket at ABC place and BCA place</h6></td>
                                    </tr> */}
                                </table>
                            </Col>
                        </>
                        ))}
                    </Row>
                ))}
                    
                </Col>   
            </Row>  
            </div>
            </div>
        </Container>
        </>
    );
}
}

export default BookingAddOn;