import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import '../../App.css';
import { getToken, tokenIsValid } from '../Function';

function ResetPassword() {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const [searchParams, setSearchParams] = useSearchParams();
  const email = searchParams.get("email");
  const key = searchParams.get("key");
  const [password,setPassword] = useState(null);
  const [rePassword,setRePassword] = useState(null);
  const [passwordError,setPasswordError] = useState(null);
  const [rePasswordError,setRePasswordError] = useState(null);
  if(getToken()== null) {
    tokenIsValid()
  }
  // console.log(password)
  // console.log(email)
  const updatePassword = async () => {
    
    try {
        const response = await fetch(`${SERVER_URL}/user/update/password`,
        {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': getToken()
          },
          body: JSON.stringify(
            {
              "NewPassword":password,
              "PrimaryEmail":email,
              "Key":key
            }
          )
        });
        if (!response.ok) {
            throw new Error(
                `This is an HTTP error: The status is ${response.status}`
            );
        }
        alert("Password updated")
        window.location.href = '/'
    } catch (err) {
      alert("Error, please try again later")
      // console.log(err)
    }
  }

  function checkValidation() {
    if(password == null || password == '') {
        setPasswordError('Password cannot be blank!')
        return false;
    }
    else setPasswordError(null)
    
    if(rePassword == null || rePassword == '') {
        setRePasswordError('Password cannot be blank!')
        return false;
    }
    else setRePasswordError(null)

    if(password.length < 6) {
        setPasswordError('Password must be at least 6 characters!')
        return false;
    }
    else setPasswordError(null)

    if(password.toString() != rePassword.toString()) {
        setRePasswordError("'Confirm Password' must be same with 'Password'!")
        return false;
    }
    else setRePasswordError(null)
    
    setPasswordError(null)
    setRePasswordError(null)
    return true;
    
  }
  function resetPassword() {
    const validation = checkValidation();
    if(validation) {
      updatePassword()
    }
  }
  return (
    <div className='ResetPassword'>
        <div className='password_box'>
            <h1>Reset Password</h1>
            <h6 className='pt-4'>Password</h6>
            <Form.Control key={`password`} type="password" onChange={event =>{setPassword(event.target.value);}} />
            <h5>{passwordError}</h5>
            <h6 className='pt-4'>Confirm Password</h6>
            <Form.Control key={`retype_password`} type="password" onChange={event =>setRePassword(event.target.value)} />
            <h5>{rePasswordError}</h5>
            <button type='button' onClick={resetPassword}>Reset</button>
        </div>
    </div>
  )
}

export default ResetPassword;
