import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import '../../App.css';
import { LoadingScreenModal } from '../LoadingScreenModal';
import { checkIsReschedule, getNextDay, tokenIsValid, getToken } from '../Function';
import { SelectDepartureAndReturn } from '../SelectDepartureAndReturn';
import { SelectDepartureHeader } from '../SelectDepartureHeader';
import { SelectedDeparture } from '../SelectedDeparture';


function PickReturn(props) {
	const SERVER_URL = process.env.REACT_APP_SERVER_URL;
	const checkReschedule = checkIsReschedule();
	const [origin_data, setOriginData] = useState(JSON.parse(sessionStorage.getItem("Origin_data")));
	const [destination_data, setDestinationData] = useState(JSON.parse(sessionStorage.getItem("Destination_data")));
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState(null);
	const [dateDetail, setDateDetail] = useState(null);
	const currency = JSON.parse(localStorage.getItem("currency"));
	const [searchParams, setSearchParams] = useSearchParams();
	const origin_subplace_unique_shortname = searchParams.get("depart");
	const destination_subplace_unique_shortname = searchParams.get("destination");
	const data_depart_date = searchParams.get("depart_date") != null ? searchParams.get("depart_date") : origin_data.DepartDate;
	const data_return_date = searchParams.get("return_date") != null ? searchParams.get("return_date") : origin_data.ReturnDate;
	const data_passenger = searchParams.get("passenger") != null ? searchParams.get("passenger") : origin_data.Passenger;
	const [selected_date, setSelectedDate] = useState(data_return_date != "null" ? data_return_date : data_depart_date);

	// console.log(selected_date)
	if (sessionStorage.getItem("departData") == null) {
		window.location.href = '/';
	}
	const departData = JSON.parse(sessionStorage.getItem("departData"));
	var formattedNextDay = getNextDay(departData.departure_time.split(" ")[0]);

	let getTripsUrl = `${SERVER_URL}/me/get/trips/${getNextDay(selected_date, -5)}/${getNextDay(selected_date, 5)}/${currency}/${selected_date}/${destination_subplace_unique_shortname}/null/${origin_subplace_unique_shortname}/${data_passenger}`
	//  let getTripsUrl = `${SERVER_URL}/me/get/trips/${getNextDay(selected_date,-5)}/${getNextDay(selected_date,5)}/${currency}/${selected_date}/${origin_subplace_unique_shortname}/null/${destination_subplace_unique_shortname}/${data_passenger}`

	if (checkReschedule === true) {
		const origin_data = sessionStorage.getItem("Origin_data") != null ? JSON.parse(sessionStorage.getItem("Origin_data")) : null;
		const destination_data = sessionStorage.getItem("Destination_data") != null ? JSON.parse(sessionStorage.getItem("Destination_data")) : null;
		//  console.log(origin_data)
		//  console.log(destination_data)
		// console.log(sessionStorage.getItem("Origin_data"))
		getTripsUrl = `${SERVER_URL}/me/get/trips/${getNextDay(selected_date, -5)}/${getNextDay(selected_date, 5)}/${currency}/${selected_date}/${destination_data.SubPlaceUniqueShortName}/null/${origin_data.SubPlaceUniqueShortName}/${origin_data.Passenger}`
		//  getTripsUrl = `${SERVER_URL}/me/get/trips/${getNextDay(selected_date,-5)}/${getNextDay(selected_date,5)}/${currency}/${selected_date}/${origin_data.SubPlaceUniqueShortName}/null/${destination_data.SubPlaceUniqueShortName}/${origin_data.Passenger}`
	}

	const handleSelectedDateCallBack = (selected_date) => {
		setSelectedDate(selected_date)
	}

	useEffect(() => {
		setLoading(true);
		const getTripsData = async () => {
			try {
				const response = await fetch(getTripsUrl,
					{
						method: "POST",
						headers: {
							'Authorization': getToken()
						}
					});
				if (!response.ok) {
					throw new Error(
						`This is an HTTP error: The status is ${response.status}`
					);
				}
				let tripsData = await response.json();
				//  console.log(tripsData)
				//  if(checkReschedule === false) {
				//     sessionStorage.setItem("Origin_data",JSON.stringify(tripsData.origin));
				//     sessionStorage.setItem("Destination_data",JSON.stringify(tripsData.destination));
				//     setOriginData(tripsData.origin)
				//     setDestinationData(tripsData.destination)
				//  }
				setData(tripsData.trips.trips);
				setDateDetail(tripsData.tripsummary);
				// if(depart.SubPlaceId !== tripsData.origin.SubPlaceId || destination.SubPlaceId !== tripsData.destination.SubPlaceId) {
				//     window.location.reload();
				// }
			} catch (err) {
				//  console.log(err)
			} finally {
				setLoading(false);
			}
		}
		tokenIsValid()
		if (getToken() != null) {
			getTripsData()
		}
		// getData()
		// getDateDetail()
	}, [currency, selected_date, getToken()])

	//  console.log(data)
	return (
		<>
			{loading && <div className='center'>
				<LoadingScreenModal />
			</div>}
			<SelectDepartureHeader
				subplace_data={props.subplace_data}
				title="Return"
				depart={origin_data}
				destination={destination_data}
				date_depart={data_depart_date}
				date_return={data_return_date}
				passenger={data_passenger}
				edit_search='Edit Search'
			>
			</SelectDepartureHeader>

			<SelectedDeparture
				title="Return"
				depart={origin_data}
				destination={destination_data}
				date_depart={data_depart_date}
				date_return={data_return_date}
				passenger={data_passenger}
				depart_area={origin_data.subplacename}
				destination_area={destination_data.subplacename}
				departure_time={departData.departure_time}
				depart_info={departData}
			/>
			
			<SelectDepartureAndReturn
				title="Return"
				link="/selectSeat"
				depart={origin_data}
				destination={destination_data}
				depart_info={departData}
				handleSelectedDateCallBack={handleSelectedDateCallBack}
				data={data}
				dateDetail={dateDetail}
				origin_subplace_unique_shortname={origin_subplace_unique_shortname}
				destination_subplace_unique_shortname={destination_subplace_unique_shortname}
				date_depart={departData.departure_time.split(" ")[0]} //delete
				date_return={data_return_date}
				passenger={data_passenger}
			/>
		</>
	);
}

export default PickReturn;
