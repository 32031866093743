import React, {useEffect,useState} from 'react';
import '../../App.css';
import Cards from '../Cards';
import HeaderSection from '../HeaderSection';
import Booking from '../Booking';
import Destination from '../Destination';
import NewsLetter from '../NewsLetter';
import Experience from '../Experience';
import { useSearchParams } from 'react-router-dom';
import { LoginSignUpModal } from '../LoginSignUpModal';

function Home(props) {
  // var dt = new Date();
  // dt.setHours( dt.getHours());
  // console.log(`[${dt.toISOString()}]`);
  // console.log(new Date(dt.toISOString()));
  // console.log(new Date())
  // console.log(new Date("October 13, 2014 11:13:00"))
  // console.log(new Date("2022-03-25"))
  // console.log(new Date(2018, 11, 24, 10, 33, 30, 0))
  // console.log(new Date("03/25/2015"))
  // console.log(new Date("Mar 25 2015"))
  // console.log(new Date("25 Mar 2015"))
  // console.log(new Date("2015-03-25T12:00:00Z"))
  // console.log(new Date("2015-03-25T12:00:00-06:30"))
  // console.log(new Date("Mar 25 2015"))
  // console.log(new Date("2022-03-05T00:00:00"))
  // console.log(localStorage)
  // console.log(props.subplace_data)
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const ucf = searchParams.get("ucf");
  if(ucf == 'https://www.causewaylink.com.my/') {
    return <LoginSignUpModal />
  }
//   useEffect(() => {
//     setLoading(true);
//     const getSubPlaceData = async () => {
//         try {
//             const response = await fetch(`${SERVER_URL}/eb/get/subplaces`,
//             {
//                 headers: {
//                   'Authorization': getToken()
//                 }
//             });
//             if (!response.ok) {
//                 throw new Error(
//                     `This is an HTTP error: The status is ${response.status}`
//                 );
//             }
//             let actualData = await response.json();
//             setSubPlaceData(actualData);
//             sessionStorage.setItem("subplace_data",JSON.stringify(actualData));
//             setError(null);
//         } catch (err) {
//             setError(err.message);
//             setSubPlaceData(null);
//         } finally {
//             setLoading(false);
//         }
//     }
//     tokenIsValid()
//     getSubPlaceData()
// }, [getToken()])
  return (
    <>
      <HeaderSection />
      <Booking 
      subplace_data={props.subplace_data}
      />
      <Cards 
      subplace_data={props.subplace_data}
      />
      {/* <Destination /> */}
      <NewsLetter />
      <Experience />
    </>
  );
}

export default Home;
