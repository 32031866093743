// import React from "react";
// export const LoyaltyPagination = ({ currentPage, itemsPerPage, totalItems, onPageChange }) => {
//     const pageNumbers = Math.ceil(totalItems / itemsPerPage);
  
//     const handlePageClick = (pageNumber) => {
//       onPageChange(pageNumber);
//     };
  
//     return (
//       <div>
//         {/* Render page numbers */}
//         {Array.from({ length: pageNumbers }, (_, index) => index + 1).map((pageNumber) => (
//           <button
//             key={pageNumber}
//             onClick={() => handlePageClick(pageNumber)}
//             disabled={pageNumber === currentPage}
//           >
//             {pageNumber}
//           </button>
//         ))}
//       </div>
//     );
//   };

import React from 'react';
import "./LoyaltyPagination.css";

export const LoyaltyPagination = ({ currentPage, itemsPerPage, totalItems, onNextPage, onPreviousPage }) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  return (
    <div className="pagination">
      <button
        className="pagination__button"
        disabled={currentPage === 1}
        onClick={onPreviousPage}
      >
        &lt;
      </button>
      <span className="pagination__current-page">Page {currentPage}</span>
      <span className="pagination__total-pages"> / {totalPages}</span>
      <button
        className="pagination__button"
        disabled={currentPage === totalPages}
        onClick={onNextPage}
      >
        &gt;
      </button>
    </div>
  );
};
  