import React from 'react';
import { Link } from 'react-router-dom';
import { getNextDay, getSubPlaceUniqueName } from './Function';
import { useSearchParams } from 'react-router-dom';

function CardItem(props) {
  const subplace_data = props.subplace_data;
  // console.log(subplace_data);
  const origin = getSubPlaceUniqueName(props.origin,props.title);
  const destination = getSubPlaceUniqueName(props.title);
  const today = new Date();
  const nextDay = getNextDay(today);
  const [searchParams, setSearchParams] = useSearchParams();
  const request_id = searchParams.get("request_id") ?? "";
  // console.log(props.origin);
  // console.log(props.title);
  // console.log(origin);
  // console.log(destination);
  const params = `depart=${origin}&destination=${destination}&depart_date=${nextDay}&return_date=null&passenger=1&request_id=${request_id}`;
  const linkto = `${props.path}?${params}`;
  const setOrigin_Destination = () => {
    if(subplace_data!=null) {
      localStorage.setItem("currency",JSON.stringify("MYR"));
      sessionStorage.removeItem("Origin_data");
      sessionStorage.removeItem("Destination_data");
      sessionStorage.removeItem("departData");
      sessionStorage.removeItem("returnData");
      sessionStorage.removeItem("busFare");
      localStorage.removeItem("loyalty");
      sessionStorage.removeItem("addOn");
      sessionStorage.removeItem("selectedDepartSeat");
      sessionStorage.removeItem("selectedReturnSeat");
      localStorage.removeItem("selected_addon_data")
      localStorage.removeItem("addOnSession");
      sessionStorage.removeItem("addon_data");
      sessionStorage.removeItem("depart_TripKey");
      sessionStorage.removeItem("return_TripKey");
      sessionStorage.removeItem('rescheduleAmount');
      sessionStorage.removeItem('history_guid');
      sessionStorage.removeItem("addOn");
      sessionStorage.removeItem('oldcartguid');
      for (let i = 0; i < subplace_data.length; i++) {
        for (let j = 0; j < subplace_data[i].subplaces.length; j++) {
            if(subplace_data[i].subplaces[j].subplaceuniqueshortname === origin) {
                sessionStorage.setItem("Origin_data",JSON.stringify(
                    {
                        "PlaceId":subplace_data[i].placeid, 
                        "PlaceName":subplace_data[i].placename, 
                        "PlaceUniqueShortName":subplace_data[i].placeuniqueshortname, 
                        "SubPlaceId":subplace_data[i].subplaces[j].subplaceid, 
                        "SubPlaceName":subplace_data[i].subplaces[j].subplacename, 
                        "SubPlaceUniqueShortName":subplace_data[i].subplaces[j].subplaceuniqueshortname
                    }
                )
              )
            } 
            if(subplace_data[i].subplaces[j].subplaceuniqueshortname === destination) {
              sessionStorage.setItem("Destination_data",JSON.stringify(
                  {
                      "PlaceId":subplace_data[i].placeid, 
                      "PlaceName":subplace_data[i].placename, 
                      "PlaceUniqueShortName":subplace_data[i].placeuniqueshortname, 
                      "SubPlaceId":subplace_data[i].subplaces[j].subplaceid, 
                      "SubPlaceName":subplace_data[i].subplaces[j].subplacename, 
                      "SubPlaceUniqueShortName":subplace_data[i].subplaces[j].subplaceuniqueshortname
                  }
              )
            )
          }             
        }
      }
    }
  }
      
  return (
    <>
      <li className='cards__item'>
          {
            props.title!='' ?
            <>
              <Link className='cards__item__link' onClick={()=>setOrigin_Destination()} to={linkto}>
                <figure className='cards__item__pic-wrap'>
                      <img
                        className='cards__item__img'
                        alt='Travel Image'
                        src={props.src}
                      />
                </figure>
                <div className='cards__item__info img-grid'>
                  <h5 className='cards__item__title header-left'>{props.title}</h5>
                  <h6 className='cards__item__text left'>{props.text}</h6>
                  <h5 className='cards__item__price header-right'>from MYR {props.price}</h5>
                  <h6 className='cards__item__text right'>{props.return}</h6>
                </div>
              </Link>
            </>
            : ""
          }
      </li>
    </>
  );
}

export default CardItem;
