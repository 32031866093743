import 'bootstrap/dist/css/bootstrap.min.css';
import React from "react";
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import DestinationTravelItem from "./DestinationTravelItem";
import "./Experience.css";
import ExperienceItem from "./ExperienceItem";

function Experience() {
    return (
        <Container>
      <Row>
        <Col md={6} className="p-0">
            <div className="experience-background">
                <div className="text-box-item">
                    <DestinationTravelItem 
                        text="EXPERIENCE"
                        title="The Ultimate Coach Experience"
                        description="First class experience, economy pricing"
                    />
                </div>
            </div>
        </Col>
        <Col md={6}>
            <Row>
                <Col md={6}  className="p-0 border-1" >
                <div className ='experience-four'>
                    <ExperienceItem
                    src='images/ic_plan.png'
                    title='Plan Your Trip'
                    text='Explore with comfort in our bus coach'
                    path='https://www.causewaylink.com.my'
                    />
                </div>
                </Col>
                <Col md={6}  className="p-0 border-1">
                <div className ='experience-four'>
                    <ExperienceItem
                    src='images/ic_booking.png'
                    title='Manage Booking'
                    text='Modify and make changes before your ride'
                    path='https://www.causewaylink.com.my'
                    />
                </div>
                </Col>
            </Row>
            <Row>
                <Col md={6}  className="p-0 border-1">
                <div className ='experience-four'>
                    <ExperienceItem
                    src='images/ic_loyalty.png'
                    title='Loyalty Program'
                    text='Discover a world of rewards with our 
                    loyalty programme'
                    path='https://www.causewaylink.com.my/others-information/loyalty-program/'
                    />
                </div>                
                </Col>
                <Col md={6}  className="p-0 border-1">
                <div className ='experience-four'>
                    <ExperienceItem
                    src='images/ic_help.png'
                    title='Help'
                    text='For any enquiries or assistance, contact us'
                    path='https://www.causewaylink.com.my/help/'
                    />
                </div>
                </Col>
            </Row>
        </Col>
      </Row>
      </Container>
    )
}

export default Experience;