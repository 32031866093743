import React from "react";
import "../App.css";
import "./HeaderSection.css";
import { Spacer } from "./Spacer";
// import video from 'video-2.mp4';

function HeaderSection() {
  return (
    <div className="Header-container">
      {/* <video src={video} autoPlay loop muted type='video/mp4' /> */}
      <div className="text-box">
        <h1>Get RM10</h1>
        <p className="right">When you spend RM200!</p>
        <div className="Header-btns right">
        <Spacer axis="vertical" size={8} />
          <a
            className="btns btn--outline btn--small"
            style={{textDecoration:'none'}}
            href="https://www.causewaylink.com.my/others-information/loyalty-program/"
          >Learn More</a>

          {/* <LinkButton
            className="btns"
            buttonStyle="btn--outline"
            buttonSize="btn--large"
            linkTo="https://www.causewaylink.com.my/others-information/loyalty-program/"
            children="Learn More"
          >
          </LinkButton> */}
        </div>
      </div>
    </div>
  );
}

export default HeaderSection;
