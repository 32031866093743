import { createContext } from "react";

export const UserContext = createContext(null);

export const OriginContext = createContext('Johor Bahru');

export const SelectSeatDepartContext = createContext([]);

export const SelectSeatReturnContext = createContext([]);

export const TotalBusFareContext = createContext(0);

export const BusFareDepartContext = createContext(0);

export const BusFareReturnContext = createContext(0);

export const BusFareAddOnContext = createContext([]);

export const LoyaltyDiscountContext = createContext(0);

export const LoyaltyIDContext = createContext(0);

export const ChildAmountContext = createContext(0);

export const DepartDataContext = createContext({});

export const ReturnDataContext = createContext({});

export const VoucherDiscountContext = createContext(0);

export const VoucherCodeContext = createContext(null);