// import downloadjs from 'downloadjs';
import React from 'react';
import Col from 'react-bootstrap/esm/Col';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import './BookingHeader.css';

export const BookingHeader = ({
	booking_status,
	trip_id,
	historyData,
	amount,
	printTicket = null,
	ticketPDF,
	ticket_id,
	ticket_id_hash,
	editBooking = false,
	email
}) => {
	// let ConvertStringToHTML = function (str) {
	//     let parser = new DOMParser();
	//     let doc = parser.parseFromString(str, 'text/html');
	//     return doc.body;
	//  };
	// console.log(ticketPDF)
	const print_ticket = async () => {
		if (ticketPDF != null && ticketPDF != "") {
			window.open(`http://res.causewaylink.com.my/pdf/${ticketPDF}`);
		}
		else if (ticket_id != null && ticket_id_hash != null) {
			window.open(`https://www.causewaylink.com.my/cwlexpress_order_summary.php?ticket_id=${ticket_id}&key=${ticket_id_hash}`);
		}
		else {
			alert('Error while generate ticket, please try again later');
		}
		// try {
		//     const response = await fetch(`http://res.causewaylink.com.my/pdf/Ticket--ilmcehdw.xyr.pdf`);
		//     if (!response.ok) {
		//         throw new Error(
		//             `This is an HTTP error: The status is ${response.status}`
		//         );
		//     }
		//     let pdf = await response.json();
		//     downloadjs(response, {ticketPDF}, 'text/pdf');
		// } catch (err) {
		//     console.log(err)
		// }
	}

	// console.log(trip_id)
	// console.log(historyData)
	// console.log(email)
	const statusIcon = booking_status == 'Confirmed' ? "images/ic_confirm.png" : "";
	// const EditBooking = editBooking != null ? editBooking : "";

	if (booking_status === 'Confirmed' && trip_id != null) {
		return (
			<>
				<div className='booking_header' id='test'>
					<Container>
						<Row className='flex-center'>
							<Col sm={printTicket == null ? 12 : 8}>

								{!editBooking && (
									<div className='booking_header_status'>
										<h5 className='status no-border'>
											Your Booking is <span className='booking-status'>{booking_status}</span>
											<img src={`${statusIcon}`} alt={booking_status} />
										</h5>
										<h6>
											Your Boarding Code is <strong>{trip_id}</strong>.
											{email && email !== 'null' ? (
												<>All details will be sent to <strong>{email}</strong></>
											) : ""}
										</h6>
									</div>
								)}

							</Col>
							<Col sm={4}>
								{
									printTicket != null ?
										<button
											className='printTicket'
											onClick={print_ticket}
										>
											<img src="images/ic_print.png" />&nbsp;{printTicket}&nbsp;
										</button>
										: ""
								}
							</Col>
							{/* {
                                editBooking == true ?
                                <Col sm={3}>
                                    <LinkButton          
                                    buttonStyle="btn--print--ticket"
                                    buttonSize="btn--ticket"
                                    linkTo="/BookingEdit"
                                    icon="images/ic_edit.png"
                                    children='Edit Booking'
                                    >
                                    </LinkButton>
                                </Col>
                                :""
                            } */}
						</Row>
					</Container>
				</div>
			</>
		)
	}
	else if (booking_status == 'Pending Confirmation') {
		return (
			<>
				<div className='booking_header'>
					<Container>
						<Row className='flex-center'>
							<Col sm={12} lg={12}>
								<div className='booking_header_status'>
									<h5 className='status no-border'>Your Booking is <span className='booking-status-pending'>{booking_status}</span>
									</h5>
									<h6>Your booking seems to be taking longer than usual to confirm.
									</h6>
									<h6>
										Your booking is currently pending confirmation, and the processing time is approximately 0-15 minutes.  We will send all details to <strong>{email}</strong> once confirmation has been received.
									</h6>
								</div>
							</Col>
						</Row>
					</Container>
				</div>
			</>
		)
	}
	else return (
		<>
			<div className='booking_header'>
				<Container>
					<Row className='flex-center'>
						<Col sm={12} lg={12}>
							<div className='booking_header_status'>
								<h5 className='status'>Your Booking was <span className='booking-status-failed'>Unsuccessful{/*booking_status*/}</span>
								</h5>
							</div>
						</Col>

						{/* <Col sm={6} lg={3}>
                            <div className='booking_header_id'>
                                <h5>Booking ID: <span>{trip_id}</span></h5>
                            </div>
                        </Col>
                        <Col sm={6} lg={2}>
                            <div className='booking_header_amount'>
                                <h5>Total Paid: <span>RM {amount}</span></h5>

                            </div>
                        </Col>
                        <Col sm={6} lg={3}>
                            <LinkButton
                                className=""
                                buttonStyle="btn--print--ticket"
                                buttonSize="btn--ticket"
                                linkTo=""
                                icon="images/ic_print.png"
                                // children={printTicket}
                            >
                            </LinkButton>
                        </Col> */}
					</Row>
				</Container>
			</div>
		</>
	);
}

