import React from 'react';
import { useSearchParams } from 'react-router-dom';
import BookingFailed from '../BookingFailed';
import BookingSuccessful from '../BookingSuccessful';


export const BookingStatus = ({

}) => {
const booking_status = true;


  const [searchParams, setSearchParams] = useSearchParams();
  const data_depart = searchParams.get("depart");
  const data_destination = searchParams.get("destination");
  const data_depart_date = searchParams.get("depart_date");
  const data_return_date = searchParams.get("return_date");
  const data_passenger = searchParams.get("passenger");
  const depart_info = searchParams.get("depart_info");
  const obj_depart_info = JSON.parse(depart_info);
  // console.log(obj_depart_info);
  const return_info = searchParams.get("return_info");
  const obj_return_info = JSON.parse(return_info);

//   const {departData, setDepartData} = useContext(DepartDataContext);
//   const {returnData, setReturnData} = useContext(ReturnDataContext);
  const departData = JSON.parse(sessionStorage.getItem("departData"));
  const returnData = JSON.parse(sessionStorage.getItem("returnData"));
  
if(booking_status) {
    return (
        <>
            <BookingSuccessful 
                booking_status = "Confirmed"
                printTicket="Print Ticket"
                editBooking="Edit Booking"
                depart_info = {departData}
                return_info = {returnData}
                data_depart = {data_depart}
                data_destination = {data_destination}
                data_passenger = {data_passenger} 
            />
        </>
    );
}
else {
    return (
        <>
            <BookingFailed />
        </>
    )
}
}