import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { getAMorPMonTime, getEstimateDateTimeArrival, getEstimateTimeArrival, getEstimateTimeDurationInMinutes } from './Function';
import './MoreDetailsPopOutModal.css';

export const MoreDetailsPopOutModal = ({
    title,
    depart,
    destination,
    details
}) => {
    const [show, setShow] = useState(true);
    const [showDetailsInfo, setShowDetailsInfo] = useState(true);
    const [showPhotoGallery, setShowPhotoGallery] = useState(false);
    const [showFareInfo, setShowFareInfo] = useState(false);
    const [showLocationInfo, setShowLocationInfo] = useState(false);
    const [iframeSrc, setiframeSrc] = useState(`https://maps.google.com/maps?q=%09${details.pickupaddress}&t=&z=13&ie=UTF8&iwloc=&output=embed`);
    const trip_duration = getEstimateTimeDurationInMinutes(details.durationinseconds)
    const departTime = getDateOrTime(details.departuredate,'time');
    const monetary_unit = details.currency === 'MYR' ? "RM" : "SGD";
    
    // console.log(depart)
    // console.log(destination)
    // console.log(iframeSrc)
    function getDateOrTime(datetime,get='date') {
        if(get=='date') {
            return datetime.split(" ")[0];
        }
        else {
            return datetime.split(" ")[1];
        }
    }

    function selectedNav(nav = 'AllDetails') {
        var elDI = document.getElementById('alldetails');
        var elPH = document.getElementById('photogallery');
        // var elFI = document.getElementById('fareinfo');
        // var elLI = document.getElementById('locationinfo');
        // var src = `https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=1.490818,103.741016+(Malaysia)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed`
        var src = `https://maps.google.com/maps?q=%09${details.pickupaddress}&t=&z=13&ie=UTF8&iwloc=&output=embed`
        setiframeSrc(src)
        if(nav == 'AllDetails') {
            setShowDetailsInfo(true);
            setShowPhotoGallery(false);
            // setShowFareInfo(false);
            // setShowLocationInfo(false);
            elDI.classList.add('selected');
            elPH.classList.remove('selected');
            // elFI.classList.remove('selected');
            // elLI.classList.remove('selected');
        }
        else if(nav == 'PhotoGallery') {
            setShowPhotoGallery(true)
            setShowDetailsInfo(false);
            // setShowFareInfo(false);
            // setShowLocationInfo(false);
            elPH.classList.add('selected');
            elDI.classList.remove('selected');
            // elFI.classList.remove('selected');
            // elLI.classList.remove('selected');
        }
        // else if(nav == 'FareInfo') {
        //     setShowFareInfo(true)
        //     setShowDetailsInfo(false);
        //     setShowPhotoGallery(false);
        //     setShowLocationInfo(false);
        //     elFI.classList.add('selected');
        //     elDI.classList.remove('selected');
        //     elAI.classList.remove('selected');
        //     elLI.classList.remove('selected');
        // }
        // else if(nav == 'LocationInfo') {
        //     setShowLocationInfo(true)
        //     setShowDetailsInfo(false);
        //     setShowPhotoGallery(false);
        //     setShowFareInfo(false);
        //     elLI.classList.add('selected');
        //     elDI.classList.remove('selected');
        //     elAI.classList.remove('selected');
        //     elFI.classList.remove('selected');
        // }
    }
  return (
    <>
        
    <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-90w no-box-sizing"
        aria-labelledby="MoreDetailsPopOutModal"
        scrollable="true"
    >
    <Modal.Header className='modal_header' closeButton>
        <p>Details</p>
    </Modal.Header>
    <Modal.Body>
    <Container className="MoreDetails">
        <Row>
            <Col md={3} sm={12} xs={12} className="MoreDetails_Nav">
                <h2 id='alldetails' onClick={()=>selectedNav('AllDetails')} className='selected'>All Details</h2>
                <h2 id='photogallery' onClick={()=>selectedNav('PhotoGallery')}>Photo Gallery</h2>
                {/* <h2 id='fareinfo' onClick={()=>selectedNav('FareInfo')}>Coach & Fare Info</h2>
                <h2 id='locationinfo' onClick={()=>selectedNav('LocationInfo')}>Depart Location Info</h2> */}
            </Col>
            <Col md={9} sm={12} xs={12} className="MoreDetails_Content">
                {
                    showDetailsInfo ?
                    <>
                    <Row>
                        <Col sm={6}>
                        <table width={'100%'} className="departInfo">
                            <tr>
                                <th colSpan={2}>Departing Info</th>
                            </tr>
                            <tr>
                                <td width={'30%'}>Date</td>
                                <td>{getDateOrTime(details.departuredate)}</td>
                            </tr>
                            <tr>
                                <td width={'30%'}>Time</td>
                                <td>{getAMorPMonTime(getDateOrTime(details.departuredate,'time'))}</td>
                            </tr>
                            <tr>
                                <td width={'30%'}>Location</td>
                                <td>{title === 'Return' ? destination : depart}</td>
                            </tr>
                            <tr>
                                <td width={'30%'}>Address</td>
                                <td>{details.pickupaddress}</td>
                            </tr>
                        </table>
                        <table width={'100%'} className="departInfo">
                            <tr>
                                <th colSpan={2}>Arriving Info</th>
                            </tr>
                            <tr>
                                <td width={'30%'}>Date</td>
                                <td>{getDateOrTime(getEstimateDateTimeArrival(details.departuredate,trip_duration))}</td>
                            </tr>
                            <tr>
                                <td width={'30%'}>Time</td>
                                <td>{getAMorPMonTime(getEstimateTimeArrival(departTime,trip_duration))}</td>
                            </tr>
                            <tr>
                                <td width={'30%'}>Location</td>
                                <td>{title === 'Return' ? depart : destination}</td>
                            </tr>
                            <tr>
                                <td width={'30%'}>Address</td>
                                <td>{details.dropoffaddress}</td>
                            </tr>
                        </table>
                        </Col>
                        <Col sm={6}>
                        <table width={'100%'} className="departInfo">
                            <tr>
                                <th colSpan={2}>Coach & Fare Info</th>
                            </tr>
                            <tr>
                                <td width={'30%'}>Bus Name</td>
                                <td>{details.coachtype}</td>
                            </tr>
                            <tr>
                                <td width={'30%'}>Ticket Fare</td>
                                <td>Adult: {monetary_unit} {details.adultprice.toFixed(2)}<br />Child: {monetary_unit} {details.childprice.toFixed(2)}</td>
                            </tr>
                        </table>
                        <table width={'100%'} className="departInfo">
                            <tr>
                                <th colSpan={2}>Depart Location Map</th>
                            </tr>
                        <div class="mapouter">
                            <div class="gmap_canvas">
                                <iframe 
                                id="gmap_canvas" 
                                src={iframeSrc} 
                                frameborder="0" 
                                scrolling="no" 
                                marginheight="0" 
                                marginwidth="0">
                                </iframe>
                            <br />
                            </div>
                        </div>
                        </table>
                        </Col>
                    </Row>
                    </>
                    :""
                }
                {
                    showPhotoGallery ?
                    <>
                        <div className='photogallery'>
                            <Row>
                               
                                <Col xs={6} sm={4}>
                                    <img src='./images/bus/operator_00042_220304215748142.jpeg' />
                                </Col>
                                <Col xs={6} sm={4}>
                                    <img src='./images/bus/operator_00042_220304220514830.jpeg' />
                                </Col>
                                <Col xs={6} sm={4}>
                                    <img src='./images/bus/operator_00042_220304220519267.jpeg' />
                                </Col>
                                <Col xs={6} sm={4}>
                                    <img src='./images/bus/operator_00042_220304220525393.jpeg' />
                                </Col>
                                <Col xs={6} sm={4}>
                                    <img src='./images/bus/operator_00042_220304220530018.jpeg' />
                                </Col>
                                <Col xs={6} sm={4}>
                                    <img src='./images/bus/operator_00042_220304220638134.jpeg' />
                                </Col>
                                <Col xs={6} sm={4}>
                                    <img src='./images/bus/operator_00042_220304220656245.jpeg' />
                                </Col>
                                <Col xs={6} sm={4}>
                                    <img src='./images/bus/operator_00042_220304220712489.jpeg' />
                                </Col>
                                <Col xs={6} sm={4}>
                                    <img src='./images/bus/operator_00042_220304220716808.jpeg' />
                                </Col>
                                <Col xs={6} sm={4}>
                                    <img src='./images/bus/operator_00042_220304220757146.jpeg' />
                                </Col>
                                <Col xs={6} sm={4}>
                                    <img src='./images/bus/operator_00042_220304220853501.jpeg' />
                                </Col>
                                <Col xs={6} sm={4}>
                                    <img src='./images/bus/operator_00042_220304220904471.jpeg' />
                                </Col>
                                <Col xs={6} sm={4}>
                                    <img src='./images/bus/operator_00042_220304220941249.jpeg' />
                                </Col>
                                <Col xs={6} sm={4}>
                                    <img src='./images/bus/operator_00042_220304220946937.jpeg' />
                                </Col>
                                <Col xs={6} sm={4}>
                                    <img src='./images/bus/operator_00042_220304220952734.jpeg' />
                                </Col>
                                <Col xs={6} sm={4}>
                                    <img src='./images/bus/operator_00042_220304221110312.jpeg' />
                                </Col>
                                <Col xs={6} sm={4}>
                                    <img src='./images/bus/operator_00042_220304221204999.jpeg' />
                                </Col>
                                <Col xs={6} sm={4}>
                                    <img src='./images/bus/operator_00042_220304221308645.jpeg' />
                                </Col>
                                <Col xs={6} sm={4}>
                                    <img src='./images/bus/operator_00042_220304221526435.jpeg' />
                                </Col>
                                <Col xs={6} sm={4}>
                                    <img src='./images/bus/operator_00042_220304221633041.jpeg' />
                                </Col>
                                <Col xs={6} sm={4}>
                                    <img src='./images/bus/operator_00042_220425105749594.jpeg' />
                                </Col>
                            </Row>
                        </div>
                    </>
                    :""
                }
                {/* {
                    showFareInfo ?
                    <table width={'100%'} className="departInfo">
                        <tr>
                            <td width={'30%'}>Bus Name</td>
                            <td>{details.coachtype}</td>
                        </tr>
                        <tr>
                            <td width={'30%'}>Ticket Fare</td>
                            <td>Adult: RM{details.adultprice}<br />Child: RM{details.childprice}</td>
                        </tr>
                    </table>
                    :""
                }
                {
                    showLocationInfo ?
                    <div class="mapouter">
                        <div class="gmap_canvas">
                            <iframe 
                            id="gmap_canvas" 
                            src={iframeSrc} 
                            frameborder="0" 
                            scrolling="no" 
                            marginheight="0" 
                            marginwidth="0">
                            </iframe>
                        <br />
                        </div>
                    </div>
                    :""
                } */}
            </Col>
        </Row>
    </Container>
    </Modal.Body>
    </Modal>
    </>
  );
}
