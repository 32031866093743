import React from 'react';
import Row from 'react-bootstrap/esm/Row';
import './ContentTitle.css';

export const ContentTitle = ({
    title,
    description,
    edit,
    button,
    onClick
    }) => {

  return (
    <>
    <div className="_title">
        <Row>
          <table>
            <tr>
              <td>
                <h1>{title}</h1>
              </td>
              {edit != null ?
              <td rowSpan={2}>
                  <i class="fa-solid fa-pencil yellow clickable" onClick={onClick}></i>
              </td>
              : ""
              }
              {button != null ? 
              <td rowSpan={2}>
                <button type='button' className='white_orange f-right' onClick={onClick}>{button}</button>
              </td>
              : ""
              }
            </tr>
            <tr>
              <td>
                <h2>{description}</h2>
              </td>
            </tr>
          </table>
        </Row>
    </div>
    </>
  );
}