import React from 'react';

function DestinationTravelItem(props) {
  return (
    <>
      <div className='travel-item'>
        <div className='travel-item__info'>
            <h6 className='travel-item__text'>{props.text}</h6>
            <h5 className='travel-item__title'>{props.title}</h5>
            <hr className='travel-item__hr'></hr>
            <h5 className='travel-item__description'>{props.description}</h5>
        </div>
      </div>
    </>
  );
}

export default DestinationTravelItem;
