import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import Modal from 'react-bootstrap/Modal';
import { AddOnCardModal } from './AddOnCardModal';
import ItemsCarousel from "react-items-carousel";

export const BookingAddOnCardGroup = ({
    component,
    data
    }) => {
    const [activeItemIndex, setActiveItemIndex] = useState(0);
    const chevronWidth = 40;
    
component = component > 5 ? "5" : component;
component = component < 5 && component > 2 ? "2" : component;
component = component < 2 ? "1" : component;
// console.log(data);
// let package_data = null;
// if(sessionStorage.getItem("addon_data")!=null) {
//     package_data = JSON.parse(sessionStorage.getItem("addon_data"))
// }
// // console.log(JSON.parse(sessionStorage.getItem("addon_data")));
if(sessionStorage.getItem("addon_data")!= 'undefined') {
    const package_data = JSON.parse(sessionStorage.getItem("addon_data"));
    // console.log(package_data);
        return(
            <div style={{ padding: `0 ${chevronWidth}px` }}>
                <ItemsCarousel
                    requestToChangeActive={setActiveItemIndex}
                    activeItemIndex={activeItemIndex}
                    numberOfCards={component}
                    gutter={20}
                    leftChevron={<button>{"<"}</button>}
                    rightChevron={<button>{">"}</button>}
                    outsideChevron
                    chevronWidth={chevronWidth}
                >
                    {
                        package_data && package_data.map(({ displayname, image, price, labelid, url}, index) => (
                            <AddOnCardModal 
                            img = {image}
                            categories = "CATEGORIES"
                            title = {displayname}
                            price = {price}
                            label_id = {labelid}
                            package_url = {url}
                            />
                        ))
                    }
                </ItemsCarousel>
            </div>
        )
    }
}