import React, { useState, useEffect, useContext } from 'react';
import '../../App.css';
import BookingAddOn from '../BookingAddOn';
import BookingAddOnDeals from '../BookingAddOnDeals';
import { BookingDetails } from '../BookingDetails';
import { BookingHeader } from '../BookingHeader';
import { useSearchParams } from 'react-router-dom';
import { LoadingScreenModal } from '../LoadingScreenModal';
import { convertEBticketToCWLticket, tokenIsValid, getToken } from '../Function';
import * as CryptoJS from 'crypto-js';

function PaymentAck() {
	localStorage.removeItem("selected_addon_data");
	sessionStorage.removeItem("busFare");
	sessionStorage.removeItem("departData");
	sessionStorage.removeItem("returnData");
	localStorage.removeItem("loyalty");
	sessionStorage.removeItem("addOn");
	sessionStorage.removeItem("selectedDepartSeat");
	sessionStorage.removeItem("selectedReturnSeat");
	localStorage.removeItem("addOnSession");
	sessionStorage.removeItem("addon_data");
	sessionStorage.removeItem("Destination_data");
	sessionStorage.removeItem("Origin_data");
	sessionStorage.removeItem("depart_TripKey");
	sessionStorage.removeItem("return_TripKey");
	sessionStorage.removeItem('rescheduleAmount');
	sessionStorage.removeItem('history_guid');
	sessionStorage.removeItem("addOn");
	sessionStorage.removeItem('oldcartguid');
	const SERVER_URL = process.env.REACT_APP_SERVER_URL;
	const MANJA_REWARD_URL = process.env.REACT_APP_MANJA_REWARD_URL;
	const MANJA_REWARD_STORED_CODE = process.env.REACT_APP_MANJA_REWARD_STORED_CODE;
	const MANJA_REWARD_KEY = process.env.REACT_APP_MANJA_REWARD_KEY;
	// var cust_email = sessionStorage.getItem("primary_email");
	// if (cust_email != null) {
	//   cust_email = JSON.parse(cust_email);
	// }
	const [searchParams, setSearchParams] = useSearchParams();
	const status_id = searchParams.get("status_id");
	const order_id = searchParams.get("order_id");
	const transaction_id = searchParams.get("transaction_id");
	const msg = searchParams.get("msg");
	const hash = searchParams.get("hash");
	const [bookingHistoryData, setBookingHistoryData] = useState(null);
	const [loading, setLoading] = useState(true);
	// const [userBookingHistoryData, setUserBookingHistoryData] = useState(null);
	const [responseStatus, setResponseStatus] = useState(null);
	const token = getToken();

	var payment_status = 'Failed';
	if (status_id === '1') {
		payment_status = 'Confirmed';
	}

	// console.log(responseStatus)
	// console.log(token)
	useEffect(() => {
		tokenIsValid()
		try {

			// Simple POST request with a JSON body using fetch
			const requestOptions = {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': token
				},
				body: JSON.stringify({
					StatusID: `${status_id}`,//min payment amount
					OrderID: `${order_id}`,
					TransactionID: `${transaction_id}`,
					Msg: `${msg}`,
					Hash: `${hash}`
				})
			};

			fetch(`${SERVER_URL}/order/update/${order_id}`, requestOptions)
				.then(response => {
					// console.log(response)
					if (response.status === 200) {
						// console.log("SUCCESSS")
						setResponseStatus(response.status)
						return response.json();
					} else if (response.status === 206) {
						// console.log("Pending confirmation")
						setResponseStatus(response.status)
						return response.json()
						// throw new Error(response.status)
					}
					else {
						// console.log("Bad Request")
						setResponseStatus(response.status)
						setLoading(false)
						return response.json()
						// throw new Error(response.status);
					}
				})
				.then(data => {

					// console.log('order update response');
					// console.dir(data);
					if (data?.ordersummary?.loyaltyid != null && data?.ordersummary?.loyaltyid != "0") {
						const loyalty_status = data?.ordersummary?.status_id == "1" ? "CLAIM" : "DELETE";
						const hash = CryptoJS.SHA1(data.orderstatus.cust_email + data.ordersummary.loyaltyid + loyalty_status + order_id);
						const hashString = hash.toString(CryptoJS.enc.Hex);
						const rewardsRequestOptions = {
							method: 'POST',
							headers: {
								'Content-Type': 'application/json',
								'apikey': MANJA_REWARD_KEY
							},
							body: JSON.stringify({
								"StoreCode": MANJA_REWARD_STORED_CODE,
								"Status": loyalty_status,
								"RefNo": order_id,
								"Hash": hashString
							})
						}
						fetch(`${MANJA_REWARD_URL}/Vouchers/${data.orderstatus.cust_email}/${data.ordersummary.loyaltyid}`, rewardsRequestOptions)
							.then(response => {
								// console.log(response)
							})
					}
					setBookingHistoryData(data);

					// console.log('bookingHistoryData=');
					// console.dir(bookingHistoryData);
				}
				);

		} catch (err) {
			// console.log(`[Err]${err}`);
			setLoading(false)
		}
	}, [token]);

	// useEffect(() => {
	//   const getBookingHistoryData = async () => {
	//       try {
	//           const response = await fetch(`${SERVER_URL}/order/history/${bookingHistoryData.orderstatus.cust_email}`,
	//           {
	//             headers: {
	//                 'Authorization': token
	//             }
	//           });
	//           if (!response.ok) {
	//               throw new Error(
	//                   `This is an HTTP error: The status is ${response.status}`
	//               );
	//           }
	//           let actualData = await response.json();
	//           setUserBookingHistoryData(actualData);
	//           // sessionStorage.setItem("subplace_data",JSON.stringify(actualData));
	//           // console.log(actualData)
	//       } catch (err) {
	//           setUserBookingHistoryData(null);
	//           setLoading(false)
	//       } 
	//   }
	//   tokenIsValid()
	//   getBookingHistoryData()
	// },[bookingHistoryData,token]);

	console.log(bookingHistoryData)
	// console.log(userBookingHistoryData)
	if (responseStatus == 200 && bookingHistoryData != null) {
		if (loading === true) {
			setLoading(false)
		}
		return (
			<>
				{loading && <div className='center'>
					<LoadingScreenModal />
				</div>}
				<BookingHeader
					printTicket="Print Ticket"

					ticket_id={bookingHistoryData.ordersummary.ticketid || (bookingHistoryData.orderinfo ? bookingHistoryData.orderinfo.ticket_id : null)}
					// ticket_id={bookingHistoryData.ordersummary.ticketid}

					ticket_id_hash={bookingHistoryData.ordersummary.ticketidhash || (bookingHistoryData.orderinfo ? bookingHistoryData.orderinfo.ticket_id_hash : null)}
					// ticket_id_hash={bookingHistoryData.ordersummary.ticketidhash}

					booking_status={payment_status}
					trip_id={bookingHistoryData.ordersummary.ordernumber}
					amount={bookingHistoryData.ordersummary.totalamount}
					email={bookingHistoryData.orderstatus.cust_email}
				>
				</BookingHeader>
				<BookingDetails
					bookingDetail={bookingHistoryData}
					// depart_info={depart_info}
					// return_info={return_info}
					// data_depart_place_name={bookingHistoryData.orders.depart_place_name}
					// data_return_place_name={bookingHistoryData.orders.return_place_name}
					// data_passenger={bookingHistoryData.orders.no_of_pax}
					view='true'
				>
				</BookingDetails>
			</>
		);
	}
	else if (responseStatus == 206 && bookingHistoryData != null) {
		if (loading === true) {
			setLoading(false)
		}
		return (
			// <div className='center'>
			// <LoadingScreenModal />
			// </div>
			<>
				<BookingHeader
					booking_status={'Pending Confirmation'}
					email={bookingHistoryData.orderstatus.cust_email}
				>
				</BookingHeader>
				<BookingDetails
					bookingDetail={bookingHistoryData}
					view='true'
				>
				</BookingDetails>
			</>
		);
	}
	else if (responseStatus == null) {
		return (
			<LoadingScreenModal />
		)
	}
	else {
		return (
			<>
				{loading && <div className='center'>
					<LoadingScreenModal />
				</div>}
				<BookingHeader
					booking_status={'Failed'}
				>
				</BookingHeader>
				<BookingDetails
					bookingDetail={bookingHistoryData}
					view='true'
				>
				</BookingDetails>
			</>
		)
	}
}

export default PaymentAck;