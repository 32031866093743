import React, { useContext, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import { useSearchParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { BusFareAddOnContext } from '../UserContext';
import './BookingAddOnDeals.css';
import { getToken, tokenIsValid } from './Function';

export const AddOnCardModal = ({
    className,
    img,
    categories,
    title,
    price,
    label_id,
    package_url,
    description,
    }) => {
  let {BusFareAddOn, setBusFareAddOn} = useContext(BusFareAddOnContext);

const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const [searchParams, setSearchParams] = useSearchParams();
const data_depart = searchParams.get("depart");
const data_destination = searchParams.get("destination");
const data_depart_date = searchParams.get("depart_date");
const data_return_date = searchParams.get("return_date");
const data_passenger = searchParams.get("passenger");    
const [show, setShow] = useState(false);
const [unique_id, setUnique_id] = useState(null);
const [addOnLink, setAddOnLink] = useState(null);
const [data, setData] = useState(null);
const [loading, setLoading] = useState(true);
const [error, setError] = useState(null);
const [value1,setValue1] = useState(0);
const [value2,setValue2] = useState(0);
const [value3,setValue3] = useState(0);
const priceAdult = [188,151];
const priceChild = [188,151];
const priceSenior = [188,151];
const params = `depart=${data_depart}&destination=${data_destination}&depart_date=${data_depart_date}&return_date=${data_return_date}&passenger=${data_passenger}`;
let href = `/selectSeat?${params}`;
// console.log(addOnLink);
// console.log(localStorage.getItem("addOnSession"));
className = className != null ? className : 'addOnCard';

function updateAddOnStatus(sessionID,labelID) {
    const update_addOn_api_url = `${SERVER_URL}/order/update/addon/status`;
        
    const update_addOn_requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': getToken()
        },
        body: JSON.stringify({
            "SessionID": sessionID,
            "LabelID": labelID,
            "Status": "C"
        })
    };
    // console.log(update_addOn_requestOptions)
    const updateStatus = async () => {
        try {
            const update_addOn_response = await fetch(
                update_addOn_api_url,update_addOn_requestOptions
            );
            if (!update_addOn_response.ok) {
              throw new Error(
                `This is an HTTP error: The status is ${update_addOn_response.status}`
              );
            }
            else {
              setError(null);
              // console.log(update_addOn_response);
              // console.log(sessionID);
              localStorage.setItem("addOnSession",JSON.stringify(sessionID));
              alert("Added to cart")
              setShow(false);
              window.location.reload();
            }
        } catch(err) {
            // console.log(err.message);
            alert(err.message);
            setError(err.message);
        } finally {
            setLoading(false);
        }  
        }
        updateStatus();
}
function addOnStart(lbl_id) {
    const uniqueID = uuid();
    setUnique_id(uniqueID);
    // console.log(lbl_id);
    // console.log("unique id on link " + uniqueID);
    let link = `https://www.causewaylink.com.my/holiday/attractions/headless-product-detail/?id=${lbl_id}&session_id=${uniqueID}`;
    if(SERVER_URL.includes('-uat')) {
        link = `https://www.causewaylink.com.my/holiday/attractions/headless-product-detail/?id=${lbl_id}&session_id=${uniqueID}&env=uat`;
    }
    setAddOnLink(link);
    //const small_id = unique_id.slice(0,8)
    //unique_id pass to backend
    // console.log("(user clicked add on), pass this id to backend " + uniqueID)
    setShow(true)
}

function addOnCancel() {
    // console.log("(user cancel), pass this id to backend " + unique_id)
    setShow(false);
}

function addToCart(label_id) {
    // console.log("user clicked confirm");
    // console.log(unique_id);
    // console.log(label_id);
    tokenIsValid()
    //call API to update add on status
    updateAddOnStatus(unique_id,label_id);
        //save selected data to local storage
        // const addOn = {
        //     'title': title,
        //     'img': img,
        //     'amount_adult': amount_adult,
        //     'priceAdult': priceAdult,
        //     'amount_child': amount_child,
        //     'priceChild': priceChild,
        //     'amount_senior': amount_senior,
        //     'priceSenior': priceSenior,
        //     'sub_totalPrice': sub_totalPrice
        // };
        // setBusFareAddOn(BusFareAddOn=>[...BusFareAddOn,addOn]);
        // sessionStorage.setItem("addOn",JSON.stringify(BusFareAddOn));
        // setShow(false);
}
// console.log(BusFareAddOn);
return (
    <>
    <Card className={className}>
        <a onClick={()=>addOnStart(label_id)}>
            <Card.Img className="p-2 addOn_img" variant="top" src={img} />
            <Card.Body>
            <Card.Text className="categories">{categories}</Card.Text>
            <Card.Title className='title'>{title}</Card.Title>
            <Card.Text className='price pt-1'>
                price from <span>{price}</span>
            </Card.Text>
            </Card.Body>
        </a>
    </Card>

    <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-90w addOnModalPopUp"
        aria-labelledby="AddOnModal"
        contentClassName='addOnModalPopUp'
        backdrop="static"
        keyboard={false}
    >
        {/* <Row>
            <Col lg={7}>
            <Modal.Header>
                <img className='img-full-width' src ={img} />
            </Modal.Header>
            <Modal.Body style={{
                maxHeight: 'calc(100vh - 210px)',
                overflowY: 'auto'
                }}>

                {description}{description}{description}{description}{description}

            </Modal.Body>
            </Col>
            <Col lg={5}>
            <Modal.Header className='modal_header' closeButton>
                <p>Package Option</p>
            </Modal.Header>
            <Modal.Body className="modal_body">
                <div className="modal_body_data">
                <div>
                    <table width={'100%'}>
                        <tr>
                            <td>
                            <h2>Theme Park Ticket - Adult</h2>
                            </td>
                            <td rowSpan={2} width='90px'>
                                <div className='quantity'>
                                    <td width={'33%'}>
                                        <a onClick={()=>{if(value1 > 0) {setValue1(value1 - 1); }}}><i className = 'fa fa-minus'></i></a>
                                    </td>
                                    <td width={'33%'}>
                                        <input type="text" id="myNumber" min="0" max="9" value={value1} readOnly />
                                    </td>
                                    <td width={'33%'}>
                                        <a onClick={()=>{if(value1 < 9) {setValue1(value1 + 1); }}}><i className = 'fa fa-plus'></i></a>
                                    </td>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                            <h2><span>RM{priceAdult[0]}</span>RM{priceAdult[1]}</h2>
                            </td>
                        </tr>
                    </table>
                </div>
                <div>
                    <table width={'100%'}>
                        <tr>
                            <td>
                            <h2>Theme Park Ticket - MyKad Child (4-12)</h2>
                            </td>
                            <td rowSpan={2} width='90px'>
                                <div className='quantity'>
                                    <td width={'33%'}>
                                        <a onClick={()=>{if(value2 > 0) {setValue2(value2 - 1); }}}><i className = 'fa fa-minus'></i></a>
                                    </td>
                                    <td width={'33%'}>
                                        <input type="text" id="myNumber" min="0" max="9" value={value2} readOnly />
                                    </td>
                                    <td width={'33%'}>
                                        <a onClick={()=>{if(value2 < 9) {setValue2(value2 + 1); }}}><i className = 'fa fa-plus'></i></a>
                                    </td>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                            <h2><span>RM{priceChild[0]}</span>RM{priceChild[1]}</h2>
                            </td>
                        </tr>
                    </table>
                </div>
                <div>
                    <table width={'100%'}>
                        <tr>
                            <td>
                            <h2>Theme Park Ticket - MyKad Senior (60+)</h2>
                            </td>
                            <td rowSpan={2} width='90px'>
                                <div className='quantity'>
                                    <td width={'33%'}>
                                        <a onClick={()=>{if(value3 > 0) {setValue3(value3 - 1); }}}><i className = 'fa fa-minus'></i></a>
                                    </td>
                                    <td width={'33%'}>
                                        <input type="text" id="myNumber" min="0" max="9" value={value3} readOnly />
                                    </td>
                                    <td width={'33%'}>
                                        <a onClick={()=>{if(value3 < 9) {setValue3(value3 + 1); }}}><i className = 'fa fa-plus'></i></a>
                                    </td>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                            <h2><span>RM{priceSenior[0]}</span>RM{priceSenior[1]}</h2>
                            </td>
                        </tr>
                    </table>
                    </div>
                    <Row>
                        <section className='bus_fare_total'>
                            <AddOnTotal 
                            priceAdult={value1*priceAdult[1]}
                            priceChild={value2*priceChild[1]}
                            priceSenior={value3*priceSenior[1]}
                            />
                        </section>
                    </Row>
                    <Row className= 'bus_fare_total'>
                        <button type='button' 
                            href = {href}
                            onClick={()=>addToCart(title,img,value1,priceAdult[1],value2,priceChild[1],value3,priceSenior[1],value1*priceAdult[1]+value2*priceChild[1]+value3*priceSenior[1])}
                            className= "btn_addToCart"
                            >
                                Add To Cart
                        </button>
                    </Row>
                </div>
            </Modal.Body>
            </Col>
        </Row> */}
        <iframe src={addOnLink}/>
        <Modal.Footer className="login_modal_footer border-0">
        <div className='addOnModalFooter'>
            <div>
                <a onClick={addOnCancel}>Cancel</a>                                            
            </div>
                <button onClick={()=>addToCart(label_id)} type='button' className='save_button'>Confirm</button>
        </div>        
        </Modal.Footer>
    </Modal>
    </>
  );

}