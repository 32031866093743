import React, { useContext, useEffect, useState } from 'react';
import Col from 'react-bootstrap/esm/Col';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import { DepartDataContext, ReturnDataContext, VoucherCodeContext, VoucherDiscountContext } from "../UserContext";
import { checkIsSgdTrip, getMonetaryUnitBasedOnCurrency, changeDateFormatToDateTime, changeDateFormatToWeekDayMonth, getAMorPMonTime, getEstimateTimeDurationInHours, getNextDay, getSubPlaceShortName, getToken, getYYYYMMDDtwoDigit, tokenIsValid, checkIncludeReturnTripTicketPlaceBySubPlaceId } from './Function';
import { LoadingScreenModal } from './LoadingScreenModal';
import { MoreDetailsPopOutModal } from './MoreDetailsPopOutModal';
import './SelectDepartureAndReturn.css';
import { SelectedOneWayTripModal } from './SelectedOneWayTripModal';
import { SelectedTripSummaryModal } from './SelectedTripSummaryModal';
import { useSearchParams } from 'react-router-dom';

export const SelectDepartureAndReturn = ({
	title,
	link,
	depart,
	destination,
	handleSelectedDateCallBack,

	data,
	dateDetail,
	// depart_info,

	origin_subplace_unique_shortname,
	destination_subplace_unique_shortname,

	date_depart,
	date_return,
	passenger
}) => {

	const SERVER_URL = process.env.REACT_APP_SERVER_URL;
	const currency = JSON.parse(localStorage.getItem("currency"));
	const monetary_unit = getMonetaryUnitBasedOnCurrency()
	const [searchParams, setSearchParams] = useSearchParams();
	const request_id = searchParams.get("request_id") ?? "";

	// const [data, setData] = useState(null);
	// const [dateDetail, setDateDetail] = useState(null);

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [from_amount, setFromAmount] = useState(0);
	const [dateCounter, setDateCounter] = useState(0);
	const [show, setShow] = useState(false);
	const [show1, setShow1] = useState(false);
	const [show2, setShow2] = useState(null);
	const [showDetails, setShowDetails] = useState({});
	const [selected_date, setSelectedDate] = useState(getDefaultDate(title));
	const [selected_return_date, setReturnDate] = useState(getNextDay(getDefaultDate(title)));

	// const str_depart_info = JSON.stringify(depart_info);
	const { departData, setDepartData } = useContext(DepartDataContext);
	const { returnData, setReturnData } = useContext(ReturnDataContext);
	const { VoucherCode, setVoucherCode } = useContext(VoucherCodeContext);
	const { VoucherDiscount, setVoucherDiscount } = useContext(VoucherDiscountContext);
	useEffect(() => {
		setVoucherCode(null);
		setVoucherDiscount(0);
	}, [])





	let [subplace_data, setSubPlaceData] = useState([]);

	function getDefaultDate(title) {
		if (title == 'Return') {
			if (date_return != null && date_return != 'null') {
				var departData = JSON.parse(sessionStorage.getItem("departData"))
				// console.log(new Date(changeDateFormatToDateTime(departData.departure_time.split(" ")[0])))
				// console.log(new Date(changeDateFormatToDateTime(date_return)))
				if (new Date(changeDateFormatToDateTime(departData.departure_time.split(" ")[0])) > new Date(changeDateFormatToDateTime(date_return))) {
					return departData.departure_time.split(" ")[0];
				}
				else {
					return date_return;
				}
			}
			else return date_depart;
		}
		else {
			return date_depart;
		}
	}
	function selectedDate(date) {
		// console.log(new Date(date));
		var no_trip = false;
		if (dateDetail != null && dateDetail.length != 0) {
			dateDetail.forEach(element => {
				// console.log(element)
				if (element.travel_date == date && element.no_of_trip == "0") {
					no_trip = true;
				}
			});
		}
		var today = new Date();
		var nextDay = getNextDay(date);
		// console.log(today);
		if (title == 'Return') {
			var departData = JSON.parse(sessionStorage.getItem("departData"))
			// console.log(new Date(changeDateFormatToDateTime(departData.departure_time.split(" ")[0])));
			today = new Date(changeDateFormatToDateTime(departData.departure_time.split(" ")[0]));
		}
		// console.log(today);
		if (new Date(nextDay) > today && no_trip == false) {
			setSelectedDate(getYYYYMMDDtwoDigit(date));
			setReturnDate(getNextDay(date));
			setDateCounter(0)
			handleSelectedDateCallBack(getYYYYMMDDtwoDigit(date))
		}
	}
	// console.log(selected_date);
	// console.log(selected_return_date);
	// console.log(depart.PlaceId);


	let new_date_return = getNextDay(date_return);
	// let url = title === 'Return'
	//     ? `${SERVER_URL}/eb/get/trips/${currency}/${selected_date}/${destination.PlaceId}/${getYYYYMMDDtwoDigit(selected_return_date)}/${depart.PlaceId}/${passenger}`
	//     : `${SERVER_URL}/eb/get/trips/${currency}/${selected_date}/${depart.PlaceId}/${getYYYYMMDDtwoDigit(selected_return_date)}/${destination.PlaceId}/${passenger}`;



	const onHover = (e, index, bus_class) => {
		var hovered_item = null;
		e.preventDefault();
		if (bus_class == 'class') {
			hovered_item = document.getElementById(`hover` + index);
		}
		else {
			hovered_item = document.getElementById(`eta` + index);
		}
		hovered_item.classList.remove('hidden');
		// console.log("hovered");
	};

	const onHoverOver = (e, index, bus_class) => {
		var hovered_item = null;
		e.preventDefault();
		if (bus_class == 'class') {
			hovered_item = document.getElementById(`hover` + index);
		}
		else {
			hovered_item = document.getElementById(`eta` + index);
		}
		hovered_item.classList.add('hidden');
	};

	function checkBusClass(TotalSeat) {
		if (TotalSeat <= 30) {
			return 'Standard';
		}
		else return 'Executive';
	}

	function getBusImage(bus_class) {
		if (bus_class == 'Standard') {
			return './images/bus/standard.jpg';
		}
		else return './images/bus/double_decker.jpeg';
	}
	// console.log(data);
	function checkReturn() {
		const checkReturnData = sessionStorage.getItem("returnData");
		if (checkReturnData !== null) {
			return "return";
		}
		else return "depart";
	}
	// console.log(JSON.parse(sessionStorage.getItem("returnData")))

	function updateSelectedDepartData(index, DepartureDate, bus_class, SeatAvailable, AdultPrice, ChildPrice, TripKey, PickupAddress, DropOffAddress, TripDuration, TripDurationInSecond, DefaultCurrencyAdultPrice, DefaultCurrencyChildPrice, ToSubPlaceId) {

		console.log('************');
		console.log(depart);
		console.log(destination);
		console.log('************');

		if (checkIsSgdTrip()) {
			sessionStorage.setItem("departData", JSON.stringify(
				{
					"departure_time": DepartureDate,
					"class": bus_class,
					"vacancy": SeatAvailable,
					"fare": AdultPrice,
					"child_fare": ChildPrice,
					"TripKey": TripKey,
					"depart_from": depart.subplacename,
					"depart_to": destination.subplacename,
					"pick_up": PickupAddress,
					"drop_off": DropOffAddress,
					"duration": TripDuration,
					"duration_in_seconds": TripDurationInSecond,
					"AdultPriceInMYR": DefaultCurrencyAdultPrice,
					"ChildPriceInMYR": DefaultCurrencyChildPrice
				}
			)
			);
		}
		else {
			sessionStorage.setItem("departData", JSON.stringify(
				{
					"departure_time": DepartureDate,
					"class": bus_class,
					"vacancy": SeatAvailable,
					"fare": AdultPrice,
					"child_fare": ChildPrice,
					"TripKey": TripKey,
					"depart_from": depart.subplacename,
					"depart_to": destination.subplacename,
					"pick_up": PickupAddress,
					"drop_off": DropOffAddress,
					"duration": TripDuration,
					"duration_in_seconds": TripDurationInSecond
				}
			)
			);
		}


		if (depart.Reschedule == true && depart.ReturnDate == 'null') {
			window.location.href = "/selectSeat";
		}
		else if (depart.Reschedule == true && depart.ReturnDate != 'null') {
			window.location.href = "/pickReturn";
		}


		else {
			if (checkIncludeReturnTripTicketPlaceBySubPlaceId(ToSubPlaceId) == true) {
				window.location.href = `/selectSeat?depart=${depart.subplaceuniqueshortname}&destination=${destination.subplaceuniqueshortname}&depart_date=${DepartureDate.split(" ")[0]}&return_date=null&passenger=${passenger}&request_id=${request_id}`;
			}
			else if (date_return != 'null') {
				window.location.href = `/pickReturn?depart=${depart.subplaceuniqueshortname}&destination=${destination.subplaceuniqueshortname}&depart_date=${DepartureDate.split(" ")[0]}&return_date=${date_return}&passenger=${passenger}&request_id=${request_id}`;
			}
			else {
				const id = `button${index}`;
				var element = document.getElementById(id);
				element.innerHTML = "<img src='images/ic_selected.png' alt='selected'/> Selected";
				element.classList.add('selected');
				setShow1(true);
			}
		}

	}
	// console.log(departData);

	function updateSelectedReturnData(index, DepartureDate, bus_class, SeatAvailable, AdultPrice, ChildPrice, TripKey, PickupAddress, DropOffAddress, TripDuration, TripDurationInSecond, DefaultCurrencyAdultPrice, DefaultCurrencyChildPrice) {
		if (checkIsSgdTrip()) {
			sessionStorage.setItem("returnData", JSON.stringify(
				{
					"departure_time": DepartureDate,
					"class": bus_class,
					"vacancy": SeatAvailable,
					"fare": AdultPrice,
					"child_fare": ChildPrice,
					"TripKey": TripKey,
					"depart_from": destination.subplacename,
					"depart_to": depart.subplacename,
					"pick_up": PickupAddress,
					"drop_off": DropOffAddress,
					"duration": TripDuration,
					"duration_in_seconds": TripDurationInSecond,
					"AdultPriceInMYR": DefaultCurrencyAdultPrice,
					"ChildPriceInMYR": DefaultCurrencyChildPrice
				}
			)
			);
		}
		else {
			sessionStorage.setItem("returnData", JSON.stringify(
				{
					"departure_time": DepartureDate,
					"class": bus_class,
					"vacancy": SeatAvailable,
					"fare": AdultPrice,
					"child_fare": ChildPrice,
					"TripKey": TripKey,
					"depart_from": destination.subplacename,
					"depart_to": depart.subplacename,
					"pick_up": PickupAddress,
					"drop_off": DropOffAddress,
					"duration": TripDuration,
					"duration_in_seconds": TripDurationInSecond
				}
			)
			);
		}
		const id = `button${index}`;
		var element = document.getElementById(id);
		element.innerHTML = "<img src='images/ic_selected.png' alt='selected'/> Selected";
		element.classList.add('selected');
		setShow(true);
	}
	// console.log(returnData);
	function showMoreDetails(currency, PickupAddress, DropOffAddress, DepartureDate, AdultPrice, ChildPrice, TripDurationInSecond, CoachType) {
		setShow2(!show2);
		setShowDetails({
			"pickupaddress": PickupAddress,
			"dropoffaddress": DropOffAddress,
			"departuredate": DepartureDate,
			"currency": currency,
			"adultprice": AdultPrice,
			"childprice": ChildPrice,
			"durationinseconds": TripDurationInSecond,
			"coachtype": CoachType
		})
	}

	useEffect(() => {
		if (show2 == false) {
			setShow2(true);
		}
	}, [show2 === false]);

	// console.log(dateDetail)
	function getDateClassName(date) {
		var today = new Date();
		var no_trip = false;
		// console.log((date));
		// console.log(new Date(date));
		// console.log(today);
		var nextDay = getNextDay(date);
		if (title == 'Return') {
			var departData = JSON.parse(sessionStorage.getItem("departData"))
			// console.log(departData.departure_time.split(" ")[0])
			// console.log(new Date((changeDateFormatToDateTime(departData.departure_time.split(" ")[0]))));
			today = new Date(changeDateFormatToDateTime(departData.departure_time.split(" ")[0]));
			// console.log(today)
		}
		// console.log(changeDateFormatToDateTime(nextDay))
		// console.log(new Date(nextDay))
		// console.log(new Date(changeDateFormatToDateTime(nextDay)));

		if (dateDetail != null && dateDetail.length != 0) {
			dateDetail.forEach(element => {
				// console.log(element)
				if (element.travel_date == date && element.no_of_trip == "0") {
					no_trip = true;
				}
			});
		}
		if (selected_date == date) {
			return 'pick_date_box date_selected';
		}
		else if (new Date(changeDateFormatToDateTime(nextDay)) <= today || no_trip == true) {
			return 'pick_date_box date_inactive';
		}
		else {
			return 'pick_date_box';
		}
	}

	if (data !== null) {
		var lowest = 1000;


		console.log(`Data = `);
		console.dir(data);


		// if (title == 'Return') {
		//     data.forEach(element => {
		//         if (element.FromSubPlaceId === destination.SubPlaceId
		//             && element.ToSubPlaceId === depart.SubPlaceId
		//             //&& element.CompanyName.includes("Causeway Link")
		//         ) {
		//             subplace_data = subplace_data.concat(element);
		//             if (element.AdultPrice < lowest) {
		//                 lowest = element.AdultPrice.toFixed(2);
		//             }
		//         }
		//     });
		// }
		// else {
		//     data.forEach(element => {

		//         // console.log(element.FromSubPlaceId + ' vs ' + depart.SubPlaceId + ' && ' + element.ToSubPlaceId + ' vs ' + destination.SubPlaceId);

		//         if (element.FromSubPlaceId === depart.SubPlaceId
		//             && element.ToSubPlaceId === destination.SubPlaceId
		//             // && element.CompanyName.includes("Causeway Link")
		//         ) {
		//             subplace_data = subplace_data.concat(element);
		//             if (element.AdultPrice < lowest) {
		//                 lowest = element.AdultPrice.toFixed(2);
		//             }
		//         }
		//     });
		// }
	}

	function getLowestAmount(date, lowest) {
		if (dateDetail != null && dateDetail.length != 0) {
			dateDetail.forEach(element => {
				if (element.travel_date.toString() === date.toString()) {
					lowest = element.lowest_price;
				}
			});
			return lowest == 1000 ? 0.00 : lowest;
		}
		else {
			return lowest == 1000 ? 0.00 : lowest;
		}
	}

	return (
		<>
			{show ? <SelectedTripSummaryModal
				title={title}
				depart={destination.subplaceuniqueshortname}
				destination={depart.subplaceuniqueshortname}
				passenger={passenger}
			/> : ""}
			{show1 ? <SelectedOneWayTripModal
				depart={depart.subplaceuniqueshortname}
				destination={destination.subplaceuniqueshortname}
				passenger={passenger}
			/> : ""}
			{show2 ? <MoreDetailsPopOutModal
				title={title}
				depart={depart.subplacename}
				destination={destination.subplacename}
				details={showDetails}
			/> : ""}
			<Container>
				<div className='upcoming-booking-container'>
					<div className='table-container'>
						<Row className="clear-margin">
							<div className='background-blue departReturn_header'>
								<Row>
									<Col xs={5} sm={5} className="hide-on-mobile">
										<h1>Select your {title}</h1>
									</Col>
									<Col xs={12} sm={7}>
										{
											title === 'Departure'
												? (<h3>{(depart.subplacedisplayname)} <img src="images/ic_to.png" alt="to" /> {(destination.subplacedisplayname)}</h3>)
												: (<h3>{(destination.subplacedisplayname)} <img src="images/ic_to.png" alt="to" /> {(depart.subplacedisplayname)}</h3>)
										}
									</Col>
								</Row>
							</div>
							<div className='background-yellow' />
						</Row>
						<Row>
							<div className="date_select_container">
								<table width={'100%'} className="date_items_center">
									<tr>
										<td>
											<img src="images/ic_left.png" alt="left" onClick={() => setDateCounter(dateCounter - 1)} />
										</td>

										<td>
											<div className={getDateClassName(getNextDay(selected_date, dateCounter - 3)) + " hidden-768"}
												onClick={() => selectedDate(getNextDay(selected_date, dateCounter - 3))}>
												<h4>{changeDateFormatToWeekDayMonth(getNextDay(selected_date, dateCounter - 3))}</h4>
												{/* <h5>From {monetary_unit} {getLowestAmount(getNextDay(selected_date, dateCounter - 3), lowest)}</h5> */}
												{/* <h6>LOWEST!</h6> */}
											</div>
										</td>
										<td>
											<div className={getDateClassName(getNextDay(selected_date, dateCounter - 2)) + " hidden-547"}
												onClick={() => selectedDate(getNextDay(selected_date, dateCounter - 2))}>
												<h4>{changeDateFormatToWeekDayMonth(getNextDay(selected_date, dateCounter - 2))}</h4>
												{/* <h5>From {monetary_unit} {getLowestAmount(getNextDay(selected_date, dateCounter - 2), lowest)}</h5> */}
												{/* <h6>LOWEST!</h6> */}
											</div>
										</td>
										<td>
											<div className={getDateClassName(getNextDay(selected_date, dateCounter - 1))}
												onClick={() => selectedDate(getNextDay(selected_date, dateCounter - 1))}>
												<h4>{changeDateFormatToWeekDayMonth(getNextDay(selected_date, dateCounter - 1))}</h4>
												{/* <h5>From {monetary_unit} {getLowestAmount(getNextDay(selected_date, dateCounter - 1), lowest)}</h5> */}
												{/* <h6>LOWEST!</h6> */}
											</div>
										</td>
										<td>
											<div className={getDateClassName(getNextDay(selected_date, dateCounter))}
												onClick={() => selectedDate(getNextDay(selected_date, dateCounter))}>
												<h4>{changeDateFormatToWeekDayMonth(getNextDay(selected_date, dateCounter))}</h4>
												{/* <h5>From {monetary_unit} {getLowestAmount(getNextDay(selected_date, dateCounter), lowest)}</h5> */}
												{/* <h6>LOWEST!</h6> */}
											</div>
										</td>
										<td>
											<div className={getDateClassName(getNextDay(selected_date, dateCounter + 1))}
												onClick={() => selectedDate(getNextDay(selected_date, dateCounter + 1))}>
												<h4>{changeDateFormatToWeekDayMonth(getNextDay(selected_date, dateCounter + 1))}</h4>
												{/* <h5>From {monetary_unit} {getLowestAmount(getNextDay(selected_date, dateCounter + 1), lowest)}</h5> */}
												{/* <h6>LOWEST!</h6> */}
											</div>
										</td>
										<td>
											<div className={getDateClassName(getNextDay(selected_date, dateCounter + 2)) + " hidden-547"}
												onClick={() => selectedDate(getNextDay(selected_date, dateCounter + 2))}>
												<h4>{changeDateFormatToWeekDayMonth(getNextDay(selected_date, dateCounter + 2))}</h4>
												{/* <h5>From {monetary_unit} {getLowestAmount(getNextDay(selected_date, dateCounter + 2), lowest)}</h5> */}
												{/* <h6>LOWEST!</h6> */}
											</div>
										</td>
										<td>
											<div className={getDateClassName(getNextDay(selected_date, dateCounter + 3)) + " hidden-768"}
												onClick={() => selectedDate(getNextDay(selected_date, dateCounter + 3))}>
												<h4>{changeDateFormatToWeekDayMonth(getNextDay(selected_date, dateCounter + 3))}</h4>
												{/* <h5>From {monetary_unit} {getLowestAmount(getNextDay(selected_date, dateCounter + 3), lowest)}</h5> */}
												{/* <h6>LOWEST!</h6> */}
											</div>
										</td>
										<td>
											<img src="images/ic_right.png" alt="right" onClick={() => setDateCounter(dateCounter + 1)} />
										</td>
									</tr>
								</table>
							</div>
						</Row>
					</div>
					<div className='mt-4 pick_departure'>
						{loading && <div className='center'>
							<LoadingScreenModal />
						</div>}
						{error && (
							<div>{`There is a problem fetching the post data - ${error}`}</div>
						)}
						<Row className="row-height clear-margin">
							<Col xs={12} sm={1}>
							</Col>
							<Col xs={4} sm={2}>
								<h5 className="mobile-left-align">Departure</h5>
							</Col>
							<Col xs={2} sm={2} className="hide-on-mobile">
								<h5>Info</h5>
							</Col>
							<Col xs={4} sm={2}>
								<h5>Vacancy</h5>
							</Col>
							<Col xs={4} sm={2}>
								<h5>Fare</h5>
							</Col>
							<Col xs={3} sm={3}>

							</Col>
						</Row>
						{
							data && data.length == 0 ?
								<div className='no_bus_trip'>
									<h3>Looking for Bus from {depart.subplacename} to {destination.subplacename}?</h3>
									<h6>Sold out or no bus trips found for the date selected. Please try other dates for Bus tickets from {depart.subplacename} to {destination.subplacename}.</h6>
								</div>
								: ""
						}

						{data && data.map((

							item
							// DepartureDate, SeatAvailable, AdultPrice, ChildPrice, TripKey, FromSubPlaceId, ToSubPlaceId, CoachType, PickupAddress, DropOffAddress, TotalSeat, TripDurationInSecond, DefaultCurrencyAdultPrice, DefaultCurrencyChildPrice 

							, index) => (
							<Row key={item.tripkey} className="row-height clear-margin depart-data">
								<Col sm={1}>
								</Col>
								<Col xs={4} sm={2} className="margin-top">
									<a className='eta mobile-left-align' onMouseEnter={(e) => onHover(e, index, 'eta')} onMouseLeave={(e) => onHoverOver(e, index, 'eta')}>{getAMorPMonTime(item.departuredate.split(" ")[1])}</a>
									<div id={'eta' + index} className='hidden hover_item'>{item.departuredate}
										<h6>Est Duration: {item.tripduration}</h6>
									</div>
								</Col>
								<Col xs={2} sm={2} className="info p-0 hide-on-mobile">
									{/* onMouseEnter={(e)=>onHover(e,index,'class')} onMouseLeave={(e)=>onHoverOver(e,index,'class')} */}
									<h2 className='class' onClick={() => showMoreDetails(currency, item.pickupaddress, item.dropoffaddress, item.departuredate, item.adultprice, item.childprice, item.tripdurationinsecond, item.coachtype)}>{item.coachtype}</h2>
									<h6 onClick={() => showMoreDetails(currency, item.pickupaddress, item.dropoffaddress, item.departuredate, item.adultprice, item.childprice, item.tripdurationinsecond, item.coachtype)}> &gt; more details</h6>
									{
										checkIncludeReturnTripTicketPlaceBySubPlaceId(item.tosubplaceid) === true ?
											<>
												<h5 className='disclaimer'>Disclaimer:<br />
													- The bus ticket includes a return ticket on the SAME DAY<br />
													- Return Trip schedule: 6:00pm From Riverside Desaru Coast to JB Sentral</h5>
											</>
											: ""
									}
									{/* <div id={'hover'+index} className='hidden hover_item'>
                                        <img className='small_size' src={getBusImage(checkBusClass(TotalSeat))}></img>
                                        <h6>*Bus images shown are indicative, and may be different from actual bus used for the journey.</h6>
                                    </div> */}
								</Col>
								<Col xs={4} sm={2}>
									<p>{item.seatavailable}</p>
								</Col>
								<Col xs={4} sm={2}>
									<p>{monetary_unit} {item.adultprice.toFixed(2)}</p>
								</Col>
								<Col xs={8} className="info display-on-mobile">
									{/* onMouseEnter={(e)=>onHover(e,index,'class')} onMouseLeave={(e)=>onHoverOver(e,index,'class')} */}
									<h2 className='class' onClick={() => showMoreDetails(currency, item.pickupaddress, item.dropoffaddress, item.departuredate, item.adultprice, item.childprice, item.tripdurationinsecond, item.coachtype)}>
										{item.coachtype}
										<span onClick={() => showMoreDetails(currency, item.pickupaddress, item.dropoffaddress, item.departuredate, item.adultprice, item.childprice, item.tripdurationinsecond, item.coachtype)}> &gt; more details</span>
										{
											checkIncludeReturnTripTicketPlaceBySubPlaceId(item.tosubplaceid) === true ?
												<>
													<h5 className='disclaimer'>Disclaimer:<br />
														- The bus ticket includes a return ticket on the SAME DAY<br />
														- Return Trip schedule: 6:00pm From Riverside Desaru Coast to JB Sentral</h5>
												</>
												: ""
										}
									</h2>
									{/* <div id={'hover'+index} className='hidden hover_item'>
                                        <img className='small_size' src={getBusImage(checkBusClass(TotalSeat))}></img>
                                        <h6>*Bus images shown are indicative, and may be different from actual bus used for the journey.</h6>
                                    </div> */}
								</Col>
								<Col xs={4} sm={3} className='text-center'>
									{title === 'Departure' ?
										(
											// <Button             
											//     id={"button"+index}
											//     className=""
											//     buttonStyle="btn--print--ticket--rectangle"
											//     buttonSize="btn--ticket"
											//     linkTo={link}
											//     onClick={()=>updateSelectedDepartData(DepartureDate,depart_class,SeatAvailable,AdultPrice,TripKey,PickupAddress,DropOffAddress)}
											//     children="Select"
											//     params={
											//         [
											//             `depart=${depart}`,
											//             `destination=${destination}`,
											//             `depart_date=${date_depart}`,
											//             `return_date=${date_return}`,
											//             `passenger=${passenger}`,
											//         ]
											//     }
											//     >
											// </Button>
											<button
												id={"button" + index}
												className="btn trips_button"
												onClick={() => updateSelectedDepartData(index, item.departuredate, item.coachtype, item.seatavailable, item.adultprice, item.childprice, item.tripkey, item.pickupaddress, item.dropoffaddress,
													// getEstimateTimeDurationInHours(item.tripdurationinsecond),
													item.tripduration,
													item.tripdurationinsecond, item.adultprice, item.childprice, item.tosubplaceid)}
												type="button"
											>
												Select
											</button>
										)
										:
										(
											// <Button       
											//     id={"button"+index}      
											//     className=""
											//     buttonStyle="btn--print--ticket--rectangle"
											//     buttonSize="btn--ticket"
											//     linkTo={link}
											//     onClick={()=>updateSelectedReturnData(index,DepartureDate,depart_class,SeatAvailable,AdultPrice,TripKey)}
											//     children="Select"
											//     params={
											//         [
											//             `depart=${depart}`,
											//             `destination=${destination}`,
											//             `depart_date=${date_depart}`,
											//             `return_date=${date_return}`,
											//             `passenger=${passenger}`,
											//             `depart_info=${str_depart_info}`,
											//             `return_info={"departure_time":"${DepartureDate}","class":"${depart_class}","vacancy":"${SeatAvailable}","fare":"${AdultPrice}","TripKey":"${TripKey}"}`
											//         ]
											//     }
											//     >
											// </Button>
											<button
												id={"button" + index}
												className="btn trips_button"
												onClick={() => updateSelectedReturnData(index, item.departuredate, item.coachtype, item.seatavailable, item.adultprice, item.childprice, item.tripkey, item.pickupaddress, item.dropoffaddress,
													// getEstimateTimeDurationInHours(item.tripdurationinsecond),
													item.tripduration,
													item.tripdurationinsecond, item.adultprice, item.childprice
												)}
												type="button"
											>
												Select
											</button>
										)
									}
								</Col>
							</Row>
						))}
					</div>
				</div>
			</Container>
		</>
	);
}
