import React, { useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import { SelectSeatDepartContext, SelectSeatReturnContext } from '../UserContext';
import { BusFareSummary } from './BusFareSummary';
import './ProceedBusFare.css';


export const ProceedBusFare = ({
    page
    }) => {
      
const [searchParams, setSearchParams] = useSearchParams();
const data_depart = searchParams.get("depart");
const data_destination = searchParams.get("destination");
const data_depart_date = searchParams.get("depart_date");
const data_return_date = searchParams.get("return_date");
const data_passenger = searchParams.get("passenger");
let {selectedDepartSeat, setDepartSeat} = useContext(SelectSeatDepartContext);
let {selectedReturnSeat, setReturnSeat} = useContext(SelectSeatReturnContext);
const origin_data = JSON.parse(sessionStorage.getItem("Origin_data"));
const destination_data = JSON.parse(sessionStorage.getItem("Destination_data"));

if(page === 'checkout') {

    selectedDepartSeat = JSON.parse(sessionStorage.getItem("selectedDepartSeat"));
    selectedReturnSeat = JSON.parse(sessionStorage.getItem("selectedReturnSeat"));
}

// console.log(selectedDepartSeat)

// const {departData, setDepartData} = useContext(DepartDataContext);
// const {returnData, setReturnData} = useContext(ReturnDataContext);

const departData = JSON.parse(sessionStorage.getItem("departData"));
const returnData = JSON.parse(sessionStorage.getItem("returnData"));
// console.log(departData);
// console.log(returnData);
  return (
<>
    <div className='bus_fare_return'>
        <BusFareSummary 
        include = "depart"
        depart_unique_name = {origin_data.subplaceuniqueshortname}
        destination_unique_name = {destination_data.subplaceuniqueshortname}
        depart = {origin_data.subplacedisplayname}
        selectedSeat = {selectedDepartSeat}
        destination = {destination_data.subplacedisplayname}
        depart_date = {data_depart_date!=null?data_depart_date:origin_data.DepartDate}
        return_date = {data_return_date!=null?data_return_date:origin_data.ReturnDate}
        passenger = {data_passenger!=null?data_passenger:origin_data.Passenger}
        depart_info = {departData}
        change_trip = "/pickDepart"
        />
    </div>
    {returnData != null ?
    <div className='bus_fare_return'>
        <BusFareSummary 
        include = "return"
        depart_unique_name = {origin_data.subplaceuniqueshortname}
        destination_unique_name = {destination_data.subplaceuniqueshortname}
        depart = {origin_data.subplacedisplayname}
        selectedSeat = {selectedReturnSeat}
        destination = {destination_data.subplacedisplayname}
        depart_date = {data_depart_date!=null?data_depart_date:origin_data.DepartDate}
        return_date = {data_return_date!=null?data_return_date:origin_data.ReturnDate}
        passenger = {data_passenger!=null?data_passenger:origin_data.Passenger}
        depart_info = {departData}
        return_info = {returnData}
        change_trip = "/pickReturn"
        />
    </div>
    :""
    }

 </>
  );
}

