import React, { useContext } from "react";
import { SelectSeatDepartContext, SelectSeatReturnContext } from "../UserContext";
import { checkOneWayTrip } from "./Function";

export const SelectSeatErrorMsg = ({}) => {
  const { selectedDepartSeat, setDepartSeat } = useContext(SelectSeatDepartContext);
  const { selectedReturnSeat, setReturnSeat } = useContext(SelectSeatReturnContext);
  const oneWayTrip = checkOneWayTrip();
  if ((!oneWayTrip && selectedDepartSeat.length == 0) || (!oneWayTrip && selectedReturnSeat.length == 0)) {
    return (
      <>
        <div className="selectSeatError mt-3">
          <h5>
            <span className="oval_red ms-3">!</span>Please select a seat
          </h5>
        </div>
      </>
    );
  } else if (oneWayTrip && selectedDepartSeat.length == 0) {
    return (
      <>
        <div className="selectSeatError mt-3">
          <h5>
            <span className="oval_red ms-3">!</span>&nbsp;Please select a seat
          </h5>
        </div>
      </>
    );
  }
};
