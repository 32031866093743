import React, { useState } from 'react';
import Row from 'react-bootstrap/esm/Row';
import Modal from 'react-bootstrap/Modal';
import './LoadingScreenModal.css';

export const LoadingScreenModal = ({
  
}) => {
  const [show, setShow] = useState(true);
  return (
    <>
    <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="LoadingScreenModal"
        animation={false}
        backdrop="static"
        keyboard={false}
    >
        <Row>
            <Modal.Body className="p-0">
                <img className='LoadingScreen' src="./images/loading.png" alt="Loading Screen" />
            </Modal.Body>
        </Row>
    </Modal>
    </>
  );
}


