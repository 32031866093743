import React, { useEffect, useState } from 'react';
import '../../App.css';
import { AccountDetails } from '../AccountDetails';
import { useSearchParams } from 'react-router-dom';
import { getToken, getUserPrimaryEmail, tokenIsValid, setLoginStatus, setUserToken, setUserTokenExpiry, setUserData } from '../Function';

function MyAccount() {
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get("key");
  const token_expiry = searchParams.get("expiry");
  const login = localStorage.getItem("login");
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const MANJA_URL = process.env.REACT_APP_MANJA_REWARD_URL;
  const API_KEY = process.env.REACT_APP_MANJA_REWARD_KEY;
  const [userLoyalty, setUserLoyalty] = useState([]);
  const email = getUserPrimaryEmail();
  if(!token && !token_expiry && login == null) {
    window.location.href = '/';
  }

  useEffect(()=> {
    if(token) {
      const getUserProfileByToken = async () => {
        try {
            const response = await fetch(`${SERVER_URL}/user/profile/token/${token}/${token_expiry}`,
            {
              headers: {
                  'Authorization': getToken()
              }
            });
            if (!response.ok) {
                throw new Error(
                    `This is an HTTP error: The status is ${response.status}`
                );
            }
            let actualData = await response.json();
            setLoginStatus(true);
            setUserToken(token);
            setUserTokenExpiry(token_expiry);
            setUserData(actualData);
            localStorage.setItem("userData", JSON.stringify(actualData));
            localStorage.setItem("login", JSON.stringify(true));
            localStorage.setItem("primaryEmail", JSON.stringify(actualData.primaryemail));
            if(actualData && !email) {
              window.location.reload();
            }
        } catch (err) {
            console.log(err)
        } finally {
          // setLoading(false)
        }
      }
      tokenIsValid()
      getUserProfileByToken();
    }
    
    //user loyalty data history
    const user_loyalty = `${MANJA_URL}/Transactions/1/email/${email}`;

    const getUserLoyalty = async () => {
      try {
        const user_loyalty_response = await fetch(
          user_loyalty,
          {
            method: 'GET',
            mode: 'cors',
            headers: {
              'apikey': API_KEY,
              'Authorization': getToken()
            }
          }
        );
        if (!user_loyalty_response.ok) {
          throw new Error(
            `This is an HTTP error: The status is ${user_loyalty_response.status}`
          );
        }
        let myUserLoyalty = await user_loyalty_response.json();
        setUserLoyalty(myUserLoyalty.results);
        // localStorage.setItem("userLoyaltyData", JSON.stringify(myUserLoyalty.results));
        // console.log(myUserLoyalty.results)
      } catch (err) {
        alert('error')
      }
    }
    tokenIsValid()
    getUserLoyalty();
    
  },[getToken()])

  if(email)
    return (
      <>
        <AccountDetails 
        userLoyalty = {userLoyalty}
        />
      </>
    );
  }
  
  export default MyAccount;
  