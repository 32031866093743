import React from 'react';
import '../../App.css';
import BookingAddOn from '../BookingAddOn';
import {BookingAddOnDeals} from '../BookingAddOnDeals';
import { BookingDetails } from '../BookingDetails';
import { BookingHeader } from '../BookingHeader';


function BookingEdit() {
//will change to read from db
  const depart_info = ({"departure_time":"2022-09-09 14:00","class":"Standard","vacancy":"30","fare":"35","TripKey":"MY-int-20443754-498f8539-f44f-401e-99c5-8733cfbc4980-5877"});
  const return_info = ({"departure_time":"2022-09-11 15:00","class":"Standard","vacancy":"30","fare":"35","TripKey":"MY-int-20443623-6ba52ca7-0206-4a77-88fc-c75e2d77f032-5877"});
  return (
    <>
      <BookingHeader
        booking_status="Confirmed"
        trip_id="AD88195"
        amount="375"
        printTicket="Print Ticket"
      >
      </BookingHeader>
      <BookingDetails
        depart_info = {depart_info}
        return_info = {return_info}
        depart_date = '2022-09-10'
        return_date = '2022-09-15'
        data_depart = 'TBS Kuala Lumpur'
        data_destination = 'Skudai'
        data_passenger = '1'
      >
      </BookingDetails>
      <BookingAddOn />
      <BookingAddOnDeals 
      component="5"
      />
    </>
  );
}

export default BookingEdit;