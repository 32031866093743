import React from 'react';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import './Footer.css';


function Footer() {
	const d = new Date();
	const current_year = d.getFullYear();
	function activeMenu(id, arrow) {
		var el = document.getElementById(id);
		var ar = document.getElementById(arrow);
		if (el.classList.contains('active')) {
			el.classList.remove('active');
			ar.classList.remove('arrow_turn')
		}
		else {
			el.classList.add('active');
			ar.classList.add('arrow_turn')
		}

	}

	return (
		<div className='footer-container'>
			<div className='footer1'>
				<Row className='g-0'>
					<Col md={4} lg={3} className='footer-link-items'>
						<div className='footer-menu' onClick={() => activeMenu('footer-1', 'footer-1-arrow')}>
							<h2>PRODUCTS AND SERVICES<span id='footer-1-arrow' className="fa-solid fa-chevron-down right"></span></h2>
						</div>
						<div id='footer-1' className='footer-menu-items'>
							<a href='https://www.causewaylink.com.my/routes-schedules/singapore-cross-border-bus/'>Singapore Bus</a>
							<a href='https://www.causewaylink.com.my/routes-schedules/local-bus/'>Local Bus</a>
							<a href='https://www.causewaylink.com.my/routes-schedules/airport-shuttle-bus/'>Airport Shuttle Bus</a>
							<a href='https://www.causewaylink.com.my/routes-schedules/mybas/'>myBAS</a>
							<a href='https://www.causewaylink.com.my/routes-schedules/bus-muafakat/'>Bus Muafakat</a>
							<a href='https://causewaylink.com.my/express/'>Express Bus</a>
							{/* <a href='https://www.causewaylink.com.my/routes-schedules/shopping-leisure-bus/'>Shopping & Leisure Bus</a> */}
							{/* <a href='https://www.causewaylink.com.my/holiday/bus-pass/pass-details/?id=1050-Travel-Bus-Pass'>Travel Bus Pass</a> */}
							<a href='https://www.causewaylink.com.my/products-and-services/bus-advertising/'>Bus Advertising</a>
						</div>
						<div className='footer-menu' onClick={() => activeMenu('footer-2', 'footer-2-arrow')}>
							<h2>BUS RENTAL<span id='footer-2-arrow' className="fa-solid fa-chevron-down right"></span></h2>
						</div>
						<div id='footer-2' className='footer-menu-items'>
							<a href='https://www.causewaylink.com.my/bus-rental/shuttle-bus/'>Shuttle Bus</a>
							<a href='https://www.causewaylink.com.my/bus-rental/exhibition-bus/'>Exhibition Bus</a>
							<a href='https://www.causewaylink.com.my/bus-rental/factory-bus/'>Factory Bus</a>
							<a href='https://www.causewaylink.com.my/bus-rental/coach-hire/'>Coach Hire</a>
							<a href='https://www.causewaylink.com.my/bus-rental/charter-bus-rental-services/'>Charter Bus Rental Services</a>
						</div>
					</Col>
					<Col md={4} lg={3} className='footer-link-items'>
						<div className='footer-menu' onClick={() => activeMenu('footer-3', 'footer-3-arrow')}>
							<h2>BUSINESS<span id='footer-3-arrow' className="fa-solid fa-chevron-down right"></span></h2>
						</div>
						<div id='footer-3' className='footer-menu-items'>
							<a href='https://www.causewaylink.com.my/business/business-partnership/'>Business Partnership</a>
							<a href='https://www.causewaylink.com.my/business/terminal-management/'>Terminal Management</a>
						</div>
						<div className='footer-menu' onClick={() => activeMenu('footer-4', 'footer-4-arrow')}>
							<h2>PARTNERS<span id='footer-4-arrow' className="fa-solid fa-chevron-down right"></span></h2>
						</div>
						<div id='footer-4' className='footer-menu-items'>
							{/* <a href='https://www.causewaylink.com.my/holiday/'>Causeway Link Holidays</a> */}
							<a href='https://www.manjalink.com.my/'>ManjaLink</a>
							<a href='https://lugo.live/'>LUGO</a>
							{/* <a href='http://manjalabs.com/'>Manja Labs</a> */}
							{/* <a href='https://manjastudios.com/'>Manja Studio</a> */}
							{/* <a href='https://www.facebook.com/people/Hipgraphy-Advertising-Sdn-Bhd/100057621963867/'>Hipgraphy</a> */}
							{/* <a href='https://kumpool.com.my/'>Kumpool</a> */}
							{/* <a href='https://www.manja.my/'>ManjaStore</a> */}
						</div>
					</Col>
					<Col md={4} lg={3} className='footer-link-items'>
						<div className='footer-menu' onClick={() => activeMenu('footer-5', 'footer-5-arrow')}>
							<h2>COMPANY INFORMATION<span id='footer-5-arrow' className="fa-solid fa-chevron-down right"></span></h2>
						</div>
						<div id='footer-5' className='footer-menu-items'>
							<a href='https://www.causewaylink.com.my/company-information/about-us/'>About Us</a>
							<a href='https://www.causewaylink.com.my/company-information/mission-vision-values/'>Mission, Vision & Values</a>
							<a href='https://www.causewaylink.com.my/company-information/milestone/'>Milestone</a>
							<a href='https://www.causewaylink.com.my/company-information/careers/'>Careers</a>
							<a href='https://www.causewaylink.com.my/category/news/'>News</a>
							<a href='https://www.causewaylink.com.my/company-information/counter-locations/'>Counter Locations</a>
							<a href='https://www.causewaylink.com.my/company-information/contact-us/'>Contact Us</a>
						</div>
						<div className='footer-menu' onClick={() => activeMenu('footer-6', 'footer-6-arrow')}>
							<h2>OTHERS INFORMATION<span id='footer-6-arrow' className="fa-solid fa-chevron-down right"></span></h2>
						</div>
						<div id='footer-6' className='footer-menu-items'>
							<a href='https://www.causewaylink.com.my/help/'>FAQs</a>
							<a href='https://www.causewaylink.com.my/category/travel-guide/'>Travel Guide</a>
							<a href='https://www.causewaylink.com.my/category/destination-guide/'>Destination Guide</a>
							<a href='https://www.causewaylink.com.my/category/promotions/'>Promotions</a>
							<a href='https://www.causewaylink.com.my/others-information/loyalty-program/'>Loyalty Program</a>
						</div>
					</Col>
					<Col md={12} lg={3} className='footer-link-items footer-images'>
						<Row className='g-0'>
							<Col md={3} lg={12} className='footer-menu'>
								<h2>COMPANIES</h2>
								<img src='/images/img_handal_indah.jpg' />
							</Col>
							<Col md={5} lg={12} className='footer-menu'>
								<h2>AWARDS</h2>
								<p>
									<img src='/images/img_bizSAFE-Enterprise-Level-3.png' />
									<img src='/images/img_brand.png' />
									<img src='/images/img_SME100.png' />
									<img src='/images/img_soe-300x150-1.png' />
									<img src='/images/img_top-100-singapore-elite-enterprise-2015-2016.png' />
								</p>
							</Col>
							<Col md={4} lg={12} className='footer-menu'>
								<h2>Powered by LUGO App</h2>
								<a href='https://rem7j.app.goo.gl/qYU7' target='_blank'>
									<img className='powered' src='images/img_store.png' />
								</a>
							</Col>
						</Row>
					</Col>
				</Row>
			</div>
			{/* <div class='footer-links'>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h2>About Us</h2>
            <Link to='/'>Milestone</Link>
            <Link to='/'>Our Mission</Link>
            <Link to='/'>Press Room</Link>
            <Link to='/'>Event</Link>
            <Link to='/'>Group Companies</Link>
            <Link to='/'>Leadership</Link>
            <Link to='/'>Awards</Link>
            <Link to='/'>Careers</Link>
          </div>
          <div class='footer-link-items'>
            <h2>Where We Covered</h2>
            <Link to='/'>Route Map</Link>
            <Link to='/'>Johor</Link>
            <Link to='/'>Singapore</Link>
            <Link to='/'>Kuala Lumpur</Link>
            <Link to='/'>Penang</Link>
            <Link to='/'>Melaka</Link>
            <Link to='/'>Ipoh</Link>
          </div>
          <div class='footer-link-items'>
            <h2>Booking</h2>
            <Link to='/'>Book Bus</Link>
            <Link to='/'>Travel</Link>
            <Link to='/'>Plan Trip</Link>
            <Link to='/'>Search for Bus</Link>
          </div>
          <div class='footer-link-items'>
            <h2>Manage</h2>
            <Link to='/'>Manage your booking</Link>
            <Link to='/'>Travel Updates</Link>
            <Link to='/'>Travel Notice</Link>
            <Link to='/'>Before the trip</Link>
          </div>
          <div class='footer-link-items'>
            <h2>Loyalty</h2>
            <Link to='/'>ManjaLink</Link>
            <Link to='/'>Join ManjaLink</Link>
            <Link to='/'>Our partners</Link>
            <Link to='/'>Benefits</Link>
          </div>
        </div>
      </div> */}
			<div className='footer-copyright-container'>
				<div className='footer-subcontainer'>
					<Row className='m-0'>
						<Col className='middle' lg={{ span: 2, order: 'last' }}>
							<div className='footer-icon-center'>
								<a className='footer-icon' href="https://www.facebook.com/causewaylink/" target="_blank">
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-facebook" viewBox="0 0 16 16">
										<path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951" />
									</svg>
								</a>
								<a className='footer-icon' href="https://twitter.com/CausewayLinkMY" target="_blank">
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-twitter-x" viewBox="0 0 16 16">
										<path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
									</svg>
								</a>
								<a className='footer-icon' href="https://www.instagram.com/causewaylink/" target="_blank">
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-instagram" viewBox="0 0 16 16">
										<path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334" />
									</svg>
								</a>
								<a className='footer-icon' href="https://www.linkedin.com/company/handal-indah-sdn-bhd" target="_blank">
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-linkedin" viewBox="0 0 16 16">
										<path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z" />
									</svg>
								</a>
								<a className='footer-icon' href="https://www.youtube.com/channel/UCg62syI04abhyfQwgnDdq6g" target="_blank">
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-youtube" viewBox="0 0 16 16">
										<path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.01 2.01 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.01 2.01 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31 31 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.01 2.01 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A100 100 0 0 1 7.858 2zM6.4 5.209v4.818l4.157-2.408z" />
									</svg>
								</a>
								<a className='footer-icon' href="mailto:feedback@support.causewaylink.com.my" target="_blank">
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
										<path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z" />
									</svg>
								</a>
								{/* <img className='mt-1 footer-icon' src='images/ic_fb.png'></img>
                <img className='mt-1 footer-icon' src='images/ic_twitter.png'></img>
                <img className='mt-1 footer-icon' src='images/ic_googleplus.png'></img>
                <img className='mt-1 footer-icon' src='images/ic_insta.png'></img>
                <img className='mt-1 footer-icon' src='images/ic_email.png'></img> */}
							</div>
						</Col>
						<Col lg={5}>
							<div className='copyright-handal-link border-left'>
								<a href='https://www.causewaylink.com.my/help/knowledge-base/causeway-link-website-disclaimers/' className='copyright-handal-link'>Legal</a>
								<a href='https://www.causewaylink.com.my/help/knowledge-base/causeway-link-privacy-policy/' className='copyright-handal-link'>Privacy Policy</a>
								<a href='https://www.causewaylink.com.my/help/knowledge-base/causeway-link-cookies-policy/' className='copyright-handal-link'>Cookies Policy</a>
								<a href='https://www.causewaylink.com.my/help/knowledge-base/terms-and-conditions/' className='copyright-handal-link'>Terms & Condition</a>
							</div>
						</Col>
						<Col lg={{ span: 5, order: 'first' }}>
							<div className='copyright-handal'>
								Copyright© 2006 – {current_year} Handal Indah Sdn . Bhd. 199401001789 (287467-M). All Rights Reserved.
							</div>
						</Col>
					</Row>
				</div>
			</div>
		</div>
	);
}

export default Footer;
