import React, { useContext, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Row from 'react-bootstrap/esm/Row';
import Form from 'react-bootstrap/Form';
import { LoyaltyDiscountContext, LoyaltyIDContext, VoucherCodeContext, VoucherDiscountContext } from '../UserContext';
import { getToken, loyaltyPriceToPoints, tokenIsValid } from './Function';
import './LoyaltyDiscount.css';

export const LoyaltyDiscount = ({
	manjaRewards = 0,
	primary_guest_email,
	departure,
	destination,
	depart_date,
	return_date,
	bus_fare,
	no_of_pax,

	depart_trip_key,
	return_trip_key,

	login
}) => {



	const SERVER_URL = process.env.REACT_APP_SERVER_URL;
	const { LoyaltyID, setLoyaltyID } = useContext(LoyaltyIDContext);
	const { TotalLoyalty, setLoyalty } = useContext(LoyaltyDiscountContext);

	// const [EnterVoucherCode, setUserEnterVoucherCode] = useState(null);
	const { VoucherCode, setVoucherCode } = useContext(VoucherCodeContext);
	const { VoucherDiscount, setVoucherDiscount } = useContext(VoucherDiscountContext);

	const currency = JSON.parse(localStorage.getItem("currency"));
	const email = JSON.parse(localStorage.getItem("primaryEmail"));


	const [suggestedVouchers, setSuggestedVouchers] = useState([]);
	const [isDropdownVisible, setDropdownVisible] = useState(false);


	// console.log(VoucherDiscount)
	function loyalty(value, element) {
		const id = element.getAttribute('id');
		setLoyalty(value);
		setLoyaltyID(id);
		setVoucherCode(null);
		setVoucherDiscount(0);
		var points = loyaltyPriceToPoints(value);
		// console.log(value)
		// console.log(points)
	}


	function changeCurrencyNaming(curr) {
		switch (curr) {
			case 'MYR': return 'RM';
			default: return curr;
		}
	}



	const fetchVoucherCodes = async () => {
		try {
			const response = await fetch(
				`${SERVER_URL}/voucher/email/${email}`,
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						'Authorization': getToken(),
					},
					body: JSON.stringify({
						Departure: departure,
						Destination: destination
					}),
				}
			);

			if (!response.ok) {
				let errorData = await response.json();
				throw new Error(errorData.msg || `HTTP error: Status ${response.status}`);
			}

			let data = await response.json();
			setSuggestedVouchers(data); // Assuming the response contains a list of vouchers

		} catch (error) {
			console.error('Error fetching voucher codes:', error);
		}
	};

	const handleInputFocus = () => {
		fetchVoucherCodes(); // Fetch voucher codes when the input is focused
		setDropdownVisible(true);
	};

	const handleVoucherSelect = (voucher) => {
		setVoucherCode(voucher); // Set the selected voucher
		setDropdownVisible(false); // Hide dropdown after selection
	};


	const handleVoucherInputChange = (value) => {
		setVoucherCode(value); // Allow manual voucher entry
		setDropdownVisible(true); // Show dropdown while user types
	};

	// Hide dropdown when input field loses focus
	const handleInputBlur = () => {
		setTimeout(() => setDropdownVisible(false), 200); // Delay hiding to allow click event on dropdown item
	};


	const checkVoucherCode = (voucher) => {
		tokenIsValid();
		const el = document.getElementById('invalidCode');
		const el2 = document.getElementById('invalidCurrency');
		el.classList.add('hidden');
		el2.classList.add('hidden');

		const postData = async () => {
			try {
				const response = await fetch(
					`${SERVER_URL}/voucher`,
					{
						method: 'POST',
						headers: {
							'Content-Type': 'application/json',
							'Authorization': getToken()
						},
						body: JSON.stringify({
							VoucherCode: voucher,
							Email: primary_guest_email,
							Departure: departure,
							Destination: destination,
							DepartureDate: depart_date,
							ReturnDate: return_date,
							BusFare: bus_fare,
							NoOfPax: no_of_pax,
							DepartTripKey: depart_trip_key,
							ReturnTripKey: return_trip_key
						})
					}
				);


				if (response.status === 404) {
					// Handle 404 specifically: invalid voucher code
					el.textContent = 'Invalid voucher code';
					el.classList.remove('hidden');
					setVoucherDiscount(0);
					setVoucherCode(voucher);
					return;
				}

				if (!response.ok) {
					let errorData = await response.json(); // Get the response body containing the error message
					throw new Error(
						errorData.msg || `This is an HTTP error: The status is ${response.status}`
					);
				}

				let actualData = await response.json();
				// console.log('loyalty data:');
				// console.log(actualData);
				if (actualData.length != 0) {
					// change MYR to RM
					if (actualData[0].vouchercurrency == changeCurrencyNaming(currency)) {
						// console.log(actualData[0].vouchercurrency == changeCurrencyNaming(currency))
						setVoucherDiscount(parseFloat(actualData[0].vouchervalue));
						setVoucherCode(voucher);
						setLoyalty(0);
						setLoyaltyID(null);
					}
					else {
						el2.classList.remove('hidden');
						setVoucherDiscount(0);
						setVoucherCode(voucher);
					}
				}
			} catch (err) {
				// Handle errors as needed
				el.textContent = err.message || 'Invalid Code';

				el.classList.remove('hidden');
				setVoucherDiscount(0);
				setVoucherCode(voucher);
			}
		};



		postData();
	};


	return (
		<>
			<div className="loyalty-container">
				<table width={'100%'} className='loyalty_discount'>
					<tr>
						<td rowSpan={2}><h1>Loyalty Discount</h1><span className="oval">i</span></td>
					</tr>
					<tr>
						<td><h5>{manjaRewards.points ? parseFloat(manjaRewards.points).toFixed() : 0} points</h5><h6>available</h6></td>
					</tr>
				</table>
				<Row className='p-3'>
					<Form.Select className="loyalty_form" id="loyalty_discount_form" name="loyalty_discount_form" onChange={(e) => loyalty(e.target.value, e.target.childNodes[e.target.selectedIndex + 1])}>
						<option class="option_label" disabled>Choose your discount</option>
						<Dropdown.Divider />
						<option value="0" selected>{manjaRewards?.rewards ? "Please select the available discount" : "No loyalty discount"}</option>
						{manjaRewards && manjaRewards.rewards && manjaRewards.rewards.map(({ id, desc, discount, name, seqno }, index) => (
							<option id={id} value={discount}>{name}</option>
						))}


					</Form.Select>
				</Row>
				<table width={'100%'} className='loyalty_discount'>
					<h1>Do you have a promo code?</h1>
				</table>
				<Row className='p-3 pb-5 content_right'>
					<Form.Control
						className="loyalty_form"
						type="text"
						id="promo_code"
						value={VoucherCode}
						onFocus={handleInputFocus} // Show suggestions on focus
						onBlur={handleInputBlur} // Hide suggestions on blur
						onChange={(e) => handleVoucherInputChange(e.target.value)} // Allow manual entry
					/>
					{isDropdownVisible && suggestedVouchers.length > 0 && (
						<ul className="voucher-suggestions">
							{suggestedVouchers.map((item) => (
								<li
									key={item.vouchercode}
									onClick={() => handleVoucherSelect(item.vouchercode)}
								>
									<div className="half-circle-border">
										<span className="half-circle"></span>
										<span className="half-circle"></span>
										<span className="half-circle"></span>
										<span className="half-circle"></span>
										<div className="content-wrapper">
											<div className="content">
												{/* Determine the display value based on the voucher type */}
												<div>
													{item.vouchertype === 'AMOUNT' ? `${item.vouchercurrency}${item.vouchervalue}` : `${item.vouchervalue}%`}
												</div>
												<div style={{ fontSize: '14px' }}>OFF</div>
											</div>
											<div className="voucher-description">
												{/* Same logic reused for the description */}
												<div style={{ fontWeight: 'bold' }}>
													{item.vouchertype === 'AMOUNT' ? `${item.vouchercurrency}${item.vouchervalue}` : `${item.vouchervalue}%`} OFF
												</div>
												<div style={{ color: '#537EB5', marginTop: '5px', fontSize: '12px', fontWeight: 'bold' }}>
													Valid until: {item.expirydate}
												</div>
											</div>
										</div>
									</div>

								</li>
							))}
						</ul>
					)}
					<label id='invalidCode' className='hidden'>Invalid Code</label>
					<label id='invalidCurrency' className='hidden'>Invalid currency to apply promo code</label>
					<button onClick={() => checkVoucherCode(VoucherCode)} className='VoucherApply'>Apply</button>
				</Row>
			</div>
		</>
	);
}

export default LoyaltyDiscount;