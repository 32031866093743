import React from 'react';
import Col from 'react-bootstrap/esm/Col';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import { Link } from 'react-router-dom';
import { checkIsReschedule, getAMorPMonTime, getSubPlaceShortName } from './Function';
import './SelectedDeparture.css';
import { useSearchParams } from 'react-router-dom';

export const SelectedDeparture = ({
    title,
    depart,
    destination,
    date_depart,
    date_return,
    passenger,
    depart_area,
    destination_area,
    data_depart,
    departure_time,
    depart_info
    }) => {
let converted_depart_date = null;
const checkReschedule = checkIsReschedule();
const [searchParams, setSearchParams] = useSearchParams();
const request_id = searchParams.get("request_id") ?? "";
if(depart_info != null) {
    const selected_depart_date = (depart_info.departure_time.split(" ")[0]);
    // console.log(selected_depart_date)
    converted_depart_date = new Intl.DateTimeFormat('en', 
    {
        year: 'numeric', 
        month: 'short',
        day: '2-digit',
        weekday: 'long'
    }
    ).format(Date.parse(selected_depart_date));
    // console.log(converted_depart_date);
}
// console.log(depart_info)
let params = `depart=${depart.subplaceuniqueshortname}&destination=${destination.subplaceuniqueshortname}&depart_date=${date_depart}&return_date=${date_return}&passenger=${passenger}&request_id=${request_id}`;
let href = `/pickDepart?${params}`;

if(checkReschedule) {
    href = '/pickDepart';
}
// console.log(depart)
// console.log(destination)
  return (
    <>
    <Container>
        <div className='selected_departure mt-3'>
            <Row>
                <h1>Your selected departure<Link to={href}>Change Trip</Link></h1>
                
            </Row>
            <Row>
                <Col xs={5} sm={2}>
                    <h6>{depart.placename}</h6>
                    <h5>{(depart.subplacedisplayname)}</h5> 
                </Col>
                <Col xs={2} sm={1}>
                    <img src = 'images/ic_oneway.png' />
                </Col>
                <Col xs={5} sm={2}>
                    <h6>{destination.placename}</h6>
                    <h5>{(destination.subplacedisplayname)}</h5> 
                </Col>
                <Col xs={12} sm={3}> 
                    <h6>Date</h6>
                    <h5>{converted_depart_date}</h5>
                </Col>
                <Col xs={6} sm={2}> 
                    <h6>Time</h6>
                    <h5>{getAMorPMonTime(depart_info.departure_time.split(" ")[1])}</h5>
                </Col>
                <Col xs={6} sm={2}> 
                    <h6>Duration</h6>
                    <h5>{depart_info.duration}</h5>
                </Col>
            </Row>
        </div>
    </Container>
    </>
  );
}

