import React from "react";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import "./UpcomingBooking.css";

function UpcomingBooking(props) {
	const booking_history_array = props.bookingHistoryData;

	function getDepartAndDestinationPlaceName(details, DepartOrDestination = "Depart") {
		var placeNameArr = details.split(" - ");
		if (DepartOrDestination === "Depart") {
			return placeNameArr[0];
		} else return placeNameArr[1];
	}

	function getDateOrTime(dateTime, DateOrTime = "Date") {

		var dateTimeArr = dateTime.split(" ");
		if (DateOrTime === "Date") {
			//return date 10/23/2022
			return dateTimeArr[0];
		}
		//return time 12:00:00 AM
		else return dateTimeArr[1] + dateTimeArr[2];
	}

	function linkToBookingHistory(id, addon, oldcartguid, departbookingcode, returnbookingcode) {
		sessionStorage.setItem("history_guid", JSON.stringify(id));
		sessionStorage.setItem("addon", JSON.stringify(addon));
		sessionStorage.setItem("oldcartguid", JSON.stringify(oldcartguid));

		sessionStorage.setItem("depart_booking_code", JSON.stringify(departbookingcode));
		sessionStorage.setItem("return_booking_code", JSON.stringify(returnbookingcode));
		

		const rescheduleData = {
			history_guid: id,
			addon: addon,
			oldcartguid: oldcartguid,
			depart_booking_code: departbookingcode,
			return_booking_code: returnbookingcode
		};
	
		// Store the object in sessionStorage as a JSON string
		sessionStorage.setItem("reschedule_data", JSON.stringify(rescheduleData));

		

	}

	function getClassName(index) {
		if (index % 2 == 0) {
			return "row-min-height clear-margin";
		} else {
			return "row-min-height clear-margin background-color-change";
		}
	}

	return (
		<Container>
			<div className="upcoming-booking-container">
				<div className="table-container">
					<Row className="clear-margin">
						<Col className="background-blue">
							<h1>UPCOMING BOOKINGS</h1>
						</Col>
					</Row>
					<Row className="row-min-height clear-margin">
						<Col xs={2} sm={2}>
							<h5>Date</h5>
						</Col>
						<Col xs={2} sm={2}>
							<h5>From</h5>
						</Col>
						<Col xs={2} sm={2}>
							<h5>To</h5>
						</Col>
						<Col xs={2} sm={2}>
							<h5>Booking Number</h5>
						</Col>
						<Col xs={2} sm={2}>
							<h5>Guest Name</h5>
						</Col>
						<Col xs={2} sm={2}></Col>
					</Row>
					{booking_history_array &&
						booking_history_array.map(
							(
								{
									newcartguid,
									oldcartguid,
									addon,
									totalamount,
									transactionid,
									cartguidid,
									currencyid,
									customeremail,
									details,
									customername,
									customerphone,
									merchantreferencecode,
									noofpax,
									departdate,
									boardingcode,
									returndate,
									statusid,
									isrescheduledepartallow,
									isreschedulereturnallow,
									departbookingcode,
									returnbookingcode,
								},
								index
							) => (
								<Row className={getClassName(index)}>
									<Col xs={2} sm={2}>
										<p>{getDateOrTime(departdate)}</p>
									</Col>
									<Col xs={2} sm={2}>
										<p>{getDepartAndDestinationPlaceName(details)}</p>
									</Col>
									<Col xs={2} sm={2}>
										<p>
											{getDepartAndDestinationPlaceName(details, "destination")}
										</p>
									</Col>
									<Col xs={2} sm={2}>
										<p>{boardingcode}</p>
									</Col>
									<Col xs={2} sm={2}>
										<p>{customername}</p>
									</Col>
									<Col xs={2} sm={2} className="margin-top">
										{
											// isrescheduledepartallow ==='1' || 
											// isreschedulereturnallow ==='1'

											//newcartguid == "" || newcartguid == null
											//  ?
											  (
												<a
													href="/bookingHistory?reschedule=true"
													onClick={() =>
														linkToBookingHistory(cartguidid, addon, oldcartguid, departbookingcode, returnbookingcode)
													}
												>
													View
												</a>
											) 
											// : (
											// 	<a>Rescheduled</a>
											// )
										}
									</Col>
								</Row>
							)
						)}
				</div>
			</div>
		</Container>
	);
}

export default UpcomingBooking;
