import React from 'react';
import Col from 'react-bootstrap/esm/Col';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import './UpcomingBooking.css';

function CompletedBooking(props) {
    const booking_history_array = props.bookingHistoryData;

    function getDepartAndDestinationPlaceName(details, DepartOrDestination = 'Depart') {
        var placeNameArr = details.split(" - ");
        if(DepartOrDestination == 'Depart') {
            return placeNameArr[0];
        }
        else return placeNameArr[1];
    }
    function getDateOrTime(dateTime,DateOrTime = 'Date') {
        var dateTimeArr = dateTime.split(" ");
        if(DateOrTime == 'Date') {
            //return date 10/23/2022
            return dateTimeArr[0];
        }
        //return time 12:00:00 AM
        else return dateTimeArr[1] + dateTimeArr[2];
    }
    function linkToBookingHistory(id,addon) {
        sessionStorage.setItem("history_guid",JSON.stringify(id));
        sessionStorage.setItem("addon",JSON.stringify(addon));
    }
    function getClassName(index) {
        if(index%2==0) {
            return "row-min-height clear-margin";
        }
        else {
            return "row-min-height clear-margin background-color-change";
        }
    }

// console.log(booking_history_array)
  return (
    <Container>
        <div className='upcoming-booking-container'>
            <div className='table-container'>
        <Row className="clear-margin">
            <Col className='background-blue'>
                <h1>PAST BOOKINGS</h1>
            </Col>    
        </Row>
        <Row className="row-min-height clear-margin">
            <Col xs={2} sm={2}>
                <h5>Date</h5>
            </Col>    
            <Col xs={2} sm={2}>
                <h5>From</h5>
            </Col>    
            <Col xs={2} sm={2}>
                <h5>To</h5>
            </Col>    
            <Col xs={2} sm={2}>
                <h5>Booking Number</h5>
            </Col>    
            <Col xs={2} sm={2}>
                <h5>Guest Name</h5>
            </Col>    
            <Col xs={2} sm={2}>
            </Col>    
        </Row>
        {
        booking_history_array && booking_history_array.map(({ newcartguid,addon,totalamount,transactionid, cartguidid,currencyid, customeremail, details, customername,customerphone,merchantreferencecode,noofpax, departdate,boardingcode,returndate,statusid}, index) => (
        <Row className={getClassName(index)}>
            <Col xs={2} sm={2}>
                <p>{getDateOrTime(departdate)}</p>
            </Col>    
            <Col xs={2} sm={2}>
                <p>{getDepartAndDestinationPlaceName(details)}</p>
            </Col>    
            <Col xs={2} sm={2}>
                <p>{getDepartAndDestinationPlaceName(details,'destination')}</p>
            </Col>    
            <Col xs={2} sm={2}>
                <p>{boardingcode}</p>
            </Col>    
            <Col xs={2} sm={2}>
                <p>{customername}</p>
            </Col>    
            <Col xs={2} sm={2} className="margin-top">
                {
                    newcartguid == "" || newcartguid == null ?
                    <a href='/bookingHistory' onClick={()=>linkToBookingHistory(cartguidid,addon)}>View</a>
                    :
                    <a>Rescheduled</a>
                }
            </Col>    
        </Row>
        ))
        }
        </div>
        </div>
    </Container>
  );
}

export default CompletedBooking;
