import React from "react";
import Row from "react-bootstrap/esm/Row";
import Form from 'react-bootstrap/Form';
import "../App.css";
import "./ConfirmationEmail.css";
import { ContentTitle } from "./ContentTitle";
import { checkIsReschedule, getUserPrimaryEmail } from "./Function";

function ConfirmationEmail({parentPrimaryContactCallback,userMobile,reschedulePassengerNumber}) {
    const checkReschedule = checkIsReschedule();
    // console.log(reschedulePassengerNumber)
  return (
    <>
        <div className="confirmationEmail">
            <ContentTitle
            title='Confirmation Email'
            description='We will send your confirmation to this email.'
            />
            <div className="confirmationEmail_content pt-3">
                <Form>
                    <Row>
                        <h3>Your confirmation will be sent to this email address.</h3>
                        <Form.Control disabled={checkReschedule==true?true:false} type="email" placeholder="Your email" defaultValue={getUserPrimaryEmail()} onChange={event => parentPrimaryContactCallback(event, event.target.value, 'primary_email')}   />
                    </Row>
                    <Row>
                        <h3>Your contact number</h3>
                        <Form.Control disabled={checkReschedule==true?true:false} type="text" placeholder="Your contact" defaultValue={checkReschedule==true?(reschedulePassengerNumber!=""?reschedulePassengerNumber:""):(userMobile != "" ? userMobile : "")} onChange={event => parentPrimaryContactCallback(event, event.target.value, 'primary_contact')}  />
                    </Row>
                </Form>
            </div>
        </div>
    </>
    );
}

export default ConfirmationEmail;