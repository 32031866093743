import React from 'react';

//get token to call API
export function setToken(token) {
	if (token != null) {
		sessionStorage.setItem("token", JSON.stringify(token))
	}
}

export function getToken() {
	var token = sessionStorage.getItem("token");
	if (token != null) {
		token = JSON.parse(token)
		return "bearer " + token
	}
	else return null;
}

export function clearToken() {
	sessionStorage.removeItem("token");
}

export function setTokenExpiry(tokenExpiry) {
	if (tokenExpiry != null) {
		sessionStorage.setItem("tokenExpiry", JSON.stringify(tokenExpiry))
	}
}

export function getTokenExpiry() {
	var tokenExpiry = sessionStorage.getItem("tokenExpiry");
	if (tokenExpiry != null) {
		tokenExpiry = JSON.parse(tokenExpiry)
		return tokenExpiry
	}
	else return 0;
}

export function clearTokenExpiry() {
	sessionStorage.removeItem("tokenExpiry");
}


// user token while logged in
export function setUserToken(UserToken) {
	if (UserToken != null) {
		localStorage.setItem("UserToken", JSON.stringify(UserToken))
	}
}

export function getUserToken() {
	var UserToken = localStorage.getItem("UserToken");
	if (UserToken != null) {
		UserToken = JSON.parse(UserToken)
		return UserToken
	}
	else return null;
}

export function clearUserToken() {
	localStorage.removeItem("UserToken");
}

export function setUserTokenExpiry(UserTokenExpiry) {
	if (UserTokenExpiry != null) {
		localStorage.setItem("UserTokenExpiry", JSON.stringify(UserTokenExpiry))
	}
}

export function getUserTokenExpiry() {
	var UserTokenExpiry = localStorage.getItem("UserTokenExpiry");
	if (UserTokenExpiry != null) {
		UserTokenExpiry = JSON.parse(UserTokenExpiry)
		return UserTokenExpiry
	}
	else return 0;
}

export function clearUserTokenExpiry() {
	localStorage.removeItem("UserTokenExpiry");
}


const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const TOKEN_GRANT_TYPE = process.env.REACT_APP_TOKEN_GRANT_TYPE
const TOKEN_CLIENT_ID = process.env.REACT_APP_TOKEN_CLIENT_ID
const TOKEN_CLIENT_SECRET = process.env.REACT_APP_TOKEN_CLIENT_SECRET
const TOKEN_SCOPE = process.env.REACT_APP_TOKEN_SCOPE
let url = `${SERVER_URL}/token`;
const generateToken = async () => {
	clearToken();
	clearTokenExpiry();
	try {
		const response = await fetch(
			url,
			{
				method: "POST",
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					GrantType: TOKEN_GRANT_TYPE,
					ClientID: TOKEN_CLIENT_ID,
					ClientSecret: TOKEN_CLIENT_SECRET,
					Scope: TOKEN_SCOPE
				})
			}
		);
		if (!response.ok) {
			throw new Error(
				`This is an HTTP error: The status is ${response.status}`
			);
		}
		let actualData = await response.json();
		setToken(actualData.access_token);
		setTokenExpiry(actualData.expires_in);
		//   console.log(actualData)
		//   console.log('successfully generate new token')
		window.location.reload();
	} catch (err) {
		// console.log('failed to generate token')
		// setToken(null)
		// setTokenExpiry(0)
	}
}
export function GenerateNewToken() {
	generateToken();
}

export function tokenIsValid() {
	const token_expiry = getTokenExpiry();
	// console.log(token_expiry)
	if (token_expiry == null || token_expiry == 0) {
		// console.log("Generating new token...")
		generateToken()
	}
	else {
		if (Date.now() >= parseInt(token_expiry) - 60000) {
			// console.log("previous token invalid, generating new token...")
			generateToken()
		}
		else {
			// console.log('previous token still valid')
			return true;
		}
	}
}

export function getPlaceNameBySubPlaceName(sub_place_name) {
	const JOHOR = ['PENGERANG', 'BATU PAHAT', 'YONG PENG', 'LARKIN TERMINAL', 'TUN AMINAH', 'JOHOR JAYA', 'SKUDAI'];
	const KL = ['TBS KUALA LUMPUR', 'PUDU'];
	const MELAKA = ['MELAKA SENTRAL', 'BANDARAYA MELAKA'];
	const SINGAPORE = ['QUEEN STREET BUS TERMINAL'];

	if (sub_place_name !== '') {
		if (JOHOR.includes(sub_place_name.toUpperCase())) {
			return "JOHOR";
		}
		else if (KL.includes(sub_place_name.toUpperCase())) {
			return "KUALA LUMPUR";
		}
		else if (MELAKA.includes(sub_place_name.toUpperCase())) {
			return "MELAKA";
		}
		else if (SINGAPORE.includes(sub_place_name.toUpperCase())) {
			return "SINGAPORE";
		}
		else {
			return "INVALID PLACE"
		}
	}
	return "INVALID PLACE"
}

export function getPlaceIDByPlaceName(place_name) {
	switch (place_name) {
		case 'MELAKA': return 1;
		case 'KUALA LUMPUR': return 2;
		case 'SINGAPORE': return 4;
		case 'JOHOR': return 7;
		case 'SELANGOR': return 8;
		default: return 0;
	}
}

// if user's destination is these places, redirect to select seat page after chosen depart details
export function checkIncludeReturnTripTicketPlaceBySubPlaceId(sub_place_id) {
	const NoReturnTripSubPlaceId = [
		7918 // Desaru Coast
	]

	const result = NoReturnTripSubPlaceId.includes(sub_place_id);
	// console.log(result);
	return result;
}

export function convertEBticketToCWLticket(ebticketHTMLstring) {
	var CWLticketHTMLstring = ebticketHTMLstring;

	//change easybook's logo to causewaylink logo
	CWLticketHTMLstring = CWLticketHTMLstring.replaceAll('width="220" height="54"', 'width="auto" height="54"')
	CWLticketHTMLstring = CWLticketHTMLstring.replaceAll('https://easycdn.blob.core.windows.net/easybook/easybook-logo.png', 'images/logo-1.png')
	// CWLticketHTMLstring = CWLticketHTMLstring.replaceAll('https://easycdn.blob.core.windows.net/easybook/easybook-logo.png','https://easycdn.blob.core.windows.net/agentsite/CausewayLink-logo.png')

	//remove easybook's reminder and its table css
	CWLticketHTMLstring = CWLticketHTMLstring.replaceAll('style=\"font-family:Arial, Helvetica, sans-serif; font-size:11px; color:#333; line-height:18px; background-color:#e9f5fe;margin-bottom:30px;\" align=\"center\" border=\"0\" cellpadding=\"10\" cellspacing=\"0\" width=\"97%\"', '')
	CWLticketHTMLstring = CWLticketHTMLstring.replaceAll('<span><strong>REMINDER:</strong></span> <strong><u>DISCLAIMER</u>: </strong><br />1) Trips schedule is subject to change. Passengers will be notified in advance (if any).<br />2) Tickets are non-refundable, non-transferable, or non-exchangeable. Should the VTL program be canceled for any reason (by authorities), the passenger who has purchased the trips will be entitled to a refund.\r\n', '')

	//remove easybook's unnecessary notice and table
	CWLticketHTMLstring = CWLticketHTMLstring.replaceAll('style=\"font-family:Arial, Helvetica, sans-serif; font-size:14px; color:#555; line-height:22px; text-size-adjust: none; -webkit-text-size-adjust: none; -moz-text-size-adjust: none; -ms-text-size-adjust: none;\" border=\"0\" cellpadding=\"10\" cellspacing=\"0\" width=\"100%\"', '')
	CWLticketHTMLstring = CWLticketHTMLstring.replaceAll('<span style=\"font-weight:bold; color:Red;\">The Terminal Fee may vary subject to the Terminal terms and conditions.</span><br />\r\n\r\n\r\n\r\n\r\n', '')

	//change easybook's hyperlink to causewaylink express
	CWLticketHTMLstring = CWLticketHTMLstring.replaceAll('<a href=\"https://www.easybook.com\" target=\"_blank\">Easybook.com</a>', '<a href=\"https://express2.causewaylink.com.my\" target=\"_blank\">Handal Indah</a>')

	//remove easybook's hyperlink
	CWLticketHTMLstring = CWLticketHTMLstring.replaceAll('<a href=\"https://www.easybook.com/car\" target=\"_blank\">', '')

	//remove easybook's banner in middle
	CWLticketHTMLstring = CWLticketHTMLstring.replaceAll('https://easycdn.blob.core.windows.net/marketing-images/rent-car-os-banner.jpg', '')

	//remove easybook's banner at bottom
	CWLticketHTMLstring = CWLticketHTMLstring.replaceAll('https://www.easybook.com/images/eb-banner-m.jpg', '')

	//change T&C words 'Easybook' to 'Handal Indah'
	CWLticketHTMLstring = CWLticketHTMLstring.replaceAll('Easybook', 'Handal Indah')

	//remove easybook's server id ("17" is the length of the server id)
	const server_position = CWLticketHTMLstring.search('Server :')
	CWLticketHTMLstring = CWLticketHTMLstring.substr(0, server_position - 17)
	return CWLticketHTMLstring
}

//change date and time to proper format of Date()
//input(date): 2022-12-12, input(time): 13:00
export function getProperDateTime(date = "2022-12-12", time = "13:00") {
	var date_arr = date.split('-');
	var time_arr = time.split(':');
	var newDateTime = new Date(date_arr[0], date_arr[1] - 1, date_arr[2], time_arr[0], time_arr[1], "00");
	return newDateTime;
}

//input(date): 2000-10-29, output: 2000-10-30
export function getNextDay(date, day = 1) {
	if (date != null) {
		if (day == 0) {
			return date;
		}
		else {
			var nextDay = null;
			if (date.toString().includes("-")) {
				nextDay = new Date(changeDateFormatToDateTime(date))
			}
			else {
				nextDay = new Date(date);
			}
			nextDay.setDate(nextDay.getDate() + day);
			var month = (nextDay.getMonth() + 1);//months (0-11)
			var day = (nextDay.getDate());//day (1-31)
			var year = nextDay.getFullYear();
			var formattedNextDay = year + "-" + month + "-" + day;
			return getYYYYMMDDtwoDigit(formattedNextDay);
		}
	}

}

export function getAMorPMonTime(time) {

	// console.log(`Time = ${time}`);

	var splitTime = time.split(":");
	if (parseInt(splitTime[0]) < 10) {
		splitTime[0] = "0" + parseInt(splitTime[0]);
		time = splitTime.join(":")
	}
	if (parseInt(splitTime[1]) < 10) {
		splitTime[1] = "0" + parseInt(splitTime[1]);
		time = splitTime.join(":")
	}
	// console.log(splitTime)
	if (splitTime[0] > 0 && splitTime[0] < 12) {
		return time + " AM";
	}
	else if (splitTime[0] == 12 && splitTime[1] >= 0) {
		return time + " PM";
	}
	else if (splitTime[0] == 0 && splitTime[1] >= 0) {
		return time + " AM";
	}
	else {
		splitTime[0] = (splitTime[0] - 12);
		time = splitTime.join(":")
		if (splitTime[0] < 10 && splitTime[0] > 0) {
			return "0" + time + " PM";
		}
		else return time + " PM";
	}
}

export function getBusSeatColumn(totalSeat = 30) {
	return parseInt(totalSeat / 3);
}

export function getSeatLetterByNumber(number) {
	switch (number) {
		case 1: return "A";
		case 2: return "";
		case 3: return "B";
		case 4: return "C";
		default: return "";
	}
}

export function getSubPlaceIDBySubPlaceName(sub_place_name) {
	if (sub_place_name !== '') {
		switch (sub_place_name.toUpperCase()) {
			case 'MELAKA SENTRAL': return 1;
			case 'JB LARKIN TERM':
			case 'LARKIN TERMINAL': return 32;
			case 'TBS (TERMINAL BERSEPADU SELATAN)':
			case 'TBS KUALA LUMPUR': return 246;
			case 'QUEEN STREET BUS TERMINAL': return 536;
			case 'PENGERANG': return 7680;
			default: return 0;
		}
	}
	return 0;
}

// export function getSubPlaceShortName(sub_place_name) {
//     switch (sub_place_name.toUpperCase()) {
//         case 'JB LARKIN TERM': 
//         case 'LARKIN TERMINAL': return 'Larkin Sentral';
//         case 'SKUDAI': return 'Skudai';
//         case 'TBS (TERMINAL BERSEPADU SELATAN)':
//         case 'TBS KUALA LUMPUR': return 'TBS';
//         case 'MELAKA SENTRAL': return 'Melaka Sentral';
//         case 'QUEEN STREET BUS TERMINAL' : return 'Queen St';
//         case 'PENGERANG' : return 'Pengerang';
//         case 'PUDU' : return 'Pudu';
//         case 'TUN AMINAH' : return 'Tun Aminah';
//         case 'YONG PENG' : return 'Yong Peng';
//         case 'BATU PAHAT' : return 'Batu Pahat';
//         default: return 'Invalid Sub Place';
//     }
// }

export function getSubPlaceShortName(sub_place_name) {

	if (sub_place_name !== '' && sub_place_name != null && typeof myVariable !== 'undefined') {
		if (sub_place_name.toUpperCase().includes('TERMINAL BERSEPADU SELATAN')) {
			return 'TBS';
		}
		else if (sub_place_name.toUpperCase().includes('LARKIN')) {
			return 'Larkin Sentral';
		}
		else if (sub_place_name.toUpperCase().includes('QUEEN STREET')) {
			return 'Queen St';
		}
		else if (sub_place_name.toUpperCase().includes('SENAI')) {
			return 'Senai Airport';
		}
	}
	return sub_place_name;
}

export function getSubPlaceUniqueName(sub_place_name, destination = 'no') {

	if (sub_place_name !== '') {
		if (sub_place_name.toUpperCase() == 'KUALA LUMPUR') {
			return "tbsterminalbersepaduselatan";
		}
		else if (sub_place_name.toUpperCase() == 'JOHOR BAHRU' && destination.toUpperCase() != 'SENAI AIRPORT') {
			return "johorbahrujblarkinterm";
		}
		else if (sub_place_name.toUpperCase() == 'JOHOR BAHRU') {
			return "jbsentral";
		}
		else if (sub_place_name.toUpperCase() == 'MERSING') {
			return "terminalbusmersing";
		}
		else if (sub_place_name.toUpperCase() == 'KLANG') {
			return "klangsentral";
		}
		else if (sub_place_name.toUpperCase() == 'SHAH ALAM') {
			return "shahalamterminal";
		}
		else if (sub_place_name.toUpperCase() == 'SENAI AIRPORT') {
			return "senaiinternationalairport";
		}
		else if (sub_place_name.toUpperCase() == 'PENGERANG') {
			return "pengerang";
		}
		else if (sub_place_name.toUpperCase() == 'SINGAPORE') {
			return "queenstreetbusterminal";
		}
		else if (sub_place_name.toUpperCase() == 'MELAKA') {
			return "melakasentral";
		}
		else if (sub_place_name.toUpperCase() == 'IPOH') {
			return "ipoh";
		}
	}
	return sub_place_name;
}

export function getSubPlaceLongName(sub_place_name) {
	if (sub_place_name.toUpperCase() == 'KUALA LUMPUR') {
		return 'TBS (Terminal Bersepadu Selatan)';
	}
	else if (sub_place_name.toUpperCase() == 'JOHOR BAHRU') {
		return 'Johor Bahru (JB Larkin Term.)';
	}
	else if (sub_place_name.toUpperCase() == 'SINGAPORE') {
		return 'Queen Street Bus Terminal';
	}
	else if (sub_place_name.toUpperCase() == 'MELAKA') {
		return 'Melaka Sentral';
	}
	else return sub_place_name;
}

export function checkVenue() {
	if (sessionStorage.getItem("Origin_data") == null || sessionStorage.getItem("Destination_data") == null) {
		return false;
	}
	else return true;
}

export function dateToTime(date) {
	//date format: 2022-08-12
	var myDate = date.split("-");
	var newDate = new Date(myDate[2], myDate[1] - 1, myDate[0]);
	return (newDate.getTime());
}

export function calculateDateRange(date1, date2) {
	var arr_date1 = date1.split("-"); //2022-09-30
	var new_date1 = new Date(arr_date1[1] + '/' + arr_date1[2] + '/' + arr_date1[0]);
	var arr_date2 = date2.split("-");
	var new_date2 = new Date(arr_date2[1] + '/' + arr_date2[2] + '/' + arr_date2[0]);
	var Difference_In_Time = new_date2.getTime() - new_date1.getTime();
	var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
	return Difference_In_Days;
}

export function calculateLoyaltyPointsToEarn(total_amount) {
	var points = total_amount;
	if (points > 0) {
		return parseInt(points);
	}
	else return 0;
}

export function loyaltyPriceToPoints(loyaltyPrice) {
	var points = 0;
	if (loyaltyPrice == 10) {
		points = 200;
	}
	else if (loyaltyPrice == 20) {
		points = 400;
	}
	else if (loyaltyPrice == 30) {
		points = 600;
	}
	else if (loyaltyPrice == 40) {
		points = 800;
	}
	else if (loyaltyPrice == 50) {
		points = 1000;
	}
	return points;
}

//input(date):2022-8-9 => output: 2022-08-09
export function getYYYYMMDDtwoDigit(date) {
	var date_arr = date.split("-");
	var year = date_arr[0];
	var month = date_arr[1];
	var day = date_arr[2];
	if (parseInt(month) < 10) {
		month = "0" + parseInt(month);
	}
	if (parseInt(day) < 10) {
		day = "0" + parseInt(day);
	}
	return year + "-" + month + "-" + day;
}

export function changeDateFormatToYYYYMMDD(date) {
	var day = date.getDate();
	if (day < 10) {
		day = '0' + day;
	}
	var month = date.getMonth() + 1;
	if (month < 10) {
		month = '0' + month;
	}
	var year = date.getFullYear();
	return year + "-" + month + "-" + day;
}

//input(date):2022-10-14 , output: 10/14/2022
export function changeDateFormatToMMDDYYYY(date) {
	var arr_newDate = date.split("-");
	if (parseInt(arr_newDate[1]) < 10) {
		arr_newDate[1] = '0' + parseInt(arr_newDate[1]);
	}
	if (parseInt(arr_newDate[2]) < 10) {
		arr_newDate[2] = '0' + parseInt(arr_newDate[2]);
	}
	return arr_newDate[1] + "/" + arr_newDate[2] + "/" + arr_newDate[0];
}

//input(date):2022-09-14 , output: 2022-09-14T00:00:00
export function changeDateFormatToDateTime(date) {
	var arr_newDate = date.split("-");
	if (parseInt(arr_newDate[1]) < 10) {
		arr_newDate[1] = '0' + parseInt(arr_newDate[1]);
	}
	if (parseInt(arr_newDate[2]) < 10) {
		arr_newDate[2] = '0' + parseInt(arr_newDate[2]);
	}
	var newDate = arr_newDate[0] + "-" + arr_newDate[1] + "-" + arr_newDate[2] + "T00:00:00";
	// console.log(newDate)

	return newDate;
}

//input(date):2022-10-5 , output: 2022-10-05
export function changeDateFormatToYYYYMMDDwithDash(date) {
	var arr_newDate = date.split("-");
	var intMonth = parseInt(arr_newDate[1]);
	var intDay = parseInt(arr_newDate[2]);
	if (intMonth < 10) {
		arr_newDate[1] = '0' + intMonth;
	}
	if (intDay < 10) {
		arr_newDate[2] = '0' + intDay;
	}
	return arr_newDate[0] + "-" + arr_newDate[1] + "-" + arr_newDate[2];
}

//input(date):2022-10-17 , output: Tue,17/10
export function changeDateFormatToWeekDayMonth(date) {
	// var arr_newDate = date.split("-");
	// var newDate = arr_newDate[2]+'/'+arr_newDate[1];
	var date_formatted = new Intl.DateTimeFormat('en-GB',
		{
			month: '2-digit',
			day: '2-digit',
			weekday: 'short'
		}
	).format(Date.parse(date));
	return date_formatted;
}

//input(date):2022-09-14 , output: 14 Sep 2022
export function changeDateFormatToDayMonthYear(date) {
	if (date != null || date != '') {
		var date_formatted = new Intl.DateTimeFormat('en-GB',
			{
				day: '2-digit',
				month: 'short',
				year: 'numeric'
			}
		).format(Date.parse(date));
		return date_formatted;
	}
	else return "-";
}

//input(date):14 Sep 2022 , output: 2022-09-14
export function changeDateFormatToYearMonthDay(date) {
	if (date != null || date != '') {
		//change to 14/09/2022
		var _date = new Intl.DateTimeFormat('en-GB',
			{
				day: '2-digit',
				month: '2-digit',
				year: 'numeric'
			}
		).format(Date.parse(date));
		//change to 2022-09-14
		var dateArr = _date.split("/");
		var date_formatted = dateArr[2] + "-" + dateArr[1] + "-" + dateArr[0];
		return date_formatted;
	}
	else return "-";
}

//input(seconds): 12600, output: 3h 30m
export function getEstimateTimeDurationInHours(durationInSeconds = 12600) {
	var minutes = parseInt(durationInSeconds / 60);
	var hours = null;
	if (minutes >= 60) {
		hours = parseInt(minutes / 60);
		minutes = parseInt(minutes - (hours * 60));
		return hours + 'h ' + minutes + 'm';
	}
	return minutes + 'm';
}

//input(seconds): 12600, output: 3h 30m
export function getEstimateTimeDurationInMinutes(durationInSeconds = 12600) {
	var minutes = parseInt(durationInSeconds / 60);
	return minutes;
}

//input(time): 15:00, input(durationInMinutes): 180, output: 18:00
export function getEstimateTimeArrival(time, durationInMinutes = 180) {
	var hours = parseInt(time.split(":")[0]);
	var minutes = parseInt(time.split(":")[1]);
	minutes += durationInMinutes;
	if (minutes >= 60) {
		var addhours = parseInt(minutes / 60);
		minutes -= addhours * 60;
		if (minutes < 10) {
			minutes = "0" + minutes;
		}
		hours += addhours;
		if (hours >= 24) {
			hours -= 24;
		}
	}
	if (parseInt(hours) < 10) {
		hours = "0" + parseInt(hours);
	}
	if (parseInt(minutes) < 10) {
		minutes = "0" + parseInt(minutes);
	}
	var ETA = hours + ":" + minutes;
	return ETA;
}

//input(datetime): "2022-11-09 08:00", input(durationInMinutes): 180, output: 18:00
export function getEstimateDateTimeArrival(datetime, durationInMinutes) {
	var datetime_arr = datetime.split(" ");
	var etaTimeArrival = getEstimateTimeArrival(datetime_arr[1], durationInMinutes);
	if (parseInt(etaTimeArrival.split(":")[0]) >= 0 && parseInt(etaTimeArrival.split(":")[0]) < parseInt(datetime_arr[1].split(":")[0])) {
		datetime_arr[0] = getNextDay(datetime_arr[0]);
	}
	return datetime_arr[0] + " " + etaTimeArrival
}

//get return data from JSON.parse(sessionStorage.getItem("returnData"))
export function checkOneWayTrip() {
	const returnData = JSON.parse(sessionStorage.getItem("returnData"));
	if (returnData === null) {
		return true;
	}
	else return false;
}

export function setLoginStatus(status) {
	localStorage.setItem("login", JSON.stringify(status));
}

export function getLoginStatus() {
	var login_status = localStorage.getItem("login") != null ? JSON.parse(localStorage.getItem("login")) : false;
	return login_status;
}

export function getUserData() {
	if (getLoginStatus() == true) {
		var userData = JSON.parse(localStorage.getItem("userData"));
		return userData;
	}
	else {
		return null;
	}
}

export function getUsername() {
	var userData = getUserData();
	if (userData != null) {
		var userName = userData.fullname;
		return userName;
	}
	else return null;
}

export function setUserData(user_data) {
	localStorage.setItem("userData", JSON.stringify(user_data));
	return true;
}

//return ic without '-', or passport no
export function getUserICorPassport() {
	var checkUserData = getUserData();
	if (checkUserData != null) {
		var userData = JSON.parse(checkUserData);
		var userICorPassport = null;
		if (userData.ic != null || userData.ic != '') {
			if (userData.ic.includes('-')) {
				userICorPassport = userData.ic.replace(/-/gi, '')
			}
			else userICorPassport = userData.ic;
		}
		else if (userData.passport != null || userData.passport != '') {
			userICorPassport = userData.passport;
		}
		return userICorPassport;
	}
	else return null;
}

export function getUserPrimaryEmail() {
	if (getLoginStatus() === true) {
		var email = localStorage.getItem("primaryEmail");
		if (email != null) {
			email = JSON.parse(email);
			return email;
		}
		else return null;
	}
	else return null
}

// export function getUserPrimaryPassword() {
//     var primaryPassword = JSON.parse(localStorage.getItem("primaryPassword"));
//     return primaryPassword;
// }

export function checkWindowLocation() {
	var mustLogInPages = ['/myAccount', '/manageBooking', '/bookingHistory', '/bookingDetail']
	if (mustLogInPages.includes(window.location.pathname)) {
		return true;
	}
	else return false;
}

export function checkWindowLocationForCurrency() {
	var backToPickDepartPage = ['/pickReturn']
	var callApiToGetPricePage = ['/selectSeat', '/checkout']
	if (backToPickDepartPage.includes(window.location.pathname)) {
		return 'BackToPickDepart';
	}
	else if (callApiToGetPricePage.includes(window.location.pathname)) {
		return 'CallApiToGetPrice';
	}
	else {
		window.location.reload();
	}
}

export function getMonetaryUnitBasedOnCurrency() {
	const currency = JSON.parse(localStorage.getItem("currency"));
	const monetary_unit = currency === 'MYR' ? "RM" : "SGD";
	return monetary_unit
}

//if sgd trip, get MYR fare for checkout
export function checkIsSgdTrip() {
	const currency = JSON.parse(localStorage.getItem("currency"));
	if (currency === 'SGD') {
		return true;
	}
	else {
		return false;
	}
}

export function passengerValidation(passenger = 1) {
	var num_passenger = parseInt(passenger);
	if (num_passenger < 1 || num_passenger > 8) {
		return false;
	}
	else return true;
}

export function getNationalityFullName(nationality) {
	switch (nationality) {
		case "": return "";
		case "MY": return "Malaysia";
		case "SG": return "Singapore";
		case "AF": return "Afghanistan";
		case "AL": return "Albania";
		case "DZ": return "Algeria";
		case "AD": return "Andorra";
		case "AO": return "Angola";
		case "AI": return "Anguilla";
		case "AG": return "Antigua and Barbuda";
		case "AR": return "Argentina";
		case "AM": return "Armenia";
		case "AW": return "Aruba";
		case "AU": return "Australia";
		case "AT": return "Austria";
		case "AZ": return "Azerbaijan";
		case "BS": return "Bahamas";
		case "BH": return "Bahrain";
		case "BD": return "Bangladesh";
		case "BB": return "Barbados";
		case "BY": return "Belarus";
		case "BE": return "Belgium";
		case "BZ": return "Belize";
		case "BJ": return "Benin";
		case "BM": return "Bermuda";
		case "BT": return "Bhutan";
		case "BO": return "Bolivia";
		case "BA": return "Bosnia and Herzegovina";
		case "BW": return "Botswana";
		case "BR": return "Brazil";
		case "VG": return "British Virgin Islands";
		case "BN": return "Brunei";
		case "BG": return "Bulgaria";
		case "BF": return "Burkina Faso";
		case "BI": return "Burundi";
		case "KH": return "Cambodia";
		case "CM": return "Cameroon";
		case "CA": return "Canada";
		case "CV": return "Cape Verde";
		case "KY": return "Cayman Islands";
		case "CF": return "Central African Republic";
		case "TD": return "Chad";
		case "CL": return "Chile";
		case "CN": return "China";
		case "CO": return "Colombia";
		case "KM": return "Comoros";
		case "CD": return "Congo, Democratic Republic of the";
		case "CG": return "Congo, Republic of";
		case "CR": return "Costa Rica";
		case "CI": return "Cote D'Ivoire (Ivory Coast)";
		case "HR": return "Croatia";
		case "CU": return "Cuba";
		case "CY": return "Cyprus";
		case "CZ": return "Czech Republic";
		case "DK": return "Denmark";
		case "DJ": return "Djibouti";
		case "DM": return "Dominica";
		case "DO": return "Dominican Republic";
		case "EC": return "Ecuador";
		case "EG": return "Egypt";
		case "SV": return "El Salvador";
		case "GQ": return "Equatorial Guinea";
		case "ER": return "Eritrea";
		case "EE": return "Estonia";
		case "ET": return "Ethiopia";
		case "FK": return "Falkland Islands";
		case "FJ": return "Fiji";
		case "FI": return "Finland";
		case "FR": return "France";
		case "GF": return "French Guiana";
		case "PF": return "French Polynesia";
		case "GA": return "Gabon";
		case "GM": return "Gambia";
		case "GE": return "Georgia";
		case "DE": return "Germany";
		case "GH": return "Ghana";
		case "GI": return "Gibraltar";
		case "GR": return "Greece";
		case "GD": return "Grenada";
		case "GP": return "Guadeloupe";
		case "GT": return "Guatemala";
		case "GN": return "Guinea";
		case "GW": return "Guinea-Bissau";
		case "GY": return "Guyana";
		case "HT": return "Haiti";
		case "HN": return "Honduras";
		case "HK": return "Hong Kong (China)";
		case "HU": return "Hungary";
		case "IS": return "Iceland";
		case "IN": return "India";
		case "ID": return "Indonesia";
		case "IR": return "Iran";
		case "IQ": return "Iraq";
		case "IE": return "Ireland";
		case "IL": return "Israel";
		case "IT": return "Italy";
		case "JM": return "Jamaica";
		case "JP": return "Japan";
		case "JO": return "Jordan";
		case "KZ": return "Kazakhstan";
		case "KE": return "Kenya";
		case "KP": return "Korea (North)";
		case "KR": return "Korea (South)";
		case "KW": return "Kuwait";
		case "KG": return "Kyrgyzstan";
		case "LA": return "Laos";
		case "LV": return "Latvia";
		case "LB": return "Lebanon";
		case "LS": return "Lesotho";
		case "LR": return "Liberia";
		case "LY": return "Libyan Arab Jamahiriya";
		case "LI": return "Liechtenstein";
		case "LT": return "Lithuania";
		case "LU": return "Luxembourg";
		case "MO": return "Macao";
		case "MK": return "Macedonia";
		case "MG": return "Madagascar";
		case "MW": return "Malawi";
		case "MV": return "Maldives";
		case "ML": return "Mali";
		case "MT": return "Malta";
		case "MH": return "Marshall Islands";
		case "MQ": return "Martinique";
		case "MR": return "Mauritania";
		case "MU": return "Mauritius";
		case "YT": return "Mayotte";
		case "MX": return "Mexico";
		case "FM": return "Micronesia, Federated States of";
		case "MD": return "Moldova";
		case "MC": return "Monaco";
		case "MN": return "Mongolia";
		case "MS": return "Montserrat";
		case "MA": return "Morocco";
		case "MZ": return "Mozambique";
		case "MM": return "Myanmar";
		case "NA": return "Namibia";
		case "NR": return "Nauru";
		case "NP": return "Nepal";
		case "NL": return "Netherlands";
		case "AN": return "Netherlands Antilles";
		case "NC": return "New Caledonia";
		case "NZ": return "New Zealand";
		case "NI": return "Nicaragua";
		case "NE": return "Niger";
		case "NG": return "Nigeria";
		case "NO": return "Norway";
		case "OM": return "Oman";
		case "00": return "Others";
		case "PK": return "Pakistan";
		case "PW": return "Palau";
		case "PS": return "Palestinian Territory";
		case "PA": return "Panama";
		case "PG": return "Papua New Guinea";
		case "PY": return "Paraguay";
		case "PE": return "Peru";
		case "PH": return "Philippines";
		case "PN": return "Pitcairn Islands";
		case "PL": return "Poland";
		case "PT": return "Portugal";
		case "QA": return "Qatar";
		case "RE": return "Reunion";
		case "RO": return "Romania";
		case "RU": return "Russia Federation";
		case "RW": return "Rwanda";
		case "SH": return "Saint Helena";
		case "KN": return "Saint Kitts and Nevis";
		case "LC": return "Saint Lucia";
		case "PM": return "Saint Pierre and Miquelon";
		case "VC": return "Saint Vincent and the Grenadines";
		case "WS": return "Samoa";
		case "SM": return "San Marino";
		case "ST": return "Sao Tome and Principe";
		case "SA": return "Saudi Arabia";
		case "SN": return "Senegal";
		case "CS": return "Serbia and Montenegro";
		case "SC": return "Seychelles";
		case "SL": return "Sierra Leone";
		case "SK": return "Slovakia";
		case "SI": return "Slovenia";
		case "SB": return "Solomon Islands";
		case "SO": return "Somalia";
		case "ZA": return "South Africa";
		case "ES": return "Spain";
		case "LK": return "Sri Lanka";
		case "SH": return "St. Helena";
		case "KN": return "St. Kitts and Nevis";
		case "LC": return "St. Lucia";
		case "PM": return "St. Pierre and Miquelon";
		case "SD": return "Sudan";
		case "SR": return "Suriname";
		case "SZ": return "Swaziland";
		case "SE": return "Sweden";
		case "CH": return "Switzerland";
		case "SY": return "Syria";
		case "TW": return "Taiwan";
		case "TJ": return "Tajikistan";
		case "TZ": return "Tanzania";
		case "TH": return "Thailand";
		case "TL": return "Timor-Leste";
		case "TG": return "Togo";
		case "TO": return "Tonga";
		case "TT": return "Trinidad and Tobago";
		case "TN": return "Tunisia";
		case "TR": return "Turkey";
		case "TM": return "Turkmenistan";
		case "TC": return "Turks and Caicos Islands";
		case "TV": return "Tuvalu";
		case "UG": return "Uganda";
		case "UA": return "Ukraine";
		case "AE": return "United Arab Emirates";
		case "GB": return "United Kingdom";
		case "US": return "United States";
		case "UY": return "Uruguay";
		case "UZ": return "Uzbekistan";
		case "VU": return "Vanuatu";
		case "VE": return "Venezuela";
		case "VN": return "Vietnam";
		case "WF": return "Wallis and Futuna Islands";
		case "EH": return "Western Sahara";
		case "YE": return "Yemen";
		case "ZR": return "Zaire (former)";
		case "ZM": return "Zambia";
		case "ZW": return "Zimbabwe";
		case "00": return "Others";
		default: return nationality;
	}
}

export function getNationalityShortName(nationality) {
	switch (nationality) {
		case "": return "";
		case "Malaysia": return "MY"
		case "Singapore": return "SG"
		case "Afghanistan": return "AF"
		case "Albania": return "AL"
		case "Algeria": return "DZ"
		case "Andorra": return "AD"
		case "Angola": return "AO"
		case "Anguilla": return "AI"
		case "Antigua and Barbuda": return "AG"
		case "Argentina": return "AR"
		case "Armenia": return "AM"
		case "Aruba": return "AW"
		case "Australia": return "AU"
		case "Austria": return "AT"
		case "Azerbaijan": return "AZ"
		case "Bahamas": return "BS"
		case "Bahrain": return "BH"
		case "Bangladesh": return "BD"
		case "Barbados": return "BB"
		case "Belarus": return "BY"
		case "Belgium": return "BE"
		case "Belize": return "BZ"
		case "Benin": return "BJ"
		case "Bermuda": return "BM"
		case "Bhutan": return "BT"
		case "Bolivia": return "BO"
		case "Bosnia and Herzegovina": return "BA"
		case "Botswana": return "BW"
		case "Brazil": return "BR"
		case "British Virgin Islands": return "VG"
		case "Brunei": return "BN"
		case "Bulgaria": return "BG"
		case "Burkina Faso": return "BF"
		case "Burundi": return "BI"
		case "Cambodia": return "KH"
		case "Cameroon": return "CM"
		case "Canada": return "CA"
		case "Cape Verde": return "CV"
		case "Cayman Islands": return "KY"
		case "Central African Republic": return "CF"
		case "Chad": return "TD"
		case "Chile": return "CL"
		case "China": return "CN"
		case "Colombia": return "CO"
		case "Comoros": return "KM"
		case "Congo, Democratic Republic of the": return "CD"
		case "Congo, Republic of": return "CG"
		case "Costa Rica": return "CR"
		case "Cote D'Ivoire (Ivory Coast)": return "CI"
		case "Croatia": return "HR"
		case "Cuba": return "CU"
		case "Cyprus": return "CY"
		case "Czech Republic": return "CZ"
		case "Denmark": return "DK"
		case "Djibouti": return "DJ"
		case "Dominica": return "DM"
		case "Dominican Republic": return "DO"
		case "Ecuador": return "EC"
		case "Egypt": return "EG"
		case "El Salvador": return "SV"
		case "Equatorial Guinea": return "GQ"
		case "Eritrea": return "ER"
		case "Estonia": return "EE"
		case "Ethiopia": return "ET"
		case "Falkland Islands": return "FK"
		case "Fiji": return "FJ"
		case "Finland": return "FI"
		case "France": return "FR"
		case "French Guiana": return "GF"
		case "French Polynesia": return "PF"
		case "Gabon": return "GA"
		case "Gambia": return "GM"
		case "Georgia": return "GE"
		case "Germany": return "DE"
		case "Ghana": return "GH"
		case "Gibraltar": return "GI"
		case "Greece": return "GR"
		case "Grenada": return "GD"
		case "Guadeloupe": return "GP"
		case "Guatemala": return "GT"
		case "Guinea": return "GN"
		case "Guinea-Bissau": return "GW"
		case "Guyana": return "GY"
		case "Haiti": return "HT"
		case "Honduras": return "HN"
		case "Hong Kong (China)": return "HK"
		case "Hungary": return "HU"
		case "Iceland": return "IS"
		case "India": return "IN"
		case "Indonesia": return "ID"
		case "Iran": return "IR"
		case "Iraq": return "IQ"
		case "Ireland": return "IE"
		case "Israel": return "IL"
		case "Italy": return "IT"
		case "Jamaica": return "JM"
		case "Japan": return "JP"
		case "Jordan": return "JO"
		case "Kazakhstan": return "KZ"
		case "Kenya": return "KE"
		case "Korea (North)": return "KP"
		case "Korea (South)": return "KR"
		case "Kuwait": return "KW"
		case "Kyrgyzstan": return "KG"
		case "Laos": return "LA"
		case "Latvia": return "LV"
		case "Lebanon": return "LB"
		case "Lesotho": return "LS"
		case "Liberia": return "LR"
		case "Libyan Arab Jamahiriya": return "LY"
		case "Liechtenstein": return "LI"
		case "Lithuania": return "LT"
		case "Luxembourg": return "LU"
		case "Macao": return "MO"
		case "Macedonia": return "MK"
		case "Madagascar": return "MG"
		case "Malawi": return "MW"
		case "Maldives": return "MV"
		case "Mali": return "ML"
		case "Malta": return "MT"
		case "Marshall Islands": return "MH"
		case "Martinique": return "MQ"
		case "Mauritania": return "MR"
		case "Mauritius": return "MU"
		case "Mayotte": return "YT"
		case "Mexico": return "MX"
		case "Micronesia, Federated States of": return "FM"
		case "Moldova": return "MD"
		case "Monaco": return "MC"
		case "Mongolia": return "MN"
		case "Montserrat": return "MS"
		case "Morocco": return "MA"
		case "Mozambique": return "MZ"
		case "Myanmar": return "MM"
		case "Namibia": return "NA"
		case "Nauru": return "NR"
		case "Nepal": return "NP"
		case "Netherlands": return "NL"
		case "Netherlands Antilles": return "AN"
		case "New Caledonia": return "NC"
		case "New Zealand": return "NZ"
		case "Nicaragua": return "NI"
		case "Niger": return "NE"
		case "Nigeria": return "NG"
		case "Norway": return "NO"
		case "Oman": return "OM"
		case "Others": return "00"
		case "Pakistan": return "PK"
		case "Palau": return "PW"
		case "Palestinian Territory": return "PS"
		case "Panama": return "PA"
		case "Papua New Guinea": return "PG"
		case "Paraguay": return "PY"
		case "Peru": return "PE"
		case "Philippines": return "PH"
		case "Pitcairn Islands": return "PN"
		case "Poland": return "PL"
		case "Portugal": return "PT"
		case "Qatar": return "QA"
		case "Reunion": return "RE"
		case "Romania": return "RO"
		case "Russia Federation": return "RU"
		case "Rwanda": return "RW"
		case "Saint Helena": return "SH"
		case "Saint Kitts and Nevis": return "KN"
		case "Saint Lucia": return "LC"
		case "Saint Pierre and Miquelon": return "PM"
		case "Saint Vincent and the Grenadines": return "VC"
		case "Samoa": return "WS"
		case "San Marino": return "SM"
		case "Sao Tome and Principe": return "ST"
		case "Saudi Arabia": return "SA"
		case "Senegal": return "SN"
		case "Serbia and Montenegro": return "CS"
		case "Seychelles": return "SC"
		case "Sierra Leone": return "SL"
		case "Slovakia": return "SK"
		case "Slovenia": return "SI"
		case "Solomon Islands": return "SB"
		case "Somalia": return "SO"
		case "South Africa": return "ZA"
		case "Spain": return "ES"
		case "Sri Lanka": return "LK"
		case "St. Helena": return "SH"
		case "St. Kitts and Nevis": return "KN"
		case "St. Lucia": return "LC"
		case "St. Pierre and Miquelon": return "PM"
		case "Sudan": return "SD"
		case "Suriname": return "SR"
		case "Swaziland": return "SZ"
		case "Sweden": return "SE"
		case "Switzerland": return "CH"
		case "Syria": return "SY"
		case "Taiwan": return "TW"
		case "Tajikistan": return "TJ"
		case "Tanzania": return "TZ"
		case "Thailand": return "TH"
		case "Timor-Leste": return "TL"
		case "Togo": return "TG"
		case "Tonga": return "TO"
		case "Trinidad and Tobago": return "TT"
		case "Tunisia": return "TN"
		case "Turkey": return "TR"
		case "Turkmenistan": return "TM"
		case "Turks and Caicos Islands": return "TC"
		case "Tuvalu": return "TV"
		case "Uganda": return "UG"
		case "Ukraine": return "UA"
		case "United Arab Emirates": return "AE"
		case "United Kingdom": return "GB"
		case "United States": return "US"
		case "Uruguay": return "UY"
		case "Uzbekistan": return "UZ"
		case "Vanuatu": return "VU"
		case "Venezuela": return "VE"
		case "Vietnam": return "VN"
		case "Wallis and Futuna Islands": return "WF"
		case "Western Sahara": return "EH"
		case "Yemen": return "YE"
		case "Zaire (former)": return "ZR"
		case "Zambia": return "ZM"
		case "Zimbabwe": return "ZW"
		case "Others": return "00"
		default: return nationality;
	}
}

export function getGenderShortForm(gender) {
	switch (gender) {
		case 'Male': return 'M';
		case 'Female': return 'F';
		default: return 'Other'
	}
}

//input '8A', output 'A8'
export function getSeatCode(code = '8A', X, Y, arr_length) {
	var checkHaveLetter = /\p{L}/u.test(code);
	if (code.includes('.')) {
		return code;
	}
	if (checkHaveLetter) {
		var arr = code.split("");
		if (/\p{L}/u.test(arr[0]) == true) {
			return code;
		}
		var newSeatCode = arr[1] + arr[0];
		if (arr.length > 2) {
			newSeatCode = arr[2] + arr[0] + arr[1];
		}
		return newSeatCode;
	}
	else {
		if (arr_length > 4) {
			return code;
		}
		else {
			var Letter = getSeatLetterByNumber(X);
			var newSeatCode = Letter + Y;
			return newSeatCode;
		}
	}
}

export function setSelectedAddOnData(addOn_Data) {
	var previous_selected = getSelectedAddOnData();
	// console.log(addOn_Data);
	if (addOn_Data != null) {
		if (previous_selected != null) {
			var allSelected = previous_selected.concat(addOn_Data);
			localStorage.setItem("selected_addon_data", JSON.stringify(allSelected));
		}
		else {
			localStorage.setItem("selected_addon_data", JSON.stringify(addOn_Data));
		}
	}
}

export function getSelectedAddOnData() {
	if (localStorage.getItem("selected_addon_data") != null && localStorage.getItem("selected_addon_data") != 'undefined') {
		var addOnData = JSON.parse(localStorage.getItem("selected_addon_data"));
		return addOnData;
	}
	else return null;
}

export function deleteSelectedAddOnData(index) {
	if (localStorage.getItem("selected_addon_data") != null && localStorage.getItem("selected_addon_data") != 'undefined') {
		var addOnData = JSON.parse(localStorage.getItem("selected_addon_data"));
		// console.log(index);
		// console.log(addOnData);
		if (index > -1) { //Make sure item is present in the array, without if condition, -n indexes will be considered from the end of the array.
			addOnData.splice(index, 1);
			localStorage.setItem("selected_addon_data", JSON.stringify(addOnData));
		}
		// console.log(addOnData);
	}
	// console.log(getSelectedAddOnData());
}

export function getSelectedAddOnSession() {
	var addon_data = getSelectedAddOnData();
	let addon_session = [];
	if (addon_data != null || addon_data != []) {
		addon_data.forEach(element => {
			addon_session.push(element.sessionid);
		});
	}
	return addon_session;
}

export function calculateAddOnSubPrice(price = 0, qty = 1) {
	var subprice = price * qty;
	// setTotalAddOnPrice(subprice);
	return subprice;
}

export function setTotalAddOnPrice(price) {
	// console.log(price);
	var previous_total = getTotalAddOnPrice();
	// console.log(previous_total);
	var new_total = previous_total + price;
	// console.log(new_total);
	localStorage.setItem("totalAddOn", JSON.stringify(new_total));
}

export function getTotalAddOnPrice() {
	var total = localStorage.getItem("totalAddOn");
	// console.log(total);
	if (total != 0 && total != null) {
		total = JSON.parse(localStorage.getItem("totalAddOn"));
		// console.log(total);
		return total;
	}
	else return 0;
}

export function clearAllLocalStorageExceptLogin() {
	localStorage.removeItem("addOnSession");
	localStorage.removeItem("selected_addon_data");
}

export function getAddOnTicketType(package_display_name) {
	if (package_display_name.includes("Adult")) {
		return "- Adult";
	}
	else if (package_display_name.includes("Child")) {
		return "- Child (4-12)";
	}
	else if (package_display_name.includes("Senior")) {
		return "- Senior (60+)";
	}
	else return package_display_name;
}

export function checkIsReschedule() {
	let origin_data = sessionStorage.getItem("Origin_data");
	if (origin_data != null) {
		origin_data = JSON.parse(origin_data);
		if (origin_data.reschedule === true) {
			return true;
		}
		else return false;
	}
	return false;
}