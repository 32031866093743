import React, { useContext, useState } from 'react';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { BusFareAddOnContext, BusFareDepartContext, BusFareReturnContext, LoyaltyDiscountContext, TotalBusFareContext } from '../UserContext';
import { setUserToken, setUserTokenExpiry, calculateLoyaltyPointsToEarn, getToken, getUserData, setLoginStatus, tokenIsValid } from './Function';
import { LoadingScreenModal } from './LoadingScreenModal';
import './LoginSignUpModal.css';
import { useSearchParams } from 'react-router-dom';

export const LoginSignUpModal = ({

}) => {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const [showPassportFields, setShowPassportFields] = React.useState(false)
  const [searchParams, setSearchParams] = useSearchParams();
  const ucf = searchParams.get("ucf");
  const [form, setForm] = useState({})
  const [sign_up_form, setSignUpForm] = useState({})
  const [errors, setErrors] = useState({})
  const [signup_errors, setSignUpErrors] = useState({})
  const [show, setShow] = useState(true);
  const [step, setStep] = useState('1');
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState(null);
  const [resetEmail, setResetEmail] = useState(null);
  const [signUpstep, setSignUpStep] = useState('1');
  const [userData, setUserData] = useState(getUserData());
  const [data, setData] = useState(null);
  const [login, setLogin] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const token = getToken();
  let { TotalBusFare, setTotalBusFare } = useContext(TotalBusFareContext);
  let { BusFareAddOn, setBusFareAddOn } = useContext(BusFareAddOnContext);
  let total_addOn = 0;
  BusFareAddOn.forEach(element => {
    total_addOn += element.sub_totalPrice;
  });
  const { BusFareDepart, setBusFareDepart } = useContext(BusFareDepartContext);
  const { BusFareReturn, setBusFareReturn } = useContext(BusFareReturnContext);
  const { TotalLoyalty, setLoyalty } = useContext(LoyaltyDiscountContext);
  TotalBusFare = TotalBusFare + BusFareDepart + BusFareReturn + total_addOn - TotalLoyalty;
  const earn_points = calculateLoyaltyPointsToEarn(TotalBusFare);

  const passwordReset_API_URL = `${SERVER_URL}/user/resetpassword`;
  const passwordReset_requestOptions = {
      method: 'POST',
      headers: { 
          'Content-Type': 'application/json',
          'Authorization': token
      },
      body: JSON.stringify({
          "Email":forgotPasswordEmail
      })
  };

  const resetPasswordAPI = async () => {
    try {
        const resetPassword_response = await fetch(
            passwordReset_API_URL,passwordReset_requestOptions
        );
        if (!resetPassword_response.ok) {
          throw new Error(
            `This is an HTTP error: The status is ${resetPassword_response.status}`
          );
        }
        setIsForgotPassword(false)
        setStep('2');
    } catch(err) {
      alert("Error, Please Try Again Later")
    }
  }

  function forgotPasswordVerification(forgot_password_email) {
    if((/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(forgot_password_email)) == false) {
      alert("Please enter your email in format: yourname@example.com");
      return false;
    }
    return true;
  }

  function resetPassword() {
    const verification = forgotPasswordVerification(forgotPasswordEmail);
    if(verification) {
      tokenIsValid();
      setResetEmail(forgotPasswordEmail);
      resetPasswordAPI();
    }
  }
  // useEffect(() => {
  //   const userLoyaltyData = [
  //     {
  //       "Date": "23 Dec 2019",
  //       "Booking_ID": "SJ550D",
  //       "Transaction": -22
  //     },
  //     {
  //       "Date": "21 Dec 2018",
  //       "Booking_ID": "SJ331D",
  //       "Transaction": -52
  //     },
  //     {
  //       "Date": "23 Dec 2017",
  //       "Booking_ID": "SJ101D",
  //       "Transaction": 200
  //     },
  //     {
  //       "Date": "22 Dec 2017",
  //       "Booking_ID": "SJ102D",
  //       "Transaction": -30
  //     },
  //     {
  //       "Date": "21 Dec 2017",
  //       "Booking_ID": "SJ103D",
  //       "Transaction": -100
  //     },
  //     {
  //       "Date": "20 Dec 2017",
  //       "Booking_ID": "SJ104D",
  //       "Transaction": 500
  //     },
  //     {
  //       "Date": "20 Dec 2016",
  //       "Booking_ID": "SJ105D",
  //       "Transaction": -200
  //     },
  //     {
  //       "Date": "20 Dec 2015",
  //       "Booking_ID": "SJ106D",
  //       "Transaction": 20
  //     },
  //     {
  //       "Date": "20 Dec 2014",
  //       "Booking_ID": "SJ107D",
  //       "Transaction": 300
  //     },
  //   ]
  //   const friendsAndFamilyData = [];
  //     //fetch api, get user data, verify user
  //     const user_data_api_url = `${SERVER_URL}/user/profile/${getUserPrimaryEmail()}`;

  //     const getUserData = async () => {
  //       try {
  //           const user_data_response = await fetch(
  //               user_data_api_url
  //           );
  //           if (!user_data_response.ok) {
  //             throw new Error(
  //               `This is an HTTP error: The status is ${user_data_response.status}`
  //             );
  //           }
  //           let myUserData = await user_data_response.json();
  //           setUserData(myUserData);
  //           setError(null);
  //           localStorage.setItem("userData", JSON.stringify(myUserData));
  //           localStorage.setItem("userLoyaltyData", JSON.stringify(userLoyaltyData));
  //           localStorage.setItem("friendsAndFamilyData", JSON.stringify(friendsAndFamilyData));
  //           // console.log(myUserData);
  //           // console.log(user_data_response);
  //           alert("hihi");
  //       } catch(err) {
  //           // console.log(err.message);
  //           setLogin(false);
  //           setError(err.message);
  //       } finally {
  //           setLoading(false);
  //       }  
  //       }
  //       getUserData();
  // }, [login])

  function checkLoginStatus() {
    return login;
  }

  function verifyUser(email, password) {
    // setLoading(true);
    const friendsAndFamilyData = [];
    //fetch api, get user data, verify user
    const user_data_api_url = `${SERVER_URL}/user/profile/${email}`;

    const getUserData = async () => {
      try {
        const user_data_response = await fetch(
          user_data_api_url,
          {
            headers: {
                'Authorization': getToken()
            }
          }
        );
        if (!user_data_response.ok) {
          throw new Error(
            `This is an HTTP error: The status is ${user_data_response.status}`
          );
        }
        let myUserData = await user_data_response.json();
        setUserData(myUserData);
        setError(null);
        localStorage.setItem("userData", JSON.stringify(myUserData));
        localStorage.setItem("primaryEmail", JSON.stringify(email));
        // localStorage.setItem("primaryPassword", JSON.stringify(password));
        // localStorage.setItem("userLoyaltyData", JSON.stringify(userLoyaltyData));
        localStorage.setItem("friendsAndFamilyData", JSON.stringify(friendsAndFamilyData));
        // console.log(myUserData);
        // console.log(user_data_response);
      } catch (err) {
        alert(err.message);
        setLogin(false);
        setError(err.message);
      }
    }
    getUserData();
    // console.log(userData)    
    // const userData = {
    //   "Username":"Testing User",
    //   "Gender":"Female",
    //   "Nationality":"MY",
    //   "IC":"111111-01-1111",
    //   "Mobile":"016-2849194",
    //   "Email": "wlteo3@handalindah.com.my",
    //   "Dob":"28 Nov 1987",
    //   "Password":"123",
    // }
    // // console.log(getLoginStatus());
    // if(getLoginStatus()==true) {
    //   return true;
    // }
    // else return false;
  
    //fetch api, verify user
    const login_api_url = `${SERVER_URL}/user/login`;

    const login_requestOptions = {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': getToken()
      },
      body: JSON.stringify({
        "Email": email,
        "Password": password
      })
    };

    // console.log(login_requestOptions)
    const userLoginStatus = async () => {
      try {
        const login_response = await fetch(
          login_api_url, login_requestOptions
        );


        // console.dir(login_response);
        if (!login_response.ok) {
          throw new Error(
            `This is an HTTP error: The status is ${login_response.status}`
          );
        }
        else {
          let loginResponse = await login_response.json();
          if(loginResponse.isfirsttimelogin == false) {
            setLogin(true);
            setLoginStatus(true);
            setError(null);
            setErrors(null);
            // console.log(loginResponse);
            alert("login successful")
            localStorage.setItem("login", JSON.stringify(true));
            setUserToken(loginResponse.token.access_token)
            setUserTokenExpiry(loginResponse.token.expires_in)
            localStorage.setItem("primaryEmail", JSON.stringify(form.signin_email));
            // console.log(ucf)
            if(ucf == 'https://www.causewaylink.com.my/' && loginResponse.token.access_token) {
              window.location.href = "https://www.causewaylink.com.my/"+ `?key=${loginResponse.token.access_token}&expiry=${loginResponse.token.expires_in}`;
            }
            else {
              // localStorage.setItem("primaryPassword", JSON.stringify(form.signin_password));
              setShow(false);
              window.location.reload();
            }
          }
          else {
            setResetEmail(form.signin_email);
            setStep('2');
          }
        }
      } catch (err) {
		console.log(err);
        setErrors({ "signin_email": "Invalid Username or Password" })
        setLogin(false);
      } finally {
        setLoading(false);
      }
    }
    userLoginStatus();

    // console.log(login);
    // console.log(checkLoginStatus());
    // const userLoyaltyData = [
    //   {
    //     "Date": "23 Dec 2019",
    //     "Booking_ID": "SJ550D",
    //     "Transaction": -22
    //   },
    //   {
    //     "Date": "21 Dec 2018",
    //     "Booking_ID": "SJ331D",
    //     "Transaction": -52
    //   },
    //   {
    //     "Date": "23 Dec 2017",
    //     "Booking_ID": "SJ101D",
    //     "Transaction": 200
    //   },
    //   {
    //     "Date": "22 Dec 2017",
    //     "Booking_ID": "SJ102D",
    //     "Transaction": -30
    //   },
    //   {
    //     "Date": "21 Dec 2017",
    //     "Booking_ID": "SJ103D",
    //     "Transaction": -100
    //   },
    //   {
    //     "Date": "20 Dec 2017",
    //     "Booking_ID": "SJ104D",
    //     "Transaction": 500
    //   },
    //   {
    //     "Date": "20 Dec 2016",
    //     "Booking_ID": "SJ105D",
    //     "Transaction": -200
    //   },
    //   {
    //     "Date": "20 Dec 2015",
    //     "Booking_ID": "SJ106D",
    //     "Transaction": 20
    //   },
    //   {
    //     "Date": "20 Dec 2014",
    //     "Booking_ID": "SJ107D",
    //     "Transaction": 300
    //   },
    // ]
  }

  function verifyUserIC(ic) {
    if (ic === userData.ic) {
      return true;
    }
    else return false;
  }

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value
    })
    // Check and see if errors exist, and remove them from the error object:
    if (!!errors[field]) setErrors({
      ...errors,
      [field]: null
    })
  }
  // console.log(form);

  const findFormErrors = () => {
    const { signin_email, signin_password } = form
    const newErrors = {}
    if (!signin_email || signin_email === '') newErrors.signin_email = 'email cannot be blank!'
    else if (!signin_password || signin_password === '') newErrors.signin_password = 'password cannot be blank!'

    return newErrors
  }

  const handleSubmit = e => {
    localStorage.removeItem("login");
    localStorage.removeItem("userData");
    localStorage.removeItem("primaryEmail");
    // localStorage.removeItem("primaryPassword");
    localStorage.removeItem("userLoyaltyData");
    localStorage.removeItem("friendsAndFamilyData");
    // get our new errors
    const newErrors = findFormErrors()
    // Conditional logic:
    if (Object.keys(newErrors).length > 0) {
      e.preventDefault()
      // We got errors!
      setErrors(newErrors)
    } else {
      tokenIsValid()
      verifyUser(form.signin_email, form.signin_password);
      // // console.log(verifyResult)
      // // console.log(getLoginStatus());
      // if (verifyResult) {
      //   // setStep('2');
      //   alert("login successful")
      //   localStorage.setItem("login", JSON.stringify(true));
      //   localStorage.setItem("primaryEmail",JSON.stringify(form.signin_email));
      //   localStorage.setItem("primaryPassword",JSON.stringify(form.signin_password));
      //   setShow(false);
      //   window.location.reload();
      // }
      // else {
      //   setErrors({"signin_email": "Invalid Email or password!"})
      // }
    }
  }
  function returnToSignIn() {
    setStep('1');
    setField('signin_email', null)
    setField('signin_password', null)
    setResetEmail(null)
  }

  const findFormErrors2 = () => {
    const { signin_ic } = form
    const newErrors = {}
    if (!signin_ic || signin_ic === '') newErrors.signin_ic = 'ic/passport cannot be blank!'

    return newErrors
  }

  const handleSubmit2 = e => {
    // get our new errors
    const newErrors = findFormErrors2()
    // Conditional logic:
    if (Object.keys(newErrors).length > 0) {
      e.preventDefault()
      // We got errors!
      setErrors(newErrors)
    } else {
      const verifyIC = verifyUserIC(form.signin_ic);
      if (verifyIC) {
        localStorage.setItem("login", JSON.stringify(true));
        setShow(false);
        window.location.reload();
      }
      else {
        setErrors({ "signin_ic": "Invalid IC/Passport!" })
      }
    }
  }

  const setSignUpField = (field, value) => {
    setSignUpForm({
      ...sign_up_form,
      [field]: value
    })
    // Check and see if errors exist, and remove them from the error object:
    if (!!signup_errors[field]) setSignUpErrors({
      ...signup_errors,
      [field]: null
    })
  }
  // console.log(sign_up_form);

  const findFormErrorsSignUp = () => {
    const { signup_email, signup_ic, signup_password, signup_cfm_password, signup_passport, signup_passport_expiry } = sign_up_form
    const newErrors = {}
    if (!signup_email || signup_email === '') newErrors.signup_email = 'email cannot be blank!'
    else if ((/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(signup_email)) == false) newErrors.signup_email = 'Please enter your email in format: yourname@example.com'
    // else if ( showPassportFields == false && !signup_ic || signup_ic === '' ) newErrors.signup_ic = 'ic cannot be blank!'
    // else if ( showPassportFields == true && !signup_passport || signup_passport === '' ) newErrors.signup_passport = 'passport cannot be blank!'
    // else if ( showPassportFields == true && !signup_passport_expiry || signup_passport_expiry === '' ) newErrors.signup_passport_expiry = 'passport_expiry cannot be blank!'
    else if (!signup_password || signup_password === '') newErrors.signup_password = 'password cannot be blank!'
    else if (!signup_cfm_password || signup_cfm_password === '') newErrors.signup_cfm_password = 'password cannot be blank!'
    else if (signup_password !== signup_cfm_password) newErrors.signup_cfm_password = 'confirm password must be same as password!'
    else if (signup_cfm_password.length < 6) newErrors.signup_cfm_password = 'Password must be at least 6 characters!'

    return newErrors
  }

  const handleSignUp = e => {
    tokenIsValid()
    // if(showPassportFields == true) {
    //   sign_up_form.signup_ic = null;
    // }
    // else {
    //   sign_up_form.signup_passport = null;
    //   sign_up_form.signup_passport_expiry = null;
    // }
    // console.log(sign_up_form);
    // get our new errors
    const newErrors = findFormErrorsSignUp()
    // Conditional logic:
    if (Object.keys(newErrors).length > 0) {
      e.preventDefault()
      // We got errors!
      setSignUpErrors(newErrors)
    } else {
      setLogin(true);
      const register_API_url = `${SERVER_URL}/user/register`;
      //call API to save data into db

      const register_requestOptions = {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': getToken()
        },
        body: JSON.stringify({
          // "IsPhoneNumber": "false",
          // "PMethod": "0",
          "Email": sign_up_form.signup_email,
          // "IC": sign_up_form.signup_ic != null ? sign_up_form.signup_ic : "",
          // "Passport": sign_up_form.signup_passport != null ? sign_up_form.signup_passport : "",
          // "PassportExpiry": sign_up_form.signup_passport_expiry != null ? sign_up_form.signup_passport_expiry : "",
          "Password": sign_up_form.signup_password,
          "ConfirmPassword": sign_up_form.signup_cfm_password,
          // "FirstName": "User",
          // "LastName": "New",
          // "CountryId": "2",
          // "PhoneCountryCode": "my",
          // "DeviceToken": "",
          // "Contact": "010-0000000",
          // "ReferralCode": "",
          // "ReferralSource": "",
          // "CaptchaCode": "",
          // "IsSubscribeEmail": "false",
          // "IsSubscribeSMS": "false"
        })
      };
      const memberRegister = async () => {
        try {
          const register_response = await fetch(
            register_API_url, register_requestOptions
          );
          let userRegisterData = await register_response.json();
          if (!register_response.ok) {
            alert(userRegisterData.message);
            throw new Error(
              `This is an HTTP error: The status is ${register_response.status}`
            );
          }
          setData(userRegisterData);
          setError(null);
          // console.log(userRegisterData);

          if (userRegisterData.status === 1) {
            setSignUpStep('1');
            alert("Registration successful, please proceed to login.")
          }
          else {
            alert(userRegisterData.message);
          }
        } catch (err) {
          setError(err.message);
          setData(null);
        } finally {
          setLoading(false);
        }
        // console.log(data);
      }
      memberRegister();

      // window.location.reload();

    }
  }
  // console.log(signup_errors);

  function signInStep1() {
    findFormErrors();
  }
  return (
    <>
      {loading && <div className='center'>
        <LoadingScreenModal />
      </div>}
      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-90w height-600"
        aria-labelledby="LoginSignUpModal"

      >
        {signUpstep == '1' ?
          <Row>
            <Col sm={6}>
              <Modal.Body className="p-0">
                <div className='signUp'>
                  <div className='text'>
                    <h1>Sign Up now and get</h1>
                    <ul>
                      <li><img src='images/img_tick_white.png'></img> Loyalty points</li>
                      <li><img src='images/img_tick_white.png'></img> Limited special offers</li>
                      <li><img src='images/img_tick_white.png'></img> Best last minute deals</li>
                      <li><img src='images/img_tick_white.png'></img> Travel deals</li>
                    </ul>
                  </div>
                  <button className='signUp_button' type='button' onClick={() => setSignUpStep('2')}>Sign up here</button>
                </div>
              </Modal.Body>
            </Col>
            <Col sm={6}>
              <div className='login'>
                {step == '1' ?
                isForgotPassword == false ?
                //normal signin mode
                  <Form>
                    <Modal.Header className='modal_header border-0' closeButton>
                      <h1>Log In</h1>
                    </Modal.Header>
                    <Modal.Body className="login_modal_body">
                      <h2>Do you have an account ? Sign in to collect {earn_points.toFixed()} points now</h2>
                      <Form.Group className="mb-3">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="eg: youremail@work.com"
                          onChange={e => setField('signin_email', e.target.value)}
                          isInvalid={!!errors.signin_email}
                        />
                        <Form.Control.Feedback type='invalid'>
                          {errors.signin_email}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Your password"
                          onChange={e => setField('signin_password', e.target.value)}
                          isInvalid={!!errors.signin_password}
                        />
                        <Form.Control.Feedback type='invalid'>
                          {errors.signin_password}
                        </Form.Control.Feedback>

                      </Form.Group>
                    </Modal.Body>
                    <Modal.Footer className="login_modal_footer border-0">
                      <a className='forgot_password_button' onClick={()=>{setIsForgotPassword(true);setForgotPasswordEmail(null)}}>Forgot Password?</a>
                      <button className='login_button' type='button' onClick={handleSubmit}>Sign In Now</button>

                      <h5>Give up your reward points? <a onClick={() => setShow(false)} className='close' aria-label='Close'>Proceed as guest here.</a></h5>
                    </Modal.Footer>
                  </Form>
                  :
                  //forgot password field
                  <Form>
                  <Modal.Header className='modal_header border-0' closeButton>
                    <h1>Forgot Password</h1>
                  </Modal.Header>
                  <Modal.Body className="login_modal_body">
                    <Form.Group className="mb-3">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="eg: youremail@work.com"
                        onChange={e => setForgotPasswordEmail(e.target.value)}
                      />
                    </Form.Group>
                      <Form.Control
                        type="text"
                        readOnly
                        className='hidden'
                      />
                  </Modal.Body>
                  <Modal.Footer className="login_modal_footer border-0">
                    <button className='login_button' type='button' onClick={resetPassword}>Reset Password</button>
                    <h5>Give up your reward points? <a onClick={() => setShow(false)} className='close' aria-label='Close'>Proceed as guest here.</a></h5>
                  </Modal.Footer>
                </Form>
                  :
                  //step = '2'
                  <Form>
                    <Modal.Header className='modal_header_2 border-0'>
                      {/* <h6>Welcome back, </h6> */}
                      <h1 className='one_step_closer'>Reset your password</h1>
                    </Modal.Header>
                    <Modal.Body className="login_modal_body">
                      <p>An Email has been sent to</p>
                      <p><strong>{resetEmail}</strong></p>
                      <p className='pb-5'>Please check your inbox for the reset link.</p>
                      {/* <p>We will need your identity card (ic) or passport number to verify your reward point’s ownership.</p> */}
                      {/* <Form.Group className="mb-3">
                        <Form.Label>Identity card (IC) or passport number</Form.Label>
                        <h4>* For identity verification purpose</h4>
                        <Form.Control
                          type="text"
                          placeholder="IC or Passport number"
                          onChange={e => setField('signin_ic', e.target.value)}
                          isInvalid={!!errors.signin_ic}
                        />
                        <Form.Control.Feedback type='invalid'>
                          {errors.signin_ic}
                        </Form.Control.Feedback>

                      </Form.Group> */}
                      <p className="pt-5">Unable to verify your account?</p>
                      <p>Send us an email at <a href='mailto:feedback@causewaylink.com.my'>feedback@causewaylink.com.my</a> for assistance.</p>
                    </Modal.Body>
                    <Modal.Footer className="login_modal_footer2 border-0 extra_margin">
                    <button className='login_button' type='button' onClick={() => returnToSignIn()}>Return to Sign in Page</button>

                      <h5>Give up your reward points? <a onClick={() => setShow(false)} className='close' aria-label='Close'>Proceed as guest here.</a></h5>
                    </Modal.Footer>
                  </Form>
                }
              </div>
            </Col>
          </Row>
          :
          <Row>
            <Col sm={6}>
              <Modal.Body className="p-0">
                <div className='signUp signUp_padding'>
                  <div className='text'>
                    <h1>Already a member?</h1>
                  </div>
                  <button className='signUp_button' type='button' onClick={() => setSignUpStep('1')}>Sign in here</button>
                </div>
              </Modal.Body>
            </Col>
            <Col sm={6}>
              <div className='login'>
                <Form>
                  <Modal.Header className='modal_header border-0' closeButton>
                    <h1>Sign Up</h1>
                  </Modal.Header>
                  <Modal.Body className="login_modal_body pb-0 ps-3">
                    <Form.Group className="mb-3">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="eg: youremail@work.com"
                        onChange={e => setSignUpField('signup_email', e.target.value)}
                        isInvalid={!!signup_errors.signup_email}
                      />
                      <Form.Control.Feedback type='invalid'>
                        {signup_errors.signup_email}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      {/* <Form.Select className="inlineBlock width50 mb-2" key={`identity_type`}  onChange={()=>setShowPassportFields(!showPassportFields)}>
                    <option selected value="IC">Identity Card</option>
                    <option value="PP">Passport</option>
                </Form.Select>
                <h4>* For identity verification purpose</h4> */}
                      {/* {
                    showPassportFields ?
                        <>
                            <Form.Control className="inlineBlock width50" key={`passport`} type="text" placeholder="Passport No." onChange={ e => setSignUpField('signup_passport', e.target.value) } isInvalid={ !!signup_errors.signup_passport } />
                            <Form.Control.Feedback type='invalid'>
                              { signup_errors.signup_passport }
                            </Form.Control.Feedback>
                            <Form.Control className="inlineBlock width50" key={`passport_expiry_date`} type="text" placeholder="Passport Expiry" onChange={ e => setSignUpField('signup_passport_expiry', e.target.value) } isInvalid={ !!signup_errors.signup_passport_expiry } />
                            <Form.Control.Feedback type='invalid'>
                              { signup_errors.signup_passport_expiry }
                            </Form.Control.Feedback>
                        </>
                        : 
                        <>
                          <Form.Control key={`ic`} type="text" placeholder="IC No" onChange={ e => setSignUpField('signup_ic', e.target.value) } isInvalid={ !!signup_errors.signup_ic } />
                          <Form.Control.Feedback type='invalid'>
                            { signup_errors.signup_ic }
                          </Form.Control.Feedback>
                        </>
                } */}

                      {/* <Form.Control 
                  type="text" 
                  placeholder="IC or Passport number" 
                  onChange={ e => setSignUpField('signup_ic', e.target.value) }
                  isInvalid={ !!signup_errors.signup_ic }
                  />
                <Form.Control.Feedback type='invalid'>
                  { signup_errors.signup_ic }
                </Form.Control.Feedback> */}
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Password</Form.Label>
                      <h4>* 6- 30 characters, no spaces</h4>
                      <Form.Control
                        type="password"
                        placeholder="Your password"
                        onChange={e => setSignUpField('signup_password', e.target.value)}
                        isInvalid={!!signup_errors.signup_password}
                      />
                      <Form.Control.Feedback type='invalid'>
                        {signup_errors.signup_password}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group>
                      <Form.Label>Confirm password </Form.Label>
                      <h4>* 6- 30 characters, no spaces</h4>
                      <Form.Control
                        type="password"
                        placeholder="Your password"
                        onChange={e => setSignUpField('signup_cfm_password', e.target.value)}
                        isInvalid={!!signup_errors.signup_cfm_password}
                      />
                      <Form.Control.Feedback type='invalid'>
                        {signup_errors.signup_cfm_password}
                      </Form.Control.Feedback>
                    </Form.Group>

                  </Modal.Body>
                  <Modal.Footer className="signUp_modal_footer border-0">
                    <button className='login_button' type='button' onClick={handleSignUp}>Sign Up</button>
                  </Modal.Footer>
                </Form>
              </div>
            </Col>
          </Row>
        }
      </Modal>
    </>
  );
}
