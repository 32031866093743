import React, { useContext } from "react";
import { useSearchParams } from "react-router-dom";
import {
  BusFareAddOnContext,
  BusFareDepartContext,
  BusFareReturnContext,
  SelectSeatDepartContext,
  SelectSeatReturnContext,
} from "../UserContext";
import { checkIsReschedule, checkOneWayTrip } from "./Function";
import { LinkButton } from "./LinkButton";

export const SelectSeatButton = ({}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const data_depart = searchParams.get("depart");
  const data_destination = searchParams.get("destination");
  const data_depart_date = searchParams.get("depart_date");
  const data_return_date = searchParams.get("return_date");
  const request_id = searchParams.get("request_id") ?? "";
  let data_passenger = searchParams.get("passenger");
  const depart_info = searchParams.get("depart_info");
  const return_info = searchParams.get("return_info");
  const { BusFareDepart, setBusFareDepart } = useContext(BusFareDepartContext);
  const { BusFareReturn, setBusFareReturn } = useContext(BusFareReturnContext);
  const TotalBusFare = BusFareDepart + BusFareReturn;
  const { selectedDepartSeat, setDepartSeat } = useContext(SelectSeatDepartContext);
  const { selectedReturnSeat, setReturnSeat } = useContext(SelectSeatReturnContext);
  const { BusFareAddOn, setBusFareAddOn } = useContext(BusFareAddOnContext);
  const checkReschedule = checkIsReschedule();
  let originData = sessionStorage.getItem("Origin_data");
  if (originData != null) {
    originData = JSON.parse(originData);
  }
  // console.log(BusFareAddOn);

  if(data_passenger > 8) {
    data_passenger = 8;
  }
  function checkValidation(selectedDepartSeat, selectedReturnSeat, e) {
    if (originData.Passenger != null) {
      data_passenger = originData.Passenger;
    }
    if (checkOneWayTrip()) {
      //check depart only
      if (selectedDepartSeat.length == 0) {
        e.preventDefault();
        alert("Please Select a Seat");
      } else if (selectedDepartSeat.length != data_passenger) {
        e.preventDefault();
        alert(`Please Select ${data_passenger} Seat`);
      }
    } else {
      //check depart and return
      if (selectedDepartSeat.length == 0 || selectedReturnSeat.length == 0) {
        e.preventDefault();
        alert("Please Select a Seat");
      } else if (selectedDepartSeat.length != data_passenger || selectedReturnSeat.length != data_passenger) {
        e.preventDefault();
        alert(`Please Select ${data_passenger} Seat`);
      }
    }
  }
  // console.log(localStorage)
  const handleSubmit = (e) => {
    checkValidation(selectedDepartSeat, selectedReturnSeat, e);
    sessionStorage.setItem("selectedDepartSeat",JSON.stringify(selectedDepartSeat));
    sessionStorage.setItem("selectedReturnSeat",JSON.stringify(selectedReturnSeat));
    sessionStorage.setItem("addOn", JSON.stringify(BusFareAddOn));
    sessionStorage.setItem("busFare", JSON.stringify(TotalBusFare));
  };

  return checkReschedule == false ? (
    <LinkButton
      children="Proceed"
      linkTo="/checkout"
      buttonStyle="btn--print--ticket--full--width"
      buttonSize="btn--ticket"
      onClick={handleSubmit}
      params={[
        `depart=${data_depart}`,
        `destination=${data_destination}`,
        `depart_date=${data_depart_date}`,
        `return_date=${data_return_date}`,
        `passenger=${data_passenger}`,
        `request_id=${request_id}`
      ]}
    ></LinkButton>
  ) : (
    <LinkButton
      children="Proceed"
      linkTo="/checkout"
      buttonStyle="btn--print--ticket--full--width"
      buttonSize="btn--ticket"
      onClick={handleSubmit}
    ></LinkButton>
  );
};
