import React, { useContext, useEffect, useState } from 'react';
import Col from 'react-bootstrap/esm/Col';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import { useSearchParams } from 'react-router-dom';
import '../../App.css';
import { LoyaltyDiscountContext } from '../../UserContext';
import { AddOn } from '../AddOn';
import { BusFareSubTotal } from '../BusFareSubTotal';
import { BusFareTotal } from '../BusFareTotal';
import { CompletedBookingButton } from '../CompleteBookingButton';
import ConfirmationEmail from '../ConfirmationEmail';
import { changeDateFormatToYearMonthDay, checkIsReschedule, getNationalityShortName, getToken, getUserData, getUserPrimaryEmail, tokenIsValid } from '../Function';
import { LoginSignUpModal } from '../LoginSignUpModal';
import { LoyaltyDiscount } from '../LoyaltyDiscount';
import PassengerDetails from '../PassengerDetails';
import { ProceedBusFare } from '../ProceedBusFare';
import { SignInToCollectLoyalty } from '../SignInToCollectLoyalty';

// import { getUserICorPassport } from '../Function';

function Checkout() {
	const login = JSON.parse(localStorage.getItem("login"));
	const userData = getUserData();

	const { TotalLoyalty, setLoyalty } = useContext(LoyaltyDiscountContext);
	const [data, setData] = useState(null);
	const [dataLoad, setDataLoad] = useState(false);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	let selected_depart_seat = sessionStorage.getItem("selectedDepartSeat");
	let selected_return_seat = sessionStorage.getItem("selectedReturnSeat");
	let array_depart_seat = eval(selected_depart_seat);//.split(",");
	let array_return_seat = eval(selected_return_seat);//.split(",");

	useEffect(() => {
		if (login == false || login == null) {
			setPrimaryGuestEmail(null)
			localStorage.removeItem("login");
			localStorage.removeItem("loginStatus");
			localStorage.removeItem("userData");
			localStorage.removeItem("primaryEmail");
			localStorage.removeItem("userLoyaltyData");
			localStorage.removeItem("friendsAndFamilyData");
		}
	}, [])
	if (selected_depart_seat == null) {
		alert("Please select your trip");
		window.location.href = "/"
	}

	//commented by Wei - 20221223
	// const guestDefaultValue = userData != null ? {
	//   "ticket_type": "A",
	//   "guest": 1,
	//   "guest_name": userData.fullname != null ? userData.fullname : null,
	//   "dob": userData.dob != null ? userData.dob : null,
	//   "gender": userData.gender != null ? userData.gender : null,
	//   "nationality": userData.nationality != null ? userData.nationality : null,
	//   "ic": userData.ic != null ? userData.ic : null,
	//   "passport": userData.passport != null ? userData.passport : null,
	//   "passport_expiry_date": userData.passportexpiry != null ? userData.passportexpiry : null,

	//   "depart_display_code": array_depart_seat[0].DisplayCode != null ? array_depart_seat[0].DisplayCode : null,
	//   "depart_seat_id": array_depart_seat[0].SeatId != null ? array_depart_seat[0].SeatId : null,
	//   "depart_seat_number": array_depart_seat[0].SeatNumber != null ? array_depart_seat[0].SeatNumber : null,
	//   "depart_seat_code": array_depart_seat[0].SeatCode != null ? array_depart_seat[0].SeatCode : null,
	//   "return_display_code": array_return_seat && array_return_seat.length != 0 ? array_return_seat[0].DisplayCode != null ? array_return_seat[0].DisplayCode : null : null,
	//   "return_seat_id": array_return_seat && array_return_seat.length != 0 ? array_return_seat[0].SeatId != null ? array_return_seat[0].SeatId : null : null,
	//   "return_seat_number": array_return_seat && array_return_seat.length != 0 ? array_return_seat[0].SeatNumber != null ? array_return_seat[0].SeatNumber : null : null,
	//   "return_seat_code": array_return_seat && array_return_seat.length != 0 ? array_return_seat[0].SeatCode != null ? array_return_seat[0].SeatCode : null : null
	// }
	//   : null;


	const guestDefaultValue = {
		"ticket_type": "A",
		"guest": 1,
		"guest_name": (userData != null && userData.fullname != null) ? userData.fullname : null,
		"dob": (userData != null && userData.dob != null) ? userData.dob : null,
		"gender": (userData != null && userData.gender != null) ? userData.gender : null,
		"nationality": (userData != null && userData.nationality != null) ? userData.nationality : null,
		"ic": (userData != null && userData.ic != null) ? userData.ic : null,
		"passport": (userData != null && userData.passport != null) ? userData.passport : null,
		"passport_expiry_date": (userData != null && userData.passportexpiry != null) ? userData.passportexpiry : null,

		"depart_display_code": array_depart_seat[0].DisplayCode != null ? array_depart_seat[0].DisplayCode : null,
		"depart_seat_id": array_depart_seat[0].SeatId != null ? array_depart_seat[0].SeatId : null,
		"depart_seat_number": array_depart_seat[0].SeatNumber != null ? array_depart_seat[0].SeatNumber : null,
		"depart_seat_code": array_depart_seat[0].SeatCode != null ? array_depart_seat[0].SeatCode : null,
		"return_display_code": array_return_seat && array_return_seat.length != 0 ? array_return_seat[0].DisplayCode != null ? array_return_seat[0].DisplayCode : null : null,
		"return_seat_id": array_return_seat && array_return_seat.length != 0 ? array_return_seat[0].SeatId != null ? array_return_seat[0].SeatId : null : null,
		"return_seat_number": array_return_seat && array_return_seat.length != 0 ? array_return_seat[0].SeatNumber != null ? array_return_seat[0].SeatNumber : null : null,
		"return_seat_code": array_return_seat && array_return_seat.length != 0 ? array_return_seat[0].SeatCode != null ? array_return_seat[0].SeatCode : null : null
	};

	//commented by Wei - 20221223
	//const [guests, setGuest] = useState([guestDefaultValue != null ? guestDefaultValue : null]);
	const [guests, setGuest] = useState([guestDefaultValue]);

	// const [primary_guest_ticket_type, setPrimaryGuestTicketType] = useState('A');
	const [primary_guest_email, setPrimaryGuestEmail] = useState(login != null ? getUserPrimaryEmail() : null);
	// sessionStorage.setItem("primary_email",JSON.stringify(primary_guest_email));
	const checkReschedule = checkIsReschedule();
	let guestContact = userData != null ? userData.mobile != null ? userData.mobile : "" : "";

	if (checkReschedule) {
		let re_origin_data = sessionStorage.getItem("Origin_data");
		if (re_origin_data != null) {
			re_origin_data = JSON.parse(re_origin_data);
			guestContact = re_origin_data?.PassengerDetails[0]?.PassengerContact;
		}
	}
	const [primary_guest_contact, setPrimaryGuestContact] = useState(guestContact);


	let busFare = sessionStorage.getItem("busFare");



	const [searchParams, setSearchParams] = useSearchParams();
	let data_depart = searchParams.get("depart");
	let data_destination = searchParams.get("destination");
	let data_depart_date = searchParams.get("depart_date");
	let data_return_date = searchParams.get("return_date");
	let data_passenger_count = searchParams.get("passenger");
	let reschedule_passengerDetails = null;

	if (sessionStorage.getItem("departData") == null) {
		alert("Please select your trip");
		window.location.href = '/';
	}
	else {
		const _object = JSON.parse(sessionStorage.getItem("departData"))

		data_depart = _object.depart_from;
		data_destination = _object.depart_to

		if (checkReschedule) {

			var reschedule_origin_data = sessionStorage.getItem("Origin_data");
			if (reschedule_origin_data != null) {
				reschedule_origin_data = JSON.parse(reschedule_origin_data);
				data_passenger_count = reschedule_origin_data.Passenger;
				reschedule_passengerDetails = reschedule_origin_data.PassengerDetails;
			}
			var reschedule_departData = sessionStorage.getItem("departData");
			data_depart_date = _object.departure_time.split(" ")[0];
			if (sessionStorage.getItem("returnData") != null) {
				var reschedule_returnData = JSON.parse(sessionStorage.getItem("returnData"))
				data_return_date = reschedule_returnData.departure_time.split(" ")[0];
			}
		}
	}
	useEffect(() => {
		if (checkReschedule == true) {
			var reschedule_guestDetails = [];
			for (let j = 0; j < reschedule_passengerDetails.length; j++) {
				reschedule_guestDetails.push({
					"ticket_type": reschedule_passengerDetails[j].PassengerType,
					"guest": j + 1,
					"guest_name": reschedule_passengerDetails[j].PassengerName,
					"dob": reschedule_passengerDetails[j].PassengerDateOfBirth.split("T")[0],
					"gender": reschedule_passengerDetails[j].PasseengerGender,
					"nationality": getNationalityShortName(reschedule_passengerDetails[j].PasseengerNationality),
					"ic": reschedule_passengerDetails[j].PassengerNric != null ? reschedule_passengerDetails[j].PassengerNric : null,
					"passport": reschedule_passengerDetails[j].PassengerPassport != null && reschedule_passengerDetails[j].PassengerPassport != "" ? reschedule_passengerDetails[j].PassengerPassport : "",
					"passport_expiry_date": reschedule_passengerDetails[j].PassengerPassportExpiryDate != null && reschedule_passengerDetails[j].PassengerPassportExpiryDate != "" ? reschedule_passengerDetails[j].PassengerPassportExpiryDate : "",
					"depart_display_code": array_depart_seat[j].DisplayCode != null ? array_depart_seat[j].DisplayCode : null,
					"depart_seat_id": array_depart_seat[j].SeatId != null ? array_depart_seat[j].SeatId : null,
					"depart_seat_number": array_depart_seat[j].SeatNumber != null ? array_depart_seat[j].SeatNumber : null,
					"depart_seat_code": array_depart_seat[j].SeatCode != null ? array_depart_seat[j].SeatCode : null,
					"return_display_code": array_return_seat && array_return_seat.length != 0 ? array_return_seat[j].DisplayCode != null ? array_return_seat[j].DisplayCode : null : null,
					"return_seat_id": array_return_seat && array_return_seat.length != 0 ? array_return_seat[j].SeatId != null ? array_return_seat[j].SeatId : null : null,
					"return_seat_number": array_return_seat && array_return_seat.length != 0 ? array_return_seat[j].SeatNumber != null ? array_return_seat[j].SeatNumber : null : null,
					"return_seat_code": array_return_seat && array_return_seat.length != 0 ? array_return_seat[j].SeatCode != null ? array_return_seat[j].SeatCode : null : null
				})
			}
			setGuest(reschedule_guestDetails)
		}
	}, [])


	const MANJA_URL = process.env.REACT_APP_MANJA_REWARD_URL;
	const API_KEY = process.env.REACT_APP_MANJA_REWARD_KEY;
	const STORE_CODE = process.env.REACT_APP_MANJA_REWARD_STORED_CODE;

	let addon_sessionID = localStorage.getItem("addOnSession");


	let user_loyalty_points = 0;
	const userPrimaryEmail = getUserPrimaryEmail();
	let url = `${MANJA_URL}/Rewards/${STORE_CODE}/${userPrimaryEmail}/2`;

	const currency = JSON.parse(localStorage.getItem("currency"));
	const depart_TripKey = JSON.parse(sessionStorage.getItem("depart_TripKey"));
	const return_TripKey = JSON.parse(sessionStorage.getItem("return_TripKey"));


	const handlePrimaryContactCallback = (event, value, component_name) => {
		if (component_name === 'primary_email') {
			setPrimaryGuestEmail(value);
			// sessionStorage.setItem("primary_email",JSON.stringify(value));
		}

		if (component_name === 'primary_contact') {
			setPrimaryGuestContact(value);
		}
	}


	const handlePassengerDetailCallback = (event, value, component_name, key) => {
		// // console.log(event.target);
		// // console.log(value);
		// // console.log(component_name);
		// // console.log(key);

		if (guests) {

			var _obj = guests[key];

			if (_obj != null) {
				_obj[component_name] = value;
			} else {
				_obj = new Object();
				_obj[component_name] = value;
			}
			_obj.guest = key + 1;

			if (key == 0 && _obj['ticket_type'] == null) {
				if (userData != null) {
					_obj.ticket_type = 'A';
				}
			}

			if (key == 0 && _obj['guest_name'] == null) {
				if (userData != null && userData.fullname != null) {
					_obj.guest_name = userData.fullname;
				}
			}

			if (key == 0 && _obj['dob'] == null) {
				if (userData != null && userData.dob != null) {
					_obj.dob = changeDateFormatToYearMonthDay(userData.dob);
				}
			}

			if (key == 0 && _obj['gender'] == null) {
				if (userData != null && userData.gender != null) {
					_obj.gender = userData.gender;
				}
			}

			if (key == 0 && _obj['nationality'] == null) {
				if (userData != null && userData.nationality != null) {
					_obj.nationality = userData.nationality;
				}
			}

			if (key == 0 && (_obj['ic'] == '' || _obj['ic'] == null)) {
				if (userData != null && (userData.ic != null && userData.ic != '')) {
					_obj.ic = userData.ic;
				}
				else if (userData != null && (userData.passport != '' && userData.passportexpiry != '')) {
					_obj.passport = userData.passport;
					_obj.passport_expiry_date = userData.passportexpiry;
				}
				// console.log(_obj.ic)
				// console.log(_obj.passport)
			}

			if (array_depart_seat) {
				if (array_depart_seat[key] && typeof (array_depart_seat[key].DisplayCode) != 'undefined') {
					_obj.depart_display_code = array_depart_seat[key].DisplayCode;
				}
				if (array_depart_seat[key] && typeof (array_depart_seat[key].SeatId) != 'undefined') {
					_obj.depart_seat_id = array_depart_seat[key].SeatId;
				}

				if (array_depart_seat[key] && typeof (array_depart_seat[key].SeatNumber) != 'undefined') {
					_obj.depart_seat_number = array_depart_seat[key].SeatNumber;
				}

				if (array_depart_seat[key] && typeof (array_depart_seat[key].SeatCode) != 'undefined') {
					_obj.depart_seat_code = array_depart_seat[key].SeatCode;
				}
			}

			if (array_return_seat) {
				if (array_return_seat[key] && typeof (array_return_seat[key].DisplayCode) != 'undefined') {
					_obj.return_display_code = array_return_seat[key].DisplayCode;
				}
				if (array_return_seat[key] && typeof (array_return_seat[key].SeatId) != 'undefined') {
					_obj.return_seat_id = array_return_seat[key].SeatId;
				}

				if (array_return_seat[key] && typeof (array_return_seat[key].SeatNumber) != 'undefined') {
					_obj.return_seat_number = array_return_seat[key].SeatNumber;
				}

				if (array_return_seat[key] && typeof (array_return_seat[key].SeatCode) != 'undefined') {
					_obj.return_seat_code = array_return_seat[key].SeatCode;
				}
			}

			guests[key] = _obj;
		}
	};


	useEffect(() => {

		//get rewards
		const getData = async () => {
			try {
				const response = await fetch(
					url,
					{
						method: 'GET',
						mode: 'cors',
						headers: {
							'apikey': API_KEY,
							'Authorization': getToken()
						}
					}
				);
				if (!response.ok) {
					throw new Error(
						`This is an HTTP error: The status is ${response.status}`
					);
				}
				let actualData = await response.json();
				setData(actualData);
				setError(null);
				setDataLoad(true);
			} catch (err) {
				setError(err.message);
				setData(null);
			} finally {
				setLoading(false);
			}
		}

		tokenIsValid()
		getData()
	}, [])



	if (data != null) {
		user_loyalty_points = data;
	}
	return (
		<>
			{
				!login ?
					<LoginSignUpModal />
					: ""
			}
			<Container>
				<Row>
					<Col lg={7}>
						<SignInToCollectLoyalty
							manjaRewards={user_loyalty_points}
							login={login}
						/>
						<PassengerDetails
							parentPassengerDetailCallback={handlePassengerDetailCallback}
							// depart={data_depart}
							// destination={data_destination}
							depart_date={data_depart_date}
							return_date={data_return_date}
							passenger_count={data_passenger_count}
							userData={userData}
							reschedulePassengerDetails={reschedule_passengerDetails != null ? reschedule_passengerDetails : null}

						/>
						{/* <TravelDeals /> */}
						{/* <GroundTransport /> */}
						<ConfirmationEmail
							parentPrimaryContactCallback={handlePrimaryContactCallback}
							userMobile={userData != null ? userData.mobile != null ? userData.mobile : "" : ""}
							reschedulePassengerNumber={reschedule_passengerDetails != null ? reschedule_passengerDetails[0].PassengerContact : null}

						/>
						{/* <CompleteYourBooking /> */}
					</Col>
					<Col lg={5}>
						<div className='proceedBusFare_container'>
							<div className='bus_fare_depart'>
								<h1>Bus Fare</h1>
								<ProceedBusFare
									page='checkout'
								/>
								<AddOn
									page='checkout'
								/>
								{
									checkReschedule == false ?
										<LoyaltyDiscount
											manjaRewards={user_loyalty_points}
											primary_guest_email={primary_guest_email}
											departure={data_depart}
											destination={data_destination}
											depart_date={data_depart_date}
											return_date={(data_return_date == null || data_return_date === 'null') ? '' : data_return_date}
											bus_fare={busFare}
											no_of_pax={data_passenger_count}
											depart_trip_key={depart_TripKey}
											return_trip_key={return_TripKey}
										// points='800'
										/>
										: ""
								}
								<Row>
									<BusFareSubTotal
										loyalty_discount_amount={TotalLoyalty}
										page='checkout'
									/>

									<BusFareTotal
										page='checkout'
									/>
								</Row>
								<Row>
									<CompletedBookingButton
										guests={checkReschedule == true ? guests.reverse() : guests}
										primary_guest_email={primary_guest_email}
										primary_guest_contact={primary_guest_contact}

										depart={data_depart}
										destination={data_destination}
										depart_date={data_depart_date}
										return_date={data_return_date}
										passenger_count={data_passenger_count}
										depart_trip_key={depart_TripKey}
										return_trip_key={return_TripKey}
										currency={currency}
									/>
								</Row>
							</div>
						</div>
					</Col>
				</Row>
			</Container>
		</>
	);
}

export default Checkout;
