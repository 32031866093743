import React, { useState } from 'react';
import { getToken, tokenIsValid } from './Function';
import { LinkButton } from "./LinkButton";
import './NewsLetter.css';

function NewsLetter() {
  const [ email_form, setEmailForm ] = useState(null)
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  function ValidateEmail(mail) {
   if (mail === null || mail === '') {
      alert("Email cannot be blank!")
      return (false);
   }
   if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
    {
      return (true)
    }
      alert("Please enter your email in format: yourname@example.com")
      return (false)
  }
  
  // console.log(email_form)
  const handleSubmit = () => {
    tokenIsValid()
    const emailValidationResult = ValidateEmail(email_form);
    if(emailValidationResult === true) {
      let url = `${SERVER_URL}/user/subscribe?email=${email_form}`;
      const getData = async () => {
        try {
          const response = await fetch(
            url,
            {
                method:"POST",
                headers: {
                    'Authorization': getToken()
                }
            }
          );
          // console.log(response)
          if (!response.ok) {
            throw new Error(
              `This is an HTTP error: The status is ${response.status}`
              );
          }
          let actualData = await response;
          setData(actualData);
          setError(null);
          // console.log(actualData)
          alert("Thank you for subscribe to our newsletter")
          window.location.reload();
        } catch(err) {
          setError(err.message);
          setData(null);
          // console.log(err);
        } finally {
          setLoading(false);
        }  
      }
      getData();
    }
  }
  
  return (
    <div className='newsLetter_container'>
        <div className='newsLetter_content'>

            <p className='newsTitle'>Newsletter</p>
            <p className='newsDescription'>Be the first to know about our travel deals and more.</p>
            <form>
            <input className='textBox' type='text' name='email' onChange={ e => setEmailForm(e.target.value) } placeholder='Email'></input>
            {/* <input className='textBox' type='submit' value='Subscribe' onClick={()=>handleSubmit()}></input> */}
            {/* <Form>
              <Form.Group>
                  <Form.Control 
                    className="newsLetter_form mb-3"
                    type="text" 
                    placeholder="eg: youremail@work.com" 
                    onChange={ e => setEmailField(e.target.value) }
                    isInvalid={ !!email_errors }
                    />
                  <Form.Control.Feedback type='invalid'>
                    { email_errors }
                  </Form.Control.Feedback> */}
                <LinkButton
                    className="btn-newsLetter"
                    type="submit"
                    buttonStyle="btn--outline"
                    buttonSize="btn--large--rectangle"
                    linkTo="/"
                    onClick={handleSubmit}
                    >
                    Subscribe
                </LinkButton>
              {/* </Form.Group>
            </Form> */}
            </form>
        </div>
    </div>
  );
}

export default NewsLetter;
