import React, { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import Col from 'react-bootstrap/esm/Col';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './AccountDetails.css';
import { ContentTitle } from './ContentTitle';
import { LoyaltyPagination } from './LoyaltyPagination';
import { changeDateFormatToYYYYMMDD, changeDateFormatToDayMonthYear, getNationalityFullName, getToken, getUserData, getUserPrimaryEmail, setUserData, tokenIsValid } from './Function';

export const AccountDetails = ({
	userLoyalty
}) => {
	const SERVER_URL = process.env.REACT_APP_SERVER_URL;
	const userData = getUserData();
	const userLoyaltyData = userLoyalty;
	const friendsAndFamilyData = JSON.parse(localStorage.getItem("friendsAndFamilyData"));
	const [editAccountDetails, setEditAccountDetails] = useState(false);
	const [editPassword, setEditPassword] = useState(false);
	const [editEmergency, setEditEmergency] = useState(false);
	const [AddNewFamilyAndFriends, setAddNewFamilyAndFriends] = useState(false);
	const [loyaltyPoints, setLoyaltyPoints] = useState(0);
	const [loyaltyShowAll, setLoyaltyShowAll] = useState(false);
	const [EditFnFMode, setEditFnFMode] = useState(false);
	const [EditDataIndex, setEditDataIndex] = useState(0);
	const [accDetails, setAccDetails] = useState(userData);
	const [friendsAndFamilyDetails, setFamilyAndFriendsDetails] = useState(friendsAndFamilyData);
	const [password, setPassword] = useState({});
	const [newFamilyAndFriends, setNewFamilyAndFriends] = useState({});
	const [FnFeditData, setFnFeditData] = useState({});
	const [showAccPassportFields, setShowAccPassportFields] = useState(false)
	const [showPassportFields, setShowPassportFields] = useState(false)
	const [FnFdata, setFnFData] = useState([]);
	const [FnFPPExp, setFnFPPExpiry] = useState(FnFeditData != null ? FnFeditData.passport_expiry != '' ? (FnFeditData.passport_expiry) : null : null);
	const [ppexp, setPPExpiry] = useState(userData != null ? userData.passportexpiry != '' ? new Date(userData.passportexpiry) : null : null)
	// console.log(FnFdata)
	// console.log(userData)
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const token = getToken();
	const MANJA_URL = process.env.REACT_APP_MANJA_REWARD_URL;
	const API_KEY = process.env.REACT_APP_MANJA_REWARD_KEY;
	const STORE_CODE = process.env.REACT_APP_MANJA_REWARD_STORED_CODE;
	const [currentPage, setCurrentPage] = useState(1);
	const [loyaltyDataPagination, setloyaltyDataPagination] = useState(null);
	const itemsPerPage = 10; // Number of items to display per page
	const [suggestedVouchers, setSuggestedVouchers] = useState([]);


	let loyalty_url = `${MANJA_URL}/Rewards/${STORE_CODE}/${getUserPrimaryEmail()}/2`;

	useEffect(() => {
		const getFnFData = async () => {
			try {
				const FnF_response = await fetch(
					`${SERVER_URL}/user/list/family/${getUserPrimaryEmail()}`,
					{
						headers: {
							'Authorization': token
						}
					}
				);
				if (!FnF_response.ok) {
					throw new Error(
						`This is an HTTP error: The status is ${FnF_response.status}`
					);
				}
				let FnF_Data = await FnF_response.json();
				setFnFData(FnF_Data);
				setError(null);
				// console.log(FnF_Data)
			} catch (err) {
				setError(err.message);
				setFnFData(null);
			} finally {
				setLoading(false);
			}
		}

		const getLoyaltyData = async () => {
			try {
				const response = await fetch(
					loyalty_url,
					{
						method: 'GET',
						mode: 'cors',
						headers: {
							'apikey': API_KEY,
							'Authorization': getToken()
						}
					}
				);
				if (!response.ok) {
					throw new Error(
						`This is an HTTP error: The status is ${response.status}`
					);
				}
				let actualData = await response.json();
				//   console.log(actualData)
				setLoyaltyPoints(actualData?.points);
			} catch (err) {
				setLoyaltyPoints(0);
			} finally {
				setLoading(false);
			}
		}

		tokenIsValid();
		getLoyaltyData();
		getFnFData();

		fetchVoucherCodes();

	}, [getToken()])
	// console.log(FnFdata)
	// console.log(password);
	// console.log(userLoyaltyData);
	// console.log(friendsAndFamilyDetails);
	// console.log(friendsAndFamilyData);
	// console.log(userData);
	// console.log(accDetails);
	// console.log(newFamilyAndFriends);
	// console.log(FnFeditData);
	// console.log(EditDataIndex);
	const today = new Date();
	const year = today.getFullYear();
	const formattedDate = accDetails.dob != '' ? changeDateFormatToDayMonthYear(accDetails.dob) : changeDateFormatToDayMonthYear("2000-01-01");
	const dobDay = formattedDate.split(" ")[0];
	const dobMonth = formattedDate.split(" ")[1];
	const dobYear = formattedDate.split(" ")[2];
	// console.log(getGenderShortForm(accDetails.gender))
	function editUserAccountDetails() {
		setEditAccountDetails(true);
		if (accDetails.ic == '' && accDetails.passport != '') {
			setShowAccPassportFields(true);
		}
		else if (accDetails.ic == '' && accDetails.passport == '') {
			setShowAccPassportFields(false);
		}
	}
	function FnFvalidation(obj, is_passport) {
		if (is_passport === false) {
			obj.passport = '';
			obj.passport_expiry = '';
			if (obj.ic == '' || obj.ic == null) {
				alert("IC cannot be blank!");
				return false;
			}
		}
		else {
			obj.ic = '';
			if (obj.passport == '' || obj.passport == null
				|| obj.passport_expiry == '' || obj.passport_expiry == null) {
				alert("Passport No. / passport expiry cannot be blank!");
				return false;
			}
		}
		if (obj.full_name == '' || obj.full_name == null) {
			alert("Full Name cannot be blank!");
			return false;
		}
		else if (obj.ticket_type == '' || obj.ticket_type == null) {
			alert("TicketType cannot be blank!");
			return false;
		}
		else if (obj.nationality == '' || obj.nationality == null) {
			alert("Please choose a valid Nationality!");
			return false;
		}
		else return true;
	}
	const editUserDob = (key, value) => {
		var newDob = formattedDate;

		if (key == 'DobDay') {
			newDob = value + " " + dobMonth + " " + dobYear;
		}
		else if (key == 'DobMonth') {
			newDob = dobDay + " " + value + " " + dobYear;
		}
		else {
			newDob = dobDay + " " + dobMonth + " " + value;
		}
		// console.log(changeDateFormatToYYYYMMDD(new Date(newDob)));
		editUserDetails('dob', changeDateFormatToYYYYMMDD(new Date(newDob)));
	}
	// console.log(dobYear);
	const updateNewFamilyAndFriends = (is_passport) => {
		const FnFValidateResult = FnFvalidation(newFamilyAndFriends, is_passport);
		if (FnFValidateResult) {
			var newArray = friendsAndFamilyDetails.push(newFamilyAndFriends);
			// console.log(newArray);
			setFamilyAndFriendsDetails(
				friendsAndFamilyDetails => [...friendsAndFamilyDetails, newArray]
			);
			// console.log(friendsAndFamilyDetails);
			tokenIsValid()
			saveFnFInfo();
		}
	}
	const editFamilyAndFriendsData = (key, e) => {
		// console.log(e);

		setFnFeditData({
			...FnFeditData,
			[key]: e
		})

	}
	const addNewFamilyAndFriends = (key, e) => {
		// console.log(e);

		setNewFamilyAndFriends({
			...newFamilyAndFriends,
			[key]: e
		})

	}
	const editUserDetails = (key, e) => {
		// console.log(e);

		setAccDetails({
			...accDetails,
			[key]: e
		})

	}
	const updateNewPassword = (key, e) => {
		// console.log(e);
		setPassword({
			...password,
			[key]: e
		})
	}



	const fetchVoucherCodes = async () => {
		try {
			const response = await fetch(
				`${SERVER_URL}/voucher/email/${getUserPrimaryEmail()}`,
				{
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
						'Authorization': getToken(),
					}
				}
			);

			if (!response.ok) {
				let errorData = await response.json();
				throw new Error(errorData.msg || `HTTP error: Status ${response.status}`);
			}

			let data = await response.json();
			setSuggestedVouchers(data); // Assuming the response contains a list of vouchers

		} catch (error) {
			console.error('Error fetching voucher codes:', error);
		}
	};



	function getReferenceID(reference) {
		return reference.substring(2, 14);
	}
	function checkTransactionClassName(mode) {
		if (mode == 'IN') {
			return 'addition';
		}
		else return 'deduction';
	}
	function checkEmergencyData() {
		if (typeof (userData.emergencycontact) != 'undefined' &&
			typeof (userData.emergencyfullname) != 'undefined' &&
			typeof (userData.emergencyrelationship) != 'undefined' &&
			userData.emergencyrelationship !== '' &&
			userData.emergencyfullname !== '' &&
			userData.emergencycontact !== '') {
			return true;
		}
		else return false;
	}
	function checkFamilyAndFriends() {
		if (FnFdata != null) {
			if (FnFdata.length !== 0) {
				return true;
			}
			else return false;
		}
		else return false;
	}
	function validateAccDetails() {
		if (showAccPassportFields === false) {
			accDetails.passport = '';
			accDetails.passportexpiry = '';
			if (accDetails.ic == '' || accDetails.ic == null) {
				alert("IC cannot be blank!");
				return false;
			}
		}
		else {
			accDetails.ic = '';
			if (accDetails.passport == '' || accDetails.passport == null
				|| accDetails.passportexpiry == '' || accDetails.passportexpiry == null) {
				alert("Passport No. / passport expiry cannot be blank!");
				return false;
			}
		}
		if (accDetails.fullname == '') {
			alert("Username cannot be blank!");
			return false;
		}
		else if (accDetails.nationality == '') {
			alert("Please choose a valid Nationality!");
			return false;
		}
		else if (accDetails.mobile == '') {
			alert("Mobile No. cannot be blank!");
			return false;
		}
		// else if(accDetails.ic == '') {
		//     alert("IC/Passport No. cannot be blank!");
		//     return false;
		// }
		else if (accDetails.gender == '') {
			accDetails.gender = 'M';
		}
		else if (accDetails.dob == '') {
			accDetails.dob = formattedDate;
		}
		else return true;
	}
	function validateEmergency() {
		if (accDetails.emergencyfullname == '' || accDetails.emergencyfullname == null) {
			alert("Fullname cannot be blank!");
			return false;
		}
		else if (accDetails.emergencyrelationship == '' || accDetails.emergencyrelationship == null) {
			alert("Please select a Relationship!");
			return false;
		}
		else if (accDetails.emergencycontact == '' || accDetails.emergencycontact == null) {
			alert("Contact No. cannot be blank!");
			return false;
		}
		else return true;
	}

	function editFnFdata(index) {
		setEditFnFMode(true);
		setEditDataIndex(index);
		setFnFeditData(FnFdata[index]);
		// console.log(FnFdata[index]);
		if (FnFdata[index].ic == '') {
			setShowPassportFields(true);
		}
	}
	// console.log(userData)
	// console.log(FnFeditData);
	function validatePassword() {
		if (password.password == '' || password.new_Password == '' || password.new_Password == null || password.password == null) {
			alert("Password cannot be blank");
		}
		else if (password.new_Password !== password.retype_Password) {
			alert("'Retype Password' must be same with 'New Password'!")
		}
		else if (password.new_Password.length < 6) {
			alert("Password must be at least 6 characters!")
		}
		else {
			setEditPassword(false);
			return true;
		}
		return false;
	}
	function updateEditFnFData(is_passport) {
		tokenIsValid()
		const FnFeditValidationResult = FnFvalidation(FnFeditData, is_passport);
		if (FnFeditValidationResult) {
			const FnF_edit_api_url = `${SERVER_URL}/user/update/family/profile`;
			//call API to save data into db

			const FnF_edit_requestOptions = {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': token
				},
				body: JSON.stringify({
					"IC": FnFeditData.ic != null ? FnFeditData.ic : "",
					"Passport": FnFeditData.passport != null ? FnFeditData.passport : "",
					"PassportExpiry": FnFeditData.passport_expiry != null ? FnFeditData.passport_expiry : "",
					"Nationality": FnFeditData.nationality,
					"FullName": FnFeditData.full_name,
					"TicketType": FnFeditData.ticket_type,
					"UUID": FnFeditData.uuid,
					"PrimaryEmail": getUserPrimaryEmail()
				})
			};
			// console.log(FnF_edit_requestOptions)
			const updateFnFData = async () => {
				try {
					const FnF_edit_response = await fetch(
						FnF_edit_api_url, FnF_edit_requestOptions
					);
					if (!FnF_edit_response.ok) {
						throw new Error(
							`This is an HTTP error: The status is ${FnF_edit_response.status}`
						);
					}
					let familyEditData = await FnF_edit_response.json();
					setData(familyEditData);
					setError(null);
					// console.log(familyEditData);
				} catch (err) {
					// console.log(err.message);
					setError(err.message);
					setData(null);
				} finally {
					setLoading(false);
				}
				// console.log(data);
			}
			updateFnFData();

			friendsAndFamilyDetails[EditDataIndex] = FnFeditData;
			setFamilyAndFriendsDetails(friendsAndFamilyDetails);
			localStorage.setItem("friendsAndFamilyData", JSON.stringify(friendsAndFamilyDetails));
			alert('Your friends & family data has been updated');
			window.location.reload();
		}
	}
	function saveFnFInfo() {
		const FnF_api_url = `${SERVER_URL}/user/update/family/profile`;
		//call API to save data into db

		const FnF_requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': token
			},
			body: JSON.stringify({
				"IC": newFamilyAndFriends.ic != null ? newFamilyAndFriends.ic : "",
				"Passport": newFamilyAndFriends.passport != null ? newFamilyAndFriends.passport : "",
				"PassportExpiry": newFamilyAndFriends.passport_expiry != null ? newFamilyAndFriends.passport_expiry : "",
				"Nationality": newFamilyAndFriends.nationality,
				"FullName": newFamilyAndFriends.full_name,
				"TicketType": newFamilyAndFriends.ticket_type,
				"UUID": "",
				"PrimaryEmail": getUserPrimaryEmail()
			})
		};
		// console.log(FnF_requestOptions)
		const updateFnFData = async () => {
			try {
				const FnF_response = await fetch(
					FnF_api_url, FnF_requestOptions
				);
				if (!FnF_response.ok) {
					throw new Error(
						`This is an HTTP error: The status is ${FnF_response.status}`
					);
				}
				let familyData = await FnF_response.json();
				setData(familyData);
				setError(null);
				// console.log(familyData);
			} catch (err) {
				// console.log(err.message);
				setError(err.message);
				setData(null);
			} finally {
				setLoading(false);
			}
			// console.log(data);
		}
		updateFnFData();
		localStorage.setItem("friendsAndFamilyData", JSON.stringify(friendsAndFamilyDetails));
		setNewFamilyAndFriends(null);

		alert('Your friends & family data has been updated!')
		window.location.reload();
	}
	function saveAccInfo(validateFor) {
		tokenIsValid()
		//simple validation
		var validationResult = false;
		if (validateFor == 'accInfo') {
			validationResult = validateAccDetails();
		}
		else if (validateFor == 'emergency') {
			validationResult = validateEmergency();
		}
		else if (validateFor == 'password') {
			validationResult = validatePassword();
		}
		if (validationResult) {
			let alert_message = "Your profile details are updated";
			let error_message = "Our system is unable to update your profile details";
			let requestOptions = {};
			let api_url = `${SERVER_URL}/user/update/profile`;
			// Simple POST request with a JSON body using fetch
			if (validateFor == 'accInfo') {
				requestOptions = {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						'Authorization': token
					},
					body: JSON.stringify({
						"DOB": accDetails.dob,
						"Gender": accDetails.gender,
						"IC": accDetails.ic != null ? accDetails.ic : "",
						"Passport": accDetails.passport != null ? accDetails.passport : "",
						"PassportExpiry": accDetails.passportexpiry != null ? accDetails.passportexpiry : "",
						"Mobile": accDetails.mobile,
						"Nationality": accDetails.nationality,
						"FullName": accDetails.fullname,
						"PrimaryEmail": getUserPrimaryEmail()
					})
				};
			}
			else if (validateFor == 'emergency') {
				alert_message = "Your emergency contact details are updated";
				error_message = "Our system is unable to update your emergency contact details";
				api_url = `${SERVER_URL}/user/update/emergency`;
				requestOptions = {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						'Authorization': token
					},
					body: JSON.stringify({
						"EmergencyContact": accDetails.emergencycontact,
						"EmergencyFullName": accDetails.emergencyfullname,
						"EmergencyRelationship": accDetails.emergencyrelationship,
						"PrimaryEmail": getUserPrimaryEmail()
					})
				};
			}
			else if (validateFor == 'password') {
				alert_message = "Your password has been updated";
				error_message = "Our system is unable to change your password";
				api_url = `${SERVER_URL}/user/update/password`;
				requestOptions = {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						'Authorization': token
					},
					body: JSON.stringify({
						"OldPassword": password.password,
						"NewPassword": password.new_Password,
						"PrimaryEmail": getUserPrimaryEmail(),
						"Key": userData.key
					})
				};
				localStorage.setItem("primaryPassword", JSON.stringify(password.new_Password));
			}
			// console.log(accDetails)
			// console.log(requestOptions)
			const updateUserData = async () => {
				try {
					const response = await fetch(
						api_url, requestOptions
					);
					if (!response.ok) {
						throw new Error(
							`${error_message}`
						);
					}
					setUserData(accDetails);
					setAccDetails(null);
					alert(alert_message)
					window.location.reload();
				} catch (err) {
					// console.log(err)
					alert(err.message)
					setError(err.message);
					setData(null);
				} finally {
					setLoading(false);
				}
			}
			updateUserData();
		}
	}
	// const handlePageChange = (pageNumber) => {
	//     setCurrentPage(pageNumber);
	// };

	const handleNextPage = () => {
		setCurrentPage((prevPage) => prevPage + 1);
	};

	const handlePreviousPage = () => {
		setCurrentPage((prevPage) => prevPage - 1);
	};

	useEffect(() => {
		if (userLoyaltyData && userLoyaltyData.length > itemsPerPage) {
			// Logic to calculate the start and end index of the items to display on the current page
			const indexOfLastItem = currentPage * itemsPerPage;
			const indexOfFirstItem = indexOfLastItem - itemsPerPage;
			const currentItems = userLoyaltyData.slice(indexOfFirstItem, indexOfLastItem);
			setloyaltyDataPagination(currentItems);
			// Logic to handle page change
		}
	}, [currentPage])

	function splitLoyaltyIntoPaginationAndShowAll() {
		if (userLoyaltyData && userLoyaltyData.length > itemsPerPage) {
			// Logic to calculate the start and end index of the items to display on the current page
			const indexOfLastItem = currentPage * itemsPerPage;
			const indexOfFirstItem = indexOfLastItem - itemsPerPage;
			const currentItems = userLoyaltyData.slice(indexOfFirstItem, indexOfLastItem);
			setloyaltyDataPagination(currentItems);
			// Logic to handle page change

		}
		setLoyaltyShowAll(true);

	}

	const AccountRef = useRef(null);
	const PasswordRef = useRef(null);
	const EmergencyRef = useRef(null);
	const FnFRef = useRef(null);
	const LoyaltyRef = useRef(null);
	const VoucherRef = useRef(null);
	const executeScroll = (reference) => reference.current.scrollIntoView();
	return (
		<>
			<Container className='pt-4'>
				<Row>
					<Col md={4}>
						<div className='acc_navbar'>
							<table width={'100%'}>
								<tr onClick={() => executeScroll(AccountRef)}>
									<td>
										<img src='images/ic_account_inactive.png' />
									</td>
									<td>
										<h3>My Account</h3>
									</td>
								</tr>
								<tr onClick={() => executeScroll(PasswordRef)}>
									<td>
										<img src='images/ic_password_inactive.png' />
									</td>
									<td>
										<h3>Change Password</h3>
									</td>
								</tr>
								<tr onClick={() => executeScroll(EmergencyRef)}>
									<td>
										<img src='images/ic_emergency_inactive.png' />
									</td>
									<td>
										<h3>Emergency Contact</h3>
									</td>
								</tr>
								<tr onClick={() => executeScroll(FnFRef)}>
									<td>
										<img src='images/ic_family_inactive.png' />
									</td>
									<td>
										<h3>Family & Friends</h3>
									</td>
								</tr>
								<tr onClick={() => executeScroll(LoyaltyRef)}>
									<td>
										<img src='images/ic_loyalty_inactive.png' />
									</td>
									<td>
										<h3>Loyalty Program</h3>
									</td>
								</tr>

								<tr onClick={() => executeScroll(VoucherRef)}>
									<td>
										<img src='images/ic_loyalty_inactive.png' />
									</td>
									<td>
										<h3>Vouchers</h3>
									</td>
								</tr>
							</table>
						</div>
					</Col>
					<Col md={8}>
						<div className='user_details'>
							<div ref={AccountRef}></div>
							<ContentTitle
								title='My Account'
								description='Change your account settings here'
								edit='edit'
								onClick={() => editUserAccountDetails()}
							/>
							<div className='acc_details'>
								{!editAccountDetails ?
									<table width={'100%'}>
										<tr>
											<td width={'50%'}>
												<h6>Full Name</h6>
												<h5>{userData.fullname}</h5>
											</td>
											<td width={'50%'}>
												<h6>Gender</h6>
												<h5>{userData.gender}</h5>
											</td>
										</tr>
										<tr>
											<td width={'50%'}>
												<h6>Nationality</h6>
												<h5>{getNationalityFullName(userData.nationality)}</h5>
											</td>
											<td width={'50%'}>
												<h6>IC/ Passport No.</h6>
												<h5>{userData.ic != null && userData.ic != '' ? userData.ic : userData.passport}</h5>
											</td>
										</tr>
										<tr>
											<td width={'50%'}>
												<h6>Mobile</h6>
												<h5>{userData.mobile}</h5>
											</td>
											<td width={'50%'}>
												<h6>Email</h6>
												<h5>{getUserPrimaryEmail()}</h5>
											</td>
										</tr>
										<tr>
											<td width={'50%'}>
												<h6>Date of Birth</h6>
												<h5>{formattedDate}</h5>
											</td>
										</tr>
									</table>
									:
									<Form>
										<table width={'100%'}>
											<tr>
												<td width={'50%'}>
													<h6>Full Name<span className='requiredStar'> *</span></h6>
													<Form.Control key={`myAcc_fullname`} onChange={event => editUserDetails('fullname', event.target.value)} type="text" placeholder="Full Name" defaultValue={userData.fullname} />
												</td>
												<td width={'50%'}>
													<h6>Email<span className='requiredStar'> *</span></h6>
													<Form.Control key={`myAcc_email`} disabled type="text" placeholder="example@example.com" defaultValue={getUserPrimaryEmail()} />
												</td>
											</tr>
											<tr>
												<td width={'50%'}>
													<h6>Gender<span className='requiredStar'> *</span></h6>
													<Form.Select key={`myAcc_gender`} onChange={event => editUserDetails('gender', event.target.value)} className="mb-2">
														{userData.gender == 'M' ? <option selected value="M">Male</option> : <option value="M">Male</option>}
														{userData.gender == 'F' ? <option selected value="F">Female</option> : <option value="F">Female</option>}
													</Form.Select>
												</td>
												<td width={'50%'}>
													<h6>Date of Birth<span className='requiredStar'> *</span></h6>
													<Form.Select key={`myAcc_dob_day`} onChange={event => editUserDob('DobDay', event.target.value)} className="mb-2 me-2 edit_dob">
														{Array.from({ length: 31 }, (_, i) =>
															<option selected={dobDay == i + 1 ? true : false} value={i + 1}>{i + 1}</option>
														)}
													</Form.Select>
													<Form.Select key={`myAcc_dob_month`} onChange={event => editUserDob('DobMonth', event.target.value)} className="mb-2 me-2 edit_dob">
														<option selected={dobMonth == 'Jan' ? true : false} value='Jan'>Jan</option>
														<option selected={dobMonth == 'Feb' ? true : false} value='Feb'>Feb</option>
														<option selected={dobMonth == 'Mar' ? true : false} value='Mar'>Mar</option>
														<option selected={dobMonth == 'Apr' ? true : false} value='Apr'>Apr</option>
														<option selected={dobMonth == 'May' ? true : false} value='May'>May</option>
														<option selected={dobMonth == 'Jun' ? true : false} value='Jun'>Jun</option>
														<option selected={dobMonth == 'Jul' ? true : false} value='Jul'>Jul</option>
														<option selected={dobMonth == 'Aug' ? true : false} value='Aug'>Aug</option>
														<option selected={dobMonth == 'Sep' ? true : false} value='Sep'>Sep</option>
														<option selected={dobMonth == 'Oct' ? true : false} value='Oct'>Oct</option>
														<option selected={dobMonth == 'Nov' ? true : false} value='Nov'>Nov</option>
														<option selected={dobMonth == 'Dec' ? true : false} value='Dec'>Dec</option>
													</Form.Select>
													<Form.Select key={`myAcc_dob_year`} onChange={event => editUserDob('DobYear', event.target.value)} className="mb-2 edit_dob">
														{Array.from({ length: 100 }, (_, i) =>
															<option selected={dobYear == i + year - 99 ? true : false} value={i + year - 99}>{i + year - 99}</option>
														)}
													</Form.Select>
												</td>
											</tr>
											<tr>
												<td width={'50%'}>
													<h6>Nationality<span className='requiredStar'> *</span></h6>
													<Form.Select key={`nationality`} className="mb-2" onChange={event => editUserDetails('nationality', event.target.value)}>
														<option value="">Nationality</option>

														<option selected={userData.nationality == 'MY' || userData.nationality == 'Malaysia' ? true : false} value="MY">Malaysia</option>
														<option selected={userData.nationality == 'SG' || userData.nationality == 'Singapore' ? true : false} value="SG">Singapore</option>

														<option selected={userData.nationality == 'AF' || userData.nationality == 'Afghanistan' ? true : false} value="AF">Afghanistan</option>
														<option selected={userData.nationality == 'AL' || userData.nationality == 'Albania' ? true : false} value="AL">Albania</option>
														<option selected={userData.nationality == 'DZ' || userData.nationality == 'Algeria' ? true : false} value="DZ">Algeria</option>
														<option selected={userData.nationality == 'AD' || userData.nationality == 'Andorra' ? true : false} value="AD">Andorra</option>
														<option selected={userData.nationality == 'AO' || userData.nationality == 'Angola' ? true : false} value="AO">Angola</option>
														<option selected={userData.nationality == 'AI' || userData.nationality == 'Anguilla' ? true : false} value="AI">Anguilla</option>
														<option selected={userData.nationality == 'AG' || userData.nationality == 'Antigua and Barbuda' ? true : false} value="AG">Antigua and Barbuda</option>
														<option selected={userData.nationality == 'AR' || userData.nationality == 'Argentina' ? true : false} value="AR">Argentina</option>
														<option selected={userData.nationality == 'AM' || userData.nationality == 'Armenia' ? true : false} value="AM">Armenia</option>
														<option selected={userData.nationality == 'AW' || userData.nationality == 'Aruba' ? true : false} value="AW">Aruba</option>
														<option selected={userData.nationality == 'AU' || userData.nationality == 'Australia' ? true : false} value="AU">Australia</option>
														<option selected={userData.nationality == 'AT' || userData.nationality == 'Austria' ? true : false} value="AT">Austria</option>
														<option selected={userData.nationality == 'AZ' || userData.nationality == 'Azerbaijan' ? true : false} value="AZ">Azerbaijan</option>
														<option selected={userData.nationality == 'BS' || userData.nationality == 'Bahamas' ? true : false} value="BS">Bahamas</option>
														<option selected={userData.nationality == 'BH' || userData.nationality == 'Bahrain' ? true : false} value="BH">Bahrain</option>
														<option selected={userData.nationality == 'BD' || userData.nationality == 'Bangladesh' ? true : false} value="BD">Bangladesh</option>
														<option selected={userData.nationality == 'BB' || userData.nationality == 'Barbados' ? true : false} value="BB">Barbados</option>
														<option selected={userData.nationality == 'BY' || userData.nationality == 'Belarus' ? true : false} value="BY">Belarus</option>
														<option selected={userData.nationality == 'BE' || userData.nationality == 'Belgium' ? true : false} value="BE">Belgium</option>
														<option selected={userData.nationality == 'BZ' || userData.nationality == 'Belize' ? true : false} value="BZ">Belize</option>
														<option selected={userData.nationality == 'BJ' || userData.nationality == 'Benin' ? true : false} value="BJ">Benin</option>
														<option selected={userData.nationality == 'BM' || userData.nationality == 'Bermuda' ? true : false} value="BM">Bermuda</option>
														<option selected={userData.nationality == 'BT' || userData.nationality == 'Bhutan' ? true : false} value="BT">Bhutan</option>
														<option selected={userData.nationality == 'BO' || userData.nationality == 'Bolivia' ? true : false} value="BO">Bolivia</option>
														<option selected={userData.nationality == 'BA' || userData.nationality == 'Bosnia and Herzegovina' ? true : false} value="BA">Bosnia and Herzegovina</option>
														<option selected={userData.nationality == 'BW' || userData.nationality == 'Botswana' ? true : false} value="BW">Botswana</option>
														<option selected={userData.nationality == 'BR' || userData.nationality == 'Brazil' ? true : false} value="BR">Brazil</option>
														<option selected={userData.nationality == 'VG' || userData.nationality == 'British' ? true : false} value="VG">British Virgin Islands</option>
														<option selected={userData.nationality == 'BN' || userData.nationality == 'Brunei' ? true : false} value="BN">Brunei</option>
														<option selected={userData.nationality == 'BG' || userData.nationality == 'Bulgaria' ? true : false} value="BG">Bulgaria</option>
														<option selected={userData.nationality == 'BF' || userData.nationality == 'Burkina Faso' ? true : false} value="BF">Burkina Faso</option>
														<option selected={userData.nationality == 'BI' || userData.nationality == 'Burundi' ? true : false} value="BI">Burundi</option>
														<option selected={userData.nationality == 'KH' || userData.nationality == 'Cambodia' ? true : false} value="KH">Cambodia</option>
														<option selected={userData.nationality == 'CM' || userData.nationality == 'Cameroon' ? true : false} value="CM">Cameroon</option>
														<option selected={userData.nationality == 'CA' || userData.nationality == 'Canada' ? true : false} value="CA">Canada</option>
														<option selected={userData.nationality == 'CV' || userData.nationality == 'Cape Verde' ? true : false} value="CV">Cape Verde</option>
														<option selected={userData.nationality == 'KY' || userData.nationality == 'Cayman Islands' ? true : false} value="KY">Cayman Islands</option>
														<option selected={userData.nationality == 'CF' || userData.nationality == 'Central African Republic' ? true : false} value="CF">Central African Republic</option>
														<option selected={userData.nationality == 'TD' || userData.nationality == 'Chad' ? true : false} value="TD">Chad</option>
														<option selected={userData.nationality == 'CL' || userData.nationality == 'Chile' ? true : false} value="CL">Chile</option>
														<option selected={userData.nationality == 'CN' || userData.nationality == 'China' ? true : false} value="CN">China</option>
														<option selected={userData.nationality == 'CO' || userData.nationality == 'Colombia' ? true : false} value="CO">Colombia</option>
														<option selected={userData.nationality == 'KM' || userData.nationality == 'Comoros' ? true : false} value="KM">Comoros</option>
														<option selected={userData.nationality == 'CD' || userData.nationality == 'Congo, Democratic Republic of the' ? true : false} value="CD">Congo, Democratic Republic of the</option>
														<option selected={userData.nationality == 'CG' || userData.nationality == 'Congo, Republic of' ? true : false} value="CG">Congo, Republic of</option>
														<option selected={userData.nationality == 'CR' || userData.nationality == 'Costa Rica' ? true : false} value="CR">Costa Rica</option>
														<option selected={userData.nationality == "CI" || userData.nationality == "Cote D'Ivoire (Ivory Coast)" ? true : false} value="CI">Cote D'Ivoire (Ivory Coast)</option>
														<option selected={userData.nationality == 'HR' || userData.nationality == 'Croatia' ? true : false} value="HR">Croatia</option>
														<option selected={userData.nationality == 'CU' || userData.nationality == 'Cuba' ? true : false} value="CU">Cuba</option>
														<option selected={userData.nationality == 'CY' || userData.nationality == 'Cyprus' ? true : false} value="CY">Cyprus</option>
														<option selected={userData.nationality == 'CZ' || userData.nationality == 'Czech Republic' ? true : false} value="CZ">Czech Republic</option>
														<option selected={userData.nationality == 'DK' || userData.nationality == 'Denmark' ? true : false} value="DK">Denmark</option>
														<option selected={userData.nationality == 'DJ' || userData.nationality == 'Djibouti' ? true : false} value="DJ">Djibouti</option>
														<option selected={userData.nationality == 'DM' || userData.nationality == 'Dominica' ? true : false} value="DM">Dominica</option>
														<option selected={userData.nationality == 'DO' || userData.nationality == 'Dominican Republic' ? true : false} value="DO">Dominican Republic</option>
														<option selected={userData.nationality == 'EC' || userData.nationality == 'Ecuador' ? true : false} value="EC">Ecuador</option>
														<option selected={userData.nationality == 'EG' || userData.nationality == 'Egypt' ? true : false} value="EG">Egypt</option>
														<option selected={userData.nationality == 'SV' || userData.nationality == 'El Salvador' ? true : false} value="SV">El Salvador</option>
														<option selected={userData.nationality == 'GQ' || userData.nationality == 'Equatorial Guinea' ? true : false} value="GQ">Equatorial Guinea</option>
														<option selected={userData.nationality == 'ER' || userData.nationality == 'Eritrea' ? true : false} value="ER">Eritrea</option>
														<option selected={userData.nationality == 'EE' || userData.nationality == 'Estonia' ? true : false} value="EE">Estonia</option>
														<option selected={userData.nationality == 'ET' || userData.nationality == 'Ethiopia' ? true : false} value="ET">Ethiopia</option>
														<option selected={userData.nationality == 'FK' || userData.nationality == 'Falkland Islands' ? true : false} value="FK">Falkland Islands</option>
														<option selected={userData.nationality == 'FJ' || userData.nationality == 'Fiji' ? true : false} value="FJ">Fiji</option>
														<option selected={userData.nationality == 'FI' || userData.nationality == 'Finland' ? true : false} value="FI">Finland</option>
														<option selected={userData.nationality == 'FR' || userData.nationality == 'France' ? true : false} value="FR">France</option>
														<option selected={userData.nationality == 'GF' || userData.nationality == 'French Guiana' ? true : false} value="GF">French Guiana</option>
														<option selected={userData.nationality == 'PF' || userData.nationality == 'French Polynesia' ? true : false} value="PF">French Polynesia</option>
														<option selected={userData.nationality == 'GA' || userData.nationality == 'Gabon' ? true : false} value="GA">Gabon</option>
														<option selected={userData.nationality == 'GM' || userData.nationality == 'Gambia' ? true : false} value="GM">Gambia</option>
														<option selected={userData.nationality == 'GE' || userData.nationality == 'Georgia' ? true : false} value="GE">Georgia</option>
														<option selected={userData.nationality == 'DE' || userData.nationality == 'Germany' ? true : false} value="DE">Germany</option>
														<option selected={userData.nationality == 'GH' || userData.nationality == 'Ghana' ? true : false} value="GH">Ghana</option>
														<option selected={userData.nationality == 'GI' || userData.nationality == 'Gibraltar' ? true : false} value="GI">Gibraltar</option>
														<option selected={userData.nationality == 'GR' || userData.nationality == 'Greece' ? true : false} value="GR">Greece</option>
														<option selected={userData.nationality == 'GD' || userData.nationality == 'Grenada' ? true : false} value="GD">Grenada</option>
														<option selected={userData.nationality == 'GP' || userData.nationality == 'Guadeloupe' ? true : false} value="GP">Guadeloupe</option>
														<option selected={userData.nationality == 'GT' || userData.nationality == 'Guatemala' ? true : false} value="GT">Guatemala</option>
														<option selected={userData.nationality == 'GN' || userData.nationality == 'Guinea' ? true : false} value="GN">Guinea</option>
														<option selected={userData.nationality == 'GW' || userData.nationality == 'Guinea-Bissau' ? true : false} value="GW">Guinea-Bissau</option>
														<option selected={userData.nationality == 'GY' || userData.nationality == 'Guyana' ? true : false} value="GY">Guyana</option>
														<option selected={userData.nationality == 'HT' || userData.nationality == 'Haiti' ? true : false} value="HT">Haiti</option>
														<option selected={userData.nationality == 'HN' || userData.nationality == 'Honduras' ? true : false} value="HN">Honduras</option>
														<option selected={userData.nationality == 'HK' || userData.nationality == 'Hong Kong (China)' ? true : false} value="HK">Hong Kong (China)</option>
														<option selected={userData.nationality == 'HU' || userData.nationality == 'Hungary' ? true : false} value="HU">Hungary</option>
														<option selected={userData.nationality == 'IS' || userData.nationality == 'Iceland' ? true : false} value="IS">Iceland</option>
														<option selected={userData.nationality == 'IN' || userData.nationality == 'India' ? true : false} value="IN">India</option>
														<option selected={userData.nationality == 'ID' || userData.nationality == 'Indonesia' ? true : false} value="ID">Indonesia</option>
														<option selected={userData.nationality == 'IR' || userData.nationality == 'Iran' ? true : false} value="IR">Iran</option>
														<option selected={userData.nationality == 'IQ' || userData.nationality == 'Iraq' ? true : false} value="IQ">Iraq</option>
														<option selected={userData.nationality == 'IE' || userData.nationality == 'Ireland' ? true : false} value="IE">Ireland</option>
														<option selected={userData.nationality == 'IL' || userData.nationality == 'Israel' ? true : false} value="IL">Israel</option>
														<option selected={userData.nationality == 'IT' || userData.nationality == 'Italy' ? true : false} value="IT">Italy</option>
														<option selected={userData.nationality == 'JM' || userData.nationality == 'Jamaica' ? true : false} value="JM">Jamaica</option>
														<option selected={userData.nationality == 'JP' || userData.nationality == 'Japan' ? true : false} value="JP">Japan</option>
														<option selected={userData.nationality == 'JO' || userData.nationality == 'Jordan' ? true : false} value="JO">Jordan</option>
														<option selected={userData.nationality == 'KZ' || userData.nationality == 'Kazakhstan' ? true : false} value="KZ">Kazakhstan</option>
														<option selected={userData.nationality == 'KE' || userData.nationality == 'Kenya' ? true : false} value="KE">Kenya</option>
														<option selected={userData.nationality == 'KP' || userData.nationality == 'Korea (North)' ? true : false} value="KP">Korea (North)</option>
														<option selected={userData.nationality == 'KR' || userData.nationality == 'Korea (South)' ? true : false} value="KR">Korea (South)</option>
														<option selected={userData.nationality == 'KW' || userData.nationality == 'Kuwait' ? true : false} value="KW">Kuwait</option>
														<option selected={userData.nationality == 'KG' || userData.nationality == 'Kyrgyzstan' ? true : false} value="KG">Kyrgyzstan</option>
														<option selected={userData.nationality == 'LA' || userData.nationality == 'Laos' ? true : false} value="LA">Laos</option>
														<option selected={userData.nationality == 'LV' || userData.nationality == 'Latvia' ? true : false} value="LV">Latvia</option>
														<option selected={userData.nationality == 'LB' || userData.nationality == 'Lebanon' ? true : false} value="LB">Lebanon</option>
														<option selected={userData.nationality == 'LS' || userData.nationality == 'Lesotho' ? true : false} value="LS">Lesotho</option>
														<option selected={userData.nationality == 'LR' || userData.nationality == 'Liberia' ? true : false} value="LR">Liberia</option>
														<option selected={userData.nationality == 'LY' || userData.nationality == 'Libyan Arab Jamahiriya' ? true : false} value="LY">Libyan Arab Jamahiriya</option>
														<option selected={userData.nationality == 'LI' || userData.nationality == 'Liechtenstein' ? true : false} value="LI">Liechtenstein</option>
														<option selected={userData.nationality == 'LT' || userData.nationality == 'Lithuania' ? true : false} value="LT">Lithuania</option>
														<option selected={userData.nationality == 'LU' || userData.nationality == 'Luxembourg' ? true : false} value="LU">Luxembourg</option>
														<option selected={userData.nationality == 'MO' || userData.nationality == 'Macao' ? true : false} value="MO">Macao</option>
														<option selected={userData.nationality == 'MK' || userData.nationality == 'Macedonia' ? true : false} value="MK">Macedonia</option>
														<option selected={userData.nationality == 'MG' || userData.nationality == 'Madagascar' ? true : false} value="MG">Madagascar</option>
														<option selected={userData.nationality == 'MW' || userData.nationality == 'Malawi' ? true : false} value="MW">Malawi</option>
														<option selected={userData.nationality == 'MV' || userData.nationality == 'Maldives' ? true : false} value="MV">Maldives</option>
														<option selected={userData.nationality == 'ML' || userData.nationality == 'Mali' ? true : false} value="ML">Mali</option>
														<option selected={userData.nationality == 'MT' || userData.nationality == 'Malta' ? true : false} value="MT">Malta</option>
														<option selected={userData.nationality == 'MH' || userData.nationality == 'Marshall Islands' ? true : false} value="MH">Marshall Islands</option>
														<option selected={userData.nationality == 'MQ' || userData.nationality == 'Martinique' ? true : false} value="MQ">Martinique</option>
														<option selected={userData.nationality == 'MR' || userData.nationality == 'Mauritania' ? true : false} value="MR">Mauritania</option>
														<option selected={userData.nationality == 'MU' || userData.nationality == 'Mauritius' ? true : false} value="MU">Mauritius</option>
														<option selected={userData.nationality == 'YT' || userData.nationality == 'Mayotte' ? true : false} value="YT">Mayotte</option>
														<option selected={userData.nationality == 'MX' || userData.nationality == 'Mexico' ? true : false} value="MX">Mexico</option>
														<option selected={userData.nationality == 'FM' || userData.nationality == 'Micronesia, Federated States of' ? true : false} value="FM">Micronesia, Federated States of</option>
														<option selected={userData.nationality == 'MD' || userData.nationality == 'Moldova' ? true : false} value="MD">Moldova</option>
														<option selected={userData.nationality == 'MC' || userData.nationality == 'Monaco' ? true : false} value="MC">Monaco</option>
														<option selected={userData.nationality == 'MN' || userData.nationality == 'Mongolia' ? true : false} value="MN">Mongolia</option>
														<option selected={userData.nationality == 'MS' || userData.nationality == 'Montserrat' ? true : false} value="MS">Montserrat</option>
														<option selected={userData.nationality == 'MA' || userData.nationality == 'Morocco' ? true : false} value="MA">Morocco</option>
														<option selected={userData.nationality == 'MZ' || userData.nationality == 'Mozambique' ? true : false} value="MZ">Mozambique</option>
														<option selected={userData.nationality == 'MM' || userData.nationality == 'Myanmar' ? true : false} value="MM">Myanmar</option>
														<option selected={userData.nationality == 'NA' || userData.nationality == 'Namibia' ? true : false} value="NA">Namibia</option>
														<option selected={userData.nationality == 'NR' || userData.nationality == 'Nauru' ? true : false} value="NR">Nauru</option>
														<option selected={userData.nationality == 'NP' || userData.nationality == 'Nepal' ? true : false} value="NP">Nepal</option>
														<option selected={userData.nationality == 'NL' || userData.nationality == 'Netherlands' ? true : false} value="NL">Netherlands</option>
														<option selected={userData.nationality == 'AN' || userData.nationality == 'Netherlands Antilles' ? true : false} value="AN">Netherlands Antilles</option>
														<option selected={userData.nationality == 'NC' || userData.nationality == 'New Caledonia' ? true : false} value="NC">New Caledonia</option>
														<option selected={userData.nationality == 'NZ' || userData.nationality == 'New Zealand' ? true : false} value="NZ">New Zealand</option>
														<option selected={userData.nationality == 'NI' || userData.nationality == 'Nicaragua' ? true : false} value="NI">Nicaragua</option>
														<option selected={userData.nationality == 'NE' || userData.nationality == 'Niger' ? true : false} value="NE">Niger</option>
														<option selected={userData.nationality == 'NG' || userData.nationality == 'Nigeria' ? true : false} value="NG">Nigeria</option>
														<option selected={userData.nationality == 'NO' || userData.nationality == 'Norway' ? true : false} value="NO">Norway</option>
														<option selected={userData.nationality == 'OM' || userData.nationality == 'Oman' ? true : false} value="OM">Oman</option>
														<option selected={userData.nationality == '00' || userData.nationality == 'Others' ? true : false} value="00">Others</option>
														<option selected={userData.nationality == 'PK' || userData.nationality == 'Pakistan' ? true : false} value="PK">Pakistan</option>
														<option selected={userData.nationality == 'PW' || userData.nationality == 'Palau' ? true : false} value="PW">Palau</option>
														<option selected={userData.nationality == 'PS' || userData.nationality == 'Palestinian Territory' ? true : false} value="PS">Palestinian Territory</option>
														<option selected={userData.nationality == 'PA' || userData.nationality == 'Panama' ? true : false} value="PA">Panama</option>
														<option selected={userData.nationality == 'PG' || userData.nationality == 'Papua New Guinea' ? true : false} value="PG">Papua New Guinea</option>
														<option selected={userData.nationality == 'PY' || userData.nationality == 'Paraguay' ? true : false} value="PY">Paraguay</option>
														<option selected={userData.nationality == 'PE' || userData.nationality == 'Peru' ? true : false} value="PE">Peru</option>
														<option selected={userData.nationality == 'PH' || userData.nationality == 'Philippines' ? true : false} value="PH">Philippines</option>
														<option selected={userData.nationality == 'PN' || userData.nationality == 'Pitcairn Islands' ? true : false} value="PN">Pitcairn Islands</option>
														<option selected={userData.nationality == 'PL' || userData.nationality == 'Poland' ? true : false} value="PL">Poland</option>
														<option selected={userData.nationality == 'PT' || userData.nationality == 'Portugal' ? true : false} value="PT">Portugal</option>
														<option selected={userData.nationality == 'QA' || userData.nationality == 'Qatar' ? true : false} value="QA">Qatar</option>
														<option selected={userData.nationality == 'RE' || userData.nationality == 'Reunion' ? true : false} value="RE">Reunion</option>
														<option selected={userData.nationality == 'RO' || userData.nationality == 'Romania' ? true : false} value="RO">Romania</option>
														<option selected={userData.nationality == 'RU' || userData.nationality == 'Russia Federation' ? true : false} value="RU">Russia Federation</option>
														<option selected={userData.nationality == 'RW' || userData.nationality == 'Rwanda' ? true : false} value="RW">Rwanda</option>
														<option selected={userData.nationality == 'SH' || userData.nationality == 'Saint Helena' ? true : false} value="SH">Saint Helena</option>
														<option selected={userData.nationality == 'KN' || userData.nationality == 'Saint Kitts and Nevis' ? true : false} value="KN">Saint Kitts and Nevis</option>
														<option selected={userData.nationality == 'LC' || userData.nationality == 'Saint Lucia' ? true : false} value="LC">Saint Lucia</option>
														<option selected={userData.nationality == 'PM' || userData.nationality == 'Saint Pierre and Miquelon' ? true : false} value="PM">Saint Pierre and Miquelon</option>
														<option selected={userData.nationality == 'VC' || userData.nationality == 'Saint Vincent and the Grenadines' ? true : false} value="VC">Saint Vincent and the Grenadines</option>
														<option selected={userData.nationality == 'WS' || userData.nationality == 'Samoa' ? true : false} value="WS">Samoa</option>
														<option selected={userData.nationality == 'SM' || userData.nationality == 'San Marino' ? true : false} value="SM">San Marino</option>
														<option selected={userData.nationality == 'ST' || userData.nationality == 'Sao Tome and Principe' ? true : false} value="ST">Sao Tome and Principe</option>
														<option selected={userData.nationality == 'SA' || userData.nationality == 'Saudi Arabia' ? true : false} value="SA">Saudi Arabia</option>
														<option selected={userData.nationality == 'SN' || userData.nationality == 'Senegal' ? true : false} value="SN">Senegal</option>
														<option selected={userData.nationality == 'CS' || userData.nationality == 'Serbia and Montenegro' ? true : false} value="CS">Serbia and Montenegro</option>
														<option selected={userData.nationality == 'SC' || userData.nationality == 'Seychelles' ? true : false} value="SC">Seychelles</option>
														<option selected={userData.nationality == 'SL' || userData.nationality == 'Sierra Leone' ? true : false} value="SL">Sierra Leone</option>
														<option selected={userData.nationality == 'SK' || userData.nationality == 'Slovakia' ? true : false} value="SK">Slovakia</option>
														<option selected={userData.nationality == 'SI' || userData.nationality == 'Slovenia' ? true : false} value="SI">Slovenia</option>
														<option selected={userData.nationality == 'SB' || userData.nationality == 'Solomon Islands' ? true : false} value="SB">Solomon Islands</option>
														<option selected={userData.nationality == 'SO' || userData.nationality == 'Somalia' ? true : false} value="SO">Somalia</option>
														<option selected={userData.nationality == 'ZA' || userData.nationality == 'South Africa' ? true : false} value="ZA">South Africa</option>
														<option selected={userData.nationality == 'ES' || userData.nationality == 'Spain' ? true : false} value="ES">Spain</option>
														<option selected={userData.nationality == 'LK' || userData.nationality == 'Sri Lanka' ? true : false} value="LK">Sri Lanka</option>
														<option selected={userData.nationality == 'SH' || userData.nationality == 'St. Helena' ? true : false} value="SH">St. Helena</option>
														<option selected={userData.nationality == 'KN' || userData.nationality == 'St. Kitts and Nevis' ? true : false} value="KN">St. Kitts and Nevis</option>
														<option selected={userData.nationality == 'LC' || userData.nationality == 'St. Lucia' ? true : false} value="LC">St. Lucia</option>
														<option selected={userData.nationality == 'PM' || userData.nationality == 'St. Pierre and Miquelon' ? true : false} value="PM">St. Pierre and Miquelon</option>
														<option selected={userData.nationality == 'SD' || userData.nationality == 'Sudan' ? true : false} value="SD">Sudan</option>
														<option selected={userData.nationality == 'SR' || userData.nationality == 'Suriname' ? true : false} value="SR">Suriname</option>
														<option selected={userData.nationality == 'SZ' || userData.nationality == 'Swaziland' ? true : false} value="SZ">Swaziland</option>
														<option selected={userData.nationality == 'SE' || userData.nationality == 'Sweden' ? true : false} value="SE">Sweden</option>
														<option selected={userData.nationality == 'CH' || userData.nationality == 'Switzerland' ? true : false} value="CH">Switzerland</option>
														<option selected={userData.nationality == 'SY' || userData.nationality == 'Syria' ? true : false} value="SY">Syria</option>
														<option selected={userData.nationality == 'TW' || userData.nationality == 'Taiwan' ? true : false} value="TW">Taiwan</option>
														<option selected={userData.nationality == 'TJ' || userData.nationality == 'Tajikistan' ? true : false} value="TJ">Tajikistan</option>
														<option selected={userData.nationality == 'TZ' || userData.nationality == 'Tanzania' ? true : false} value="TZ">Tanzania</option>
														<option selected={userData.nationality == 'TH' || userData.nationality == 'Thailand' ? true : false} value="TH">Thailand</option>
														<option selected={userData.nationality == 'TL' || userData.nationality == 'Timor-Leste' ? true : false} value="TL">Timor-Leste</option>
														<option selected={userData.nationality == 'TG' || userData.nationality == 'Togo' ? true : false} value="TG">Togo</option>
														<option selected={userData.nationality == 'TO' || userData.nationality == 'Tonga' ? true : false} value="TO">Tonga</option>
														<option selected={userData.nationality == 'TT' || userData.nationality == 'Trinidad and Tobago' ? true : false} value="TT">Trinidad and Tobago</option>
														<option selected={userData.nationality == 'TN' || userData.nationality == 'Tunisia' ? true : false} value="TN">Tunisia</option>
														<option selected={userData.nationality == 'TR' || userData.nationality == 'Turkey' ? true : false} value="TR">Turkey</option>
														<option selected={userData.nationality == 'TM' || userData.nationality == 'Turkmenistan' ? true : false} value="TM">Turkmenistan</option>
														<option selected={userData.nationality == 'TC' || userData.nationality == 'Turks and Caicos Islands' ? true : false} value="TC">Turks and Caicos Islands</option>
														<option selected={userData.nationality == 'TV' || userData.nationality == 'Tuvalu' ? true : false} value="TV">Tuvalu</option>
														<option selected={userData.nationality == 'UG' || userData.nationality == 'Uganda' ? true : false} value="UG">Uganda</option>
														<option selected={userData.nationality == 'UA' || userData.nationality == 'Ukraine' ? true : false} value="UA">Ukraine</option>
														<option selected={userData.nationality == 'AE' || userData.nationality == 'United Arab Emirates' ? true : false} value="AE">United Arab Emirates</option>
														<option selected={userData.nationality == 'GB' || userData.nationality == 'United Kingdom' ? true : false} value="GB">United Kingdom</option>
														<option selected={userData.nationality == 'US' || userData.nationality == 'United States' ? true : false} value="US">United States</option>
														<option selected={userData.nationality == 'UY' || userData.nationality == 'Uruguay' ? true : false} value="UY">Uruguay</option>
														<option selected={userData.nationality == 'UZ' || userData.nationality == 'Uzbekistan' ? true : false} value="UZ">Uzbekistan</option>
														<option selected={userData.nationality == 'VU' || userData.nationality == 'Vanuatu' ? true : false} value="VU">Vanuatu</option>
														<option selected={userData.nationality == 'VE' || userData.nationality == 'Venezuela' ? true : false} value="VE">Venezuela</option>
														<option selected={userData.nationality == 'VN' || userData.nationality == 'Vietnam' ? true : false} value="VN">Vietnam</option>
														<option selected={userData.nationality == 'WF' || userData.nationality == 'Wallis and Futuna Islands' ? true : false} value="WF">Wallis and Futuna Islands</option>
														<option selected={userData.nationality == 'EH' || userData.nationality == 'Western Sahara' ? true : false} value="EH">Western Sahara</option>
														<option selected={userData.nationality == 'YE' || userData.nationality == 'Yemen' ? true : false} value="YE">Yemen</option>
														<option selected={userData.nationality == 'ZR' || userData.nationality == 'Zaire (former)' ? true : false} value="ZR">Zaire (former)</option>
														<option selected={userData.nationality == 'ZM' || userData.nationality == 'Zambia' ? true : false} value="ZM">Zambia</option>
														<option selected={userData.nationality == 'ZW' || userData.nationality == 'Zimbabwe' ? true : false} value="ZW">Zimbabwe</option>
														<option selected={userData.nationality == '00' || userData.nationality == 'Others' ? true : false} value="00">Others</option>
													</Form.Select>
												</td>
												<td width={'50%'}>
													<h6>Mobile<span className='requiredStar'> *</span></h6>
													<Form.Control key={`myAcc_mobile`} onChange={event => editUserDetails('mobile', event.target.value)} type="text" placeholder="XXX-XXXXXXX" defaultValue={userData.mobile} />
												</td>
											</tr>
											<td width={'50%'}>
												<h6>IC/Passport No.</h6>
												<Form.Select defaultValue={!showAccPassportFields ? "IC" : "PP"} key={`identity_type`} className="mb-4" onChange={() => setShowAccPassportFields(!showAccPassportFields)}>
													<option value="IC">Identity Card</option>
													<option value="PP">Passport</option>
												</Form.Select>
											</td>
											<tr>
												{
													!showAccPassportFields
														?
														<td>
															<h6>IC No.<span className='requiredStar'> *</span></h6>
															<Form.Control className='mb-4' key={`familyAndFriends_ic`} onChange={event => editUserDetails('ic', event.target.value)} type="text" defaultValue={userData.ic} placeholder="810426-01-7890" />
														</td>
														:
														<>
															<td>
																<h6>Passport No.<span className='requiredStar'> *</span></h6>
																<Form.Control className='mb-4' key={`familyAndFriends_passport`} onChange={event => editUserDetails('passport', event.target.value)} type="text" defaultValue={userData.passport} placeholder="Passport No." />
															</td>
															<td>
																<h6>Passport Expiry<span className='requiredStar'> *</span></h6>
																<DatePicker
																	onChange={(date) => {
																		editUserDetails('passportexpiry', changeDateFormatToYYYYMMDD(date))
																		setPPExpiry(date)
																	}}
																	selected={ppexp}
																	peekNextMonth
																	showMonthDropdown
																	showYearDropdown
																	minDate={new Date()}
																	dropdownMode="select"
																	placeholderText="Passport Expiry"
																	dateFormat="yyyy-MM-dd"
																	className='AccDetails_Date_Picker mb-4'
																/>
																{/* <Form.Control className='mb-4' key={`familyAndFriends_passport_exp`} onChange={event =>editUserDetails('passportexpiry',event.target.value)} type="text" defaultValue={userData.passportexpiry} placeholder="Passport Expiry"/> */}
															</td>
														</>
												}
											</tr>
											<tr>
												<td colSpan={2}>
													<div className='accDetails_footer'>
														<div>
															<a onClick={() => { setEditAccountDetails(false); window.location.reload() }}>Cancel</a>
														</div>
														<button type='button' className='save_button' onClick={() => saveAccInfo('accInfo')}>Save</button>
													</div>
												</td>
											</tr>
										</table>
									</Form>
								}
							</div>

							{!editPassword ?
								<div className='password mt-4' ref={PasswordRef}>
									<table width={'100%'}>
										<tr>
											<td width={'50%'}>
												<h6>Password</h6>
												<h5>***********</h5>
											</td>
											<td width={'50%'}>
												<button className='white_orange f-right' type='button' onClick={() => setEditPassword(true)}>Reset Password</button>
											</td>
										</tr>
									</table>
								</div>
								:
								<>
									<div className='mt-4'></div>
									<ContentTitle
										title='Reset Password'
										description='Change your password here'
									/>
									<div className='password'>
										<table width={'100%'}>
											<tr>
												<td width={'100%'}>
													<h6>Current Password<span className='requiredStar'> *</span></h6>
													<Form.Control key={`myAcc_password`} type="password" onChange={event => updateNewPassword('password', event.target.value)} />
													<h6 className='pt-4'>New Password<span className='requiredStar'> *</span></h6>
													<Form.Control key={`myAcc_new_password`} type="password" onChange={event => { updateNewPassword('new_Password', event.target.value); editUserDetails('Password', event.target.value) }} />
													<h6 className='pt-4'>Retype Password<span className='requiredStar'> *</span></h6>
													<Form.Control className='mb-4' key={`myAcc_retype_password`} type="password" onChange={event => updateNewPassword('retype_Password', event.target.value)} />
												</td>
											</tr>
											<tr>
												<td colSpan={2}>
													<div className='accDetails_footer'>
														<div>
															<a onClick={() => { setEditPassword(false); window.location.reload() }}>Cancel</a>
														</div>
														<button type='submit' className='save_button' onClick={() => { saveAccInfo('password'); }}>Update</button>
													</div>
												</td>
											</tr>
										</table>
									</div>
								</>
							}



							<div className='mt-4' ref={EmergencyRef} />
							{!editEmergency ?
								!checkEmergencyData() ?
									<>
										<ContentTitle
											title='Emergency Contact'
											description='Edit your emergency contact'
											button='Add'
											onClick={() => setEditEmergency(true)}
										/>

										<div className='emergency_contact'>
											<img src='images/ic_emergency_active.png' />
											<h4>Please add an emergency contact, we will call this contact in the event of an alarm.</h4>
										</div>
									</>
									:
									<>
										<ContentTitle
											title='Emergency Contact'
											description='Edit your emergency contact'
											edit='edit'
											onClick={() => setEditEmergency(true)}
										/>

										<div className='acc_details'>
											<table width={'100%'}>
												<tr>
													<td colSpan={2}>
														<h6>Full Name</h6>
														{userData.emergencyfullname}
													</td>
												</tr>
												<tr>
													<td>
														<h6>Relationship</h6>
														{userData.emergencyrelationship}
													</td>
													<td>
														<h6>Contact No</h6>
														{userData.emergencycontact}
													</td>
												</tr>
											</table>
										</div>
									</>

								:
								<>
									<ContentTitle
										title='Emergency Contact'
										description='Edit your emergency contact'
									/>

									<div className='edit_emergency_contact'>
										<table width={'100%'}>
											<tr>
												<td colSpan={2}>
													<h6>Full Name<span className='requiredStar'> *</span></h6>
													<Form.Control className='mb-4' key={`emergencyfullname`} type="text" onChange={event => editUserDetails('emergencyfullname', event.target.value)}
														defaultValue={userData.emergencyfullname !== null ? userData.emergencyfullname : null} />
												</td>
											</tr>
											<tr>
												<td>
													<h6>Relationship<span className='requiredStar'> *</span></h6>
													<Form.Select className='mb-4' key={`emergencyrelationship`} onChange={event => editUserDetails('emergencyrelationship', event.target.value)}>
														<option value=''>Choose Relationship</option>
														<option selected={userData.emergencyrelationship == 'Mother' ? true : false} value='Mother'>Mother</option>
														<option selected={userData.emergencyrelationship == 'Father' ? true : false} value='Father'>Father</option>
														<option selected={userData.emergencyrelationship == 'Daughter' ? true : false} value='Daughter'>Daughter</option>
														<option selected={userData.emergencyrelationship == 'Son' ? true : false} value='Son'>Son</option>
														<option selected={userData.emergencyrelationship == 'Sister' ? true : false} value='Sister'>Sister</option>
														<option selected={userData.emergencyrelationship == 'Brother' ? true : false} value='Brother'>Brother</option>
														<option selected={userData.emergencyrelationship == 'Aunt' ? true : false} value='Aunt'>Aunt</option>
														<option selected={userData.emergencyrelationship == 'Uncle' ? true : false} value='Uncle'>Uncle</option>
														<option selected={userData.emergencyrelationship == 'Niece' ? true : false} value='Niece'>Niece</option>
														<option selected={userData.emergencyrelationship == 'Nephew' ? true : false} value='Nephew'>Nephew</option>
														<option selected={userData.emergencyrelationship == 'Friend' ? true : false} value='Friend'>Friend</option>
													</Form.Select>
												</td>
												<td>
													<h6>Contact No<span className='requiredStar'> *</span></h6>
													<Form.Control className='mb-4' key={`emergencycontact`} type="text" onChange={event => editUserDetails('emergencycontact', event.target.value)}
														defaultValue={userData.emergencycontact !== null ? userData.emergencycontact : null} />
												</td>
											</tr>
											<tr>
												<td colSpan={2}>
													<div className='accDetails_footer'>
														<div>
															<a onClick={() => { setEditEmergency(false); window.location.reload() }}>Cancel</a>
														</div>
														<button type='submit' className='save_button' onClick={() => saveAccInfo('emergency')}>Save</button>
													</div>
												</td>
											</tr>
										</table>
									</div>
								</>
							}




							<div className='mt-4' ref={FnFRef} />
							{!AddNewFamilyAndFriends ?
								!checkFamilyAndFriends() ?
									//if no Friends and Family data, display info
									<>
										<ContentTitle
											title='Family & Friends'
											description='Save your favourite travel partner(s) information'
											button='Add'
											onClick={() => setAddNewFamilyAndFriends(true)}
										/>
										<div className='family_and_friends'>
											<img src='images/ic_family_active.png' />
											<h4>Save your favourite travel partner(s) details (maximum 5) to make your bookings easier! You can quickly add your family members in a single click without the inconvenience of manually adding details and risking typos and other mistakes.</h4>
										</div>
									</>
									:
									!EditFnFMode ?
										<>
											{FnFdata.length < 5 ?
												//Friends and Family < 5 can add more
												<ContentTitle
													title='Family & Friends'
													description='Save your favourite travel partner(s) information'
													button='Add'
													onClick={() => setAddNewFamilyAndFriends(true)}
												/>
												:
												//Friends and Family > 5 will not able to add more
												<ContentTitle
													title='Family & Friends'
													description='Save your favourite travel partner(s) information'
												/>
											}
											{FnFdata.map(({ full_name, nationality, ic, passport }, index) => (
												<div className='family_and_friends'>
													<table width={'100%'}>
														<tr>
															<td width={'30%'}>
																<h6>Full Name</h6>
															</td>
															<td width={'30%'}>
																<h6>Nationality</h6>
															</td>
															<td width={'30%'}>
																<h6>IC/ Passport No.</h6>
															</td>
															<td width={'10%'} rowSpan={2}>
																<i class="fa-solid fa-pencil yellow clickable" onClick={() => editFnFdata(index)}></i>
															</td>
														</tr>
														<tr>
															<td>
																{FnFdata[index].full_name}
															</td>
															<td>
																{getNationalityFullName(FnFdata[index].nationality)}
															</td>
															<td>
																{FnFdata[index].ic != '' ? FnFdata[index].ic : FnFdata[index].passport}
															</td>
														</tr>
													</table>
												</div>
											))}
										</>
										:
										//edit family and friends
										<>
											<ContentTitle
												title='Family & Friends'
												description='Save your favourite travel partner(s) information'
											/>
											<div className='family_and_friends'>
												<Form>
													<table width={'100%'}>
														<tr>
															<td width={'50%'}>
																<h6>Full Name<span className='requiredStar'> *</span></h6>
																<Form.Control className='mb-4' key={`familyAndFriends_full_name`} onChange={event => editFamilyAndFriendsData('full_name', event.target.value)} type="text" defaultValue={FnFeditData.full_name} placeholder="Full Name" />
															</td>
															<td width={'50%'}>
																<h6>Ticket Type<span className='requiredStar'> *</span></h6>
																<Form.Select key={`familyAndFriends_ticketType`} className="mb-4" onChange={event => editFamilyAndFriendsData('ticket_type', event.target.value)}>
																	<option selected={FnFeditData.ticket_type == 'A' ? true : false} value="A">Adult</option>
																	{/* <option selected={FnFeditData.ticket_type == 'S' ? true : false} value="S">Senior Citizen</option> */}
																	<option selected={FnFeditData.ticket_type == 'C' ? true : false} value="C">Child</option>
																	{/* <option selected={FnFeditData.ticket_type == 'O' ? true : false} value="O">OKU</option> */}
																</Form.Select>
															</td>
														</tr>
														<tr>
															<td width={'50%'}>
																<h6>IC/Passport No.</h6>
																<Form.Select key={`identity_type`} className="mb-4" onChange={() => setShowPassportFields(!showPassportFields)}>
																	<option selected={!showPassportFields ? true : false} value="IC">Identity Card</option>
																	<option selected={showPassportFields ? true : false} value="PP">Passport</option>
																</Form.Select>
															</td>
															<td width={'50%'}>
																<h6>Nationality<span className='requiredStar'> *</span></h6>
																<Form.Select key={`familyAndFriends_nationality`} defaultValue={FnFeditData.nationality} className="mb-4" onChange={event => editFamilyAndFriendsData('nationality', event.target.value)}>
																	<option value="">Nationality</option>

																	<option value="MY">Malaysia</option>
																	<option value="SG">Singapore</option>

																	<option value="AF">Afghanistan</option>
																	<option value="AL">Albania</option>
																	<option value="DZ">Algeria</option>
																	<option value="AD">Andorra</option>
																	<option value="AO">Angola</option>
																	<option value="AI">Anguilla</option>
																	<option value="AG">Antigua and Barbuda</option>
																	<option value="AR">Argentina</option>
																	<option value="AM">Armenia</option>
																	<option value="AW">Aruba</option>
																	<option value="AU">Australia</option>
																	<option value="AT">Austria</option>
																	<option value="AZ">Azerbaijan</option>
																	<option value="BS">Bahamas</option>
																	<option value="BH">Bahrain</option>
																	<option value="BD">Bangladesh</option>
																	<option value="BB">Barbados</option>
																	<option value="BY">Belarus</option>
																	<option value="BE">Belgium</option>
																	<option value="BZ">Belize</option>
																	<option value="BJ">Benin</option>
																	<option value="BM">Bermuda</option>
																	<option value="BT">Bhutan</option>
																	<option value="BO">Bolivia</option>
																	<option value="BA">Bosnia and Herzegovina</option>
																	<option value="BW">Botswana</option>
																	<option value="BR">Brazil</option>
																	<option value="VG">British Virgin Islands</option>
																	<option value="BN">Brunei</option>
																	<option value="BG">Bulgaria</option>
																	<option value="BF">Burkina Faso</option>
																	<option value="BI">Burundi</option>
																	<option value="KH">Cambodia</option>
																	<option value="CM">Cameroon</option>
																	<option value="CA">Canada</option>
																	<option value="CV">Cape Verde</option>
																	<option value="KY">Cayman Islands</option>
																	<option value="CF">Central African Republic</option>
																	<option value="TD">Chad</option>
																	<option value="CL">Chile</option>
																	<option value="CN">China</option>
																	<option value="CO">Colombia</option>
																	<option value="KM">Comoros</option>
																	<option value="CD">Congo, Democratic Republic of the</option>
																	<option value="CG">Congo, Republic of</option>
																	<option value="CR">Costa Rica</option>
																	<option value="CI">Cote D'Ivoire (Ivory Coast)</option>
																	<option value="HR">Croatia</option>
																	<option value="CU">Cuba</option>
																	<option value="CY">Cyprus</option>
																	<option value="CZ">Czech Republic</option>
																	<option value="DK">Denmark</option>
																	<option value="DJ">Djibouti</option>
																	<option value="DM">Dominica</option>
																	<option value="DO">Dominican Republic</option>
																	<option value="EC">Ecuador</option>
																	<option value="EG">Egypt</option>
																	<option value="SV">El Salvador</option>
																	<option value="GQ">Equatorial Guinea</option>
																	<option value="ER">Eritrea</option>
																	<option value="EE">Estonia</option>
																	<option value="ET">Ethiopia</option>
																	<option value="FK">Falkland Islands</option>
																	<option value="FJ">Fiji</option>
																	<option value="FI">Finland</option>
																	<option value="FR">France</option>
																	<option value="GF">French Guiana</option>
																	<option value="PF">French Polynesia</option>
																	<option value="GA">Gabon</option>
																	<option value="GM">Gambia</option>
																	<option value="GE">Georgia</option>
																	<option value="DE">Germany</option>
																	<option value="GH">Ghana</option>
																	<option value="GI">Gibraltar</option>
																	<option value="GR">Greece</option>
																	<option value="GD">Grenada</option>
																	<option value="GP">Guadeloupe</option>
																	<option value="GT">Guatemala</option>
																	<option value="GN">Guinea</option>
																	<option value="GW">Guinea-Bissau</option>
																	<option value="GY">Guyana</option>
																	<option value="HT">Haiti</option>
																	<option value="HN">Honduras</option>
																	<option value="HK">Hong Kong (China)</option>
																	<option value="HU">Hungary</option>
																	<option value="IS">Iceland</option>
																	<option value="IN">India</option>
																	<option value="ID">Indonesia</option>
																	<option value="IR">Iran</option>
																	<option value="IQ">Iraq</option>
																	<option value="IE">Ireland</option>
																	<option value="IL">Israel</option>
																	<option value="IT">Italy</option>
																	<option value="JM">Jamaica</option>
																	<option value="JP">Japan</option>
																	<option value="JO">Jordan</option>
																	<option value="KZ">Kazakhstan</option>
																	<option value="KE">Kenya</option>
																	<option value="KP">Korea (North)</option>
																	<option value="KR">Korea (South)</option>
																	<option value="KW">Kuwait</option>
																	<option value="KG">Kyrgyzstan</option>
																	<option value="LA">Laos</option>
																	<option value="LV">Latvia</option>
																	<option value="LB">Lebanon</option>
																	<option value="LS">Lesotho</option>
																	<option value="LR">Liberia</option>
																	<option value="LY">Libyan Arab Jamahiriya</option>
																	<option value="LI">Liechtenstein</option>
																	<option value="LT">Lithuania</option>
																	<option value="LU">Luxembourg</option>
																	<option value="MO">Macao</option>
																	<option value="MK">Macedonia</option>
																	<option value="MG">Madagascar</option>
																	<option value="MW">Malawi</option>
																	<option value="MV">Maldives</option>
																	<option value="ML">Mali</option>
																	<option value="MT">Malta</option>
																	<option value="MH">Marshall Islands</option>
																	<option value="MQ">Martinique</option>
																	<option value="MR">Mauritania</option>
																	<option value="MU">Mauritius</option>
																	<option value="YT">Mayotte</option>
																	<option value="MX">Mexico</option>
																	<option value="FM">Micronesia, Federated States of</option>
																	<option value="MD">Moldova</option>
																	<option value="MC">Monaco</option>
																	<option value="MN">Mongolia</option>
																	<option value="MS">Montserrat</option>
																	<option value="MA">Morocco</option>
																	<option value="MZ">Mozambique</option>
																	<option value="MM">Myanmar</option>
																	<option value="NA">Namibia</option>
																	<option value="NR">Nauru</option>
																	<option value="NP">Nepal</option>
																	<option value="NL">Netherlands</option>
																	<option value="AN">Netherlands Antilles</option>
																	<option value="NC">New Caledonia</option>
																	<option value="NZ">New Zealand</option>
																	<option value="NI">Nicaragua</option>
																	<option value="NE">Niger</option>
																	<option value="NG">Nigeria</option>
																	<option value="NO">Norway</option>
																	<option value="OM">Oman</option>
																	<option value="00">Others</option>
																	<option value="PK">Pakistan</option>
																	<option value="PW">Palau</option>
																	<option value="PS">Palestinian Territory</option>
																	<option value="PA">Panama</option>
																	<option value="PG">Papua New Guinea</option>
																	<option value="PY">Paraguay</option>
																	<option value="PE">Peru</option>
																	<option value="PH">Philippines</option>
																	<option value="PN">Pitcairn Islands</option>
																	<option value="PL">Poland</option>
																	<option value="PT">Portugal</option>
																	<option value="QA">Qatar</option>
																	<option value="RE">Reunion</option>
																	<option value="RO">Romania</option>
																	<option value="RU">Russia Federation</option>
																	<option value="RW">Rwanda</option>
																	<option value="SH">Saint Helena</option>
																	<option value="KN">Saint Kitts and Nevis</option>
																	<option value="LC">Saint Lucia</option>
																	<option value="PM">Saint Pierre and Miquelon</option>
																	<option value="VC">Saint Vincent and the Grenadines</option>
																	<option value="WS">Samoa</option>
																	<option value="SM">San Marino</option>
																	<option value="ST">Sao Tome and Principe</option>
																	<option value="SA">Saudi Arabia</option>
																	<option value="SN">Senegal</option>
																	<option value="CS">Serbia and Montenegro</option>
																	<option value="SC">Seychelles</option>
																	<option value="SL">Sierra Leone</option>
																	<option value="SK">Slovakia</option>
																	<option value="SI">Slovenia</option>
																	<option value="SB">Solomon Islands</option>
																	<option value="SO">Somalia</option>
																	<option value="ZA">South Africa</option>
																	<option value="ES">Spain</option>
																	<option value="LK">Sri Lanka</option>
																	<option value="SH">St. Helena</option>
																	<option value="KN">St. Kitts and Nevis</option>
																	<option value="LC">St. Lucia</option>
																	<option value="PM">St. Pierre and Miquelon</option>
																	<option value="SD">Sudan</option>
																	<option value="SR">Suriname</option>
																	<option value="SZ">Swaziland</option>
																	<option value="SE">Sweden</option>
																	<option value="CH">Switzerland</option>
																	<option value="SY">Syria</option>
																	<option value="TW">Taiwan</option>
																	<option value="TJ">Tajikistan</option>
																	<option value="TZ">Tanzania</option>
																	<option value="TH">Thailand</option>
																	<option value="TL">Timor-Leste</option>
																	<option value="TG">Togo</option>
																	<option value="TO">Tonga</option>
																	<option value="TT">Trinidad and Tobago</option>
																	<option value="TN">Tunisia</option>
																	<option value="TR">Turkey</option>
																	<option value="TM">Turkmenistan</option>
																	<option value="TC">Turks and Caicos Islands</option>
																	<option value="TV">Tuvalu</option>
																	<option value="UG">Uganda</option>
																	<option value="UA">Ukraine</option>
																	<option value="AE">United Arab Emirates</option>
																	<option value="GB">United Kingdom</option>
																	<option value="US">United States</option>
																	<option value="UY">Uruguay</option>
																	<option value="UZ">Uzbekistan</option>
																	<option value="VU">Vanuatu</option>
																	<option value="VE">Venezuela</option>
																	<option value="VN">Vietnam</option>
																	<option value="WF">Wallis and Futuna Islands</option>
																	<option value="EH">Western Sahara</option>
																	<option value="YE">Yemen</option>
																	<option value="ZR">Zaire (former)</option>
																	<option value="ZM">Zambia</option>
																	<option value="ZW">Zimbabwe</option>
																	<option value="00">Others</option>
																</Form.Select>
															</td>
														</tr>
														<tr>
															{
																!showPassportFields
																	?
																	<td>
																		<h6>IC No.<span className='requiredStar'> *</span></h6>
																		<Form.Control className='mb-4' key={`familyAndFriends_ic`} onChange={event => editFamilyAndFriendsData('ic', event.target.value)} type="text" defaultValue={FnFeditData.ic} placeholder="810426-01-7890" />
																	</td>
																	:
																	<>
																		<td>
																			<h6>Passport No.<span className='requiredStar'> *</span></h6>
																			<Form.Control className='mb-4' key={`familyAndFriends_passport`} onChange={event => editFamilyAndFriendsData('passport', event.target.value)} type="text" defaultValue={FnFeditData.passport} placeholder="Passport No." />
																		</td>
																		<td>
																			<h6>Passport Expiry<span className='requiredStar'> *</span></h6>
																			<DatePicker
																				onChange={(date) => {
																					editFamilyAndFriendsData('passport_expiry', changeDateFormatToYYYYMMDD(date))
																				}}
																				selected={FnFeditData.passport_expiry != '' ? new Date(FnFeditData.passport_expiry) : null}
																				peekNextMonth
																				showMonthDropdown
																				showYearDropdown
																				minDate={new Date()}
																				dropdownMode="select"
																				placeholderText="Passport Expiry"
																				dateFormat="yyyy-MM-dd"
																				className='AccDetails_Date_Picker mb-4'
																			/>
																			{/* <Form.Control className='mb-4' key={`familyAndFriends_passport_exp`} onChange={event =>editFamilyAndFriendsData('passport_expiry',event.target.value)} type="text" defaultValue={FnFeditData.passport_expiry} placeholder="Passport Expiry"/> */}
																		</td>
																	</>
															}
														</tr>
														<tr>
															<td colSpan={2}>
																<div className='accDetails_footer'>
																	<div>
																		<a onClick={() => { setEditFnFMode(false); window.location.reload() }}>Cancel</a>
																	</div>
																	<button type='button' className='save_button' onClick={() => updateEditFnFData(showPassportFields)}>Update</button>
																</div>
															</td>
														</tr>
													</table>
												</Form>
											</div>
										</>
								:
								//add new Family & Friends
								<>
									<ContentTitle
										title='Family & Friends'
										description='Save your favourite travel partner(s) information'
									/>
									<div className='family_and_friends'>
										<Form>
											<table width={'100%'}>
												<tr>
													<td width={'50%'}>
														<h6>Full Name<span className='requiredStar'> *</span></h6>
														<Form.Control className='mb-4' key={`familyAndFriends_full_name`} onChange={event => addNewFamilyAndFriends('full_name', event.target.value)} type="text" placeholder="Full Name" />
													</td>
													<td width={'50%'}>
														<h6>Ticket Type<span className='requiredStar'> *</span></h6>
														<Form.Select key={`familyAndFriends_ticketType`} className="mb-4" onChange={event => addNewFamilyAndFriends('ticket_type', event.target.value)}>
															<option selected value="">Choose Ticket Type</option>
															<option value="A">Adult</option>
															{/* <option value="S">Senior Citizen</option> */}
															<option value="C">Child</option>
															{/* <option value="O">OKU</option> */}
														</Form.Select>
													</td>
												</tr>
												<tr>
													<td width={'50%'}>
														<h6>IC/Passport No.</h6>
														<Form.Select key={`identity_type`} className="mb-4" onChange={() => setShowPassportFields(!showPassportFields)}>
															<option selected value="IC">Identity Card</option>
															<option value="PP">Passport</option>
														</Form.Select>
													</td>
													<td width={'50%'}>
														<h6>Nationality<span className='requiredStar'> *</span></h6>
														<Form.Select key={`familyAndFriends_nationality`} className="mb-4" onChange={event => addNewFamilyAndFriends('nationality', event.target.value)}>
															<option value="">Nationality</option>

															<option value="MY">Malaysia</option>
															<option value="SG">Singapore</option>

															<option value="AF">Afghanistan</option>
															<option value="AL">Albania</option>
															<option value="DZ">Algeria</option>
															<option value="AD">Andorra</option>
															<option value="AO">Angola</option>
															<option value="AI">Anguilla</option>
															<option value="AG">Antigua and Barbuda</option>
															<option value="AR">Argentina</option>
															<option value="AM">Armenia</option>
															<option value="AW">Aruba</option>
															<option value="AU">Australia</option>
															<option value="AT">Austria</option>
															<option value="AZ">Azerbaijan</option>
															<option value="BS">Bahamas</option>
															<option value="BH">Bahrain</option>
															<option value="BD">Bangladesh</option>
															<option value="BB">Barbados</option>
															<option value="BY">Belarus</option>
															<option value="BE">Belgium</option>
															<option value="BZ">Belize</option>
															<option value="BJ">Benin</option>
															<option value="BM">Bermuda</option>
															<option value="BT">Bhutan</option>
															<option value="BO">Bolivia</option>
															<option value="BA">Bosnia and Herzegovina</option>
															<option value="BW">Botswana</option>
															<option value="BR">Brazil</option>
															<option value="VG">British Virgin Islands</option>
															<option value="BN">Brunei</option>
															<option value="BG">Bulgaria</option>
															<option value="BF">Burkina Faso</option>
															<option value="BI">Burundi</option>
															<option value="KH">Cambodia</option>
															<option value="CM">Cameroon</option>
															<option value="CA">Canada</option>
															<option value="CV">Cape Verde</option>
															<option value="KY">Cayman Islands</option>
															<option value="CF">Central African Republic</option>
															<option value="TD">Chad</option>
															<option value="CL">Chile</option>
															<option value="CN">China</option>
															<option value="CO">Colombia</option>
															<option value="KM">Comoros</option>
															<option value="CD">Congo, Democratic Republic of the</option>
															<option value="CG">Congo, Republic of</option>
															<option value="CR">Costa Rica</option>
															<option value="CI">Cote D'Ivoire (Ivory Coast)</option>
															<option value="HR">Croatia</option>
															<option value="CU">Cuba</option>
															<option value="CY">Cyprus</option>
															<option value="CZ">Czech Republic</option>
															<option value="DK">Denmark</option>
															<option value="DJ">Djibouti</option>
															<option value="DM">Dominica</option>
															<option value="DO">Dominican Republic</option>
															<option value="EC">Ecuador</option>
															<option value="EG">Egypt</option>
															<option value="SV">El Salvador</option>
															<option value="GQ">Equatorial Guinea</option>
															<option value="ER">Eritrea</option>
															<option value="EE">Estonia</option>
															<option value="ET">Ethiopia</option>
															<option value="FK">Falkland Islands</option>
															<option value="FJ">Fiji</option>
															<option value="FI">Finland</option>
															<option value="FR">France</option>
															<option value="GF">French Guiana</option>
															<option value="PF">French Polynesia</option>
															<option value="GA">Gabon</option>
															<option value="GM">Gambia</option>
															<option value="GE">Georgia</option>
															<option value="DE">Germany</option>
															<option value="GH">Ghana</option>
															<option value="GI">Gibraltar</option>
															<option value="GR">Greece</option>
															<option value="GD">Grenada</option>
															<option value="GP">Guadeloupe</option>
															<option value="GT">Guatemala</option>
															<option value="GN">Guinea</option>
															<option value="GW">Guinea-Bissau</option>
															<option value="GY">Guyana</option>
															<option value="HT">Haiti</option>
															<option value="HN">Honduras</option>
															<option value="HK">Hong Kong (China)</option>
															<option value="HU">Hungary</option>
															<option value="IS">Iceland</option>
															<option value="IN">India</option>
															<option value="ID">Indonesia</option>
															<option value="IR">Iran</option>
															<option value="IQ">Iraq</option>
															<option value="IE">Ireland</option>
															<option value="IL">Israel</option>
															<option value="IT">Italy</option>
															<option value="JM">Jamaica</option>
															<option value="JP">Japan</option>
															<option value="JO">Jordan</option>
															<option value="KZ">Kazakhstan</option>
															<option value="KE">Kenya</option>
															<option value="KP">Korea (North)</option>
															<option value="KR">Korea (South)</option>
															<option value="KW">Kuwait</option>
															<option value="KG">Kyrgyzstan</option>
															<option value="LA">Laos</option>
															<option value="LV">Latvia</option>
															<option value="LB">Lebanon</option>
															<option value="LS">Lesotho</option>
															<option value="LR">Liberia</option>
															<option value="LY">Libyan Arab Jamahiriya</option>
															<option value="LI">Liechtenstein</option>
															<option value="LT">Lithuania</option>
															<option value="LU">Luxembourg</option>
															<option value="MO">Macao</option>
															<option value="MK">Macedonia</option>
															<option value="MG">Madagascar</option>
															<option value="MW">Malawi</option>
															<option value="MV">Maldives</option>
															<option value="ML">Mali</option>
															<option value="MT">Malta</option>
															<option value="MH">Marshall Islands</option>
															<option value="MQ">Martinique</option>
															<option value="MR">Mauritania</option>
															<option value="MU">Mauritius</option>
															<option value="YT">Mayotte</option>
															<option value="MX">Mexico</option>
															<option value="FM">Micronesia, Federated States of</option>
															<option value="MD">Moldova</option>
															<option value="MC">Monaco</option>
															<option value="MN">Mongolia</option>
															<option value="MS">Montserrat</option>
															<option value="MA">Morocco</option>
															<option value="MZ">Mozambique</option>
															<option value="MM">Myanmar</option>
															<option value="NA">Namibia</option>
															<option value="NR">Nauru</option>
															<option value="NP">Nepal</option>
															<option value="NL">Netherlands</option>
															<option value="AN">Netherlands Antilles</option>
															<option value="NC">New Caledonia</option>
															<option value="NZ">New Zealand</option>
															<option value="NI">Nicaragua</option>
															<option value="NE">Niger</option>
															<option value="NG">Nigeria</option>
															<option value="NO">Norway</option>
															<option value="OM">Oman</option>
															<option value="00">Others</option>
															<option value="PK">Pakistan</option>
															<option value="PW">Palau</option>
															<option value="PS">Palestinian Territory</option>
															<option value="PA">Panama</option>
															<option value="PG">Papua New Guinea</option>
															<option value="PY">Paraguay</option>
															<option value="PE">Peru</option>
															<option value="PH">Philippines</option>
															<option value="PN">Pitcairn Islands</option>
															<option value="PL">Poland</option>
															<option value="PT">Portugal</option>
															<option value="QA">Qatar</option>
															<option value="RE">Reunion</option>
															<option value="RO">Romania</option>
															<option value="RU">Russia Federation</option>
															<option value="RW">Rwanda</option>
															<option value="SH">Saint Helena</option>
															<option value="KN">Saint Kitts and Nevis</option>
															<option value="LC">Saint Lucia</option>
															<option value="PM">Saint Pierre and Miquelon</option>
															<option value="VC">Saint Vincent and the Grenadines</option>
															<option value="WS">Samoa</option>
															<option value="SM">San Marino</option>
															<option value="ST">Sao Tome and Principe</option>
															<option value="SA">Saudi Arabia</option>
															<option value="SN">Senegal</option>
															<option value="CS">Serbia and Montenegro</option>
															<option value="SC">Seychelles</option>
															<option value="SL">Sierra Leone</option>
															<option value="SK">Slovakia</option>
															<option value="SI">Slovenia</option>
															<option value="SB">Solomon Islands</option>
															<option value="SO">Somalia</option>
															<option value="ZA">South Africa</option>
															<option value="ES">Spain</option>
															<option value="LK">Sri Lanka</option>
															<option value="SH">St. Helena</option>
															<option value="KN">St. Kitts and Nevis</option>
															<option value="LC">St. Lucia</option>
															<option value="PM">St. Pierre and Miquelon</option>
															<option value="SD">Sudan</option>
															<option value="SR">Suriname</option>
															<option value="SZ">Swaziland</option>
															<option value="SE">Sweden</option>
															<option value="CH">Switzerland</option>
															<option value="SY">Syria</option>
															<option value="TW">Taiwan</option>
															<option value="TJ">Tajikistan</option>
															<option value="TZ">Tanzania</option>
															<option value="TH">Thailand</option>
															<option value="TL">Timor-Leste</option>
															<option value="TG">Togo</option>
															<option value="TO">Tonga</option>
															<option value="TT">Trinidad and Tobago</option>
															<option value="TN">Tunisia</option>
															<option value="TR">Turkey</option>
															<option value="TM">Turkmenistan</option>
															<option value="TC">Turks and Caicos Islands</option>
															<option value="TV">Tuvalu</option>
															<option value="UG">Uganda</option>
															<option value="UA">Ukraine</option>
															<option value="AE">United Arab Emirates</option>
															<option value="GB">United Kingdom</option>
															<option value="US">United States</option>
															<option value="UY">Uruguay</option>
															<option value="UZ">Uzbekistan</option>
															<option value="VU">Vanuatu</option>
															<option value="VE">Venezuela</option>
															<option value="VN">Vietnam</option>
															<option value="WF">Wallis and Futuna Islands</option>
															<option value="EH">Western Sahara</option>
															<option value="YE">Yemen</option>
															<option value="ZR">Zaire (former)</option>
															<option value="ZM">Zambia</option>
															<option value="ZW">Zimbabwe</option>
															<option value="00">Others</option>
														</Form.Select>
													</td>
												</tr>
												<tr>
													{
														!showPassportFields
															?
															<td>
																<h6>IC No.<span className='requiredStar'> *</span></h6>
																<Form.Control className='mb-4' key={`familyAndFriends_ic`} onChange={event => addNewFamilyAndFriends('ic', event.target.value)} type="text" placeholder="810426-01-7890" />
															</td>
															:
															<>
																<td>
																	<h6>Passport No.<span className='requiredStar'> *</span></h6>
																	<Form.Control className='mb-4' key={`familyAndFriends_passport`} onChange={event => addNewFamilyAndFriends('passport', event.target.value)} type="text" placeholder="Passport No." />
																</td>
																<td>
																	<h6>Passport Expiry<span className='requiredStar'> *</span></h6>
																	<DatePicker
																		onChange={(date) => {
																			addNewFamilyAndFriends('passport_expiry', changeDateFormatToYYYYMMDD(date))
																			setFnFPPExpiry(date)
																		}}
																		selected={FnFPPExp}
																		peekNextMonth
																		showMonthDropdown
																		showYearDropdown
																		minDate={new Date()}
																		dropdownMode="select"
																		placeholderText="Passport Expiry"
																		dateFormat="yyyy-MM-dd"
																		className='AccDetails_Date_Picker mb-4'
																	/>
																	{/* <Form.Control className='mb-4' key={`familyAndFriends_passport_exp`} onChange={event =>addNewFamilyAndFriends('passport_expiry',event.target.value)} type="text" placeholder="Passport Expiry"/> */}
																</td>
															</>
													}
												</tr>
												<tr>
													<td colSpan={2}>
														<div className='accDetails_footer'>
															<div>
																<a onClick={() => { setAddNewFamilyAndFriends(false); window.location.reload() }}>Cancel</a>
															</div>
															<button type='button' className='save_button' onClick={() => { updateNewFamilyAndFriends(showPassportFields); }}>Add New</button>
														</div>
													</td>
												</tr>
											</table>
										</Form>
									</div>
								</>
							}
							<div className='mt-4' ref={LoyaltyRef} />
							<ContentTitle
								title='Loyalty Program'
								description='Your loyalty points transaction'
							/>
							<div className='loyalty_program'>
								<Row>
									<Col>
										<div className='text-left total-points'>
											<h6>Total Points</h6>
											<p>{loyaltyPoints} Pts</p>
										</div>
									</Col>
								</Row>
								<Row>
									<Col>
										<div className='text-left recent-transaction'>
											<label>RECENT TRANSACTIONS</label>
										</div>
									</Col>
								</Row>
								<table width={'100%'}>
									{/* <tr>
                                <td width={'50%'} className='text-left'>
                                    <label>Membership number</label>
                                    <p>123456789</p>
                                </td>
                                <td colSpan={2} width={'50%'} className='text-left ps-5'>
                                    <label>Total Points</label>
                                    <p>321 Tcr</p>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={3} className='text-left'>RECENT TRANSACTIONS</td>
                            </tr> */}
									<tr>
										<td width={'30%'}><h6>Date</h6></td>
										<td width={'40%'}><h6>Reference ID</h6></td>
										<td width={'30%'}><h6>Transaction</h6></td>
									</tr>
									{userLoyaltyData != null && userLoyaltyData != {} ?
										!loyaltyShowAll ?
											//maximum show 5 transaction
											<>
												{userLoyaltyData && userLoyaltyData.length <= 5 ?
													//if transaction history < 5
													(
														Array.from({ length: userLoyaltyData.length }, (_, i) =>
															<tr>
																<td><h5>{userLoyaltyData[i].date}</h5></td>
																<td><h5>{(userLoyaltyData[i].reference)}</h5></td>
																<td><h5 className={checkTransactionClassName(userLoyaltyData[i].mode)}>{userLoyaltyData[i].point}</h5></td>
															</tr>
														)
													)
													:
													//if transaction history > 5, show 5 only
													(
														Array.from({ length: 5 }, (_, i) =>
															<tr>
																<td><h5>{userLoyaltyData[i].date}</h5></td>
																<td><h5>{(userLoyaltyData[i].reference)}</h5></td>
																<td><h5 className={checkTransactionClassName(userLoyaltyData[i].mode)}>{userLoyaltyData[i].point}</h5></td>
															</tr>
														)
													)
												}
												<tr className='loyalty_button_border'>
													<td colSpan={3} >
														<button className='loyalty_button mt-3' type='button' onClick={() => splitLoyaltyIntoPaginationAndShowAll(true)}>Show All</button>
													</td>
												</tr>
											</>
											:
											//if clicked on Show All button will display all transaction history

											<>
												{
													userLoyaltyData.length <= itemsPerPage ?
														<>
															{userLoyaltyData.map(({ date, mode, point, reference, Transaction }, index) => (
																<tr>
																	<td><h5>{date}</h5></td>
																	<td><h5>{(reference)}</h5></td>
																	<td><h5 className={checkTransactionClassName(mode)}>{point}</h5></td>
																</tr>
															))}
															<tr className='loyalty_button_border'>
																<td colSpan={3} >
																	<button className='loyalty_button mt-3' type='button' onClick={() => setLoyaltyShowAll(false)}>Hide</button>
																</td>
															</tr>
														</>
														:
														<>
															{loyaltyDataPagination && loyaltyDataPagination.map(({ date, mode, point, reference, Transaction }, index) => (
																<tr>
																	<td><h5>{date}</h5></td>
																	<td><h5>{(reference)}</h5></td>
																	<td><h5 className={checkTransactionClassName(mode)}>{point}</h5></td>
																</tr>
															))}
															<tr className='loyalty_pagination'>
																<td colSpan={3} >
																	<LoyaltyPagination
																		currentPage={currentPage}
																		itemsPerPage={itemsPerPage}
																		totalItems={userLoyaltyData.length}
																		// onPageChange={handlePageChange}
																		onNextPage={handleNextPage}
																		onPreviousPage={handlePreviousPage}
																	/>
																</td>
															</tr>
															<tr className='loyalty_button_border'>
																<td colSpan={3} >
																	<button className='loyalty_button mt-3' type='button' onClick={() => setLoyaltyShowAll(false)}>Hide</button>
																</td>
															</tr>
														</>
												}
											</>

										: ""
									}

								</table>
							</div>





							<div className='mt-4' ref={VoucherRef} />

							<>
								<ContentTitle
									title='Vouchers'
									description='Your available vouchers'
								/>

								<>
									<div className="loyalty-container">
										{/* Other components/elements */}

										<table width={'100%'}>
											{suggestedVouchers.map((item) => (
												<tr key={item.vouchercode}>
													<td style={{ padding: '20px' }}>
														<div className="half-circle-border">
															<span className="half-circle"></span>
															<span className="half-circle"></span>
															<span className="half-circle"></span>
															<span className="half-circle"></span>
															<div className="content-wrapper">
																<div className="content">
																	<div>
																		{(item.vouchertype === 'AMOUNT') ? item.vouchercurrency : ''}
																		{item.vouchervalue}
																		{(item.vouchertype === 'PERCENTAGE') ? '%' : ''}
																	</div>
																	<div style={{ fontSize: '14px' }}>OFF</div>
																</div>
																<div className="voucher-description">
																	<div style={{ fontWeight: 'bold' }}>
																		{(item.vouchertype === 'AMOUNT') ? item.vouchercurrency : ''}
																		{item.vouchervalue}
																		{(item.vouchertype === 'PERCENTAGE') ? '%' : ''} OFF
																	</div>
																	<div style={{ color: '#537EB5', marginTop: '5px', fontSize: '12px', fontWeight: 'bold' }}>
																		Valid until: {item.expirydate}
																	</div>
																</div>
															</div>
														</div>
													</td>
													<td style={{ padding: '20px' }}><div
														style={{
															backgroundColor: item.isactive ? '#2CB672' : '#F2F2F2',
															color: item.isactive ? 'white' : '#8D8D8D',
															padding: '5px 10px',   // Adds padding for better visual appearance
															borderRadius: '4px',   // Optional: for rounded corners
															textAlign: 'center',   // Center the text within the div
															fontWeight: 'bold',    // Make the text bold
														}}
													>
														{item.isactive ? 'Active' : 'Inactive'}
													</div></td>
												</tr>
											))}
										</table>
									</div>
								</>
							</>

						</div>
					</Col>
				</Row>
			</Container>
		</>
	);
}